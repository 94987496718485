import Vue from 'vue';
import { getClientUsers, getAdminUsers, getAdminUserByID, updateAdminUser, deleteAdminUser, deleteClientAdminUser } from '@/api/request/admins';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_ADMIN_USERS: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADMIN_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdminUsers(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let adminUsersSource = resp.data;

              let newAdminUsers = {};

              let adminUsers = adminUsersSource.map((value) => {
                return {
                  userID: value.id,
                  roleID: value.role._id,
                  roleLabel: value.role.label,
                  roleName: value.role.name,
                  firstname: value.firstname || '',
                  lastname: value.lastname || '',
                  fullName: `${value.firstname} ${value.lastname}` || '',
                  email: value.email || '',
                  status: value.isActive === 1 ? 'active' : 'inactive',
                  isDeleted: value.isDeleted,
                  isBlocked: value.isBlocked,
                  isActive: value.isActive,
                  createdAt: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  createdYmd: moment(new Date(value.createdAt)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.createdAt)).format('HH:mm:SS'),
                  actions: value.id,
                };
              });

              adminUsers = adminUsers.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });

              newAdminUsers['all'] = adminUsers.filter((admin) => admin.isDeleted === 0);
              newAdminUsers['active'] = adminUsers.filter((admin) => admin.status === 'active' && admin.isDeleted === 0);
              newAdminUsers['inactive'] = adminUsers.filter((admin) => admin.status === 'inactive' && admin.isDeleted === 0);
              newAdminUsers['deleted'] = adminUsers.filter((admin) => admin.isDeleted === 1);

              commit('SET_ADMIN_USER_SUCCESS', newAdminUsers);
              resolve(newAdminUsers);
            } else {
              commit('GET_ADMIN_USER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADMIN_USER_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CLIENT_USERS: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADMIN_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return getClientUsers(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let businessDataSources = resp.data;

              let newClientUsers = {};

              let clientUsers = businessDataSources.map((value) => {
                return {
                  userID: value._id,
                  roleID: value.roleID,
                  roleLabel: value.roleLabel,
                  firstname: value.firstname || '',
                  lastname: value.lastname || '',
                  phone: value.phone,
                  fullName: `${value.firstname} ${value.lastname}` || '',
                  email: value.email || '',
                  status: value.isActive === 1 ? 'active' : 'inactive',
                  isDeleted: value.isDeleted,
                  isBlocked: value.isBlocked,
                  isActive: value.isActive,
                  createdAt: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  createdYmd: moment(new Date(value.createdAt)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.createdAt)).format('HH:mm:SS'),
                  actions: value._id,
                };
              });

              clientUsers = clientUsers.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });

              newClientUsers['all'] = clientUsers.filter((admin) => admin.isDeleted === 0);
              newClientUsers['active'] = clientUsers.filter((admin) => admin.status === 'active' && admin.isDeleted === 0);
              newClientUsers['inactive'] = clientUsers.filter((admin) => admin.status === 'inactive' && admin.isDeleted === 0);
              newClientUsers['deleted'] = clientUsers.filter((admin) => admin.isDeleted === 1);

              commit('SET_CLIENT_USER_SUCCESS', newClientUsers);
              resolve(newClientUsers);
            } else {
              commit('GET_ADMIN_USER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADMIN_USER_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_ADMIN_USER_BY_ID: ({ commit, dispatch }, adminID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_ADMIN_USER_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdminUserByID(token, adminID)
        .then((resp) => {
          if (resp) {
            if (resp.data.business) {
              let businessDataSources = resp.data;
              businessDataSources.business.city_status = businessDataSources.business.city.show;
              businessDataSources.business.city = businessDataSources.business.city._id;
              commit('SET_ADMIN_USER_BY_ID', businessDataSources);
              resolve(businessDataSources);
            } else {
              commit('GET_ADMIN_USER_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADMIN_USER_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_ADMIN_USER: ({ commit, dispatch }, { userID, userData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADMIN_REQUEST');
    return new Promise((resolve, reject) => {
      return updateAdminUser(token, userID, userData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADMIN_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_ADMIN_USER: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADMIN_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteAdminUser(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADMIN_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_CLIENT_ADMIN_USER: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADMIN_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteClientAdminUser(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADMIN_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
