<template>
  <div class="subscriptionsList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Subscriptions List</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <!-- <router-link :to="`/contents/cities/create`" class="btn btn-aqua opacity-8 float-right">
              <i class="fa fa-plus-circle"></i>
              Add Subscription
            </router-link> -->
          </div>
        </div>
        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-container>
                  <b-row>
                    <b-col class="d-flex">
                      <!-- <div class="mr-3 text-nowrap">User Status</div>
                      <b-form-select v-model="selectedStatus" :options="statuses" name="Filter" :disabled="loading" class="float-right"/> -->
                    </b-col>
                    <b-col> </b-col>
                    <b-col>
                      <!-- <base-input v-model="searchRequest"  :disabled="loading" prepend-icon="fas fa-search" placeholder="Search"></base-input> -->
                    </b-col>
                  </b-row>
                </b-container>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="subscriptionTable"
                  id="subscriptionTable"
                  responsive
                  hover
                  :items="subscriptionsList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                  :filter-included-fields="['name', 'description']"
                  @context-changed="loadInitialPendingVisibleItems">
                  <template v-slot:cell(price)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">{{ data.item.currency }} {{ data.item.price }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(numberOfImages)="data">
                    <div class="data text-center">
                      {{ data.item.numberOfImages }}
                      <!-- <span class="bg-success badge-pill text-white">{{data.item.numberOfImages}}</span> -->
                    </div>
                  </template>
                  <template v-slot:cell(isActive)="data">
                    <div class="data text-center">
                      <span
                        :class="{
                          'bg-success': data.item.hidden === 0,
                          'bg-warning': data.item.hidden === 1,
                          'bg-danger': data.item.hidden === 2,
                        }"
                        class="badge badge-pill text-white"
                        >{{ data.item.hidden === 0 ? 'Active' : 'Hidden' }}</span
                      >
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data" class="data">
                    <div>
                      <router-link
                        :to="`/settings/subscriptions/edit/${data.item.actions}`"
                        v-b-tooltip.hover
                        title="Update Subscription"
                        class="btn btn-outline-primary btn-sm">
                        <span class="btn-inner--icon"><i class="far fa-edit"></i> </span>
                      </router-link>
                      <a
                        @click="toggleStatus(data.item.hidden, data.item.actions)"
                        v-b-tooltip.hover
                        :title="data.item.hidden === 0 ? 'Hide Subscription' : 'Show Subscription'"
                        class="btn btn-outline-vobo btn-sm text-vobo">
                        <span class="btn-inner--icon"><i :class="data.item.hidden === 0 ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></i> </span>
                      </a>
                    </div>
                  </template>
                </b-table>
                <b-row v-if="subscriptionsList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center" />
                <div class="float-right" v-if="totalRows > perPage && subscriptionsList.length !== 0">
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import showConfirmation from '@/utils/mixins/deleteNotify';

Vue.use(Cookies);

export default {
  name: 'subscriptionsList',
  mixins: [showConfirmation],
  data: function () {
    return {
      listCollection: [],
      errorFields: [],
      transactionID: '',
      subscriptionListFilter: [],
      searchRequest: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      allTimeStatus: false,
      selectedFilter: 'all',
      selectedStatus: 'all',
      statuses: [
        { value: 'all', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'deleted', text: 'Deleted' },
      ],
      sortBy: 'dateCreated',
      sortDesc: true,
      ListFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
        },
        {
          key: 'numberOfImages',
          label: 'Number of Images Allowed',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'date_updated',
          label: 'Date Updated',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'actions',
        },
      ],
      updateAccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.subscriptions.loading;
    },
    subscriptionsList: {
      get: function () {
        return this.subscriptionListFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadSubscriptions() {
      this.$store.dispatch('subscriptions/GET_SUBSCRIPTIONS').then((resp) => {
        this.subscriptionListFilter = this.$store.state.subscriptions.subscriptionsList;
        this.totalRows = this.subscriptionListFilter.length;
      });
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    toggleStatus(currentStatus, id) {
      let hidden = 0;
      let statusValue = '';
      const subscriptionID = String(id).trim();
      if (currentStatus === 1) {
        hidden = 0;
        statusValue = 'Hidden';
      } else {
        hidden = 1;
        statusValue = 'Activated';
      }
      const subscriptionData = { hidden };
      this.$store
        .dispatch('subscriptions/UPDATE_SUBSCRIPTION', {
          subscriptionID: subscriptionID,
          subscriptionData,
        })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Subscription successfully ' + statusValue),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadSubscriptions(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error updating subscription status'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.subscriptionListFilter = this.$store.state.subscriptions.subscriptionsList[context];
      this.totalRows = this.subscriptionListFilter.length;
    },
  },
  mounted() {
    this.loadSubscriptions();
  },
};
</script>
<style lang="scss">
.subscriptionTable tr {
  th {
    text-align: center;
  }
  &:focus {
    outline: 0 !important;
    outline: none !important;
  }
}
</style>
