import state from './state';

const mutations = {};

Object.keys(state).forEach((key) => {
  mutations[key] = (state, value) => {
    state[key] = value;
  };
});

export default mutations;
