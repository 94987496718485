const mutations = {
  GET_CONTENT_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  SET_CONTENT_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.content = data;
  },
  GET_CONTENT_FAILED: (state) => {
    state.loading = false;
    state.content = {};
  },
  UPDATE_CONTENT_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  UPDATE_CONTENT_SUCCESS: (state) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  UPDATE_CONTENT_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
};
export default mutations;
