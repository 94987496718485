import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/
const getBusinessList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/business`, null, 'GET', header);
};

const getClientUsers = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/clients`, null, 'GET', header);
};

const getBusinessClientUserByID = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/business/${userID}`, null, 'GET', header);
};

const createAdminUser = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/`, data, 'POST', header);
};

const cancelSubscription = (token, businessID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token } : null;
  return $http(`/api/business/${businessID}/cancel-subscription`, null, 'PUT', header);
};

const updateBusiness = (token, businessID, businessData, query) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token } : null;
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : '';
  console.log(query);
  return $http(`/api/business/${businessID}${query}`, businessData, 'PUT', header);
};

const deleteAdminUser = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}`, '', 'DELETE', header);
};

export { getClientUsers, getBusinessList, getBusinessClientUserByID, cancelSubscription, createAdminUser, updateBusiness, deleteAdminUser };
