const state = {
  loading: false,
  loadedOnce: false,
  userloadedOnce: false,
  success: false,
  error: false,
  errorUser: false,
  roles: [],
  clientRoles: [],
  role: {},
  userList: [],
  selectedUser: {},
};

export default state;
