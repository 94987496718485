import $http from '../utils/http';

export function getCategories(token, query) {
  const header = token ? { authorization: token } : null;
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : '';
  return $http(`/api/categories${query}`, null, 'GET', header);
}

export function createCategory(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/categories`, data, 'POST', header);
}

export function updateCategory(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };
  const id = JSON.parse(data.get('category'))._id;

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/categories/${id}`, data, 'PUT', header);
}

export function updateCategoryOrder(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/categories/update/order`, data, 'PUT', header);
}

export function deleteCategory(token, id) {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories/${id}`, null, 'DELETE', header);
}

export function getPages(token, id) {
  const header = token ? { authorization: token } : null;
  return $http('/api/pages', null, 'GET', header);
}
