<template>
  <base-nav ref="navColor" container-classes="container-fluid" class="bg-default navbar-top navbar-expand default navbar-dark">
    <div class="align-items-left pull-left" :backgroundColor="'darkrs'">
      <a class="navbar-brand" href="#">
        <span><img ref="logo" src="/img/brand/logo.png" class="navbar-brand-img" alt="XB" /></span>
        <span v-if="showVersion" class="development-label">{{ packageVersion }}</span>
      </a>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <base-dropdown titleTag="a" :hasToggle="false" :menuOnRight="true" class="nav-link pr-0">
          <div v-if="!pageLoading" class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="/img/avatars/user-avatar.png" />
            </span>
            <div class="media-body ml-2 d-lg-block navbar-user">
              <span class="mb-0 text-sm font-weight-bold" v-if="firstname && lastname">{{ firstname }} {{ lastname }}</span>
              <span class="mb-0 text-sm font-weight-bold" v-else>{{ fullName }}</span>
              <br />
              <span class="mb-0 text-xs">{{ email }}</span>
            </div>
          </div>
          <div v-if="pageLoading" class="media align-items-center" slot="title">
            <LoadingAccountHeader style="width: 180px" />
            <!--<scale-loader :color="'rgba(255,255,255,0.4)'"></scale-loader>-->
          </div>
          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link v-if="userGroup == 1" to="/user/profile" class="dropdown-item">
              <i class="ni ni-circle-08"></i>
              <span>My profile</span>
            </router-link>
            <!--<router-link to="/profile" class="dropdown-item">
                        <i class="ni ni-settings-gear-65"></i>
                        <span>Settings</span>
                    </router-link>
                    <router-link to="/profile" class="dropdown-item">
                        <i class="ni ni-calendar-grid-58"></i>
                        <span>Activity</span>
                    </router-link>
                    <router-link to="/profile" class="dropdown-item">
                        <i class="ni ni-support-16"></i>
                        <span>Support</span>
                    </router-link>-->
            <div class="dropdown-divider"></div>
            <a @click="logout" class="dropdown-item">
              <i class="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import Vue from 'vue';
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import LoadingAccountHeader from '@/components/ContentLoader/AccountHeader';
import Cookies from 'js-cookie';

Vue.use(Cookies);

export default {
  name: 'DashboardNavbar',
  components: {
    // CollapseTransition,
    // Modal,
    LoadingAccountHeader,
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)',
    },
    showVersion: {
      type: Boolean,
      default: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging',
      description: 'Show app version',
    },
  },
  computed: {
    pageLoading() {
      const loaded = this.$store.state.user.profileLoaded;
      return !loaded ? this.$store.getters['user/loading'] : false;
    },
    fullName() {
      const fullName = this.$store.state.user.fullName;
      return typeof fullName !== 'undefined' ? fullName : '';
    },
    firstname() {
      const firstname = this.$store.state.user.firstname;
      return typeof firstname !== 'undefined' ? firstname : '';
    },
    lastname() {
      const lastname = this.$store.state.user.lastname;
      return typeof lastname !== 'undefined' ? lastname : '';
    },
    email() {
      const email = this.$store.state.user.email;
      return typeof email !== 'undefined' ? email : '';
    },
    username() {
      const username = this.$store.state.user.username;
      return typeof username !== 'undefined' ? username : '';
    },
    userGroup() {
      const userGroup = this.$store.state.user.userGroup;
      return typeof userGroup !== 'undefined' ? userGroup : '';
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      packageVersion: process.env.PACKAGE_VERSION,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      const location = this.companyID || 'login';
      this.$store.dispatch('auth/AUTH_LOGOUT').then(() => {
        this.$router.push(`/${location}`);
      });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scope>
.middleFloat {
  padding-top: 10px;
}
.development-label {
  color: var(--danger);
  font-size: 14px;
  border: 1px solid var(--danger);
  border-radius: 5px;
  padding: 3px 6px;
  margin-left: 15px;
}
</style>
