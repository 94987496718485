<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createAdminContainer">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Subscriptions</h3>
              </div>
            </div>
            <b-row id="processAdminForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-row v-if="loading">
                    <div class="col-lg-12 text-center">
                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                    </div>
                  </b-row>
                  <b-card-body v-else class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <h1 class="form-title mb-5">Subscription Details</h1>
                          <div v-if="subscription != null" class="row px-4">
                            <div class="col-lg-8">
                              <div class="row">
                                <div class="col-lg-12">
                                  <b-form-group disabled role="group" class="mb-4 has-feedback">
                                    <label class="w-100 form-control-label" for="firstname">Subscription Status </label>
                                    <span
                                      :class="{
                                        'bg-success': subscription.status === 'active',
                                        'bg-blue': data.item.subscription === 'trialing',
                                        'bg-canceling': subscription.status === 'canceling',
                                        'bg-canceled': subscription.status === 'canceled',
                                        'bg-danger': subscription.status === 'suspended',
                                      }"
                                      class="badge badge-pill text-white"
                                      >{{ subscription.status }}</span
                                    >
                                  </b-form-group>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-4">
                                  <b-form-group disabled role="group" class="mb-1 has-feedback custom-disabled-input">
                                    <label class="w-100 form-control-label" for="firstname">Customer </label>
                                    <input type="text" name="name" v-model.trim="subscription.customer" class="form-control no-instruction" />
                                  </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                  <b-form-group disabled role="group" class="mb-1 has-feedback custom-disabled-input">
                                    <label class="w-100 form-control-label" for="firstname">Subscription </label>
                                    <input type="text" name="name" v-model.trim="subscription.subscription" class="form-control no-instruction" />
                                  </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                  <b-form-group disabled role="group" class="has-feedback custom-disabled-input">
                                    <label class="w-100 form-control-label" for="firstname">Subscription Expiry Date </label>
                                    <input type="text" name="name" v-model.trim="subscription.expirationDate" class="form-control no-instruction" />
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-4">
                                  <label class="w-100 form-control-label" for="firstname">Amount </label>
                                  <b-input-group role="group" prepend="$" class="mb-1 has-feedback custom-disabled-input">
                                    <b-form-input disabled class="form-control no-instruction" v-model.trim="subscription.amount"> </b-form-input>
                                  </b-input-group>
                                </div>

                                <div class="col-lg-4">
                                  <b-form-group disabled role="group" class="mb-1 has-feedback custom-disabled-input">
                                    <label class="w-100 form-control-label" for="firstname">Interval </label>
                                    <input type="text" name="name" v-model.trim="subscription.interval" class="form-control no-instruction" />
                                  </b-form-group>
                                </div>

                                <div class="col-lg-4">
                                  <b-form-group disabled role="group" class="mb-1 has-feedback custom-disabled-input">
                                    <label class="w-100 form-control-label" for="firstname">Duration </label>
                                    <input type="text" name="name" v-model.trim="subscription.duration" class="form-control no-instruction" />
                                  </b-form-group>
                                </div>
                              </div>

                              <div class="subcription-action mt-4">
                                <button
                                  v-if="subscription.status == 'canceled' || subscription.status == 'suspended'"
                                  type="button"
                                  id="renewSubscription"
                                  @click="renewProcess()"
                                  class="btn btn-primary">
                                  <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1"></b-icon>
                                  Renew Subscription
                                </button>
                                <button
                                  v-if="subscription.status != 'canceled' && subscription.status != 'canceling' && subscription.status != 'suspended'"
                                  type="button"
                                  id="cancelSubscription"
                                  @click="cancelProcess()"
                                  class="btn btn-outline-danger">
                                  <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1"></b-icon>
                                  Cancel Subscription
                                </button>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <b-row v-if="!cardLoaded">
                                <div class="col-lg-12 text-center">
                                  <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                                </div>
                              </b-row>
                              <div v-else>
                                <div v-if="hasCard" class="card-preview">
                                  <p class="card-label">Card Number</p>
                                  <span class="c-card">
                                    {{ cardNumber }}
                                  </span>
                                  <br /><br />

                                  <p class="card-label">Expiration Date</p>
                                  <div class="d-flex">
                                    <div class="text-center card-col">
                                      <span>Month</span><br />
                                      <span class="c-card-2">{{ cardExpMonth }}</span>
                                    </div>
                                    <div class="text-center card-col">
                                      <span>Year</span><br />
                                      <span class="c-card-2">{{ cardExpYear }}</span>
                                    </div>
                                    <div class="text-center card-col">
                                      <span>CVC</span><br />
                                      <span class="c-card-2">***</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-else class="card-message text-center pt-3 pb-4">
                                  <img src="../../assets/images/card.png" width="100%" alt="" />
                                  <!-- <h2>You have no card.</h2> -->
                                  <h2>{{ getCardErrorEdited }}</h2>
                                </div>
                                <div class="card-action">
                                  <button type="button" id="addCard" @click="openCardFormModal(hasCard)" class="btn btn-sm btn-primary">
                                    <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1"></b-icon>
                                    {{ hasCard ? 'Update Card' : 'Add Card' }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>

    <!-- Add/Update Card -->
    <b-modal v-model="openCardForm" size="lg" hide-header-close no-close-on-esc no-close-on-backdrop centered>
      <template v-slot:modal-title>
        <span style="color: #000; text-align: center">{{ hasCard ? 'Update' : 'Add' }} Card Information</span>
      </template>
      <b-row v-if="subscriptionLoading">
        <div class="col-lg-12 text-center">
          <pulse-loader color="var(--pulse-loader)"></pulse-loader>
        </div>
      </b-row>
      <div v-else class="modalData">
        <b-form-group role="group" class="has-feedback">
          <label class="w-100 form-control-label" for="website">Cardholder Name</label>
          <b-form-input
            name="cardHolder"
            v-model.trim="billing.card.fullName"
            data-vv-as="cardHolder"
            data-vv-name="cardHolder"
            v-validate="'required'"
            autocomplete="NoAutocomplete"
            size="sm"
            class="form-control card no-instruction"
            :class="{
              input: true,
              'is-invalid': errors.has('cardHolder'),
              'has-invalid': errors.has('cardHolder'),
            }">
          </b-form-input>
          <span class="has-icon-feedback"></span>
          <div class="invalid-feedback" :is-invalid="errors.has('cardHolder')">
            {{ errors.first('cardHolder') }}
          </div>
        </b-form-group>

        <div class="form-group">
          <label for="card-element" class="form-control-label">Card Information</label>
          <div class="has-label">
            <div v-if="openCardForm" id="card-element" class="form-control card"></div>
            <div v-if="hasCardError" class="card-error-msg">
              {{ cardError }}
            </div>
          </div>
          <div class="invalid-feedback" style="display: block" v-if="cardInput && cardInput.error">
            {{ cardInput.error.message }}
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <b-form-group role="group" class="mb-3 has-feedback">
              <label class="w-100 form-control-label" for="address">Address</label>
              <b-form-input
                name="address"
                v-model.trim="billing.address.line1"
                data-vv-as="address address"
                v-validate="'required'"
                size="sm"
                data-vv-name="address"
                placeholder="Address"
                autocomplete="NoAutocomplete"
                class="form-control card no-instruction"
                :class="{
                  input: true,
                  'is-invalid': errors.has('address'),
                  'has-invalid': errors.has('line1'),
                }">
              </b-form-input>
              <span class="has-icon-feedback"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('address')">
                {{ errors.first('address') }}
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <b-form-group role="group" class="mb-3 has-feedback">
              <label class="w-100 form-control-label" for="billingCity">City</label>
              <b-form-input
                type="text"
                name="billingCity"
                v-model.trim="billing.address.city"
                size="sm"
                data-vv-as="City"
                v-validate="'required'"
                data-vv-name="billingCity"
                autocomplete="NoAutocomplete"
                class="form-control card no-instruction"
                :class="{
                  input: true,
                  'is-invalid': errors.has('billingCity'),
                  'has-invalid': errors.has('billingCity'),
                }">
              </b-form-input>
              <span class="has-icon-feedback"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('billingCity')">
                {{ errors.first('billingCity') }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-5">
            <base-input label="State" rules="required">
              <b-form-select
                name="state"
                v-model="billing.address.state"
                :options="stateOptions"
                data-vv-as="state"
                size="sm"
                v-validate="'required'"
                data-vv-name="state"
                placeholder="Enter Your state"
                autocomplete="NoAutocomplete"
                class="form-select card"
                :class="{
                  select: true,
                  'is-invalid': errors.has('state'),
                  'has-invalid': errors.has('state'),
                }">
              </b-form-select>
              <span class="has-icon-feedback state-error-icon"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('state')">
                {{ errors.first('state') }}
              </div>
            </base-input>
          </div>
          <!-- <div class="col-lg-3">
          <b-form-group role="group" class="mb-3 has-feedback">
              <label class="w-100 form-control-label" for="website">Zipcode</label>
              <b-form-input type="text"
                    name="Zipcode"
                    v-model.trim="billing.address.postal_code"
                    size="sm"
                    data-vv-as="Zipcode"
                    v-validate="'required'"
                    data-vv-name="Zipcode"
                    autocomplete="NoAutocomplete"
                    class="form-control card no-instruction"
                    :class="{'input': true, 'is-invalid': errors.has('Zipcode'),'has-invalid': errors.has('Zipcode')}"
              >
              </b-form-input>
              <span class="has-icon-feedback mb-3"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('Zipcode')">
                {{errors.first('Zipcode')}}
              </div>
          </b-form-group>
          </div> -->
        </div>
      </div>
      <template v-slot:modal-footer>
        <div style="flex: 1"></div>
        <b-button id="cardFormAction" type="flat" variant="primary" size="sm" @click="addCard()">
          <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1"></b-icon>
          {{ hasCard ? 'Update' : 'Add' }}
        </b-button>
        <b-button type="flat" variant="medium" size="sm" @click="closeModal()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import { decoder } from '@/utils/sessions/jwt';
import { loadStripeCheckout } from '../../utils/loadStripe';
import config from 'config';
import moment from 'moment';
import showConfirmation from '@/utils/mixins/customNotify';

Vue.use(Cookies);

export default {
  mixins: [showConfirmation],
  data() {
    return {
      loaded: false,
      cardLoaded: false,
      stripe: undefined,
      card: undefined,
      cardInput: undefined,
      currentCard: undefined,
      billing: {
        card: {
          fullName: undefined,
          cardNumber: undefined,
          expiration: undefined,
          cv: undefined,
          postal_code: undefined,
          city: undefined,
          state: undefined,
        },
        address: {
          line1: undefined,
          line2: undefined,
          city: undefined,
          state: null,
          postal_code: undefined,
        },
      },
      hasCardError: false,
      cardError: '',
      currentUser: {
        _id: '',
        email: '',
        phone: '',
      },
      getCardError: undefined,
      // subscription: {
      //   customer: null,
      //   subscription: null,
      //   amount:null,
      //   interval:null,
      //   duration:null,
      //   status:null,
      //   expirationDate:null,
      // },
      subscription: null,
      hasCard: false,
      openCardForm: false,
      stateOptions: [
        { value: null, text: 'SELECT FROM BELOW', disabled: true },
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District Of Columbia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
      ],
    };
  },
  computed: {
    cardNumber() {
      return `**** **** **** ${this.currentCard.last4}`;
    },
    cardExpMonth() {
      return this.currentCard.expiration.month < 10 ? `0${this.currentCard.expiration.month}` : this.currentCard.expiration.month;
    },
    cardExpYear() {
      return this.currentCard.expiration.year;
    },
    userCard() {
      return this.$store.state.subscriptions.card;
    },
    subscriptionLoading() {
      return this.$store.state.subscriptions.loading;
    },
    getCardErrorEdited() {
      let message = this.getCardError ? this.getCardError.replace('User', 'You') : '';
      return message;
    },
    loading() {
      return this.$store.state.users.loading;
    },
  },
  methods: {
    renewProcess() {
      this.showConfirmation(
        this.renewSubscription,
        null,
        'You are renewing your subscription to XB Starter Kit, 30 USD for 30 days. Are you sure you want to proceed ? Please confirm.'
      );
    },
    cancelProcess() {
      this.showConfirmation(this.cancelSubscription, null, 'Are you sure you want to cancel your subscription ? Please confirm.');
    },
    getUserSubscription() {
      this.$store.dispatch('users/GET_USER_BY_ID', this.currentUser._id).then((user) => {
        const userSubscription = this.$store.state.users.userSubscription;
        this.hasCard = userSubscription.billing.paymentMethodId ? true : false;
        this.currentUser.email = userSubscription.email;
        this.currentUser.phone = userSubscription.phone;
        this.subscription = {
          customer: userSubscription.billing.name,
          subscription: userSubscription.billing.subscription.nickname,
          amount: userSubscription.billing.subscription.amount,
          interval: userSubscription.billing.subscription.interval,
          duration: userSubscription.billing.subscription.interval_count,
          status: userSubscription.billing.subscription.status,
          expirationDate: moment(userSubscription.billing.subscription.nextBilling).format('LL'),
        };

        this.billing.card.fullName = userSubscription.billing.name || '';
        this.billing.address.line1 = userSubscription.billing.address ? userSubscription.billing.address.line1 : '';
        this.billing.address.city = userSubscription.billing.address ? userSubscription.billing.address.city : '';
        this.billing.address.state = userSubscription.billing.address ? userSubscription.billing.address.state : '';
        this.billing.address.postal_code = userSubscription.billing.address ? userSubscription.billing.address.postal_code : '';

        console.log(12312123);
        this.getCard(this.currentUser._id);
      });
    },
    addCard() {
      const promiseBtn = document.getElementById('cardFormAction');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      if (this.cardInput == undefined) {
        this.hasCardError = true;
        this.cardError = 'Please input card number';
        btnLoader.classList.add('hidden');
        promiseBtn.removeAttribute('disabled', 'disabled');
      }
      return this.$validator.validateAll().then(async (isValid) => {
        if (isValid) {
          const { error, token } = await this.stripe.createToken(this.card, {
            name: this.billing.card.fullName,
            address_line1: this.billing.address.line1,
            address_city: this.billing.address.city,
            address_state: this.billing.address.state,
            address_postal_code: this.billing.address.postal_code,
          });

          if (error) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.message),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            btnLoader.classList.add('hidden');
            promiseBtn.removeAttribute('disabled', 'disabled');
          }

          const cardData = {
            token: token.id,
            name: this.billing.card.fullName,
            address: {
              line1: this.billing.address.line1,
              city: this.billing.address.city,
              state: this.billing.address.state,
              postal_code: this.billing.address.postal_code,
            },
          };
          this.$store
            .dispatch('subscriptions/UPDATE_CARD', {
              userId: this.currentUser._id,
              cardData,
            })
            .then(async (res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Card Successfully Added!'),
                  timeout: 5000,
                  icon: 'fas fa-check',
                  type: 'success',
                });

                this.openCardForm = false;
                this.hasCard = true;
                location.reload();
              } else {
                console.log(res.data.error);
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error adding card.'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }
              btnLoader.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
            })
            .catch((err) => {
              this.openCardForm = false;
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                btnLoader.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
              });
            });
        } else {
          btnLoader.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
        }
      });
    },
    getCard(userId) {
      this.$store
        .dispatch('subscriptions/GET_CARD', userId)
        .then((cardData) => {
          const card = cardData.data;
          this.currentCard = card;
          // this.billing.card.fullName = card.name
          // this.billing.address.line1 = card.address.line1
          // this.billing.address.city = card.address.city
          // this.billing.address.state = card.address.state
          // this.billing.address.postal_code = card.address.postal_code
          this.cardLoaded = true;
        })
        .catch((error) => {
          this.cardLoaded = true;
          error.then((e) => {
            console.log(e);
            this.getCardError = e.errMessage;
          });
        });
    },
    renewSubscription() {
      const promiseBtn = document.getElementById('renewSubscription');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      if (!this.hasCard) {
        this.$notify({
          message: this.$options.filters.capitalizeWords('Please update or add your card first.'),
          timeout: 4000,
          icon: 'fas fa-exclamation-triangle',
          type: 'warning',
        });
        btnLoader.classList.add('hidden');
        promiseBtn.removeAttribute('disabled', 'disabled');
        return;
      }

      this.$store
        .dispatch('subscriptions/RENEW_SUBSCRIPTION', this.currentUser._id)
        .then(async (res) => {
          if (res.data.success) {
            this.subscription = {
              customer: null,
              subscription: null,
              amount: null,
              interval: null,
              duration: null,
              status: null,
              expirationDate: null,
            };
            this.$notify({
              message: this.$options.filters.capitalizeWords('Subscription Successfully Renewed!'),
              timeout: 5000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.getUserSubscription();
          } else {
            console.log(res.data.error);
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error renewing subcription.'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }

          btnLoader.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        })
        .catch((err) => {
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            btnLoader.classList.add('hidden');
            promiseBtn.removeAttribute('disabled', 'disabled');
            bodyWrapper.classList.remove('non-clickable');
          });
        });
    },
    cancelSubscription() {
      const promiseBtn = document.getElementById('cancelSubscription');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');
      this.$store
        .dispatch('subscriptions/CANCEL_SUBSCRIPTION', this.currentUser._id)
        .then(async (res) => {
          if (res.data.success) {
            this.subscription = {
              customer: null,
              subscription: null,
              amount: null,
              interval: null,
              duration: null,
              status: null,
              expirationDate: null,
            };
            this.$notify({
              message: this.$options.filters.capitalizeWords('Subscription Canceled!'),
              timeout: 5000,
              icon: 'fas fa-check',
              type: 'success',
            });
            await this.getUserSubscription();
            // location.reload()
          } else {
            console.log(res.data.error);
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error canceling subcription.'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }

          btnLoader.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        })
        .catch((err) => {
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            btnLoader.classList.add('hidden');
            promiseBtn.removeAttribute('disabled', 'disabled');
            bodyWrapper.classList.remove('non-clickable');
          });
        });
    },
    openCardFormModal() {
      this.openCardForm = true;

      if (this.openCardForm == true) {
        const stripePk =
          process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'staging'
            ? `${process.env.VUE_APP_STRIPE_PK_TEST_KEY}`
            : `${process.env.VUE_APP_STRIPE_PK_LIVE_KEY}`;
        // const stripePk = process.env.VUE_APP_STRIPE_PK_LIVE_KEY
        const style = {
          base: {
            color: '#8898aa',
            fontFamily: '"Avenir Book", sans-serif',
            fontSize: '15px',
          },
        };
        const initializeStripe = () => {
          this.stripe = Stripe(stripePk);
          this.card = this.stripe.elements().create('card', { style });
          setTimeout(() => this.card.mount('#card-element'), 500);
          this.card.on('change', (card) => {
            this.hasCardError = false;
            this.cardInput = card;
          });
        };
        !window.Stripe ? loadStripeCheckout(stripePk, 'v3', initializeStripe) : initializeStripe();
      }
    },
    closeModal() {
      this.openCardForm = false;
      //   this.billing = {
      //   card: {
      //     fullName: undefined,
      //     cardNumber: undefined,
      //     expiration: undefined,
      //     cv: undefined,
      //     postal_code: undefined
      //   },
      //   address: {
      //     address: [null],
      //     city: undefined,
      //     state: null,
      //     postal_code: undefined
      //   },
      //   paymentMethod: undefined
      // }
    },
  },
  async mounted() {
    const token = Cookies.get('user-token');
    const decode = decoder({
      secret: config.secret,
    });
    var payload = decode(token);
    this.currentUser._id = payload._id;
    await this.getUserSubscription();
  },
};
</script>
<style lang="scss" scoped>
.createAdminContainer select {
  color: #525f7f !important;
}
.progress {
  height: 14px !important;
}
.form-title {
  text-align: center;
  text-transform: uppercase;
}

.preview-image {
  h3 {
    display: block;
    margin: auto;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .cover {
    width: 300px;
    margin: auto;
    img {
      width: 100%;
    }
  }
  .images {
    margin: auto;
    img {
      width: 100%;
      height: auto;
      // opacity: 0.4;
    }
  }
}
.form-control {
  color: #525f7f !important;
}

.img-container {
  height: auto;
  margin-bottom: 12px;

  .action {
    display: flex;
    justify-content: center;
    .action-icon {
      margin: 0 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .select-icon {
      color: #0cb799;
    }
    .selected-icon {
      color: var(--primary);
    }
    .delete-icon {
      color: rgba(255, 0, 0, 0.733);
    }
    .add-icon {
      color: #53afff;
      text-align: center;
      border: 1px solid #53afff;
      padding: 5px 10px;
      margin-top: 25px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: #53afff;
        color: white;
      }
      p {
        font-size: 15px;
        font-weight: 900;
      }
    }
  }

  .current-img {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    border: 3px solid var(--primary);
    // &:after {
    //   background-image: url('../../assets/images/checked.png');
    //   background-size: 20px 20px;
    //   background-repeat: no-repeat;
    //   width: 20px;
    //   height: 20px;
    //   position: absolute;
    //   top: 10px;
    //   right: 10px;
    //   content: '';
    // }

    img {
      opacity: 1;
      display: block;
      margin: auto;
    }
  }
}

.new-image-container {
  h3 {
    text-align: center;
  }
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}
.form-control.card {
  border: none;
  background-color: #e7e7e7;
  padding: 15px;
  margin-bottom: 12px;
}
.form-select.card {
  border: none;
  background-color: #e7e7e7;
  padding: 5px;
  margin-bottom: 12px;
}
.form-group {
  margin-bottom: 0;
}
.btn-remove-address {
  padding: 6px;
}
.state-error-icon {
  margin: 0 27px 60px 0;
}
.card-error-msg {
  font-size: 80%;
  color: #f4516c;
  margin-bottom: 10px;
}
.custom-disabled-input {
  input {
    border: none !important;
  }
  .input-group-prepend {
    .input-group-text {
      border: none;
      background: #e2e2e2 !important;
    }
  }
}
.card-preview {
  padding: 20px;
  border: 1px solid #526483;
  border-radius: 10px;
  margin: auto;
  width: fit-content;
}
p.card-label {
  font-weight: bold;
  text-transform: uppercase;
  color: #526483;
}
.card-col {
  margin: auto;
}
#addCard {
  display: block;
  margin: 10px auto;
}
.card-message {
  img {
    width: 100px;
  }
}
span.c-card {
  padding: 10px 20px;
  background: #363636;
  border-radius: 5px;
  color: white;
}
span.c-card-2 {
  background: #363636;
  padding: 2px 14px;
  border-radius: 5px;
  color: white;
}
</style>
