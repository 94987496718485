<template>
  <div class="adminList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Settings</h3>
          </div>
        </div>
        <div class="card-body settingsBody row">
          <b-row class="pt-5 no-gutters w-100">
            <div class="col-lg-12 col-md-12 col-12 no-gutters">
              <b-card no-body class="m-0 pb-5">
                <b-row class="m-0 p-0">
                  <div class="card-body settingContainer p-0 m-0">
                    <div class="col-lg-12 col-md-12 col-12">
                      <b-row>
                        <a class="setting w-100" v-b-toggle.websiteLinks visible>
                          <div class="col-lg-12">
                            <h6 class="heading-small m-0"><i class="fas fa-external-link-alt text-vobo text-icon"></i> Website Links and Emails</h6>
                          </div>
                        </a>
                        <b-collapse id="websiteLinks" visible class="mt-2 collapse active">
                          <b-card class="m-0 p-0">
                            <div class="row px-4">
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.manageAccount">Manage Account URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.manageAccount"
                                    v-model.trim="websiteLinks.manageAccount"
                                    data-vv-as="manageAccount"
                                    data-vv-name="websiteLinks.manageAccount"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.manageAccount'),
                                      'has-invalid': errors.has('websiteLinks.manageAccount'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.manageAccount')">
                                    {{ errors.first('websiteLinks.manageAccount') }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.addProfile">Add Profile URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.addProfile"
                                    v-model.trim="websiteLinks.addProfile"
                                    data-vv-as="addProfile"
                                    data-vv-name="websiteLinks.addProfile"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.addProfile'),
                                      'has-invalid': errors.has('websiteLinks.addProfile'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.addProfile')">
                                    {{ errors.first('websiteLinks.addProfile') }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="row px-4">
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.termsOfUse">Terms and Conditions Direct PDF Download URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.termsOfUse"
                                    v-model.trim="websiteLinks.termsOfUse"
                                    data-vv-as="termsOfUse"
                                    data-vv-name="websiteLinks.termsOfUse"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.termsOfUse'),
                                      'has-invalid': errors.has('websiteLinks.termsOfUse'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.termsOfUse')">
                                    {{ errors.first('websiteLinks.termsOfUse') }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.privacyPolicy">Privacy Policy URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.privacyPolicy"
                                    v-model.trim="websiteLinks.privacyPolicy"
                                    data-vv-as="privacyPolicy"
                                    data-vv-name="websiteLinks.privacyPolicy"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.privacyPolicy'),
                                      'has-invalid': errors.has('websiteLinks.privacyPolicy'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.privacyPolicy')">
                                    {{ errors.first('websiteLinks.privacyPolicy') }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="row px-4">
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.about">About URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.about"
                                    v-model.trim="websiteLinks.about"
                                    data-vv-as="about"
                                    data-vv-name="websiteLinks.about"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{ input: true, 'is-invalid': errors.has('websiteLinks.about'), 'has-invalid': errors.has('websiteLinks.about') }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.about')">
                                    {{ errors.first('websiteLinks.about') }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.termsAndConditions">Sweepstakes Terms & Conditions URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.termsAndConditions"
                                    v-model.trim="websiteLinks.termsAndConditions"
                                    data-vv-as="termsAndConditions"
                                    data-vv-name="websiteLinks.termsAndConditions"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.termsAndConditions'),
                                      'has-invalid': errors.has('websiteLinks.termsAndConditions'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.termsAndConditions')">
                                    {{ errors.first('websiteLinks.termsAndConditions') }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="row px-4">
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.register">Registration URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.register"
                                    v-model.trim="websiteLinks.register"
                                    data-vv-as="register"
                                    data-vv-name="websiteLinks.register"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.register'),
                                      'has-invalid': errors.has('websiteLinks.register'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.register')">
                                    {{ errors.first('websiteLinks.register') }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.feedback">Email </label>
                                  <input
                                    type="text"
                                    name="websiteLinks.feedback"
                                    v-model.trim="websiteLinks.feedback"
                                    v-validate="'required|email'"
                                    data-vv-name="websiteLinks.feedback"
                                    data-vv-delay="1000"
                                    data-vv-scope="websiteLinks"
                                    placeholder="Enter email address for deedbacks"
                                    autocomplete="noAutoComplete"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.feedback'),
                                      'has-invalid': errors.has('websiteLinks.feedback'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.feedback')">
                                    {{ errors.first('websiteLinks.feedback') }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="row px-4">
                              <div class="col-lg-6 col-md-6 col-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="websiteLinks.menuTerms">Terms and Conditions URL</label>
                                  <input
                                    type="text"
                                    name="websiteLinks.menuTerms"
                                    v-model.trim="websiteLinks.menuTerms"
                                    data-vv-as="menuTerms"
                                    data-vv-name="websiteLinks.menuTerms"
                                    data-vv-scope="websiteLinks"
                                    v-validate="'url: {require_protocol: true }'"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('websiteLinks.menuTerms'),
                                      'has-invalid': errors.has('websiteLinks.menuTerms'),
                                    }"
                                    placeholder="Please use http: or https://" />
                                  <div class="invalid-feedback" :is-invalid="errors.has('websiteLinks.menuTerms')">
                                    {{ errors.first('websiteLinks.menuTerms') }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6 col-md-6 col-6">
                                <!--  -->
                              </div>
                            </div>
                            <div class="row px-4">
                              <div class="col-lg-12 col-md-12 col-12">
                                <button
                                  type="button"
                                  id="websiteLinksButton"
                                  :disabled="websiteLinksLoading"
                                  v-promise-btn
                                  @click="processWebsiteLinks"
                                  class="btn btn-aqua float-right w-sm-100 mb-3 mb-md-0">
                                  SAVE
                                </button>
                              </div>
                            </div>
                          </b-card>
                        </b-collapse>
                      </b-row>
                    </div>
                  </div>
                </b-row>
              </b-card>
            </div>
          </b-row>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import moment from 'moment';
import showConfirmation from '@/utils/mixins/deleteNotify';

Vue.use(Cookies);

const defaultProps = {
  hex: '#B71C1C',
  rgba: {
    r: 25,
    g: 77,
    b: 51,
    a: 1,
  },
};

export default {
  name: 'Settings',
  mixins: [showConfirmation],
  data: function () {
    return {
      websiteLinks: {
        manageAccount: '',
        addProfile: '',
        termsAndConditions: '',
        termsOfUse: '',
        privacyPolicy: '',
        about: '',
        feedback: '',
        register: '',
        menuTerms: '',
      },
      searchRequest: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      pageOptions: [5, 10],
      sortBy: 'dateCreated',
      sortDesc: true,
    };
  },
  computed: {
    websiteLinksLoading: {
      get: function () {
        return this.$store.state.settings.websiteLinksLoading;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadProfile() {
      this.$store.dispatch('user/USER_PROFILE_REQUEST');
    },
    loadSettings() {
      this.$store
        .dispatch('settings/GET_SETTINGS')
        .then((resp) => {
          if (resp.length > 0) {
            const websiteLinksSettings = resp.filter((setting) => {
              return setting.name === 'website-links';
            });

            if (websiteLinksSettings.length > 0) {
              const websiteLinks = Object.assign({}, websiteLinksSettings[0]);
              this.websiteLinks = websiteLinks.setting;
            }
          } else {
            this.websiteLinks = {
              manageAccount: '',
              addProfile: '',
              termsAndConditions: '',
              termsOfUse: '',
              privacyPolicy: '',
              about: '',
              feedback: '',
              register: '',
              menuTerms: '',
            };
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    processWebsiteLinks() {
      return this.$validator.validateAll('websiteLinks').then((isValid) => {
        if (isValid) {
          const settingsData = {
            setting: {
              manageAccount: this.websiteLinks.manageAccount,
              addProfile: this.websiteLinks.addProfile,
              termsAndConditions: this.websiteLinks.termsAndConditions,
              termsOfUse: this.websiteLinks.termsOfUse,
              privacyPolicy: this.websiteLinks.privacyPolicy,
              about: this.websiteLinks.about,
              feedback: this.websiteLinks.feedback,
              register: this.websiteLinks.register,
              menuTerms: this.websiteLinks.menuTerms,
            },
            name: 'website-links',
          };

          const settingName = 'website-links';

          this.$store
            .dispatch('settings/CREATE_SETTINGS', { settingName, settingsData })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Website Links URLS successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error updating Website Links URLS'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });
            });
        } else {
          const saveButton = document.getElementById('websiteLinksButton');
          const promiseButton = saveButton.querySelector('.promise-btn__spinner-wrapper');
          promiseButton.classList.add('hidden');
          this.backgroundLoading = false;
        }
      });
    },
  },
  mounted() {
    this.loadProfile();
    this.loadSettings();
  },
};
</script>
<style lang="scss">
.settingsBody {
  margin: 0;
  padding: 0 50px !important;
}
.text-icon {
  font-size: 18px !important;
  margin-right: 5px;
}
.settingContainer {
  padding: 0 !important;
  .vc-slider {
    width: 323px !important;
  }
}
.setting,
.settingContainer .collapse {
  border: 1px solid #e5e5e5;
  padding: 8px 0 !important;
  width: 100%;
}
.setting {
  margin-top: 2px;
  background: #eff1f7;
}
.setting.collapsed {
  background: #ffffff !important;
}
.settingContainer .collapse {
  margin-top: 0px !important;
}
textarea.form-control.input {
  color: #525f7f !important;
}
.accessCode {
  width: 100px !important;
  height: 2.05rem !important;
  .custom-toggle-slider:before {
    height: 27px;
    width: 27px;
  }
  .custom-toggle-slider:after {
    line-height: 31px;
  }
}
.accessCode.custom-toggle input:checked + .custom-toggle-slider:before {
  transform: translateX(67px);
}
</style>
