<template>
  <div
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
    :data="backgroundColor">
    <div v-if="false" class="sidenav-header d-flex align-items-center bg-vobo">
      <slot name="logo"></slot>
      <div class="ml-auto">
        <!-- Sidenav toggler -->
        <!--<div class="sidenav-toggler d-none d-xl-block text-white"
              :class="{'active': !$sidebar.isMinimized }"
              @click="minimizeSidebar">
           <div class="sidenav-toggler-inner">
             <i class="sidenav-toggler-line"></i>
             <i class="sidenav-toggler-line"></i>
             <i class="sidenav-toggler-line"></i>
           </div>
         </div>-->
      </div>
    </div>
    <slot name="dropdown-list"></slot>
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
              <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink"> </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
Vue.use(Cookies);
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Vobo',
      description: 'Sidebar title',
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: (value) => {
        let acceptedValues = ['', 'vue', 'blue', 'green', 'orange', 'red', 'primary', 'darkrs'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar background color (vue|blue|green|orange|red|primary)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
.sidenav.navbar.navbar-vertical {
  margin-top: 69px;
}
</style>
