<template>
  <div>
    <!-- Header -->
    <div class="header bg-default py-lg-5 py-1 pt-lg-4 pt-6">
      <div class="container text-center">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <img src="/img/assets/logo.png" class="company-logo" />
              <h1 class="text-white pt-2">Login</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="login container container mt-lg--8 mt--6">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0 p-3">
            <div class="card-body">
              <!--<p v-show="hasError" :class="{'is-invalid':hasError}" class="form-feedback">{{error | capitalizeWords}}</p>-->
              <form role="form" v-promise-btn="{ action: 'submit' }" @submit.prevent="login">
                <base-input
                  id="username"
                  name="username"
                  class="mb-3 text-gray fixed-ic-w"
                  prepend-icon="fas fa-envelope"
                  type="text"
                  v-model.trim="account['username']"
                  placeholder="Enter your email or username"
                  v-validate="'required'"
                  :error="getError('username')"
                  :class="{ input: true, 'is-invalid': errors.has('username') }">
                </base-input>

                <base-input
                  id="password"
                  name="password"
                  class="mb-3 text-gray fixed-ic-w"
                  prepend-icon="fa fa-lock"
                  type="password"
                  v-model.trim="account['password']"
                  placeholder="Enter your password"
                  v-validate="'required'"
                  :error="getError('password')"
                  data-vv-delay="1000"
                  :class="{ input: true, 'is-invalid': errors.has('password') }">
                </base-input>
                <div class="row mt-3">
                  <div class="col-6">
                    <router-link to="/reset-password/user" class="text-primary"><small>Forgot password?</small></router-link
                    ><br /><br />
                    <!-- <router-link to="/register-page" class="text-torquioise"><small>Register Now!</small></router-link> -->
                  </div>
                  <div class="col-6 text-center mt-4">
                    <button type="submit" name="submit" class="btn primary btn-submit btn-primary padded" size="vobo">Sign in</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5" id="footer-main">
      <div class="row align-items-center justify-content-xl-between">
        <div class="col-xl-12">
          <div class="copyright text-center text-muted">
            <small> &copy; {{ year }} XB LLC . All Rights Reserved.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      account: {
        username: '',
        password: '',
      },
      error: '',
      hasError: false,
      year: new Date().getFullYear(),
    };
  },
  methods: {
    getError(name) {
      return this.errors.first(name);
    },
    login: function () {
      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let promiseButton = document.querySelector('.promise-btn__spinner-wrapper');
          promiseButton.classList.remove('hidden');
          const { username, password } = this.account;
          return this.$store
            .dispatch('auth/AUTH_REQUEST', { username, password })
            .then((res) => {
              if (this.$store.getters['auth/isAuthenticated']) {
                this.$router.push('/');
              }
              promiseButton.classList.add('hidden');
            })
            .catch((err) => {
              if (this.$store.state.auth.hasOwnProperty('userEmail') && this.$store.state.auth.userEmail !== '') {
                this.$router.push({ path: '/verify' });
              } else {
                promiseButton.classList.add('hidden');
                err.then((error) => {
                  this.notifyVue('danger', this.$options.filters.capitalizeWords(error.errMessage));
                  this.error = error.errMessage;
                  this.hasError = true;
                });
              }
            });
        }
      });
    },
    notifyVue(type = 'default', message) {
      this.$notify({
        message: message,
        timeout: 4000,
        icon: 'fas fa-exclamation-triangle',
        type,
      });
    },
  },
  watch: {
    account: {
      handler: function (after, before) {
        this.hasError = false;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.btn-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1824px) {
  .login.container,
  .forgot-password.container {
    max-width: 1124px !important;
  }
}
.company-logo {
  width: 100%;
  max-width: 440px;
}
.fixed-ic-w {
  i {
    min-width: 0.875rem;
  }
}
</style>
