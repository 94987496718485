import Vue from 'vue';
import Cookies from 'js-cookie';
import { getCities, addCity, getCitiesByID, updateCity, updateCityStatus, deleteCity } from '@/api/request/cities';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  ADD_CITY: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);
    try {
      let data = await addCity(token, formData);
      return data;
    } catch (error) {
      const { success, message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  GET_CITY_BY_ID: async ({ commit, dispatch }, cityID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);
    try {
      let {
        data: { success, data, message },
      } = await getCitiesByID(token, cityID);
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  UPDATE_CITY: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);
    try {
      let data = await updateCity(token, formData);
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  UPDATE_CITY_STATUS: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);
    try {
      let data = await updateCityStatus(token, formData);
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  GET_CITIES: async ({ commit, dispatch }, query) => {
    // const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true);
    try {
      let {
        data: { success, data, message },
      } = await getCities(query);

      let cityList = data.map((value) => {
        return {
          _id: value._id,
          actions: value._id,
          city: value.city,
          show: value.show,
          createdAt: value.timestamp.created,
          createdAt: moment(new Date(value.timestamp.created)).format('YYYY-MM-DD HH:mm:SS'),
        };
      });
      commit('setCityList', cityList);
      commit('loading', false);
      return cityList;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  DELETE_CITY: async ({ commit, dispatch }, id) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);

    try {
      let data = await deleteCity(token, id);
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
};

export default actions;
