<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-6 col-md-6" v-if="currentUserGroup !== 2">
          <stats-card
            title="Paid Subscriptions"
            v-if="dashboardItems.paidSubscriptions"
            type="primary"
            subTitleType="number"
            :sub-title="dashboardItems.paidSubscriptions.toString()"
            icon="fas fa-address-card">
          </stats-card>
          <content-loader v-else :height="125" :width="339" :speed="1" primaryColor="#f3f3f3" secondaryColor="#dce2fc" style="margin-bottom: 30px">
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div>

        <div class="col-xl-6 col-md-6">
          <stats-card
            title="All Subscriptions"
            v-if="dashboardItems.clients"
            type="gray"
            subTitleType="number"
            :sub-title="dashboardItems.clients.toString()"
            icon="far fa-address-card">
          </stats-card>
          <content-loader v-else :height="125" :width="339" :speed="1" primaryColor="#f3f3f3" secondaryColor="#dce2fc" style="margin-bottom: 30px">
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div>

        <!-- <div class="col-xl-6 col-md-6" v-if="currentUserGroup !== 2">
          <stats-card
            title="Trial Subscriptions"
            v-if="dashboardItems.trialSubscriptions"
            type="primary"
            subTitleType="number"
            :sub-title="dashboardItems.trialSubscriptions.toString()"
            icon="fas fa-address-card"
          >
          </stats-card>
          <content-loader
            v-else
            :height="125"
            :width="339"
            :speed="1"
            primaryColor="#f3f3f3"
            secondaryColor="#dce2fc"
            style="margin-bottom: 30px"
          >
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div> -->

        <div class="col-xl-6 col-md-6" v-if="currentUserGroup !== 2">
          <stats-card
            title="Pending Community Ads"
            v-if="dashboardItems.pendingCommunityAds && parseInt(dashboardItems.pendingCommunityAds) > 0"
            type="danger"
            subTitleType="number"
            :sub-title="dashboardItems.pendingCommunityAds.toString()"
            icon="fas fa-address-card"
            link="/business/ads/list?status=pending">
          </stats-card>
          <content-loader
            v-else-if="!dashboardItems.pendingCommunityAds"
            :height="125"
            :width="339"
            :speed="1"
            primaryColor="#f3f3f3"
            secondaryColor="#dce2fc"
            style="margin-bottom: 30px">
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div>

        <div class="col-xl-6 col-md-6" v-if="currentUserGroup !== 2">
          <stats-card
            title="Pending Advertisements"
            v-if="dashboardItems.pendingAdvertisements && parseInt(dashboardItems.pendingAdvertisements) > 0"
            type="danger"
            subTitleType="number"
            :sub-title="dashboardItems.pendingAdvertisements.toString()"
            icon="fas fa-address-card">
          </stats-card>
          <content-loader
            v-else-if="!dashboardItems.pendingAdvertisements"
            :height="125"
            :width="339"
            :speed="1"
            primaryColor="#f3f3f3"
            secondaryColor="#dce2fc"
            style="margin-bottom: 30px">
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div>

        <!-- <div class="col-xl-4 col-md-6" >
          <stats-card title="IOS"
                      v-if="dashboardItems.iosSubscribers"
                      type="primary"
                      subTitleType="number"
                      :sub-title="dashboardItems.iosSubscribers"
                      icon="fab fa-apple">
          </stats-card>
          <content-loader
            v-else
            :height="125"
            :width="339"
            :speed="1"
            primaryColor="#f3f3f3"
            secondaryColor="#dce2fc"
            style="margin-bottom: 30px"
          >
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div> -->
        <div class="col-xl-4 col-md-6" v-if="currentUserGroup !== 2 && false">
          <stats-card
            title="Coop Funds"
            v-if="dashboardItems.coopFunds"
            type="info"
            prefix="$"
            subTitleType="decimal"
            :sub-title="dashboardItems.coopFunds"
            icon="ni ni-money-coins">
          </stats-card>
          <content-loader v-else :height="125" :width="339" :speed="1" primaryColor="#f3f3f3" secondaryColor="#dce2fc" style="margin-bottom: 30px">
            <rect x="1" y="-2" rx="5" ry="5" width="400" height="108" />
          </content-loader>
        </div>
      </div>
    </base-header>
    <!--Charts-->
    <div class="container mt--6">
      <div class="row">
        <div class="col-xl-12" v-if="currentUserGroup === 1">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col col-lg-9">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Revenue</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3" href="#" :class="{ active: bigLineChart.activeIndex === 1 }">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div slot="header" class="row align-items-center pt-2" v-if="currentSelection === 'month'">
              <div class="col pt-5"></div>
            </div>
            <line-chart :height="350" ref="bigChart" :chart-data="bigLineChart.chartData" :extra-options="bigLineChart.extraOptions"> </line-chart>
          </card>
        </div>
        <!-- <div :class="{'col-xl-4': currentUserGroup === 1, 'col-xl-12': currentUserGroup !== 1}">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col col-lg-5 col-md-5">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Users</h6>
                <h5 class="h3 mb-0">Subscribers</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills" :class="{'justify-content-end': currentUserGroup !== 1, 'no-gutters': currentUserGroup === 1}">
                  <li class="nav-item mr-2 mr-md-0">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: currentSelection === 'year'}"
                       @click.prevent="selectGraphUserYear(null, 'year')">
                      <span class="d-none d-md-block">Year</span>
                      <span class="d-md-none">Y</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link py-2 px-3"
                       href="#"
                       :class="{active: currentSelection === 'month'}"
                       @click.prevent="selectGraphUserYear(null, 'month')">
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div slot="header" class="row align-items-center pt-2" v-if="currentSelection === 'month'">
              <div class="col">
                <b-form-select
                  v-if="userYears.length > 0"
                  v-model="selectedUserYear"
                  :options="userYears"
                  name="userYears"
                  class="pull-right"
                  :class="{'col-lg-12': currentUserGroup === 1, 'col-lg-2': currentUserGroup !== 1}"
                >
                </b-form-select>
              </div>
            </div>
            <bar-chart
              v-if="currentSelection === 'month'"
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
            <bar-chart
              v-if="currentSelection === 'year'"
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.yearChartData"
            >
            </bar-chart>
          </card>
        </div> -->
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { ContentLoader } from 'vue-content-loader';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import StatsCard from '@/components/Cards/StatsCard';
import Cookies from 'js-cookie';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';

Vue.use(Cookies);

const numToDate = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};
var labels = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var userData = [25, 20, 30, 22, 17, 29];

export default {
  components: {
    ContentLoader,
    RouteBreadCrumb,
    StatsCard,
    LineChart,
    // BarChart
  },
  data() {
    return {
      currentUserGroup: 1,
      currentYear: new Date().getFullYear(),
      currentSelection: 'month',
      selectedUserYear: new Date().getFullYear(),
      userYears: [],
      userGraphDataMonth: [],
      userGraphDataYear: [],
      redBarChart: {
        chartData: {},
      },
      dashboardItems: {
        androidSubscribers: false,
        companies: false,
        coopFunds: false,
        iosSubscribers: false,
        paidSubscribers: false,
        subscribers: false,
        recoveredUsers: false,
        paidSubscriptions: false,
        trialSubscriptions: false,
        pendingCommunityAds: false,
        pendingAdvertisements: false,
        clients: false,
      },
      bigLineChart: {
        allData: [],
        activeIndex: 0,
        chartData: {},
      },
    };
  },
  methods: {
    groupBy(objectArray, property, needed, multiplier = false) {
      if (objectArray) {
        return objectArray.reduce(function (acc, obj) {
          var key = obj[property];
          if (!acc[key]) {
            acc[key] = [];
          }

          if (needed) {
            if (multiplier) {
              obj['revenue'] = obj[needed] * multiplier;
              acc[key].push(obj['revenue']);
            } else {
              acc[key].push(obj[needed]);
            }
          } else {
            acc[key].push(obj);
          }

          return acc;
        }, {});
      }
    },
    removeDuplicate(objectArray) {
      let unique = {};
      if (objectArray.length > 0) {
        objectArray.forEach(function (i) {
          if (!unique[i]) {
            unique[i] = true;
          }
        });
        return Object.keys(unique);
      }
    },
    sortArray(objectArray) {
      if (objectArray !== 0 && objectArray !== '0') {
        return objectArray.sort(function (a, b) {
          if (a.year < b.year) {
            return -1;
          } else if (a.year > b.year) {
            return 1;
          } else {
            if (a.month < b.month) {
              return -1;
            } else if (a.month > b.month) {
              return 1;
            }
          }
        });
      }
    },
    getDashboard(payload) {
      const pooling = false;
      this.$store
        .dispatch('user/GET_USER_DASHBOARD', { pooling })
        .then((res) => {
          if (res.success) {
            this.dashboardItems = this.$store.state.user.dashboard;

            this.dashboardItems.subscribers = String(Number(this.dashboardItems.androidSubscribers) + Number(this.dashboardItems.iosSubscribers));

            this.userGraphDataMonth = [];

            this.bigLineChart = {
              activeIndex: 0,
              chartData: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                  {
                    label: 'Gross Volume ($)',
                    data: this.dashboardItems.payments.months,
                  },
                ],
              },
              extraOptions: chartConfigs.blueChartOptions,
            };
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            console.log('ERROR:', { error });
          });
        });
    },
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: 'Performance',
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    selectGraphUserYear(e, type = 'year') {
      this.currentSelection = type;
      if (type === 'year') {
        var selecteduserGraphDataYear = [];
        var selecteduserGraphDataYearCount = [];
        var dashboardUsersYear = this.userGraphDataYear.sort((a, b) => {
          return new Date(a.year) - new Date(b.year);
        });
        dashboardUsersYear.map((year) => {
          selecteduserGraphDataYear.push(year.year);
          selecteduserGraphDataYearCount.push(year.count);
        });

        this.redBarChart = {
          yearChartData: {
            labels: selecteduserGraphDataYear,
            datasets: [
              {
                label: 'Registered Users',
                data: selecteduserGraphDataYearCount,
              },
            ],
          },
          extraOptions: chartConfigs.blueChartOptions,
        };
      } else {
        var selecteduserGraphDataMonth = this.userGraphDataMonth.filter((data) => {
          return data.year == this.currentYear;
        });

        this.redBarChart = {
          chartData: {
            labels: selecteduserGraphDataMonth[0].labels,
            datasets: [
              {
                label: 'Registered Users',
                data: selecteduserGraphDataMonth[0].userData,
              },
            ],
          },
          extraOptions: chartConfigs.blueChartOptions,
        };
      }
    },
  },
  watch: {
    selectedUserYear: function (value) {
      var selecteduserGraphDataMonth = this.userGraphDataMonth.filter((data) => {
        return data.year == value;
      });

      this.redBarChart = {
        chartData: {
          labels: selecteduserGraphDataMonth[0].labels,
          datasets: [
            {
              label: 'Registered Users',
              data: selecteduserGraphDataMonth[0].userData,
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      };
    },
  },
  mounted() {
    let token = Cookies.get('user-token') || '';

    const decode = decoder({
      secret: config.secret,
    });

    var payload = decode(token);

    this.initBigChart(0);
    this.getDashboard(payload);
  },
};
</script>
<style></style>
