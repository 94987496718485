import Vue from 'vue';
import { getUserReport, getUserReportFilter } from '@/api/request/reports';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_USER_REPORTS: ({ commit, dispatch, state }, { startDate, endDate }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return getUserReportFilter(token, startDate, endDate)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              var userListSource = resp.data.data;
              var newUserList = {};
              let userList = userListSource.map((value) => {
                var profilesStatuses = [];
                var shippingAddress = value.shipping.address[0] || [];
                var billingAddress = value.billing.address[0] || [];
                var profileDatePaymentStatus = '';
                var profileDateResumesPaymentStatus = '';
                /*if (value.profiles.length > 0) {
                  value.profiles.map(profile => {
                      profilesStatuses.push(profile.billing.subscription.status)
                  })
                }*/
                // console.log(value)

                if (value.shipping.address.length > 1) {
                  shippingAddress = `${shippingAddress}, ${value.shipping.address[1]}`;
                }

                if (value.billing.address.length > 1) {
                  billingAddress = `${billingAddress}, ${value.billing.address[1]}`;
                }

                if (value.billing.subscription.status === 'canceling') {
                  profileDatePaymentStatus = moment(new Date(value.billing.subscription.canceledAt)).format('YYYY-MM-DD') || '';
                }

                if (value.billing.subscription.status === 'pausing') {
                  profileDatePaymentStatus = moment(new Date(value.billing.subscription.pausedAt)).format('YYYY-MM-DD') || '';
                }

                if (value.billing.subscription.status === 'pausing' || value.billing.subscription.status === 'paused') {
                  profileDateResumesPaymentStatus =
                    'resumesAt' in value.billing.subscription ? moment(new Date(value.billing.subscription.resumesAt)).format('YYYY-MM-DD') : '';
                }

                let data = {
                  subscription: value.subscription,
                  subscriptionType: value.subscriptionType,
                  profileStartDate: moment(new Date(value.billing.subscription.created)).format('YYYY-MM-DD') || '',
                  profileOrderNumber: value.billing.orderNumber || '',
                  profileSubscriptionId: value.billing.subscriptionId || '',
                  profilePromoCode: value.billing.subscription.promoCode || '',
                  profileTimeStatus: value.status || '',
                  profilePaymentStatus: value.billing.subscription.status || '',
                  profileDatePaymentStatus: profileDatePaymentStatus || '',
                  profileDateResumesPaymentStatus: profileDateResumesPaymentStatus || '',
                  profileFirstname: value.firstname || '',
                  profileLastname: value.lastname || '',
                  profileShippingAddress: shippingAddress,
                  profileShippingCity: value.shipping.city || '',
                  profileShippingState: value.shipping.state || '',
                  profileShippingZipCode: value.shipping.zipCode || '',
                  profilePoints: value.points || 0,
                  paymentPlan: value.planName || '',
                  nextBillingDate: moment(new Date(value.billing.subscription.nextBilling)).format('YYYY-MM-DD') || '',
                  age: value.age,
                  gender: value.gender,
                  email: value.userInfo.email,
                  firstname: value.userInfo.firstname,
                  lastname: value.userInfo.lastname,
                  billingAddress: billingAddress,
                  billingCity: value.billing.city || '',
                  billingState: value.billing.state || '',
                  billingZipCode: value.billing.zipCode || '',
                  phone: value.userInfo.phone || '',
                  source: value.reference === 1 ? '' : value.reference,
                  subscribeOffers: !!value.userInfo.subscribeOffers,
                  subscribeSubaru: !!value.userInfo.subscribeSubaru,
                };
                return data;
              });

              userList = userList.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              newUserList['all'] = userList || [];
              // newUserList['active'] = userList.filter((user) => { return user.status === 'active'}) || []
              // newUserList['inactive'] = userList.filter((user) => { return user.status === 'inactive' && user.blockedStatus !== 'blocked'}) || []
              // newUserList['failed'] = userList.filter((user) => { return user.status === 'failed registration' && user.blockedStatus !== 'blocked'}) || []
              // newUserList['unverified'] = userList.filter((user) => { return user.status === 'unverified' && user.blockedStatus !== 'blocked'}) || []
              // newUserList['abandoned'] = userList.filter((user) => { return user.status === 'abandoned' && user.blockedStatus !== 'blocked'}) || []
              // newUserList['suspended'] = userList.filter((user) => { return user.blockedStatus === 'blocked'}) || []
              // newUserList['suspended'] = userList.filter((user) => { return user.status === 'suspended'}) || []

              commit('SET_USERS_SUCCESS', newUserList);
              resolve(newUserList);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
