<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Roles and Permissions</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <router-link :to="handleLink('add')" class="btn btn-primary float-right">
              <i class="fa fa-plus-circle"></i>
              Add New Role
            </router-link>
          </div>
        </div>

        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="col-lg-3 pull-right no-gutters">
                      <base-input v-model="searchRequest" prepend-icon="fas fa-search" placeholder="Search"></base-input>
                    </div>
                  </div>
                </b-row>

                <b-table
                  hover
                  :items="roles"
                  :fields="ListFields"
                  :filter="searchRequest"
                  @filtered="onSearch"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  class="roleList">
                  <div slot="role" slot-scope="item" class="data">
                    <span class="title">
                      {{ item.item.name }}
                    </span>
                  </div>

                  <div slot="permissions" slot-scope="item" class="data">
                    <span class="description" v-for="(role, index, key) in filterItems(item.item.rolePermissions)" :key="role.label">
                      <span v-if="role.isChecked">
                        {{ role.label }}
                        <span v-if="key + 1 !== Object.keys(filterItems(item.item.rolePermissions)).length">,</span>
                      </span>
                      <!-- <i v-for="sub in role.subPermission" :key="sub.name">
                        {{sub.name}},
                      </i> -->
                    </span>
                  </div>

                  <div slot="actions" slot-scope="item" class="data">
                    <router-link :to="`${handleLink('edit')}/${item.item._id}`" v-b-tooltip.hover title="Edit Role" class="btn btn-outline-primary btn-sm">
                      <span class="btn-inner--icon"><i class="far fa-edit"></i> </span>
                    </router-link>

                    <a @click="deleteRole(item.item._id)" v-b-tooltip.hover title="Delete Role" class="btn btn-outline-danger btn-sm text-danger">
                      <span class="btn-inner--icon"><i class="far fa-trash-alt"></i> </span>
                    </a>
                  </div>
                </b-table>
                <b-pagination v-if="totalRows > 5" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" align="center" />
                <div class="float-right" v-if="totalRows > 5">
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  name: 'AdminRoles',
  data: function () {
    return {
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      deleteRoleId: null,
      searchRequest: '',
    };
  },
  computed: {
    roles() {
      const roles = this.$store.state.roles.roles;
      return roles;
    },
    ListFields() {
      return [
        {
          role: {
            label: 'role',
          },
          permissions: {
            label: 'permissions',
          },
          actions: {
            label: 'actions',
          },
        },
      ];
    },
  },
  created() {
    this.$store.dispatch('roles/GET_ROLE_LIST');
    this.totalRows = this.roles.length;
  },
  methods: {
    updateFilter() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.totalRows = this.roles.length;
        this.searchRequest = '';
      });
    },
    onSearch(items) {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.totalRows = this.items.length;
      });
    },
    handleLink(type) {
      return `/permissions/${type}/role`;
    },
    deleteRole(roleId) {
      this.deleteRoleId = roleId;
      this.$swal({
        title: 'Delete Role',
        text: 'Are you sure, you want to delete this role?',
        type: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it',
        cancelButtonText: 'Cancel',
        cancelButtonClass: 'btn btn-secondary pr-5 pl-5',
        confirmButtonClass: 'btn btn-danger pr-5 pl-5',
        reverseButtons: true,
        buttonsStyling: false,
        allowOutsideClick: false,
      });
    },
    handleDeleteRole(status) {
      if (status) {
        this.$store
          .dispatch('roles/deleteRole', this.deleteRoleId)
          .then((result) => {
            if (result && result.status === 200) {
              this.$noty.success('role successfully deleted!', {
                theme: 'bootstrap-v4',
                timeout: 1000,
              });
              this.$store.dispatch('roles/GET_ROLE_LIST');
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    filterItems: function (roles) {
      if (roles) {
        const items = Object.values(roles);
        const value = items.filter(function (item) {
          return item.isChecked;
        });
        return Object.assign({}, value);
      }
    },
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.non-clickable {
  pointer-events: none;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #20a8d8 !important;
}

.content-search {
  padding: 0 1.5rem;
}

.roleList .thead-light th:nth-child(2) {
  width: 70% !important;
}

a.btn-outline-danger:hover {
  color: #fff !important;
}
</style>
