<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createConnectionContainer">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Edit Connection</h3>
              </div>
            </div>

            <b-row id="processConnectionForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-12">
                              <!-- <h1 class="form-title">Event Information</h1><br/> -->
                              <div class="row">
                                <div class="col-lg-6">
                                  <cropper
                                    v-if="cropperThumbnail"
                                    ref="thumbnail"
                                    class="cropper"
                                    :src="cropperThumbnail"
                                    :stencil-props="{
                                      aspectRatio: thumbnailLimitations.aspectRatio,
                                    }"
                                    :default-boundaries="defaultThumbnailBoundaries"
                                    @change="thumbnailOnChange" />
                                  <div v-if="!cropperThumbnail" class="preview-image">
                                    <img v-if="connection.avatar" width="100%" :src="connection.avatar" alt="" srcset="" />
                                    <img v-else width="50%" src="../../assets/images/img-placeholder.png" alt="" srcset="" />
                                  </div>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="avatar">Thumbnail</label>
                                    <b-form-file
                                      id="forAvatar"
                                      accept="image/*"
                                      data-vv-name="avatar"
                                      @change="onFileChange"
                                      @ondrop="onFileChange"
                                      @ondragover="onFileChange"
                                      placeholder="Choose a file or drop it here..."
                                      drop-placeholder="Drop file here..."
                                      class="form-control no-instruction"
                                      :class="{
                                        'is-invalid': avatarImageError,
                                        'has-invalid': avatarImageError,
                                      }"></b-form-file>
                                    <div class="invalid-feedback" :is-invalid="avatarImageError">
                                      {{ imageErrorMessage.avatar }}
                                    </div>
                                  </b-form-group>
                                  <h5>
                                    <i>For best display results, make sure to upload images with a 1:1 aspect ratio. Minimum recommended size is 360x360</i>
                                  </h5>
                                  <br />

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="title">Event Title </label>
                                    <input
                                      type="text"
                                      name="title"
                                      v-model.trim="connection.title"
                                      data-vv-as="event title"
                                      v-validate="'required'"
                                      data-vv-name="title"
                                      placeholder="Event Title"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('title'),
                                        'has-invalid': errors.has('title'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('title')">
                                      {{ errors.first('title') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="description">Description</label>
                                    <input
                                      type="text"
                                      name="description"
                                      v-model.trim="connection.description"
                                      data-vv-as="event description"
                                      data-vv-name="description"
                                      placeholder="Event description"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('description'),
                                        'has-invalid': errors.has('description'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('description')">
                                      {{ errors.first('description') }}
                                    </div>
                                  </b-form-group>
                                  <div class="row">
                                    <!-- <b-form-group role="group" class="mb-3 has-feedback">
                                          <label class="w-100 form-control-label" for="date">Date</label>
                                          <b-form-datepicker
                                          v-model="date"
                                          format="YYYY-MM-DD"
                                          formatted="DD MMM YYYY"
                                          :min="min"
                                          locale="en"
                                          v-validate="`required`"
                                          name="date"
                                          onlyDate
                                          data-vv-name="date"
                                          data-vv-as="Date"
                                          class="reportDatePicker no-instruction"
                                          :class="{'is-invalid': errors.has('date'),'has-invalid': errors.has('date'), 'has-error': errors.has('date')}"
                                          ></b-form-datepicker>
                                          <span class="has-icon-feedback"></span>
                                          <div class="invalid-feedback" :is-invalid="errors.has('date')">
                                            {{errors.first('date')}}
                                          </div>
                                        </b-form-group> -->
                                    <div class="col-lg-6">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="startDate">Start Date</label>
                                        <b-form-datepicker
                                          v-model="connection.startDate"
                                          format="YYYY-MM-DD"
                                          formatted="DD MMM YYYY"
                                          :min="minDate"
                                          v-validate="`required|validateDate:${connection.endDate}`"
                                          name="startDate"
                                          onlyDate
                                          data-vv-name="startDate"
                                          data-vv-as="Start Date"
                                          class="reportDatePicker no-instruction"
                                          :class="{
                                            'is-invalid': errors.has('startDate'),
                                            'has-invalid': errors.has('startDate'),
                                            'has-error': errors.has('startDate'),
                                          }"></b-form-datepicker>
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('startDate')">
                                          {{ errors.first('startDate') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                    <div class="col-lg-6">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="endDate">End Date</label>
                                        <b-form-datepicker
                                          v-model="connection.endDate"
                                          format="YYYY-MM-DD"
                                          formatted="DD MMM YYYY"
                                          :min="minEndDate"
                                          v-validate="`required`"
                                          name="endDate"
                                          onlyDate
                                          data-vv-name="endDate"
                                          data-vv-as="End Date"
                                          class="reportDatePicker no-instruction"
                                          :class="{
                                            'is-invalid': errors.has('endDate'),
                                            'has-invalid': errors.has('endDate'),
                                            'has-error': errors.has('endDate'),
                                          }"></b-form-datepicker>
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('endDate')">
                                          {{ errors.first('endDate') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                    <div class="col-lg-6">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="time">Time</label>
                                        <b-form-timepicker
                                          v-model="connection.time"
                                          v-validate="`required`"
                                          name="time"
                                          data-vv-name="time"
                                          data-vv-as="Time"
                                          class="reportDatePicker no-instruction mb-2"
                                          :class="{
                                            'is-invalid': errors.has('time'),
                                            'has-invalid': errors.has('time'),
                                          }">
                                        </b-form-timepicker>
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('time')">
                                          {{ errors.first('time') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                    <div class="col-lg-6">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="url">Article Link Url</label>
                                        <input
                                          type="text"
                                          name="url"
                                          v-model.trim="connection.url"
                                          data-vv-as="aricle url"
                                          data-vv-name="url"
                                          placeholder="https://www.example.com"
                                          autocomplete="NoAutocomplete"
                                          class="form-control no-instruction"
                                          :class="{
                                            input: true,
                                            'is-invalid': errors.has('urls'),
                                            'has-invalid': errors.has('urls'),
                                          }" />
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('urls')">
                                          {{ errors.first('urls') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-lg-12">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="email">Email Adddress</label>
                                        <input
                                          type="text"
                                          name="email"
                                          v-model.trim="connection.email"
                                          data-vv-as="email"
                                          data-vv-name="email"
                                          v-validate="'email'"
                                          placeholder="user@example.com"
                                          autocomplete="NoAutocomplete"
                                          class="form-control no-instruction"
                                          :class="{
                                            input: true,
                                            'is-invalid': errors.has('email'),
                                            'has-invalid': errors.has('email'),
                                          }" />
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('email')">
                                          {{ errors.first('email') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6">
                                  <cropper
                                    v-if="cropperCover"
                                    ref="cover"
                                    class="cropper"
                                    :src="cropperCover"
                                    :stencil-props="{
                                      aspectRatio: coverLimitations.aspectRatio,
                                    }"
                                    :default-boundaries="defaultCoverBoundaries"
                                    @change="coverOnChange" />
                                  <div v-if="!cropperCover" class="preview-image">
                                    <img v-if="connection.cover.url" width="100%" :src="connection.cover.url" alt="" srcset="" />
                                    <img v-else width="100%" src="../../assets/images/img-placeholder.png" alt="" srcset="" />
                                  </div>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="cover">Connection Flyer</label>
                                    <b-form-file
                                      id="forConver"
                                      accept="image/*"
                                      data-vv-name="cover"
                                      @change="onFileChange"
                                      @ondrop="onFileChange"
                                      @ondragover="onFileChange"
                                      placeholder="Choose a file or drop it here..."
                                      drop-placeholder="Drop file here..."
                                      class="form-control no-instruction"
                                      :class="{
                                        'is-invalid': coverImageError,
                                        'has-invalid': coverImageError,
                                      }"></b-form-file>
                                    <div class="invalid-feedback" :is-invalid="coverImageError">
                                      {{ imageErrorMessage.cover }}
                                    </div>
                                  </b-form-group>
                                  <h5>
                                    <i>Please use 4:3 aspect ratio for best results.</i>
                                  </h5>
                                  <br />
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="city">Select City </label>
                                    <b-form-select
                                      v-model="connection.city"
                                      :options="cities"
                                      name="city"
                                      placeholder="Please select city"
                                      v-validate="'required'"
                                      data-vv-as="city"
                                      data-vv-name="city"
                                      class="form-control no-instruction custom-select"
                                      :class="{
                                        'is-invalid': errors.has('city'),
                                        'has-invalid': errors.has('city'),
                                      }">
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('city')">
                                      {{ errors.first('city') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="connectionParentCategory">Select Connection Category </label>
                                    <b-form-select
                                      v-model="connectionParentCategory"
                                      :options="connectionCategories"
                                      name="connectionParentCategory"
                                      placeholder="Please select connectionParentCategory"
                                      v-validate="'required'"
                                      data-vv-as="connectionParentCategory"
                                      data-vv-name="connectionParentCategory"
                                      class="form-control no-instruction custom-select"
                                      :class="{
                                        'is-invalid': errors.has('connectionParentCategory'),
                                        'has-invalid': errors.has('connectionParentCategory'),
                                      }"
                                      @change="getSubCategories()">
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('connectionParentCategory')">
                                      {{ errors.first('connectionParentCategory') }}
                                    </div>
                                  </b-form-group>
                                  <b-form-group v-if="subCategories.length > 1" role="group" class="mb-3 has-feedback">
                                    <div v-if="subCategories.length > 1">
                                      <label class="w-100 form-control-label" for="subCategory">Select Sub Category </label>
                                      <b-form-select
                                        v-model="connectionSubCategory"
                                        :options="subCategories"
                                        name="subCategory"
                                        placeholder="Please select subCategory"
                                        v-validate="'required'"
                                        data-vv-as="subCategory"
                                        data-vv-name="subCategory"
                                        class="form-control no-instruction custom-select"
                                        :class="{
                                          'is-invalid': errors.has('subCategory'),
                                          'has-invalid': errors.has('subCategory'),
                                        }">
                                      </b-form-select>
                                      <span class="has-icon-feedback"></span>
                                      <div class="invalid-feedback" :is-invalid="errors.has('subCategory')">
                                        {{ errors.first('subCategory') }}
                                      </div>
                                    </div>
                                    <div v-else>
                                      <label class="w-100 form-control-label error-msg" for="error">No Sub Category Found</label>
                                    </div>
                                  </b-form-group>
                                </div>
                                <div class="col-lg-12 mt-6">
                                  <b-row v-if="ckeditorReady == false">
                                    <div class="col-lg-12 text-center">
                                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                                      <b>Loading...</b>
                                    </div>
                                  </b-row>
                                  <b-form-group v-else role="group" class="mb-3 has-feedback">
                                    <!-- <label class="w-100 form-control-label pb-2" for="content">Content</label> -->
                                    <ckeditor
                                      v-if="ckeditorReady"
                                      id="contentBlackCard"
                                      name="content"
                                      :editor="editor"
                                      v-model="connection.content"
                                      :config="editorConfig"
                                      v-validate="'required'"
                                      data-vv-name="content"
                                      data-vv-as="content"
                                      data-vv-scope="content"
                                      class="no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('content'),
                                        'has-invalid': errors.has('content'),
                                      }">
                                    </ckeditor>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('content')">
                                      {{ errors.first('content') }}
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-5 px-4">
                            <div class="col-md-3 col-lg-4">
                              <router-link ref="clear" :to="`/connections/list`" exact class="btn btn-outline-danger pr-5 pl-5 w-sm-100">Cancel</router-link>
                            </div>
                            <div v-if="connectionStatus === 'pending'" class="col-md-3 col-lg-3">
                              <button
                                type="button"
                                id="approveConnection"
                                @click="approveConnection(connectionID)"
                                class="btn btn-aqua float-right w-sm-100 mb-3 mb-md-0">
                                Approve Event
                                <span class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                                ></span>
                              </button>
                            </div>
                            <div
                              class="col-md-3"
                              :class="{
                                'col-lg-5': connectionStatus === 'pending' && action !== 'create',
                                'col-lg-8': connectionStatus !== 'pending' || action === 'create',
                              }">
                              <button
                                type="button"
                                id="processConnection"
                                @click="processConnection(connectionID)"
                                class="btn btn-primary float-right w-sm-100 mb-3 mb-md-0">
                                <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1"></b-icon>
                                {{ action === 'create' ? 'Create Connection' : 'Update Connection' }}
                                <span class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import { Validator } from 'vee-validate';
import moment from 'moment';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';

import Link from '@ckeditor/ckeditor5-link/src/link';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import List from '@ckeditor/ckeditor5-list/src/list';
import Font from '@ckeditor/ckeditor5-font/src/font';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import ParagraphButtonUI from '@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';

import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';

import { API_URL } from '@/api/constants/baseUrl';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

Vue.use(Cookies);

const validateDate = {
  getMessage: (field) => `Please select date earlier than event end date`,
  validate(value, param) {
    return new Promise((resolve) => {
      resolve({
        valid: param[0] !== 'null' ? new Date(value) <= new Date(param[0]) : true,
        data: value === '' ? { message: `Start Date field is required` } : '',
      });
    });
  },
};

Validator.extend('validateDate', validateDate);

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      action: 'create',
      editor: ClassicEditor,
      startDate: null,
      endDate: null,
      minDate: String(moment(new Date()).format('YYYY-MM-DD')),
      maxDate: String(moment(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).format('YYYY-MM-DD HH:mm')),
      minEndDate: String(moment(new Date()).format('YYYY-MM-DD')),
      editorConfig: {
        plugins: [
          EasyImage,
          EssentialsPlugin,
          Heading,
          BoldPlugin,
          ItalicPlugin,
          BlockQuote,
          Underline,
          LinkPlugin,
          ParagraphPlugin,
          Link,
          List,
          Alignment,
          Indent,
          IndentBlock,
          Font,
          HeadingButtonsUI,
          ParagraphButtonUI,
          Alignment,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          ImageUpload,
          CloudServices,
          Autoformat,
        ],
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'blockQuote',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo',
          '|',
          'link',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'alignment:left',
          'alignment:right',
          'alignment:center',
          'alignment:justify',
          '|',
          'imageUpload',
          'imageStyle:side',
          'imageStyle:full',
        ],
        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            'imageTextAlternative',
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            'resizeImage:50',
            'resizeImage:75',
            'resizeImage:original',
          ],
          styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight'],
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
          ],
        },
        indentBlock: {
          classes: [
            'custom-block-indent-a', // First step - smallest indentation.
            'custom-block-indent-b',
            'custom-block-indent-c', // Last step - biggest indentation.
          ],
        },
        cloudServices: {
          tokenUrl: API_URL + '/endpoint',
          uploadUrl: 'https://79127.cke-cs.com/easyimage/upload/',
        },
      },
      connection: {
        title: '',
        description: '',
        content: '',
        cover: {
          url: '',
          preview: '',
          previewCoordinates: null,
        },
        url: null,
        avatar: null,
        date: undefined,
        startDate: null,
        endDate: null,
        time: null,
        city: null,
        category: null,
      },
      connectionStatus: null,
      // date: undefined,
      // time: undefined,
      cropperThumbnail: undefined,
      cropperCover: undefined,
      formData: new FormData(),
      imagePlaceholder: '../../assets/images/img-placeholder.png',
      connectionParentCategory: null,
      connectionSubCategory: null,
      dateTime: undefined,
      sample: undefined,
      newImages: {
        avatar: undefined,
        cover: undefined,
      },
      date: '',
      time: '',
      avatarImageError: false,
      coverImageError: false,
      imageErrorMessage: [],
      ckeditorReady: false,
      thumbnailLimitations: {
        maxWidth: 360,
        maxHeight: 360,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      coverLimitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        // aspectRatio: 4 / 3
      },
      thumbnailImageName: null,
      coverImageName: null,
      updatedThumbnail: null,
      updatedPreview: null,
      coordinatesSet: false,
      coordinatesChanges: false,
      thumbnailCoordinatesSet: false,
      thumbnailCoordinatesChanges: false,
      thumbnailCoordinates: null,
      previewFileExtension: '',
    };
  },
  computed: {
    connectionStartDate() {
      return moment(this.connection.startDate).valueOf();
    },
    connectionEndDate() {
      return moment(this.connection.endDate).valueOf();
    },
    connectionTime() {
      return moment(this.connection.time, 'HH:mm').valueOf();
    },
    currentTime() {
      return moment().format('HH:mm:ss');
    },
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [{ value: null, text: 'Please select city', disabled: true }, ...data];
    },
    cityList: {
      get: function () {
        return this.$store.state.connections.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    connectionCategories() {
      let data = [];
      this.connectionParentCategoryList.map((connectionParentCategory) => {
        data.push({
          value: connectionParentCategory._id,
          text: connectionParentCategory.title,
        });
      });

      return [
        {
          value: null,
          text: 'Please select connection category',
          disabled: true,
        },
        ...data,
      ];
    },
    connectionParentCategoryList: {
      get: function () {
        return this.$store.state.connections.connectionCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    subCategories() {
      let data = [];
      this.subCategoryList.map((subCategory) => {
        data.push({ value: subCategory._id, text: subCategory.title });
      });

      return [{ value: null, text: 'Please select sub category', disabled: true }, ...data];
    },
    subCategoryList: {
      get: function () {
        return this.$store.state.connections.subCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.connections.loading;
    },
  },
  methods: {
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    },
    defaultCoverBoundaries({ cropper, imageSize }) {
      if (!this.connection.cover.previewCoordinates) {
        this.connection.cover.previewCoordinates = { width: imageSize.width, height: imageSize.height, left: 0, top: 0 };
      }
      return {
        width: cropper.clientWidth,
        height: cropper.clientHeight,
      };
    },
    defaultThumbnailBoundaries({ cropper, imageSize }) {
      if (!this.thumbnailCoordinates) {
        this.thumbnailCoordinates = { width: imageSize.width, height: imageSize.height, left: 0, top: 0 };
      }
      return {
        width: cropper.clientWidth,
        height: cropper.clientHeight,
      };
    },
    coverOnChange({ coordinates, canvas }) {
      const { cover } = this.$refs;
      // Check if cropper is loaded or changed
      if (this.action == 'update' && this.coordinatesSet == false) {
        // If loaded, set coordinates from DB
        if (this.connection.cover.previewCoordinates) {
          cover.setCoordinates(this.connection.cover.previewCoordinates);
        }
        // Set to true if the coordinates is set
        this.coordinatesSet = true;
      } else {
        // If the cropper is changed, store the coordinates and set
        this.coordinatesChanges = true;
        this.connection.cover.previewCoordinates = coordinates;
      }
      console.log('Updated Cover!!!');
      // Get the cropped image
      // this.updatedPreview = this.dataURLtoFile(canvas.toDataURL(), this.coverImageName ? this.coverImageName : `${this.convertToSlug(this.connection.title)}-preview`)
      this.updatedPreview = this.dataURLtoFile(
        canvas.toDataURL(),
        this.coverImageName ? this.coverImageName : `${this.convertToSlug(this.connection.title)}-preview.${this.previewFileExtension}`
      );
    },
    thumbnailOnChange({ coordinates, canvas }) {
      const { thumbnail } = this.$refs;
      if (this.action == 'update' && this.thumbnailCoordinatesSet == false) {
        // If loaded, set coordinates from DB
        if (this.thumbnailCoordinates) {
          thumbnail.setCoordinates(this.thumbnailCoordinates);
        }
        // Set to true if the coordinates is set
        this.thumbnailCoordinatesSet = true;
      } else {
        this.thumbnailCoordinatesChanges = true;
        this.thumbnailCoordinates = coordinates;
      }
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      console.log('Updated Thumbnail!!!');
      this.updatedThumbnail = this.dataURLtoFile(canvas.toDataURL());
    },
    makeid(length) {
      var result = [];
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
      }
      return result.join('');
    },
    dataURLtoFile(dataurl, filename) {
      let name = filename ? filename : this.makeid(10);
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name, { type: mime });
    },
    setTime() {
      // const [h, m] = this.time.split(':')
      let [year, month, date] = this.date.split('-');
      // In JS, months in a year starts at 0
      month = month - 1;
      // Set year, month, and date of given time data
      this.dateTime = moment(this.dateTime).set({ year, month, date }).valueOf();
      // this.dateTime = moment(this.dateTime).set({ h, m }).valueOf()
      return this.dateTime;
    },
    loadConnection(connectionID) {
      this.$store.dispatch('connections/GET_CONNECTION_BY_ID', connectionID).then((selectedConnection) => {
        console.log({ selectedConnection });
        let category;
        let sub_category;
        // If no category or removed
        if (!selectedConnection.data.category) {
          category = null;
          sub_category = null;
        } else {
          category = selectedConnection.data.category.parent || selectedConnection.data.category._id;
          sub_category = selectedConnection.data.category._id || null;
        }

        this.connectionParentCategory = category;
        this.connectionSubCategory = sub_category;
        this.dateTime = selectedConnection.data.date;
        this.connection.startDate = moment(selectedConnection.data.startDate).format('YYYY-MM-DD');
        this.connection.endDate = moment(selectedConnection.data.endDate).format('YYYY-MM-DD');
        this.connection.time = moment(selectedConnection.data.time).format('HH:mm');
        this.connection.title = selectedConnection.data.title;
        this.connection.description = selectedConnection.data.description || "";
        this.connection.content = selectedConnection.data.content;
        this.connection.city = selectedConnection.data.city._id;
        this.connection.avatar = selectedConnection.data.avatar;
        this.connection.cover = selectedConnection.data.cover || '';
        this.connection.url = selectedConnection.data.url;
        this.connection.email = selectedConnection.data.email;
        this.connectionStatus = selectedConnection.data.status;

        // Load to Cropper
        this.previewFileExtension = selectedConnection.data.cover.url.split(/[#?]/)[0].split('.').pop().trim();
        this.cropperCover = selectedConnection.data.cover.url;
        this.cropperThumbnail = selectedConnection.data.avatar;
        this.connection.cover.previewCoordinates = selectedConnection.data.cover.previewCoordinates || null;

        this.time = moment(this.dateTime).format('HH:mm');
        this.date = moment(this.dateTime).format('YYYY-MM-DD');
        this.getSubCategories();
        this.ckeditorReady = true;
      });
    },
    processConnection(connectionID) {
      if (this.action === 'create') {
        this.addConnection();
      } else {
        this.updateConnection(connectionID);
      }
    },
    addConnection() {
      const promiseBtn = document.getElementById('processConnection');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processConnectionForm');
      bodyWrapper.classList.add('non-clickable');

      if (this.cropperThumbnail == undefined) {
        this.avatarImageError = true;
        this.imageErrorMessage.avatar = 'Thumbnail image is required';
        console.log(this.imageErrorMessage.avatar);
      } else {
        this.avatarImageError = false;
      }

      if (this.cropperCover == undefined) {
        this.coverImageError = true;
        this.imageErrorMessage.cover = 'Cover image is required';
        console.log(this.imageErrorMessage.cover);
      } else {
        this.coverImageError = false;
      }

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let canvasCover;
          let canvasThumbnail;
          let cover_preview;
          let thumbnail;

          const connection = {
            title: this.connection.title,
            description: this.connection.description || '',
            content: this.connection.content,
            cover: {
              url: this.connection.cover,
              type: 'image',
              previewCoordinates: this.connection.cover.previewCoordinates,
            },
            avatar: this.connection.avatar,
            date: this.setTime(),
            startDate: this.connectionStartDate,
            endDate: this.connectionEndDate,
            time: this.connectionTime,
            city: this.connection.city,
            url: this.connection.url || null,
            email: this.connection.email || null,
            category: this.subCategories.length > 1 ? this.connectionSubCategory : this.connectionParentCategory,
          };

          // lowercase the following
          connection.url = connection.url ? connection.url.replace(/^https/i, 'https') : null;
          connection.url = connection.url ? connection.url.replace(/^http/i, 'http') : null;
          connection.url = connection.url ? connection.url.replace(/www./i, 'www.') : null;

          this.formData.set('avatar', this.updatedThumbnail);
          this.formData.set('cover_preview', this.updatedPreview);

          this.formData.set('connection', JSON.stringify(connection));

          this.$store
            .dispatch('connections/CREATE_CONNECTION', this.formData)
            .then((res) => {
              if (res.data.success) {
                (this.event = {
                  title: '',
                  description: '',
                  cover: null,
                  avatar: null,
                  date: undefined,
                }),
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Connection successfully created'),
                    timeout: 4000,
                    icon: 'fas fa-check',
                    type: 'success',
                  });

                this.$router.push('/connections/list');
              } else {
                if (res.data.error === 'error_user_already_exist') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Email already in use'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else if (res.data.error === 'Error_creating_business') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Business name already in use'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Error creating user'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                }
              }
              btnLoader.classList.add('hidden');
              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                if (error.errMessage == 'error_creating_business') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Business name already in use!'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add('hidden');
                  promiseBtn.removeAttribute('disabled', 'disabled');
                  bodyWrapper.classList.remove('non-clickable');
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(error.errMessage),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                  this.hasError = true;

                  btnLoader.classList.add('hidden');
                  promiseBtnInner.classList.add('hidden');
                  promiseBtn.removeAttribute('disabled', 'disabled');
                  bodyWrapper.classList.remove('non-clickable');
                }
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords('Please fill up the required fields.'),
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'danger',
          });
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateConnection(connectionID) {
      const promiseBtn = document.getElementById('processConnection');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processConnectionForm');
      bodyWrapper.classList.add('non-clickable');

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let canvasCover;
          let canvasThumbnail;
          let cover_preview;
          let thumbnail;

          const connectionData = {
            title: this.connection.title,
            description: this.connection.description.length ? this.connection.description : undefined,
            content: this.connection.content,
            date: this.setTime(),
            startDate: this.connectionStartDate,
            endDate: this.connectionEndDate,
            time: this.connectionTime,
            city: this.connection.city,
            url: this.connection.url || null,
            email: this.connection.email || null,
            category: this.connectionParentCategory,
            cover: this.connection.cover,
            avatar: this.connection.avatar,
          };

          // lowercase the following
          connectionData.url = connectionData.url ? connectionData.url.replace(/^https/i, 'https') : null;
          connectionData.url = connectionData.url ? connectionData.url.replace(/^http/i, 'http') : null;
          connectionData.url = connectionData.url ? connectionData.url.replace(/www./i, 'www.') : null;

          if (this.cropperCover && this.coordinatesChanges == true) {
            this.formData.set('cover_preview', this.updatedPreview);
          }

          if (this.cropperThumbnail) {
            canvasThumbnail = this.$refs.thumbnail.getResult().canvas;
            thumbnail = this.dataURLtoFile(canvasThumbnail.toDataURL(), this.thumbnailImageName);
            this.formData.set('avatar', thumbnail);
          }

          this.formData.set('connection', JSON.stringify(connectionData));

          this.$store
            .dispatch('connections/UPDATE_CONNECTION', {
              connectionID,
              connectionData: this.formData,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Connection successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.$router.push('/connections/list');
                this.user = {};
                this.name = '';
                this.email = '';
                this.password = '';
                (this.role = null),
                  (this.business = {
                    name: '',
                    address: {
                      line1: '',
                      line2: '',
                    },
                    email: '',
                    contactNumber: '',
                  });
              } else {
                if (res.data.error === 'error_user_already_exist') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Connection already exists'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Error updating connection'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                }
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
                promiseBtnInner.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
                bodyWrapper.classList.remove('non-clickable');
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords('Please fill up the required fields.'),
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'warning',
          });
          btnLoader.classList.add('hidden');
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    approveConnection(connectionID) {
      const promiseBtn = document.getElementById('processConnection');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processConnectionForm');
      bodyWrapper.classList.add('non-clickable');

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const connectionData = {
            status: 'approved',
          };
          this.formData.set('connection', JSON.stringify(connectionData));
          this.$store
            .dispatch('connections/UPDATE_CONNECTION', {
              connectionID,
              connectionData: this.formData,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Connection successfully approved'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.$router.push('/connections/list');
                this.user = {};
                this.name = '';
                this.email = '';
                this.password = '';
                (this.role = null),
                  (this.business = {
                    name: '',
                    address: {
                      line1: '',
                      line2: '',
                    },
                    email: '',
                    contactNumber: '',
                  });
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error approving connection'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
                promiseBtnInner.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
                bodyWrapper.classList.remove('non-clickable');
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords('Please fill up the required fields.'),
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'warning',
          });
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword = this.password = this.confirmPassword = this.changePasswordError = this.progressLabel = '';
      this.progressWidth = 0;
      this.hasNumber = this.hasUpperCase = this.hasLowerCase = this.hasLowercaseUppercase = this.hasSpecialChar = this.hasPeriodDash = this.hasError = false;
      this.cancelUpdateAccount();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      const id = e.target.id;

      if (id == 'forAvatar') {
        this.thumbnailImageName = file.name;
        this.cropperThumbnail = URL.createObjectURL(file);
        this.connection.avatar = URL.createObjectURL(file);
        this.avatarImageError = false;
      } else {
        this.coverImageName = file.name;
        this.cropperCover = URL.createObjectURL(file);
        this.connection.cover.url = URL.createObjectURL(file);
        this.coverImageError = false;
        this.formData.append('cover', file);
      }
    },
    getCities() {
      this.$store.dispatch('connections/GET_CITY_LIST');
    },
    getConnectionCategories() {
      this.$store.dispatch('connections/GET_CONNECTION_CATEGORY_LIST');
    },
    getSubCategories() {
      this.$store.dispatch('connections/GET_SUB_CATEGORY_LIST', this.connectionParentCategory);
    },
  },
  watch: {
    'connection.startDate': function (value) {
      this.$nextTick(() => {
        this.minEndDate = value;
      });
      if (value === null) {
        this.connection.endDate = value;
        this.$validator.remove('endDate');
      }
    },
    password(value) {
      if (value.length > 0) {
        this.passwordShow = true;
      } else {
        this.passwordShow = false;
      }
    },
  },
  mounted() {
    this.getCities();
    this.getConnectionCategories();
    this.action = this.$route.params.id ? 'update' : 'create';
    this.ckeditorReady = this.action == 'create' ? true : false;
    this.connectionID = this.$route.params.id;
    if (this.$route.params.id) {
      this.loadConnection(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss">
.createConnectionContainer select {
  color: #525f7f !important;
}
.progress {
  height: 14px !important;
}
.form-title {
  text-align: center;
  text-transform: uppercase;
}
.preview-image {
  margin-top: 15px;
  img {
    display: block;
    margin: auto;
  }
}
.error-msg {
  color: red;
}
.ck-content {
  background: #000000 !important;
}
body {
  color: white;
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}
</style>
