var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-4 col-7"},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)])])]),_c('div',{staticClass:"container mt--6 minHeight"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{staticClass:"createAdminContainer",attrs:{"no-body":"","body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg-10 col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Add Admin User")])])]),_c('b-row',{attrs:{"id":"processAdminForm"}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pt-0"},[_c('b-row',[_c('form',{staticClass:"w-100"},[_c('div',{staticClass:"p-lg-4 px-lg-4 px-md-4 px-sm-4"},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"firstname"}},[_vm._v("First Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.firstname),expression:"firstname",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                  input: true,
                                  'is-invalid': _vm.errors.has('firstname'),
                                  'has-invalid': _vm.errors.has('firstname'),
                                },attrs:{"type":"text","name":"firstname","data-vv-as":"first name","data-vv-name":"firstname","placeholder":"First Name","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstname=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('firstname')}},[_vm._v(" "+_vm._s(_vm.errors.first('firstname'))+" ")])])],1),_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"lastname"}},[_vm._v("Last Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.lastname),expression:"lastname",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                  input: true,
                                  'is-invalid': _vm.errors.has('lastname'),
                                  'has-invalid': _vm.errors.has('lastname'),
                                },attrs:{"type":"text","name":"lastname","data-vv-as":"last name","data-vv-name":"lastname","placeholder":"Last Name","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastname=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('lastname')}},[_vm._v(" "+_vm._s(_vm.errors.first('lastname'))+" ")])])],1)]),_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"email"}},[_vm._v("Email ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-control no-instruction",class:{
                                  input: true,
                                  'is-invalid': _vm.errors.has('email'),
                                  'has-invalid': _vm.errors.has('email'),
                                },attrs:{"type":"text","name":"email","data-vv-name":"email","placeholder":"Enter Email Address","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('email')}},[_vm._v(" "+_vm._s(_vm.errors.first('email'))+" ")])])],1),_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"role"}},[_vm._v("Select Role ")]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction custom-select",class:{
                                  'is-invalid': _vm.errors.has('role'),
                                  'has-invalid': _vm.errors.has('role'),
                                },attrs:{"options":_vm.roles,"name":"role","placeholder":"Please select role","data-vv-as":"role","data-vv-name":"role"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('role')}},[_vm._v(" "+_vm._s(_vm.errors.first('role'))+" ")])],1)],1)]),_c('div',{staticClass:"row px-4"},[_c('b-col',{staticClass:"order-md-last text-default strong",attrs:{"md":"6"}},[_c('small',{staticClass:"font-weight-bold"},[_vm._v("User password must not contain any blank spaces and meet the following criteria:")]),_c('ul',{staticClass:"list-icon pt-3"},[_c('li',{class:{
                                  'icon-check-circle-o muted': _vm.password.length == 0,
                                  'icon-check-circle valid': _vm.password.length >= 8,
                                  'icon-warning invalid': _vm.password.length < 8 && _vm.password.length > 0,
                                }},[_vm._v(" 8 characters ")]),_c('li',{class:{
                                  'icon-check-circle-o muted': _vm.hasLowerCase === false && _vm.hasUpperCase === false,
                                  'icon-exclamation-circle warning': (!_vm.hasLowerCase && _vm.hasUpperCase) || (_vm.hasUpperCase === false && _vm.hasLowerCase),
                                  'icon-check-circle valid': _vm.hasLowercaseUppercase,
                                  'icon-warning invalid': _vm.hasLowercaseUppercase === false && _vm.password.length > 0,
                                }},[_vm._v(" Uppercase and lowercase letters ")]),_c('li',{class:{
                                  'icon-check-circle-o muted': _vm.password.length == 0,
                                  'icon-check-circle valid': _vm.hasNumber,
                                  'icon-warning invalid': !_vm.hasNumber && _vm.password.length > 0,
                                }},[_vm._v(" Number(s) ")]),_c('li',{class:{
                                  'icon-check-circle-o muted': _vm.password.length == 0,
                                  'icon-check-circle valid': _vm.hasSpecialChar,
                                  'icon-warning invalid': !_vm.hasSpecialChar && _vm.password.length > 0,
                                }},[_vm._v(" Special character(s) ")])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar passwordMeter",style:({ width: _vm.progressWidth + '%' }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.progressLabel)+" ")])])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"password"}},[_vm._v("Password "),(_vm.passwordShow)?_c('small',{staticClass:"pull-right mr-1"},[_c('a',[_c('i',{staticClass:"text-gray toggle-visibility",class:_vm.iconType.password,on:{"click":function($event){return _vm.switchVisibility('password')}}})])]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:({ required: _vm.action === 'create' }),expression:"{ required: action === 'create' }"}],staticClass:"form-control no-instruction",class:{
                                  input: true,
                                  'is-invalid': _vm.errors.has('password'),
                                  'has-invalid': _vm.errors.has('password') || (_vm.password.length > 0 && _vm.progressWidth <= 40),
                                  'has-valid': _vm.progressWidth >= 75 && _vm.hasLowercaseUppercase && _vm.hasNumber && _vm.hasSpecialChar,
                                  'has-warning': _vm.password.length > 0 && _vm.progressWidth > 40 && _vm.progressWidth < 75,
                                },attrs:{"type":_vm.passwordFieldType.password,"name":"password","data-vv-as":"new password","data-vv-name":"password","data-vv-delay":"1000","placeholder":"Enter new password","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.password=$event.target.value.trim()},_vm.passwordCheck],"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('password')}},[_vm._v(" "+_vm._s(_vm.errors.first('password'))+" ")])])],1)],1),_c('div',{staticClass:"row mt-5 px-4"},[_c('div',{staticClass:"col-lg-5 col-md-5 col-12"},[_c('router-link',{ref:"clear",staticClass:"btn btn-outline-danger pr-5 pl-5 w-sm-100",attrs:{"to":"/user/admins","exact":""}},[_vm._v("Cancel")])],1),_c('div',{staticClass:"col-lg-7 col-md-7 col-12"},[_c('button',{staticClass:"btn btn-primary float-right w-sm-100 mb-3 mb-md-0",attrs:{"type":"button","id":"processAdminUser"},on:{"click":function($event){return _vm.processAdminUser(_vm.userID)}}},[_vm._v(" "+_vm._s(_vm.action === 'create' ? 'Create User' : 'Update User')+" "),_c('span',{staticClass:"promise-btn__spinner-wrapper hidden"},[_c('span',{staticClass:"spinner",staticStyle:{"border-top-color":"rgb(52, 152, 219)","width":"18px","height":"18px","border-width":"4px","animation-duration":"1s"},attrs:{"data-v-cc3e8e04":""}})])])])])])])])],1)],1)],1)])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }