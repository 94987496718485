import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

/*
  $http (url, data, method, header)
*/

const getSettings = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/all`, null, 'GET', header);
};

const createSettings = (token, settingUrl, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/${settingUrl}`, data, 'POST', header);
};

const updateSettings = (token, settingUrl, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/${settingUrl}`, data, 'PUT', header);
};

export { getSettings, createSettings, updateSettings };
