import $http from '../utils/http';

export function getVideos(token, query) {
  const header = token ? { authorization: token } : null;
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : '';
  return $http(`/api/videos${query}`, null, 'GET', header);
}

export function createVideo(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/videos`, data, 'POST', header);
}

export function updateVideo(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };
  const id = JSON.parse(data.get('video'))._id;

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/videos/${id}`, data, 'PUT', header);
}

export function deleteVideo(token, id) {
  const header = token ? { authorization: token } : null;
  return $http(`/api/videos/${id}`, null, 'DELETE', header);
}
