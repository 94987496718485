<template>
  <card class="card-stats" :show-footer-line="true">
    <a :href="link" class="card-link" :class="{ 'has-link': link != null }"
      ><div class="row">
        <div class="col">
          <slot>
            <h5 class="card-title text-uppercase text-gray mb-0" v-if="title">{{ title }}</h5>
            <span class="h1 font-weight-bold mb-0" v-if="subTitle">
              {{ prefix }}
              <ICountUp v-if="countUp" :delay="delay" :endVal="formattedSubTitle" :options="countUpOptions" />
              <span v-else>{{ subTitle }}</span>
            </span>
          </slot>
        </div>

        <div class="col-auto" v-if="$slots.icon || icon">
          <slot name="icon">
            <div class="icon icon-shape text-white rounded-circle shadow" :class="[`bg-${type}`, iconClasses]">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
      </div>
    </a>

    <p class="mt-3 mb-0 text-sm">
      <slot name="footer"> </slot>
    </p>
  </card>
</template>
<script>
import Card from './Card.vue';
import ICountUp from 'vue-countup-v2';

export default {
  name: 'stats-card',
  components: {
    Card,
    ICountUp,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    icon: String,
    title: String,
    subTitle: String,
    prefix: {
      type: String,
      default: '',
    },
    subTitleType: {
      type: String,
      default: 'number',
    },
    countUp: {
      type: Boolean,
      default: true,
    },
    iconClasses: [String, Array],
    link: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formattedSubTitle: 0,
      delay: 1000,
      countUpOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    };
  },
  created() {
    this.formattedSubTitle =
      this.subTitleType === 'number' ? parseInt(this.subTitle) : this.subTitleType === 'decimal' ? parseFloat(this.subTitle) : this.subTitle;
  },
};
</script>
<style>
.card-link {
  cursor: default !important;
}

.has-link {
  cursor: pointer !important;
}
</style>
