import Vue from 'vue';
import { getMobileUsers, getClientUsers, updateUserByID, deleteMobileByUserID, cancelSubscription, getUserByID } from '@/api/request/users';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_MOBILE_USERS: ({ commit, dispatch, state }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return getMobileUsers(token)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              var userListSource = resp.data.data;
              var newUserList = {};
              let userList = userListSource.map((value) => {
                var profilesStatuses = [];
                let data = {
                  userID: value._id,
                  email: value.email,
                  firstname: value.firstname || '',
                  lastname: value.lastname || '',
                  fullname: `${value.firstname} ${value.lastname}` || '',
                  displayName: value.displayName || '',
                  avatar: value.avatar || '/img/avatars/user-sample-gray.png',
                  points: value.points,
                  status: value.isActive === 1 ? 'active' : ['unverified', 'abandoned'].includes(value.status) ? value.status : 'inactive',
                  blockedStatus: value.isBlocked === 1 ? 'blocked' : 'active',
                  profiles: value.profiles,
                  profilesStatuses: profilesStatuses,
                  stripe: value.stripe,
                  dateCreated: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  dateCreatedYmd: moment(new Date(value.createdAt)).format('YYYY-MM-DD'),
                  dateCreatedHms: moment(new Date(value.createdAt)).format('HH:mm:SS'),
                  actions: value._id,
                  validationCode: value.validationCode,
                };
                return data;
              });

              userList = userList.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              newUserList['all'] = userList || [];
              newUserList['active'] =
                userList.filter((user) => {
                  return user.status === 'active';
                }) || [];
              // newUserList['inactive'] = userList.filter((user) => { return user.status === 'inactive' && user.blockedStatus !== 'blocked'}) || []
              // newUserList['failed'] = userList.filter((user) => { return user.status === 'failed registration' && user.blockedStatus !== 'blocked'}) || []
              newUserList['unverified'] =
                userList.filter((user) => {
                  return user.status === 'unverified' && user.blockedStatus !== 'blocked';
                }) || [];
              newUserList['abandoned'] =
                userList.filter((user) => {
                  return user.status === 'abandoned' && user.blockedStatus !== 'blocked';
                }) || [];
              newUserList['suspended'] =
                userList.filter((user) => {
                  return user.blockedStatus === 'blocked';
                }) || [];

              // newUserList['suspended'] = userList.filter((user) => { return user.status === 'suspended'}) || []

              commit('SET_USERS_SUCCESS', newUserList);
              resolve(newUserList);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_USER_INFO: ({ commit, dispatch }, { userData, userID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_USERS_INFO_REQUEST');
    return new Promise((resolve, reject) => {
      return updateMobileUsers(token, 'info', userData, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_UPDATE_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('SET_UPDATE_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_UPDATE_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_USER_BILLING: ({ commit, dispatch }, { userChildBillingData, profileID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_USERS_INFO_REQUEST');
    return new Promise((resolve, reject) => {
      return updateMobileUsers(token, 'billing', userChildBillingData, profileID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_UPDATE_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('SET_UPDATE_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('SET_UPDATE_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_USER_STATUS: ({ commit, dispatch }, { userData, userID }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateUserByID(token, 'status', userData, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_MOBILE_USER: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteMobileByUserID(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_DELETED_USER_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  CANCEL_SUBSCRIPTION: ({ commit, dispatch }, { profileID, action, formData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USERS_REQUEST');
    return new Promise((resolve, reject) => {
      return cancelSubscription(token, profileID, action, formData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_USERS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('GET_USERS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_USERS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CLIENT_USERS: ({ commit, dispatch, state }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_CLIENTS_REQUEST');
    return new Promise((resolve, reject) => {
      return getClientUsers(token)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              var userListSource = resp.data.data;
              var newUserList = {};
              let userList = userListSource.map((value) => {
                var profilesStatuses = [];

                let data = {
                  userID: value._id,
                  email: value.email,
                  firstname: value.firstname || '',
                  lastname: value.lastname || '',
                  fullname: `${value.firstname} ${value.lastname}` || '',
                  username: value.username || '',
                  avatar: value.avatar || '/img/avatars/user-sample-gray.png',
                  phone: value.phone,
                  textingOk: value.textingOk,
                  status: value.isActive === 1 ? 'active' : ['unverified'].includes(value.status) ? value.status : 'inactive',
                  billing: value.billing || {},
                  address: value.billing && 'address' in value.billing ? value.billing.address : {},
                  isActive: value.isActive,
                  isBlock: value.isBlock,
                  isDeleted: value.isDeleted,
                  blockedStatus: value.isBlocked === 1 ? 'blocked' : 'active',
                  dateCreated: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  dateCreatedYmd: moment(new Date(value.createdAt)).format('YYYY-MM-DD'),
                  dateCreatedHms: moment(new Date(value.createdAt)).format('HH:mm:SS'),
                  actions: value._id,
                  _showDetails: false,
                };
                return data;
              });

              userList = userList.sort(function (a, b) {
                return new Date(b.dateCreated) - new Date(a.dateCreated);
              });

              newUserList['all'] = userList || [];
              newUserList['active'] =
                userList.filter((user) => {
                  return user.status === 'active';
                }) || [];
              newUserList['unverified'] =
                userList.filter((user) => {
                  return user.status === 'unverified' && user.blockedStatus !== 'blocked';
                }) || [];
              newUserList['suspended'] =
                userList.filter((user) => {
                  return user.blockedStatus === 'blocked';
                }) || [];

              // newUserList['suspended'] = userList.filter((user) => { return user.status === 'suspended'}) || []

              commit('SET_CLIENTS_SUCCESS', newUserList);
              resolve(newUserList);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          commit('GET_CLIENTS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_USER_BY_ID: ({ commit, dispatch }, userId) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USER_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getUserByID(token, userId)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_USER_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_USER_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('GET_USER_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
