import Vue from 'vue';
import {
  getSubscriptions,
  getPlans,
  getUserSubscriptionByID,
  getSubscriptionByID,
  getCard,
  addCard,
  updateCard,
  updateSubscription,
  renewSubscription,
  getAdvertisementPlans,
  cancelSubscription,
} from '@/api/request/subscriptions';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_ADVERTISEMENTPLANS: ({ commit, dispatch }, query) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADVERTISEMENTPLANS_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdvertisementPlans(token, query)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('GET_ADVERTISEMENTPLANS_LIST_SUCCESS', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_ADVERTISEMENTPLANS_LIST_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADVERTISEMENTPLANS_LIST_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_SUBSCRIPTIONS: ({ commit, dispatch }, query) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_SUBSCRIPTIONS_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getSubscriptions(token, query)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let subscriptionsList = resp.data.data;
              let SubscriptionsList = subscriptionsList.map((value) => {
                return {
                  _id: value.default.stripeSubscriptionId,
                  plan_id: value._id,
                  name: value.name,
                  description: value.description,
                  price: value.default.price,
                  term: value.default.term,
                  trial: value.default.trial,
                  numberOfImages: value.numberOfImages,
                  isActive: value.isActive,
                  hidden: value.hidden,
                  currency: value.default.currency,
                  date_updated: moment(new Date(value.timestamp.updated)).format('YYYY-MM-DD hh:mm A'),
                  actions: value._id,
                };
              });
              commit('SET_SUBSCRIPTIONS_LIST_SUCCESS', SubscriptionsList);
              resolve(SubscriptionsList);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_PLANS: ({ commit, dispatch }, query) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_SUBSCRIPTIONS_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getPlans(token, query)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let subscriptionsList = resp.data.data;
              let SubscriptionsList = subscriptionsList.map((value) => {
                return {
                  _id: value._id,
                  name: value.name,
                  numberOfImages: value.numberOfImages,
                };
              });
              commit('SET_PLANS_LIST_SUCCESS', SubscriptionsList);
              resolve(SubscriptionsList);
            } else {
              commit('GET_SUBSCRIPTIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_SUBSCRIPTIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_SUBSCRIPTION_BY_ID: ({ commit, dispatch }, subscriptionID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_SUBSCRIPTION_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getSubscriptionByID(token, subscriptionID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_SUBSCRIPTION_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_SUBSCRIPTION_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('GET_SUBSCRIPTION_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_USER_SUBSCRIPTION_BY_ID: ({ commit, dispatch }, userId) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_USER_SUBSCRIPTION_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getUserSubscriptionByID(token, userId)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_USER_SUBSCRIPTION_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_USER_SUBSCRIPTION_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('GET_USER_SUBSCRIPTION_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CARD: ({ commit, dispatch }, userId) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CARD_REQUEST');
    return new Promise((resolve, reject) => {
      return getCard(token, userId)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              resolve(resp.data);
              commit('GET_CARD_SUCCESS', resp.data);
            } else {
              commit('GET_CARD_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_CARD_FAILED');
          reject(errors(err));
        });
    });
  },
  ADD_CARD: ({ commit, dispatch }, { userId, cardData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_CARD_REQUEST');
    return new Promise((resolve, reject) => {
      return addCard(token, userId, cardData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CARD_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_CARD_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_CARD_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_CARD: ({ commit, dispatch }, { userId, cardData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_CARD_REQUEST');
    return new Promise((resolve, reject) => {
      return updateCard(token, userId, cardData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CARD_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_CARD_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_CARD_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_SUBSCRIPTION: ({ commit, dispatch }, { subscriptionID, subscriptionData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_SUBSCRIPTION_REQUEST');
    return new Promise((resolve, reject) => {
      return updateSubscription(token, subscriptionID, subscriptionData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_SUBSCRIPTION_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_SUBSCRIPTION_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_SUBSCRIPTION_FAILED');
          reject(errors(err));
        });
    });
  },
  RENEW_SUBSCRIPTION: ({ commit, dispatch }, userId) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('RENEW_SUBSCRIPTION_REQUEST');
    return new Promise((resolve, reject) => {
      return renewSubscription(token, userId)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('RENEW_SUBSCRIPTION_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('RENEW_SUBSCRIPTION_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('RENEW_SUBSCRIPTION_FAILED');
          reject(errors(err));
        });
    });
  },
  CANCEL_SUBSCRIPTION: ({ commit, dispatch }, userId) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('CANCEL_SUBSCRIPTION_REQUEST');
    return new Promise((resolve, reject) => {
      return cancelSubscription(token, userId)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('CANCEL_SUBSCRIPTION_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('CANCEL_SUBSCRIPTION_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('CANCEL_SUBSCRIPTION_FAILED');
          reject(errors(err));
        });
    });
  },
};
export default actions;
