const state = {
  loading: false,
  loadedOnce: false,
  userloadedOnce: false,
  success: false,
  error: false,
  errorUser: false,
  userList: {},
  userClientList: {},
  selectedUser: {},
  selectedBusiness: {},
  selectedUserSuccess: false,
  selectedUserError: false,
};

export default state;
