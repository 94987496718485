<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createAdminContainer">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Add City</h3>
              </div>
            </div>

            <b-row id="processCity">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-row v-if="loading">
                    <div class="col-lg-12 text-center">
                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                    </div>
                  </b-row>
                  <b-card-body class="pt-0" v-else>
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row">
                            <div class="col-lg-12">
                              <b-form-group role="group" class="mb-3 has-feedback">
                                <label class="w-100 form-control-label" for="city">City Name </label>
                                <input
                                  type="text"
                                  name="city"
                                  v-model.trim="city"
                                  data-vv-as="city"
                                  v-validate="'required'"
                                  data-vv-name="city"
                                  placeholder="City Name"
                                  autocomplete="NoAutocomplete"
                                  class="form-control no-instruction"
                                  :class="{
                                    input: true,
                                    'is-invalid': errors.has('city'),
                                    'has-invalid': errors.has('city'),
                                  }" />
                                <span class="has-icon-feedback"></span>
                                <div class="invalid-feedback" :is-invalid="errors.has('city')">
                                  {{ errors.first('city') }}
                                </div>
                              </b-form-group>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link ref="clear" :to="`/contents/cities`" exact class="btn btn-outline-danger pr-5 pl-5 w-sm-100">Cancel</router-link>
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button type="button" id="processCity" @click="processCity(cityID)" class="btn btn-primary float-right w-sm-100 mb-3 mb-md-0">
                                {{ action === 'create' ? 'Create City' : 'Update City' }}
                                <span class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import { Validator } from 'vee-validate';

Vue.use(Cookies);

export default {
  data() {
    return {
      action: 'create',
      city: '',
      cityID: '',
    };
  },
  computed: {
    loading() {
      return this.$store.state.cities.loading;
    },
  },
  methods: {
    getCity(cityID) {
      this.$store.dispatch('cities/GET_CITY_BY_ID', cityID).then((selectedCity) => {
        this.city = selectedCity.city;
        this.cityID = selectedCity._id;
      });
    },
    processCity(cityID) {
      if (this.action === 'create') {
        this.createCity();
      } else {
        this.updateCity(cityID);
      }
    },
    createCity() {
      const promiseBtn = document.getElementById('processCity');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processCity');
      bodyWrapper.classList.add('non-clickable');

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let formData = new FormData();
          let city = { city: this.city };
          console.log(city);
          formData.set('city', JSON.stringify(city));
          this.$store
            .dispatch('cities/ADD_CITY', formData)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                this.city = '';

                this.$notify({
                  message: this.$options.filters.capitalizeWords('City successfully created'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });

                this.$router.push('/contents/cities');
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error adding city'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }
              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.log({ err });
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
                promiseBtnInner.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
                bodyWrapper.classList.remove('non-clickable');
              });
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateCity() {
      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          let formData = new FormData();
          let city = {
            _id: this.cityID,
            city: this.city,
          };
          formData.set('city', JSON.stringify(city));

          this.$store
            .dispatch('cities/UPDATE_CITY', formData)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('City successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.$router.push('/contents/cities');
                this.user = {};
                this.name = '';
                this.email = '';
                this.password = '';
                (this.role = null),
                  (this.business = {
                    name: '',
                    address: {
                      line1: '',
                      line2: '',
                    },
                    email: '',
                    contactNumber: '',
                  });
              } else {
                if (res.data.error === 'error_user_already_exist') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Connection already exists'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Error updating connection'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                }
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
                promiseBtnInner.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
                bodyWrapper.classList.remove('non-clickable');
              });
            });
        }
      });
    },
  },
  mounted() {
    this.action = this.$route.params.id ? 'update' : 'create';
    this.getCity(this.$route.params.id);
  },
};
</script>
<style lang="scss"></style>
