<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <h1 class="page-header">{{ type === 'user' ? 'Add Role to User' : 'Add New Role' }}</h1>
      </b-col>
    </b-row>

    <b-row v-if="type === 'user'">
      <b-col cols="12" class="mb-3">
        <SuggestUserInput placeholder="Search User" :onGetUser="handleSelectSuggestUser" :validationStatus="validateUsername" mode="custom" />
      </b-col>
    </b-row>

    <form>
      <b-row>
        <b-col cols="12">
          <b-card no-body>
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-form-group class="mb-5" v-if="type == 'role'">
                    <label for="amount" class="d-block">Set Role Name</label>
                    <input
                      type="text"
                      name="roleName"
                      placeholder="ex. Marketing"
                      v-model="roleName"
                      v-validate="'required'"
                      data-vv-as="role name"
                      class="form-control"
                      :class="{ 'is-invalid': errors.has('roleName') }" />
                    <div class="invalid-feedback" :is-invalid="errors.has('roleName')">
                      {{ errors.first('roleName') }}
                    </div>
                  </b-form-group>
                </b-col>

                <b-col md="12" v-if="type === 'user'">
                  <div class="highlight-msg py-2" v-if="userData.length >= 1">
                    <b-row v-if="userData.length == 1 && userDataList.length <= 1">
                      <b-col>
                        <span class="text-gray">Username</span>
                        <h3 class="card-heading mb-1">{{ userData[0].userName }}</h3>
                        <!-- <small class="text-gray">Current Role</small> -->
                        <!-- <p class="mb-0">Role name here</p> -->
                      </b-col>
                      <b-col>
                        <small class="text-gray">Name</small>
                        <p class="mb-2">{{ userData[0].firstName }} {{ userData[0].lastName }}</p>
                        <small class="text-gray">Email</small>
                        <p class="mb-0">{{ userData[0].email }}</p>
                      </b-col>
                    </b-row>
                    <div class="tag-container" v-if="userDataList.length > 1">
                      <span class="text-gray">Users</span>
                      <ul class="tags my-3">
                        <li class="tag bg-secondary text-white" v-for="(user, key) in userData" :key="key">
                          <div class="content" :id="`userInfo${key}`">
                            <div class="tag-center">
                              <span>{{ user.userName }}</span>
                            </div>
                          </div>
                          <div class="actions">
                            <i class="tag-close" @click="removeUserData(user._id)" v-b-tooltip.hover title="Remove User"></i>
                          </div>
                          <b-popover :target="`userInfo${key}`" triggers="hover" placement="top">
                            <div class="text-center">
                              <span class="d-block">{{ user.firstName }} {{ user.lastName }}</span>
                              <!-- <span class="d-block">Current Role Name</span> -->
                              <span class="text-gray-600">{{ user.email }}</span>
                            </div>
                          </b-popover>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr class="mb-0" v-if="userDataList.length >= 1" />
                  <p class="text-center" v-if="userDataList.length >= 1">
                    <i class="fa fa-angle-down text-gray"></i>
                  </p>
                </b-col>
                <b-col cols="12" v-if="userData.length < 1 && type !== 'role'">
                  <h4 class="text-center my-5">Search a username to assign a role</h4>
                </b-col>
                <b-col md="6" v-if="userData.length >= 1 || type == 'role'">
                  <b-form-group class="mb-5" v-if="type === 'user'">
                    <label for="amount" class="d-block">Select Role</label>
                    <div class="d-flex">
                      <b-form-select v-model="selectedRole" :options="filter" name="Filter" placeholder="Please select role"> </b-form-select>
                      <div class="d-flex align-items-center">
                        <span class="text-gray mx-3">Or</span>
                        <a @click="addNewRole" class="btn btn-secondary cursor-pointer text-white">
                          <i class="fa fa-plus-circle"></i>
                          Create New Role
                        </a>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-5" v-if="type === 'user' && isNewRole">
                    <label for="amount" class="d-block">Set Role Name</label>
                    <b-form-input type="text" v-model="newRoleName" placeholder="ex. Marketing"> </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Permission Table-->
              <b-card no-body v-if="selectedPermission.length > 0 && type === 'user'">
                <b-card-body>
                  <b-row>
                    <b-col class="col-12">
                      <h4 class="card-heading float-left" cols="6">Role: {{ this.selectedRole.name }}</h4>
                      <b-table
                        class="my-4"
                        responsive="sm"
                        :items="type === 'user' ? selectedPermission : []"
                        :fields="permissionFields"
                        v-if="selectedPermission.length > 0"
                        head-variant="light">
                        <div slot="module" slot-scope="item">
                          <i :class="item.item.module.icon" class="fa mr-2 text-gray-300"></i>
                          {{ item.item.module.label }}
                        </div>

                        <div slot="permissions" slot-scope="item" class="text-secondary">
                          <ul v-show="moreInfo" class="list-unstyled list-inline">
                            <li class="list-inline-item mx-5">
                              <i class="fa fa-eye" :class="{ 'd-none': !item.item.permissions.view }"></i>
                              <span :class="{ 'd-none': !item.item.permissions.view }">View</span>
                            </li>

                            <li class="list-inline-item mx-5">
                              <i class="fa fa-plus" :class="{ 'd-none': !item.item.permissions.create }"></i>
                              <span :class="{ 'd-none': !item.item.permissions.view }">Add</span>
                            </li>

                            <li class="list-inline-item mx-5">
                              <i class="fa fa-pencil" :class="{ 'd-none': !item.item.permissions.update }"></i>
                              <span :class="{ 'd-none': !item.item.permissions.view }">Edit</span>
                            </li>

                            <li class="list-inline-item mx-5">
                              <i class="fa fa-trash" :class="{ 'd-none': !item.item.permissions.remove }"></i>
                              <span :class="{ 'd-none': !item.item.permissions.view }">Delete</span>
                            </li>
                          </ul>
                        </div>
                      </b-table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <!-- End Permission Table -->
              <!-- New Role and Permission Setting -->
              <b-row v-if="(isNewRole && type === 'user') || type === 'role'">
                <b-col class="col-12 col-md-3">
                  <span @click="moreInfo = !moreInfo" class="btn btn-link btn-sm text-muted float-right">
                    {{ moreInfo ? 'Hide Info' : 'Show Info' }}
                  </span>

                  <h4 class="card-heading">Access</h4>
                  <div class="side-nav-container">
                    <ul class="side-nav">
                      <li class="side-nav-item" v-for="(permission, key) in type === 'role' ? rolePermissions : newRolePermissions" :key="key">
                        <a class="cursor-pointer pl-0 pr-0" :class="handleActiveSubPermission(key)" @click="handleSetPermissionData(key)">
                          <div class="w-100 d-flex">
                            <span class="">
                              <i :class="permission.icon" class="fa ml-3"></i>
                            </span>
                            <span class="subtitle">
                              {{ permission.label }}
                            </span>
                          </div>
                          <ul v-show="moreInfo" class="list-unstyled permission-list">
                            <li :class="{ 'd-none': !permission.view }" v-b-tooltip.hover title="View Lorem ipsum dolor sit amet.">
                              <i class="fa fa-eye"></i>
                            </li>

                            <li :class="{ 'd-none': !permission.create }" v-b-tooltip.hover title="Add Lorem ipsum dolor sit amet.">
                              <i class="fa fa-plus"></i>
                            </li>

                            <li :class="{ 'd-none': !permission.update }" v-b-tooltip.hover title="Edit / Update Lorem ipsum dolor sit amet.">
                              <i class="fa fa-pencil"></i>
                            </li>

                            <li :class="{ 'd-none': !permission.remove }" v-b-tooltip.hover title="Delete Lorem ipsum dolor sit amet.">
                              <i class="fa fa-trash"></i>
                            </li>
                          </ul>
                        </a>
                      </li>
                    </ul>
                  </div>
                </b-col>

                <b-col class="col-12 col-md-9">
                  <b-row>
                    <b-col sm="12">
                      <b-card class="mb-4">
                        <button class="btn btn-link btn-sm float-right" @click="handleCheckAll()" type="button">
                          {{ isUncheckAll ? 'Uncheck all' : 'Check All' }}
                        </button>

                        <h4 class="card-heading">
                          Permissions {{ permissionData.label !== undefined && ':' }}
                          {{ permissionData.label !== undefined && permissionData.label }}
                        </h4>

                        <b-list-group flush v-if="permissionData.label !== undefined">
                          <b-list-group-item @click="handleCheckOthers">
                            <div class="d-flex w-100 justify-content-between my-2">
                              <div>
                                <i
                                  class="fa fa-eye text-white circle-icon mr-3 float-left"
                                  :class="{ 'bg-secondary': permissionData.view, 'bg-gray-200': !permissionData.view }">
                                </i>
                              </div>
                              <div class="w-100">
                                <h5 class="mb-1">View Page</h5>
                                <p class="mb-1 text-gray">
                                  {{ permissionData.viewDesc }}
                                </p>
                              </div>

                              <div class="d-flex align-items-center">
                                <div class="custom-control custom-checkbox custom-control-inline m-0">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="ViewCheckbox"
                                    :checked="permissionData.view"
                                    @change="handleCheckOthers" />

                                  <label class="custom-control-label" for="ViewCheckbox"> </label>
                                </div>
                              </div>
                            </div>
                          </b-list-group-item>
                          <b-list-group-item v-if="permissionData.createEnable" @click="enableView('create')">
                            <div class="d-flex w-100 justify-content-between my-2">
                              <div>
                                <i
                                  class="fa fa-plus text-white circle-icon mr-3 float-left"
                                  :class="{ 'bg-secondary': permissionData.create, 'bg-gray-200': !permissionData.create }"></i>
                              </div>
                              <div class="w-100">
                                <h5 class="mb-1">Add</h5>
                                <p class="mb-1 text-gray">
                                  {{ permissionData.createDesc }}
                                </p>
                              </div>

                              <div class="d-flex align-items-center">
                                <div class="custom-control custom-checkbox custom-control-inline m-0">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="AddCheckbox"
                                    :checked="permissionData.create"
                                    @change="enableView('create')" />
                                  <label class="custom-control-label" for="AddCheckbox"> </label>
                                </div>
                              </div>
                            </div>
                          </b-list-group-item>

                          <b-list-group-item v-if="permissionData.updateEnable" @click="enableView('update')">
                            <div class="d-flex w-100 justify-content-between my-2">
                              <div>
                                <i
                                  class="fa fa-pencil text-white circle-icon mr-3 float-left"
                                  :class="{ 'bg-secondary': permissionData.update, 'bg-gray-200': !permissionData.update }"></i>
                              </div>

                              <div class="w-100">
                                <h5 class="mb-1">Edit / Update</h5>
                                <p class="mb-1 text-gray">
                                  {{ permissionData.updateDesc }}
                                </p>
                              </div>

                              <div class="d-flex align-items-center">
                                <div class="custom-control custom-checkbox custom-control-inline m-0">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="EditCheckbox"
                                    :checked="permissionData.update"
                                    @change="enableView('update')" />
                                  <label class="custom-control-label" for="EditCheckbox"> </label>
                                </div>
                              </div>
                            </div>
                          </b-list-group-item>

                          <b-list-group-item v-if="permissionData.removeEnable" @click="enableView('remove')">
                            <div class="d-flex w-100 justify-content-between my-2">
                              <div>
                                <i
                                  class="fa fa-trash text-white circle-icon mr-3 float-left"
                                  :class="{ 'bg-secondary': permissionData.remove, 'bg-gray-200': !permissionData.remove }"></i>
                              </div>
                              <div class="w-100">
                                <h5 class="mb-1">Delete</h5>
                                <p class="mb-1 text-gray">
                                  {{ permissionData.removeDesc }}
                                </p>
                              </div>

                              <div class="d-flex align-items-center">
                                <div class="custom-control custom-checkbox custom-control-inline m-0">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="deleteCheckbox"
                                    :checked="permissionData.remove"
                                    @change="enableView('remove')" />
                                  <label class="custom-control-label" for="deleteCheckbox"> </label>
                                </div>
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- End New Role and Permission Setting -->

              <div class="row form-action">
                <div class="col-md-6 order-md-last">
                  <button
                    @click="handleSave"
                    type="button"
                    name="button"
                    v-promise-btn
                    id="btn-spinner"
                    class="btn btn-primary pr-5 pl-5 float-right w-sm-100 mb-3 mb-md-0"
                    v-if="userData.length >= 1 || type == 'role'">
                    Save
                  </button>
                </div>

                <div class="col-md-6">
                  <router-link to="/permissions" exact tag="button" name="button" class="btn btn-default pr-5 pl-5 w-sm-100"> Cancel </router-link>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import { rolesSerializer } from '@/utils/accessList';

export default {
  name: 'EditAdminRoles',
  data() {
    return {
      moreInfo: true,
      isNewRole: false,
      type: '',
      permissions: {},
      permissionData: {},
      newPermissions: {},
      roleName: '',
      selectedRole: null,
      newRoleName: '',
      validateUsername: null,
      userData: [],
      userDataList: [],
      selectedPermission: [],
      permissionFields: {
        module: {
          icon: 'icon',
          label: 'module',
          thStyle: {
            display: 'none',
          },
        },
        permissions: {
          label: 'permissions',
          thStyle: {
            display: 'none',
          },
        },
      },
    };
  },
  created() {
    if (!this.$store.state.roles.loadedOnce) {
      this.$store.dispatch('roles/GET_ROLE_LIST');
    }

    this.type = this.$route.params.type;
    if (this.$route.params.type === 'role') {
      this.getPermissionList();
    }
  },
  computed: {
    filter() {
      const data = this.roleList.map((role) => {
        return { value: role, text: role.name };
      });

      return [{ value: null, text: 'Please select role', disabled: true }, { value: 'new', text: 'Create New Role' }, ...data];
    },
    newRolePermissions() {
      return this.newPermissions;
    },
    rolePermissions: {
      get: function () {
        return this.permissions;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    roleList: {
      get: function () {
        return this.$store.state.roles.roles;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    isUncheckAll() {
      var permissionValue = [];
      var permissionEnable = [];
      var arrayChecked = [];
      permissionEnable['view'] = rolesSerializer[this.permissionData.id].viewEnable;
      permissionEnable['create'] = rolesSerializer[this.permissionData.id].createEnable;
      permissionEnable['remove'] = rolesSerializer[this.permissionData.id].removeEnable;
      permissionEnable['update'] = rolesSerializer[this.permissionData.id].updateEnable;

      for (let key in permissionEnable) {
        if (permissionEnable[key] !== undefined) {
          permissionValue.push(key);
        }
      }

      for (let key in permissionValue) {
        arrayChecked.push(this.permissionData[permissionValue[key]]);
      }

      const checkedFields = arrayChecked.every((isChecked) => isChecked);

      if (checkedFields) {
        return true;
      }

      return false;
    },
  },
  watch: {
    selectedRole(role) {
      if (role === 'new') {
        this.isNewRole = true;
        this.selectedPermission = [];
        this.newPermissions = rolesSerializer;
        this.getPermissionList();
      } else {
        this.isNewRole = false;
        this.roleList = this.$store.state.roles.roles;
        this.permissions = role.rolePermissions;
        // this.selectedPermission = Object.values(role.rolePermissions)
        var selectedPermission = Object.keys(role.rolePermissions).map(function (key, values) {
          return {
            id: key,
            ...role.rolePermissions[key],
          };
        });
        this.selectedPermission = selectedPermission.map((permission, key) => {
          return {
            module: {
              icon: rolesSerializer[permission.id].icon,
              label: permission.label,
            },
            permissions: {
              view: permission.view,
              create: permission.create,
              update: permission.update,
              remove: permission.remove,
            },
          };
        });
      }
    },
  },
  methods: {
    getPermissionList() {
      let permissions = {};
      this.permissions = rolesSerializer;

      for (var key in this.permissions) {
        permissions[key] = this.permissions[key];
      }

      const permission = Vue.lodash.filter(permissions, { index: 0 });
      this.permissionData = permission[0];
    },
    addNewRole() {
      this.isNewRole = true;
      this.selectedRole = 'new';
      this.selectedPermission = [];
      this.newPermissions = rolesSerializer;
      this.getPermissionList();
    },
    handleSetPermissionData(value) {
      this.permissionData = this.rolePermissions[value];
      this.permissionData.icon = rolesSerializer[this.permissionData.id].icon;
      this.permissionData.viewDesc = rolesSerializer[this.permissionData.id].viewDesc;
      this.permissionData.createDesc = rolesSerializer[this.permissionData.id].createDesc;
      this.permissionData.updateDesc = rolesSerializer[this.permissionData.id].updateDesc;
      this.permissionData.removeDesc = rolesSerializer[this.permissionData.id].removeDesc;
      this.permissionData.viewEnable = rolesSerializer[this.permissionData.id].viewEnable;
      this.permissionData.removeEnable = rolesSerializer[this.permissionData.id].removeEnable;
      this.permissionData.updateEnable = rolesSerializer[this.permissionData.id].updateEnable;
      this.permissionData.createEnable = rolesSerializer[this.permissionData.id].createEnable;
    },
    handleSelectSuggestUser(user) {
      this.userData.push(user);
      this.userDataList.push(user._id);
    },
    removeUserData(id) {
      const indexUserDataList = this.userDataList.indexOf(id);
      Vue.lodash.remove(this.userData, { _id: id });
      if (indexUserDataList > -1) {
        this.userDataList.splice(indexUserDataList, 1);
      }
    },
    handleActiveSubPermission(id) {
      return this.permissionData.id === id && 'active';
    },
    handleCheckOthers() {
      this.permissionData.view = !this.permissionData.view;
      if (!this.permissionData.view) {
        this.permissionData.create = this.permissionData.update = this.permissionData.remove = false;
      }
      // this.permissionData.create = this.permissionData.createEnable && this.permissionData.view = this.permissionData.remove = this.permissionData.removeEnable && this.permissionData.view = this.permissionData.update = this.permissionData.updateEnable && this.permissionData.view
      this.permissionData.isChecked = this.permissionData.view;
    },
    handleCheckAll() {
      if (this.isUncheckAll) {
        this.permissionData.update = false;
        this.permissionData.view = false;
        this.permissionData.remove = false;
        this.permissionData.create = false;
        this.permissionData.isChecked = false;
      } else {
        this.permissionData.view = true;
        this.permissionData.create = this.permissionData.createEnable && this.permissionData.view;
        this.permissionData.remove = this.permissionData.removeEnable && this.permissionData.view;
        this.permissionData.update = this.permissionData.updateEnable && this.permissionData.view;
        this.permissionData.isChecked = true;
      }
    },
    enableView(action) {
      this.permissionData[action] = !this.permissionData[action];
      if (this.permissionData[action] || this.permissionData[action] || this.permissionData[action]) {
        this.permissionData.view = true;
        this.permissionData.isChecked = true;
      }
      // this.isUncheckAll = this.permissionData.view && this.permissionData.update && this.permissionData.remove && this.permissionData.create
    },
    handleUpdateRole(status) {
      if (status) {
        let promiseButton = document.getElementById('btn-spinner').querySelector('.promise-btn__spinner-wrapper');
        promiseButton.classList.remove('hidden');

        if (this.type === 'role') {
          const payload = {
            name: this.roleName,
            rolePermissions: this.rolePermissions,
            isDeleted: false,
          };

          this.$store
            .dispatch('roles/addRole', payload)
            .then((result) => {
              if (result && result.status === 200) {
                this.$noty.success('Role Successfully added!', {
                  theme: 'bootstrap-v4',
                  timeout: 1000,
                });
                setTimeout(() => {
                  this.$router.push('/permissions');
                }, 1500);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          if (!this.isNewRole) {
            const payload = {
              users: this.userDataList,
              roleID: this.selectedRole._id,
            };

            this.$store
              .dispatch('roles/setUserRole', payload)
              .then((result) => {
                this.$noty.success('Successfully set role to user!', {
                  theme: 'bootstrap-v4',
                  timeout: 1000,
                });
                setTimeout(() => {
                  this.$router.push('/permissions');
                }, 1500);
              })
              .catch();
          } else {
            const payload = {
              users: this.userDataList,
              role: {
                name: this.newRoleName,
                rolePermissions: this.rolePermissions,
                isDeleted: false,
              },
            };

            this.$store
              .dispatch('roles/setUserRole', payload)
              .then((result) => {
                this.$noty.success('Successfully set role to user!', {
                  theme: 'bootstrap-v4',
                  timeout: 1000,
                });
                setTimeout(() => {
                  this.$router.push('/permissions');
                }, 1500);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        }
      }
    },
    handleSave() {
      this.$validator.validateAll().then((isValid) => {
        const title = this.type === 'user' ? 'Add Role to User' : 'Add New Role';
        let message;
        if (this.type === 'user') {
          const usernames = this.userData.map(function (user) {
            return user.userName;
          });

          if (this.userData.length === 1) {
            message = 'Are you sure, you want to add ' + this.selectedRole.name + ' to ' + this.userData[0].userName + '?';
          } else if (this.userData.length === 2) {
            message = 'Are you sure, you want to add ' + this.selectedRole.name + ' to ' + usernames.join(' and ') + '?';
          } else {
            message = 'Are you sure, you want to add ' + this.selectedRole.name + ' to ' + usernames.join(', ') + '?';
          }
        } else {
          message = 'Are you sure, you want to add this role?';
        }

        if (isValid) {
          this.$swal({
            title: title,
            text: message,
            type: 'info',
            showCancelButton: true,
            cancelButtonClass: 'btn btn-default pr-5 pl-5',
            confirmButtonClass: 'btn btn-danger pr-5 pl-5',
            reverseButtons: true,
            confirmButtonText: 'Yes, add it!',
          }).then((result) => {
            if (result.value) {
              this.show2FA(this.handleUpdateRole);
            }
          });
        }
      });
    },
    tableLoaded() {
      if (!this.$refs.table) {
        return;
      }

      var headers = this.$refs.table.$el.querySelectorAll('thead tr');
      if (headers.length > 1) {
        return;
      }

      var firstTopHeader = document.createElement('tr');
      firstTopHeader.innerHTML = '<th>MODULE</th>' + '<th>GLOBAL PERMISSION</th>' + '<th colspan="4">Permissions</th>';
      headers[0].parentNode.insertBefore(firstTopHeader, headers[0]);

      var secondTopHeader = document.createElement('tr');
      secondTopHeader.innerHTML =
        '<th></th>' +
        '<th></th>' +
        '<th class="text-center"><i class="fa fa-eye"></i> VIEW</th><th class="text-center"><i class="fa fa-plus"></i> CREATE</th><th class="text-center"><i class="fa fa-pencil"></i> UPDATE</th><th class="text-center"><i class="fa fa-trash"></i> REMOVE</th>';
      headers[0].parentNode.insertBefore(secondTopHeader, headers[1]);
      headers[0].parentNode.childNodes[0].childNodes[2].classList.add('text-center');
      headers[0].parentNode.childNodes[1].remove();
    },
  },
  destroyed() {
    for (var key in this.permissions) {
      this.permissions[key].create = false;
      this.permissions[key].update = false;
      this.permissions[key].view = false;
      this.permissions[key].remove = false;
      this.permissions[key].isChecked = false;
    }
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.table tr td {
  width: 50% !important;
}
.permissionTable {
  width: 500px;
}
.permissionList li {
  width: 25% !important;
}
.non-clickable {
  pointer-events: none;
}
.permissions {
  font-size: 0.75rem;
}
.text-custom {
  color: #536c79;
}
.list-group.list-group-flush .list-group-item {
  cursor: pointer;
}
.side-nav .side-nav-item a .permission-list {
  margin-left: 0;
  padding-left: 3.5rem;
  margin-right: 0;
}

.slide-fade-enter-active {
  transition: 0.5s;
}
.slide-fade-leave-active {
  transition: 1s;
  opacity: 1;
}
.slide-fade-enter {
  opacity: 0;
  transform: translate(0, -150%);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-fade-leave-to {
  opacity: 0;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #20a8d8 !important;
}
.tag-center {
  cursor: default;
}
</style>
