import $http from '../utils/http';

export function getCities(query) {
  // const header = (token) ? { authorization: token } : null
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : '';
  return $http(`/api/cities${query}`, null, 'GET', null);
}

export function getCitiesByID(token, cityID) {
  const header = token ? { authorization: token } : null;
  return $http(`/api/cities/${cityID}`, null, 'GET', header);
}

export function addCity(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/cities`, data, 'POST', header);
}

export function updateCityStatus(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };
  const id = JSON.parse(data.get('city'))._id;

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/cities/${id}`, data, 'PUT', header);
}

export function updateCity(token, data) {
  const header = { 'Content-Type': 'multipart/form-data' };
  const id = JSON.parse(data.get('city'))._id;

  if (token) {
    header.authorization = token;
  }

  return $http(`/api/cities/${id}`, data, 'PUT', header);
}

export function deleteCity(token, id) {
  const header = token ? { authorization: token } : null;
  return $http(`/api/cities/${id}`, null, 'DELETE', header);
}
