import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/

const getSettings = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/appconfigs`, null, 'GET', header);
};

const updateSettings = (token, content) => {
  const header = token ? { 'Content-Type': undefined, authorization: token } : null;
  return $http(`/api/appconfigs`, content, 'PUT', header);
};

export { getSettings, updateSettings };
