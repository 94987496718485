import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

/*
  $http (url, data, method, header)
*/

const getMobileUsers = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/users/`, null, 'GET', header);
};

const getClientUsers = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/users/client`, null, 'GET', header);
};

const updateUserByID = (token, action, formData, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/${action}/${userID}`, formData, 'PUT', header);
};

const getUserByID = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/users/${userID}`, null, 'get', header);
};

const deleteMobileByUserID = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/users/${userID}`, '', 'DELETE', header);
};

const cancelSubscription = (token, profileID, action, formData) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/user/profile/${profileID}/subscription/${action}`, formData, 'PUT', header);
};

export { getUserByID, getMobileUsers, getClientUsers, updateUserByID, deleteMobileByUserID, cancelSubscription };
