<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Edit Role</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-12 col-8">
            <h3 class="mb-0">Edit Role</h3>
          </div>
        </div>

        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col v-if="type === 'role'" md="12">
                    <div class="highlight-msg py-2">
                      <b-row>
                        <b-col>
                          <span class="text-gray text-sm text-uppercase">Role Name</span>
                          <h4 class="card-heading mb-1">{{ role.name }}</h4>
                        </b-col>
                      </b-row>
                    </div>
                    <hr class="m-0" />
                    <p class="text-center">
                      <i class="fa fa-angle-down text-gray"></i>
                    </p>
                  </b-col>
                </b-row>
                <!-- Permission Table-->
                <b-card no-body v-if="selectedPermission.length > 0 && type === 'user'">
                  <b-card-body>
                    <b-row class="editRoleContainer">
                      <b-col class="col-12">
                        <h4 class="card-heading float-left" cols="6">Role: {{ this.selectedRole.name }}</h4>
                        <b-table
                          class="my-4"
                          responsive="sm"
                          :items="type === 'user' ? selectedPermission : []"
                          :fields="permissionFields"
                          v-if="selectedPermission.length > 0"
                          head-variant="light">
                          <div slot="module" slot-scope="item">
                            <i :class="item.item.module.icon" class="mr-2 text-gray-300"></i>
                            {{ item.item.module.label }}
                          </div>

                          <div slot="permissions" slot-scope="item" class="text-secondary permissionTable">
                            <ul v-show="moreInfo" class="permissionList list-unstyled permission-list">
                              <li class="pull-left">
                                <i class="fa fa-eye" :class="{ 'd-none': !item.item.permissions.view }"></i>
                                <span :class="{ 'd-none': item.item.permissions.view }">&nbsp;</span>
                              </li>

                              <li class="pull-left">
                                <i class="fa fa-plus" :class="{ 'd-none': !item.item.permissions.create }"></i>
                                <span :class="{ 'd-none': item.item.permissions.view }">&nbsp;</span>
                              </li>

                              <li class="pull-left">
                                <i class="fa fa-pencil" :class="{ 'd-none': !item.item.permissions.update }"></i>
                                <span :class="{ 'd-none': item.item.permissions.view }">&nbsp;</span>
                              </li>

                              <li class="pull-left">
                                <i class="fa fa-trash" :class="{ 'd-none': !item.item.permissions.remove }"></i>
                                <span class="{'d-none': item.item.permissions.view}">&nbsp;</span>
                              </li>
                            </ul>
                          </div>
                        </b-table>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
                <!-- End Permission Table -->

                <b-row v-if="(isNewRole && type === 'user') || type === 'role'">
                  <b-col class="col-12 col-md-3 no-gutter-right">
                    <span @click="moreInfo = !moreInfo" class="btn btn-link btn-sm text-gray float-right">
                      {{ moreInfo ? 'Hide Info' : 'Show Info' }}
                    </span>

                    <h4 class="card-heading">Access</h4>
                    <perfect-scrollbar>
                      <div id="access-list" class="side-nav-container">
                        <ul class="side-nav">
                          <li class="side-nav-item" v-for="(permission, key) in type === 'role' ? role.rolePermissions : newRolePermissions" :key="key">
                            <a class="cursor-pointer pl-0 pr-0" :class="handleActiveSubPermission(key)" @click="handleSetPermissionData(key)">
                              <div class="w-100 d-flex">
                                <span class="">
                                  <i :class="permission.icon" class="mx-3"></i>
                                </span>
                                <span class="subtitle">
                                  {{ permission.label }}
                                </span>
                              </div>

                              <ul v-show="moreInfo" class="list-unstyled permission-list">
                                <li :class="{ 'd-none': !permission.view }">
                                  <i class="fa fa-eye"></i>
                                </li>

                                <li :class="{ 'd-none': !permission.create }">
                                  <i class="fa fa-plus"></i>
                                </li>

                                <li :class="{ 'd-none': !permission.update }">
                                  <i class="fa fa-pencil"></i>
                                </li>

                                <li :class="{ 'd-none': !permission.remove }">
                                  <i class="fa fa-trash"></i>
                                </li>
                              </ul>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </perfect-scrollbar>
                  </b-col>

                  <b-col class="col-12 col-md-9">
                    <b-row>
                      <b-col sm="12">
                        <b-card class="mb-4">
                          <button class="btn btn-primary btn-sm float-right" @click="handleCheckAll()" type="button">
                            {{ isUncheckAll ? 'Uncheck all' : 'Check All' }}
                          </button>

                          <h4 class="card-heading">Permissions : {{ permissionData.label }}</h4>

                          <b-list-group flush>
                            <b-list-group-item @click="handleCheckOthers">
                              <div class="d-flex w-100 justify-content-between my-2">
                                <div>
                                  <i
                                    class="fa fa-eye text-white circle-icon mr-3 float-left"
                                    :class="{ 'bg-secondary': permissionData.view, 'bg-gray-200': !permissionData.view }">
                                  </i>
                                </div>
                                <div class="w-100">
                                  <h5 class="mb-1">View Page</h5>
                                  <p class="mb-1 text-gray">
                                    {{ permissionData.viewDesc }}
                                  </p>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div class="custom-control custom-checkbox custom-control-inline m-0">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="ViewCheckbox"
                                      :checked="permissionData.view"
                                      @change="handleCheckOthers" />
                                    <label class="custom-control-label" for="ViewCheckbox"> </label>
                                  </div>
                                </div>
                              </div>
                            </b-list-group-item>

                            <b-list-group-item v-if="permissionData.createEnable" @click="enableView('create')">
                              <div class="d-flex w-100 justify-content-between my-2">
                                <div>
                                  <i
                                    class="fa fa-plus text-white circle-icon mr-3 float-left"
                                    :class="{ 'bg-secondary': permissionData.create, 'bg-gray-200': !permissionData.create }"></i>
                                </div>
                                <div class="w-100">
                                  <h5 class="mb-1">Add</h5>
                                  <p class="mb-1 text-gray">
                                    {{ permissionData.createDesc }}
                                  </p>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div class="custom-control custom-checkbox custom-control-inline m-0">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="AddCheckbox"
                                      :checked="permissionData.create"
                                      @change="permissionData.create = !permissionData.create" />
                                    <label class="custom-control-label" for="AddCheckbox"> </label>
                                  </div>
                                </div>
                              </div>
                            </b-list-group-item>

                            <b-list-group-item v-if="permissionData.updateEnable" @click="enableView('update')">
                              <div class="d-flex w-100 justify-content-between my-2">
                                <div>
                                  <i
                                    class="fa fa-pencil text-white circle-icon mr-3 float-left"
                                    :class="{ 'bg-secondary': permissionData.update, 'bg-gray-200': !permissionData.update }"></i>
                                </div>
                                <div class="w-100">
                                  <h5 class="mb-1">Edit / Update</h5>
                                  <p class="mb-1 text-gray">
                                    {{ permissionData.updateDesc }}
                                  </p>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div class="custom-control custom-checkbox custom-control-inline m-0">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="EditCheckbox"
                                      :checked="permissionData.update"
                                      @change="permissionData.update = !permissionData.update" />
                                    <label class="custom-control-label" for="EditCheckbox"> </label>
                                  </div>
                                </div>
                              </div>
                            </b-list-group-item>

                            <b-list-group-item v-if="permissionData.removeEnable" @click="enableView('remove')">
                              <div class="d-flex w-100 justify-content-between my-2">
                                <div>
                                  <i
                                    class="fa fa-trash text-white circle-icon mr-3 float-left"
                                    :class="{ 'bg-secondary': permissionData.remove, 'bg-gray-200': !permissionData.remove }"></i>
                                </div>
                                <div class="w-100">
                                  <h5 class="mb-1">Delete</h5>
                                  <p class="mb-1 text-gray">
                                    {{ permissionData.removeDesc }}
                                  </p>
                                </div>
                                <div class="d-flex align-items-center">
                                  <div class="custom-control custom-checkbox custom-control-inline m-0">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="deleteCheckbox"
                                      :checked="permissionData.remove"
                                      @change="permissionData.remove = !permissionData.remove" />
                                    <label class="custom-control-label" for="deleteCheckbox"> </label>
                                  </div>
                                </div>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <div class="row form-action mt-3">
                  <div class="col-md-6 order-md-last">
                    <button
                      id="btn-spinner"
                      type="button"
                      name="button"
                      @click="handleSave"
                      v-promise-btn
                      class="btn btn-primary pr-5 pl-5 float-right w-sm-100 mb-3 mb-md-0">
                      Save
                    </button>
                  </div>
                  <div class="col-md-6">
                    <router-link to="/permissions" exact tag="button" name="button" class="btn btn-secondary pr-5 pl-5 w-sm-100"> Cancel </router-link>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { rolesSerializer } from '@/utils/accessList';

export default {
  name: 'EditAdminRoles',
  data: function () {
    return {
      moreInfo: true,
      isNewRole: false,
      newRoleName: '',
      roleId: '',
      type: '',
      permissionData: {},
      newPermissions: {},
      validateUsername: null,
      selectedRole: null,
      selectedPermission: [],
      permissionFields: {
        module: {
          icon: 'icon',
          label: 'module',
          thStyle: {
            display: 'none',
          },
        },
        permissions: {
          label: 'permissions',
          thStyle: {
            display: 'none',
          },
        },
      },
    };
  },
  created() {
    this.roleId = this.$route.params.id;
    this.type = this.$route.params.type;

    if (this.type === 'role') {
      this.$store
        .dispatch('roles/GET_ROLE_BY_ID', this.$route.params.id)
        .then((result) => {
          const permission = this.getRolePermissions() || [];
          this.permissionData = permission[0];
          this.permissionData.viewDesc = rolesSerializer[permission[0].id].viewDesc;
          this.permissionData.createDesc = rolesSerializer[permission[0].id].createDesc;
          this.permissionData.updateDesc = rolesSerializer[permission[0].id].updateDesc;
          this.permissionData.removeDesc = rolesSerializer[permission[0].id].removeDesc;
          this.permissionData.viewEnable = rolesSerializer[permission[0].id].viewEnable;
          this.permissionData.removeEnable = rolesSerializer[permission[0].id].removeEnable;
          this.permissionData.updateEnable = rolesSerializer[permission[0].id].updateEnable;
          this.permissionData.createEnable = rolesSerializer[permission[0].id].createEnable;
        })
        .catch();
    } else {
      if (!this.$store.state.roles.loadedOnce) {
        this.$store.dispatch('roles/GET_ROLE_LIST');
      }

      if (!this.$store.state.roles.userloadedOnce) {
        // this.$store.dispatch('roles/GET_USER_ROLE_BY_ID', this.$route.params.id)
      }

      // this.permissionData = this.user.rolePermissions
    }
  },
  computed: {
    role() {
      const role = this.$store.state.roles.role;
      var permissions = [];
      var permissionsContainer = [];

      var roleDb = role.rolePermissions ? Object.keys(role.rolePermissions).sort() : [];
      var roleStatic = Object.keys(rolesSerializer).sort();

      if (roleDb.length !== roleStatic.length) {
        var keyDifference = this.getKeyDifference(roleDb, roleStatic);

        for (var permissionsKey in role.rolePermissions) {
          permissionsContainer[permissionsKey] = role.rolePermissions[permissionsKey];
        }

        for (var arrayKey in keyDifference) {
          permissionsContainer[keyDifference[arrayKey]] = rolesSerializer[keyDifference[arrayKey]];
        }

        const finalRolePermission = Object.assign({}, permissionsContainer);
        for (var keyFinalRolePermission in finalRolePermission) {
          permissions[keyFinalRolePermission] = finalRolePermission[keyFinalRolePermission];
          permissions[keyFinalRolePermission].icon = rolesSerializer[keyFinalRolePermission].icon;
        }
        role.rolePermissions = finalRolePermission;
      } else {
        for (var key in role.rolePermissions) {
          permissions[key] = role.rolePermissions[key];
          permissions[key].icon = rolesSerializer[key].icon;
        }
      }

      return role;
    },

    user() {
      let selectedUser, roleID;
      if (!this.$store.state.roles.userloadedOnce) {
        selectedUser = this.$store.state.roles.selectedUser;
        roleID = selectedUser.roleID;
      } else {
        const userList = this.$store.state.roles.userList;
        selectedUser = Vue.lodash.filter(userList, { _id: this.$route.params.id })[0];
        roleID = selectedUser.role.roleID._id;
        selectedUser.firstname = selectedUser.personalInfo.firstname;
        selectedUser.lastname = selectedUser.personalInfo.lastname;
      }
      /*let selectedRole = Vue.lodash.filter(this.roleList, {_id: roleID})
      const role = Object.assign({}, selectedRole[0])
      this.selectedRole = role*/
      // selectedUser.roleName = role
      // selectedUser.role = selectedRole[0]
      // selectedUser.roleName = selectedRole[0].name
      return selectedUser || null;
    },
    isUncheckAll() {
      var permissionValue = [];
      var permissionEnable = [];
      var arrayChecked = [];

      if (this.permissionData.id) {
        permissionEnable['view'] = rolesSerializer[this.permissionData.id].viewEnable;
        permissionEnable['create'] = rolesSerializer[this.permissionData.id].createEnable;
        permissionEnable['remove'] = rolesSerializer[this.permissionData.id].removeEnable;
        permissionEnable['update'] = rolesSerializer[this.permissionData.id].updateEnable;

        for (let key in permissionEnable) {
          if (permissionEnable[key] !== undefined) {
            permissionValue.push(key);
          }
        }

        for (let key in permissionValue) {
          arrayChecked.push(this.permissionData[permissionValue[key]]);
        }

        const checkedFields = arrayChecked.every((isChecked) => isChecked);

        if (checkedFields) {
          return true;
        }
      }

      return false;
    },
    isRole() {
      return this.type === 'role';
    },
    filter() {
      const data = this.roleList.map((role) => {
        return { value: role, text: role.name };
      });

      return [{ value: null, text: 'Please select role', disabled: true }, { value: 'new', text: 'Create New Role' }, ...data];
    },
    roleList() {
      return this.$store.state.roles.roles;
    },
    newRolePermissions() {
      return this.newPermissions;
    },
    rolePermissions: {
      get: function () {
        return this.permissions;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    getKeyDifference(a1, a2) {
      var a = [];
      var diff = [];
      for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
      }
      for (var j = 0; j < a2.length; j++) {
        if (a[a2[j]]) {
          delete a[a2[j]];
        } else {
          a[a2[j]] = true;
        }
      }
      for (var k in a) {
        diff.push(k);
      }
      return diff;
    },
    getRolePermissions() {
      let permissions = {};
      let count = 0;

      for (var key in this.role.rolePermissions) {
        permissions[key] = this.role.rolePermissions[key];
        permissions[key].id = key;
        permissions[key].index = count++;
      }
      return Vue.lodash.filter(permissions, { index: 0 });
    },
    getPermissionList() {
      let permissions = {};
      this.permissions = rolesSerializer;

      for (var key in this.permissions) {
        permissions[key] = this.permissions[key];
      }

      const permission = Vue.lodash.filter(permissions, { index: 0 });
      this.permissionData = permission[0];
    },
    addNewRole() {
      this.isNewRole = true;
      this.selectedRole = 'new';
      this.selectedPermission = [];
      this.newPermissions = rolesSerializer;
      this.getPermissionList();
    },
    handleSetPermissionData(value) {
      if (this.type === 'role') {
        this.permissionData = this.role.rolePermissions[value];
        this.permissionData.icon = rolesSerializer[value].icon;
        this.permissionData.viewDesc = rolesSerializer[value].viewDesc;
        this.permissionData.createDesc = rolesSerializer[value].createDesc;
        this.permissionData.updateDesc = rolesSerializer[value].updateDesc;
        this.permissionData.removeDesc = rolesSerializer[value].removeDesc;
        this.permissionData.viewEnable = rolesSerializer[value].viewEnable;
        this.permissionData.removeEnable = rolesSerializer[value].removeEnable;
        this.permissionData.updateEnable = rolesSerializer[value].updateEnable;
        this.permissionData.createEnable = rolesSerializer[value].createEnable;
      } else {
        this.permissionData = this.rolePermissions[value];
        this.permissionData.icon = rolesSerializer[this.permissionData.id].icon;
        this.permissionData.viewDesc = rolesSerializer[this.permissionData.id].viewDesc;
        this.permissionData.createDesc = rolesSerializer[this.permissionData.id].createDesc;
        this.permissionData.updateDesc = rolesSerializer[this.permissionData.id].updateDesc;
        this.permissionData.removeDesc = rolesSerializer[this.permissionData.id].removeDesc;
      }
    },
    handleActiveSubPermission(id) {
      return this.permissionData.id === id && 'active';
    },
    handleCheckOthers() {
      this.permissionData.view = !this.permissionData.view;
      if (!this.permissionData.view) {
        this.permissionData.create = this.permissionData.update = this.permissionData.remove = false;
      }
      // this.permissionData.create = this.permissionData.view = this.permissionData.remove = this.permissionData.update = this.permissionData.view
      // this.permissionData.isChecked = this.permissionData.view && this.permissionData.create && this.permissionData.update && this.permissionData.remove
      this.permissionData.isChecked = this.permissionData.view;
    },
    handleCheckAll() {
      if (this.isUncheckAll) {
        this.permissionData.update = false;
        this.permissionData.view = false;
        this.permissionData.remove = false;
        this.permissionData.create = false;
        this.permissionData.isChecked = false;
      } else {
        this.permissionData.view = true;
        this.permissionData.create = this.permissionData.createEnable && this.permissionData.view;
        this.permissionData.remove = this.permissionData.removeEnable && this.permissionData.view;
        this.permissionData.update = this.permissionData.updateEnable && this.permissionData.view;
        this.permissionData.isChecked = true;
      }
    },
    enableView(action) {
      this.permissionData[action] = !this.permissionData[action];
      if (this.permissionData[action] || this.permissionData[action] || this.permissionData[action]) {
        this.permissionData.view = true;
        this.permissionData.isChecked = true;
      }
    },
    handleUpdateRole(status) {
      if (status) {
        let promiseButton = document.getElementById('btn-spinner').querySelector('.promise-btn__spinner-wrapper');
        promiseButton.classList.remove('hidden');
        if (this.type === 'role') {
          var roles = this.role;
          for (let role in roles.rolePermissions) {
            delete roles.rolePermissions[role].id;
            delete roles.rolePermissions[role].index;
            delete roles.rolePermissions[role].viewDesc;
            delete roles.rolePermissions[role].createDesc;
            delete roles.rolePermissions[role].updateDesc;
            delete roles.rolePermissions[role].removeDesc;
          }

          this.$store
            .dispatch('roles/updateRole', roles)
            .then((result) => {
              if (result && result.status === 200) {
                this.$noty.success('Role successfully updated!', {
                  theme: 'bootstrap-v4',
                  timeout: 1000,
                });
                setTimeout(() => {
                  this.$router.push('/roles');
                }, 1500);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          if (!this.isNewRole) {
            const payload = {
              userID: this.user._id,
              roleID: this.selectedRole._id,
            };

            this.$store
              .dispatch('roles/updateUserRoleById', payload)
              .then((result) => {
                if (result && result.status === 200) {
                  this.$noty.success('User role successfully updated!', {
                    theme: 'bootstrap-v4',
                    timeout: 1000,
                  });
                  setTimeout(() => {
                    this.$router.push('/roles');
                  }, 1500);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            const payload = {
              users: [this.user._id],
              role: {
                name: this.newRoleName,
                rolePermissions: this.rolePermissions,
                isDeleted: false,
              },
            };

            this.$store
              .dispatch('roles/setUserRole', payload)
              .then((result) => {
                this.$noty.success('Successfully set role to user!', {
                  theme: 'bootstrap-v4',
                  timeout: 1000,
                });
                setTimeout(() => {
                  this.$router.push('/permissions');
                }, 1500);
              })
              .catch();
          }
        }
      }
    },
    handleSave() {
      this.$swal({
        title: 'Update role',
        text: 'Are you sure, you want to update role?',
        type: 'info',
        showCancelButton: true,
        cancelButtonClass: 'btn btn-default pr-5 pl-5',
        confirmButtonText: 'Yes, update it!',
        confirmButtonClass: 'btn btn-secondary pr-5 pl-5',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.show2FA(this.handleUpdateRole);
        }
      });
    },
  },
  watch: {
    selectedRole(role) {
      if (role === 'new') {
        this.isNewRole = true;
        this.selectedPermission = [];
        this.newPermissions = rolesSerializer;
        this.getPermissionList();
      } else {
        this.isNewRole = false;
        if (role.rolePermissions) {
          this.permissions = role.rolePermissions;

          var selectedPermission = Object.keys(role.rolePermissions).map(function (key, values) {
            return {
              id: key,
              ...role.rolePermissions[key],
            };
          });

          this.selectedPermission = selectedPermission.map((permission, key) => {
            return {
              module: {
                icon: rolesSerializer[permission.id].icon,
                label: permission.label,
              },
              permissions: {
                view: permission.view,
                create: permission.create,
                update: permission.update,
                remove: permission.remove,
              },
            };
          });
        }
      }
    },
  },
  destroyed() {
    this.permissions = rolesSerializer;
    this.$store.commit('roles/setUserRoleById', {});
  },
};
</script>
<style scoped>
.editRoleContainer {
  min-height: 500px;
}
.cursor-pointer {
  cursor: pointer;
}
.table tr td {
  width: 50% !important;
}
.permissionTable {
  width: 500px;
}
.permissionList li {
  width: 25% !important;
}
.non-clickable {
  pointer-events: none;
}
.permissions {
  font-size: 0.75rem;
}
.text-custom {
  color: #536c79;
}
.list-group.list-group-flush .list-group-item {
  cursor: pointer;
}
.side-nav .side-nav-item a .permission-list {
  margin-left: 0;
  padding-left: 3.5rem;
  margin-right: 0;
}

.slide-fade-enter-active {
  transition: 0.5s;
}
.slide-fade-leave-active {
  transition: 1s;
  opacity: 1;
}
.slide-fade-enter {
  opacity: 0;
  transform: translate(0, -150%);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-fade-leave-to {
  opacity: 0;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #20a8d8 !important;
}
.tag-center {
  cursor: default;
}
.ps {
  height: 400px;
  padding-right: 7px;
}
</style>
