<template>
  <div class="adminList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Client User Admin</h3>
          </div>
          <!-- <div class="col-lg-3 pull-left">
            <router-link :to="`/user/business-clients/create`" class="btn btn-aqua opacity-8 float-right">
              <i class="fa fa-plus-circle"></i>
              Add New Client
            </router-link>
          </div> -->
        </div>
        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-container>
                  <b-row>
                    <b-col class="d-flex">
                      <div class="mr-3 text-nowrap">User Status</div>
                      <b-form-select v-model="selectedStatus" :options="statuses" name="Filter" :disabled="loading" class="float-right" />
                    </b-col>
                    <b-col> </b-col>
                    <b-col>
                      <base-input v-model="searchRequest" :disabled="loading" prepend-icon="fas fa-search" placeholder="Search"></base-input>
                    </b-col>
                  </b-row>
                </b-container>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="adminTable"
                  id="adminTable"
                  responsive
                  hover
                  :items="adminUserList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                  :filter-included-fields="['fullName', 'email']"
                  @context-changed="loadInitialPendingVisibleItems">
                  <template v-slot:cell(fullName)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">{{ data.item.fullName }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(email)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">{{ data.item.email }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(role)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">{{ data.item.roleLabel }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(createdAt)="data">
                    <div class="data">
                      <span class="title text-break-all w-100 text-sm-custom text-center">{{ data.item.createdYmd }} {{ data.item.createdHms }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(status)="data">
                    <div class="data">
                      <div class="title text-break-all w-100 text-sm-custom text-center">
                        <span
                          :class="{
                            'bg-success': data.item.status === 'active',
                            'bg-warning': data.item.status === 'inactive',
                            'bg-danger': data.item.isDeleted === 1,
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.status }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data" class="data">
                    <div>
                      <!-- <div v-if="data.item.actions !== currentUserID && data.item.isDeleted === 0"> -->

                      <!-- <a @click="resetPassword(data.item.actions)" v-b-tooltip.hover title="Reset Password" class="btn btn-outline-primary btn-sm text-primary">
                          <span class="btn-inner--icon"><i class="fas fa-lock"></i> </span>
                        </a> -->

                      <router-link
                        :to="`business-clients/edit/${data.item.actions}`"
                        v-b-tooltip.hover.left="'Edit Business Client User Account'"
                        class="btn btn-outline-primary btn-sm">
                        <span class="btn-inner--icon"><i class="far fa-edit"></i> </span>
                      </router-link>

                      <a
                        @click="activateAdminUser(data.item.isActive, data.item.actions)"
                        v-b-tooltip.hover.left="data.item.isActive === 1 ? 'Deactivate Client User' : 'Activate Client User'"
                        class="btn btn-outline-vobo btn-sm text-vobo">
                        <span class="btn-inner--icon"><i :class="data.item.isActive === 1 ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></i> </span>
                      </a>

                      <a
                        v-if="roleName == 'superAdmin'"
                        @click="deleteProcess(data.item.actions, data.item)"
                        v-b-tooltip.hover.left="'Delete Business Client User Account'"
                        class="btn btn-outline-danger btn-sm text-danger">
                        <span class="btn-inner--icon"><i class="far fa-trash-alt"></i> </span>
                      </a>
                    </div>
                  </template>
                </b-table>
                <b-row v-if="adminUserList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center" />
                <div class="float-right" v-if="totalRows > perPage && adminUserList.length !== 0">
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import moment from 'moment';
import showConfirmation from '@/utils/mixins/deleteNotify';

import { Validator } from 'vee-validate';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';

Vue.use(Cookies);

export default {
  name: 'AdminList',
  mixins: [showConfirmation],
  data: function () {
    return {
      listCollection: [],
      errorFields: [],
      transactionID: '',
      clientUserListFilter: [],
      searchRequest: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      allTimeStatus: false,
      selectedFilter: 'all',
      selectedStatus: 'all',
      statuses: [
        { value: 'all', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'deleted', text: 'Deleted' },
      ],
      sortBy: 'dateCreated',
      sortDesc: true,
      ListFields: [
        {
          key: 'fullName',
          label: 'Full Name',
          sortable: true,
        },
        {
          key: 'email',
          label: 'email',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'phone',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'actions',
        },
      ],
      updateAccess: false,
      roleName: undefined,
    };
  },
  computed: {
    loading() {
      return this.$store.state.admin.loading;
    },
    adminUserList: {
      get: function () {
        return this.clientUserListFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadClientUsers(currentPage) {
      this.$store.dispatch('admin/GET_CLIENT_USERS').then((resp) => {
        var filter = 'all';
        this.clientUserListFilter = this.$store.state.admin.userClientList[filter];
        this.totalRows = this.clientUserListFilter.length;
      });
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    deleteProcess(id, data) {
      const adminUserID = String(id).trim();
      const fullName = String(data.fullName).trim();
      const email = String(data.email).trim();
      const otherInfo = `<br/>
                          <table class="table table-borderless table-popup">
                            <tr><td><strong>Full Name</strong></td><td>${fullName}</td></tr>
                            <tr><td><strong>Email</strong></td><td>${email}</td></tr>
                          </table>`;

      this.showConfirmation(this.deleteAdminUser, adminUserID, 'this client user admin? Please confirm the details below:', otherInfo, true);
    },
    deleteAdminUser(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch('admin/DELETE_CLIENT_ADMIN_USER', userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Client account successfully deleted'),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadClientUsers(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error deleting client user'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
    resetPassword(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch('user/RESET_PASSWORD_REQUEST', userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Email sent to user to reset the password'),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error resetting user password'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
    activateAdminUser(currentStatus, id) {
      let isActive = 0;
      let statusValue = '';
      const userID = String(id).trim();

      if (currentStatus === 1) {
        isActive = 0;
        statusValue = 'Deactivated';
      } else {
        isActive = 1;
        statusValue = 'Activated';
      }

      const status = { isActive };

      this.$store
        .dispatch('user/UPDATE_USER_STATUS', { userID, status })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Client account successfully ' + statusValue),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadClientUsers(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error creating user'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.clientUserListFilter = this.$store.state.admin.userClientList[context];
      this.totalRows = this.clientUserListFilter.length;
    },
  },
  mounted() {
    const token = Cookies.get('user-token');
    const decode = decoder({
      secret: config.secret,
    });
    var payload = decode(token);
    this.roleName = payload.roleName;
    this.loadClientUsers(this.currentPage);
  },
};
</script>
<style lang="scss" scoped>
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}

a.btn-outline-danger:hover {
  color: #fff !important;
}
a.btn-outline-vobo:hover {
  color: #fff !important;
}
a.btn-outline-default:hover {
  color: #fff !important;
}
a.btn-outline-primary:hover {
  color: #fff !important;
}
.adminTable td a {
  margin-right: 0.25rem !important;
}
.adminTable td a:last-child {
  margin-right: 0 !important;
}
.adminTable .data .title {
  font-size: 0.8rem !important;
}
.adminTable .table td {
  padding: 0.7rem;
}
.adminTable tr {
  th {
    text-align: center;
  }
  &:focus {
    outline: 0 !important;
    outline: none !important;
  }
}
.adminTable tr th:first-child,
.adminTable tr td:first-child {
  width: 20% !important;
}
.adminTable tr th:nth-child(2),
.adminTable tr td:nth-child(2) {
  width: 25% !important;
}
.adminTable tr th:nth-child(3),
.adminTable tr td:nth-child(3) {
  width: 15% !important;
}
.adminTable tr th:nth-child(4),
.adminTable tr td:nth-child(4) {
  width: 12% !important;
}
.adminTable tr th:nth-child(5),
.adminTable tr td:nth-child(5) {
  width: 10% !important;
}
.adminTable tr th:nth-child(6),
.adminTable tr td:nth-child(6) {
  width: 20% !important;
}
.text-break-all {
  word-break: break-all;
}
.target-fade-cancel {
  -webkit-animation: target-fade-cancel 2s 1;
  -moz-animation: target-fade-cancel 2s 1;
}

@-webkit-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

@-moz-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

.target-fade-complete {
  -webkit-animation: target-fade-complete 2s 1;
  -moz-animation: target-fade-complete 2s 1;
}

@-webkit-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}

@-moz-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}
</style>
