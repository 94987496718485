import state from './state';

const mutations = {
  setFeaturedData: (state, data) => {
    state.topFeatured = data.top;
    state.bottomFeatured = data.bottom;
  },
};

Object.keys(state).forEach((key) => {
  mutations[key] = (state, value) => {
    state[key] = value;
  };
});

export default mutations;
