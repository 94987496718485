<template>
  <div class="userList">
    <Drawer :direction="'right'" :exist="true" :isOverlayEnable="true" :width="600" ref="profileEditor" class="profileEditor">
      <div class="sidenav-header d-flex align-items-center navbar-dark navbar-expand-l bg-default">
        <div class="pull-right text-right w-100 pr-2">
          <a class="text-aqua btn btn-outline-aqua closeBtn" @click="closeEditProfile"
            >CLOSE <span class="btn-inner--icon"><i class="fas fa-arrow-right"></i></span
          ></a>
        </div>
      </div>
      <div class="col-lg-12 editProfileForm" id="editProfileForm">
        <b-row v-if="updateLoading">
          <div class="col-lg-12 text-center mt-3">
            <pulse-loader :color="'#0cb799'"></pulse-loader>
          </div>
        </b-row>
        <b-row>
          <div class="col-lg-6 col-md-6 col-6 no-gutter-right">
            <b-card no-body>
              <b-card-body class="mx-0 px-0">
                <b-row>
                  <div class="col-lg-12 col-md-12">
                    <b-form-group role="group" class="mb-3 has-feedback">
                      <label class="w-100 form-control-label" for="user.firstname">First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        v-model="user.firstname"
                        v-validate="'required'"
                        data-vv-as="First Name"
                        data-vv-name="user.firstname"
                        data-vv-scope="user"
                        class="form-control no-instruction"
                        :class="{ input: true, 'is-invalid': errors.has('user.firstname'), 'has-invalid': errors.has('user.firstname') }" />
                      <span class="has-icon-feedback"></span>
                      <div class="invalid-feedback" :is-invalid="errors.has('user.firstname')">
                        {{ errors.first('user.firstname') }}
                      </div>
                    </b-form-group>

                    <b-form-group role="group" class="mb-3 has-feedback">
                      <label class="w-100 form-control-label" for="user.displayName">Display Name</label>
                      <input
                        type="text"
                        name="displayName"
                        v-model="user.displayName"
                        v-validate="'required'"
                        data-vv-as="Display Name"
                        data-vv-name="user.displayName"
                        data-vv-scope="user"
                        class="form-control no-instruction"
                        :class="{ input: true, 'is-invalid': errors.has('user.displayName'), 'has-invalid': errors.has('user.displayName') }" />
                      <span class="has-icon-feedback"></span>
                      <div class="invalid-feedback" :is-invalid="errors.has('user.displayName')">
                        {{ errors.first('user.displayName') }}
                      </div>
                    </b-form-group>

                    <b-form-group role="group" class="mb-3 has-feedback">
                      <label class="w-100 form-control-label" for="user.points">Points</label>
                      <input
                        type="text"
                        name="points"
                        v-model="user.points"
                        v-validate="'required'"
                        v-mask="'#######'"
                        data-vv-as="Points"
                        data-vv-name="user.points"
                        data-vv-scope="user"
                        class="form-control no-instruction"
                        :class="{ input: true, 'is-invalid': errors.has('user.points'), 'has-invalid': errors.has('user.points') }" />
                      <span class="has-icon-feedback"></span>
                      <div class="invalid-feedback" :is-invalid="errors.has('user.points')">
                        {{ errors.first('user.points') }}
                      </div>
                    </b-form-group>
                  </div>
                </b-row>
              </b-card-body>
            </b-card>
          </div>
          <div class="col-lg-6 col-md-6 col-6 no-gutter-left">
            <b-card no-body>
              <b-card-body class="mx-0 px-0">
                <b-row>
                  <div class="col-lg-12 col-md-12">
                    <b-form-group role="group" class="mb-3 has-feedback">
                      <label class="w-100 form-control-label" for="user.lastname">Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        v-model="user.lastname"
                        v-validate="'required'"
                        data-vv-as="Last Name"
                        data-vv-name="user.lastname"
                        data-vv-scope="user"
                        class="form-control no-instruction"
                        :class="{ input: true, 'is-invalid': errors.has('user.lastname'), 'has-invalid': errors.has('user.lastname') }" />
                      <span class="has-icon-feedback"></span>
                      <div class="invalid-feedback" :is-invalid="errors.has('user.lastname')">
                        {{ errors.first('user.lastname') }}
                      </div>
                    </b-form-group>

                    <b-form-group role="group" class="mb-3 has-feedback">
                      <label class="w-100 form-control-label" for="user.email">Email</label>
                      <input
                        type="text"
                        name="email"
                        v-model="user.email"
                        v-validate="'required|email'"
                        data-vv-as="Display Name"
                        data-vv-name="user.email"
                        data-vv-scope="user"
                        class="form-control no-instruction"
                        :class="{ input: true, 'is-invalid': errors.has('user.email'), 'has-invalid': errors.has('user.email') }" />
                      <span class="has-icon-feedback"></span>
                      <div class="invalid-feedback" :is-invalid="errors.has('user.email')">
                        {{ errors.first('user.email') }}
                      </div>
                    </b-form-group>
                  </div>
                </b-row>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
        <b-row>
          <div class="col-lg-5 col-md-5 col-12 col-12 py-3 pl-4"></div>
          <div class="col-lg-7 col-md-7 col-12 col-12">
            <button type="button" :disabled="loading" id="updateUserInfo" @click="updateUserInfo" class="btn btn-aqua float-right w-sm-100 mb-3 mb-md-0">
              Update
              <span class="promise-btn__spinner-wrapper hidden"
                ><span
                  data-v-cc3e8e04=""
                  class="spinner"
                  style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
              ></span>
            </button>
          </div>
        </b-row>
      </div>
    </Drawer>

    <Drawer :direction="'right'" :exist="true" :isOverlayEnable="true" :width="800" ref="childProfileEditor" class="childProfileEditor">
      <div class="sidenav-header d-flex align-items-center navbar-dark navbar-expand-l bg-default">
        <div class="pull-right text-right w-100 pr-2">
          <a class="text-aqua btn btn-outline-aqua closeBtn" @click="closeChildProfileEditor"
            >CLOSE <span class="btn-inner--icon"><i class="fas fa-arrow-right"></i></span
          ></a>
        </div>
      </div>
      <div class="col-lg-12 no-gutters editProfileForm" id="editProfileForm">
        <b-card no-body>
          <b-tabs pills card vertical>
            <b-tab title="User Profile" active>
              <b-row v-if="updateLoading">
                <div class="col-lg-12 text-center mt-3">
                  <pulse-loader :color="'#0cb799'"></pulse-loader>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-6 col-md-6 col-6 no-gutter-right">
                  <b-card no-body>
                    <b-card-body class="mx-0 px-0 py-0 box">
                      <b-row>
                        <div class="col-lg-12 col-md-12">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="profile.firstname">First Name</label>
                            <input
                              type="text"
                              v-if="Object.keys(profile).length > 0"
                              name="firstname"
                              v-model="profile.firstname"
                              v-validate="'required'"
                              data-vv-as="First Name"
                              data-vv-name="profile.firstname"
                              data-vv-scope="profile"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('profile.firstname'), 'has-invalid': errors.has('profile.firstname') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('profile.firstname')">
                              {{ errors.first('profile.firstname') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="profile.gender">Gender</label>
                            <b-form-select
                              v-if="Object.keys(profile).length > 0"
                              id="gender"
                              v-model="profile.gender"
                              :options="gender"
                              name="profile.gender"
                              placeholder="Select Gender"
                              data-vv-as="Gender"
                              data-vv-name="profile.gender"
                              data-vv-scope="profile"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('profile.gender'), 'has-invalid': errors.has('profile.gender') }">
                            </b-form-select>
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('profile.gender')">
                              {{ errors.first('profile.gender') }}
                            </div>
                          </b-form-group>

                          <div class="row">
                            <div class="col-sm col-lg-12 col-md-12">
                              <b-row>
                                <div class="col-sm col-lg-12 col-md-12">
                                  <label class="w-100 form-control-label">Date of Birth</label>
                                </div>
                              </b-row>
                              <b-row>
                                <div class="col-sm col-lg-4 col-md-4">
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="profile.birthdate.month">Month</label>
                                    <b-form-select
                                      v-if="Object.keys(profile).length > 0"
                                      id="birthMonth"
                                      v-model="profile.birthdate.month"
                                      :options="birthMonth"
                                      name="profile.birthdate.month"
                                      placeholder="Select Month"
                                      data-vv-as="profile.birthdate.month"
                                      data-vv-name="profile.birthdate.month"
                                      data-vv-scope="billing"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('profile.birthdate.month'),
                                        'has-invalid': errors.has('profile.birthdate.month'),
                                      }">
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('profile.birthdate.month')">
                                      {{ errors.first('profile.birthdate.month') }}
                                    </div>
                                  </b-form-group>
                                </div>
                                <div class="col-sm col-lg-4 col-md-4 no-gutter-left">
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="profile.birthdate.date">Day</label>
                                    <b-form-select
                                      v-if="Object.keys(profile).length > 0"
                                      id="birthDate"
                                      v-model="profile.birthdate.date"
                                      :options="birthDate"
                                      name="profile.birthdate.date"
                                      placeholder="Select Day"
                                      data-vv-as="profile.birthdate.date"
                                      data-vv-name="profile.birthdate.date"
                                      data-vv-scope="profile"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('profile.birthdate.date'),
                                        'has-invalid': errors.has('profile.birthdate.date'),
                                      }">
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('profile.birthdate.date')">
                                      {{ errors.first('profile.birthdate.date') }}
                                    </div>
                                  </b-form-group>
                                </div>
                                <div class="col-sm col-lg-4 col-md-4 no-gutter-left">
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="profile.birthdate.year">Year</label>
                                    <b-form-select
                                      v-if="Object.keys(profile).length > 0"
                                      id="birthYear"
                                      v-model="profile.birthdate.year"
                                      :options="birthYear"
                                      name="profile.birthdate.year"
                                      placeholder="Select Year"
                                      data-vv-as="profile.birthdate.year"
                                      data-vv-name="profile.birthdate.year"
                                      data-vv-scope="profile"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('profile.birthdate.year'),
                                        'has-invalid': errors.has('profile.birthdate.year'),
                                      }">
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('profile.birthdate.year')">
                                      {{ errors.first('profile.birthdate.year') }}
                                    </div>
                                  </b-form-group>
                                </div>
                              </b-row>
                            </div>
                          </div>
                        </div>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>
                <div class="col-lg-6 col-md-6 col-6 no-gutter-left">
                  <b-card no-body>
                    <b-card-body class="mx-0 px-0 py-0 box">
                      <b-row>
                        <div class="col-lg-12 col-md-12">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="profile.lastname">Last Name</label>
                            <input
                              type="text"
                              v-if="Object.keys(profile).length > 0"
                              name="lastname"
                              v-model="profile.lastname"
                              v-validate="'required'"
                              data-vv-as="Last Name"
                              data-vv-name="profile.lastname"
                              data-vv-scope="profile"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('profile.lastname'), 'has-invalid': errors.has('profile.lastname') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('profile.lastname')">
                              {{ errors.first('profile.lastname') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="profile.firstname">Points</label>
                            <input
                              type="text"
                              v-if="Object.keys(profile).length > 0"
                              name="points"
                              v-model="profile.points"
                              v-validate="'required'"
                              v-mask="'#######'"
                              data-vv-as="Points"
                              data-vv-name="profile.points"
                              data-vv-scope="profile"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('profile.points'), 'has-invalid': errors.has('profile.points') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('profile.points')">
                              {{ errors.first('profile.points') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="profile.status">Status</label>
                            <b-form-select
                              v-if="Object.keys(profile).length > 0"
                              id="status"
                              v-model="profile.status"
                              :options="profileStatuses"
                              name="profile.status"
                              placeholder="Select Status"
                              data-vv-as="Status"
                              data-vv-name="profile.status"
                              data-vv-scope="profile"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('profile.status'), 'has-invalid': errors.has('profile.status') }">
                            </b-form-select>
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('profile.status')">
                              {{ errors.first('profile.status') }}
                            </div>
                          </b-form-group>
                          <button
                            v-if="false"
                            size="sm"
                            id="popover-remarks"
                            variant="primary"
                            ref="popover-remarks"
                            class="btn btn-outline-vobo btn-sm text-vobo">
                            <span class="btn-inner--icon"><i class="far fa-comment-dots"></i> </span>
                          </button>
                          <button
                            type="button"
                            :disabled="loading || ['canceled', 'suspended', 'canceling'].includes(profile.billing.subscription.status)"
                            id="cancelSubscription"
                            @click="cancelProcess(profile)"
                            class="btn btn-sm btn-vobo float-right w-sm-100 mb-md-0">
                            Cancel Subscription
                            <span class="promise-btn__spinner-wrapper hidden"
                              ><span
                                data-v-cc3e8e04=""
                                class="spinner"
                                style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                            ></span></button
                          ><br />
                          <span v-if="remarksLatest.remarks !== ''" class="text-default pt-2 pull-right text-small pull-left">
                            <small class="col-md-12 w-100 pull-left no-gutters">
                              <span class="col-md-12 pull-left no-gutters" style="color: 777679"
                                ><span class="text-danger">* </span>{{ remarksLatest.created }}</span
                              >
                              <span class="col-md-12 pull-left no-gutters text-left pl-4">
                                <span class="pl-3"> {{ remarksLatest.remarks }}</span></span
                              >
                            </small>
                          </span>
                          <b-popover
                            target="popover-remarks"
                            triggers="click"
                            :show.sync="remarksShow"
                            placement="auto"
                            container="my-container"
                            ref="popover-remarks">
                            <template v-slot:title>
                              <b-button @click="onRemarksClose" class="close" aria-label="Close">
                                <span class="d-inline-block" aria-hidden="true">&times;</span>
                              </b-button>
                              Remarks
                            </template>

                            <div></div>
                          </b-popover>
                        </div>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-5 col-md-5 col-12 col-12 py-3 pl-4"></div>
                <div class="col-lg-7 col-md-7 col-12 col-12">
                  <button
                    type="button"
                    :disabled="loading"
                    id="updateChildProfile"
                    @click="updateChildProfile"
                    class="btn btn-aqua float-right w-sm-100 mb-3 mb-md-0 mr-3">
                    Update
                    <span class="promise-btn__spinner-wrapper hidden"
                      ><span
                        data-v-cc3e8e04=""
                        class="spinner"
                        style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                    ></span>
                  </button>
                </div>
              </b-row>
            </b-tab>
            <b-tab title="Billing Info">
              <b-row v-if="updateLoading">
                <div class="col-lg-12 text-center mt-3">
                  <pulse-loader :color="'#0cb799'"></pulse-loader>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-6 col-md-6 col-6 no-gutter-right">
                  <b-card no-body>
                    <b-card-body class="mx-0 px-0 box">
                      <b-row>
                        <div class="col-lg-12 col-md-12">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="billing.address[0]">Address Line 1</label>
                            <input
                              type="text"
                              name="address[0]"
                              v-model="billing.address[0]"
                              v-validate="{ required: profile.subscriptionType !== 'school' }"
                              data-vv-as="Address line 1"
                              data-vv-name="billing.address[0]"
                              data-vv-scope="billing"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('billing.address[0]'), 'has-invalid': errors.has('billing.address[0]') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('billing.address[0]')">
                              {{ errors.first('billing.address[0]') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="billing.city">City</label>
                            <input
                              type="text"
                              name="city"
                              v-model="billing.city"
                              v-validate="{ required: profile.subscriptionType !== 'school' }"
                              data-vv-as="City"
                              data-vv-name="billing.city"
                              data-vv-scope="billing"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('billing.city'), 'has-invalid': errors.has('billing.city') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('billing.city')">
                              {{ errors.first('billing.city') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="billing.zipCode">Zip Code</label>
                            <input
                              type="text"
                              name="zipCode"
                              v-model="billing.zipCode"
                              v-validate="{ required: profile.subscriptionType !== 'school' }"
                              v-mask="'#####'"
                              data-vv-as="Zip Code"
                              data-vv-name="billing.zipCode"
                              data-vv-scope="billing"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('billing.zipCode'), 'has-invalid': errors.has('billing.zipCode') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('billing.zipCode')">
                              {{ errors.first('billing.zipCode') }}
                            </div>
                          </b-form-group>
                        </div>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>
                <div class="col-lg-6 col-md-6 col-6 no-gutter-left">
                  <b-card no-body>
                    <b-card-body class="mx-0 px-0 box">
                      <b-row>
                        <div class="col-lg-12 col-md-12">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="billing.address[1]">Address Line 2</label>
                            <input type="text" name="address[1]" v-model="billing.address[1]" class="form-control no-instruction" />
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="billing.state">State</label>
                            <b-form-select
                              id="state"
                              v-model="billing.state"
                              :options="states"
                              name="billing.state"
                              placeholder="Select State"
                              data-vv-as="Status"
                              data-vv-name="billing.state"
                              data-vv-scope="billing"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('billing.state'), 'has-invalid': errors.has('billing.state') }">
                            </b-form-select>
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('billing.state')">
                              {{ errors.first('billing.state') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="billing.planId">Payment Plan</label>
                            <b-form-select
                              id="planId"
                              v-model="billing.planId"
                              :options="plans"
                              name="billing.planId"
                              placeholder="Select State"
                              data-vv-as="Status"
                              data-vv-name="billing.planId"
                              data-vv-scope="billing"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('billing.planId'), 'has-invalid': errors.has('billing.planId') }"
                              disabled>
                            </b-form-select>
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('billing.planId')">
                              {{ errors.first('billing.planId') }}
                            </div>
                          </b-form-group>
                        </div>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-5 col-md-5 col-12 col-12 py-3 pl-4"></div>
                <div class="col-lg-7 col-md-7 col-12 col-12">
                  <button
                    type="button"
                    :disabled="loading"
                    id="updateBillingInfo"
                    @click="updateBillingInfo(user.userID)"
                    class="btn btn-aqua float-right w-sm-100 mb-3 mb-md-0">
                    Update
                    <span class="promise-btn__spinner-wrapper hidden"
                      ><span
                        data-v-cc3e8e04=""
                        class="spinner"
                        style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                    ></span>
                  </button>
                </div>
              </b-row>
            </b-tab>
            <b-tab title="Shipping Info">
              <b-row v-if="updateLoading">
                <div class="col-lg-12 text-center mt-3">
                  <pulse-loader :color="'#0cb799'"></pulse-loader>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-6 col-md-6 col-6 no-gutter-right">
                  <b-card no-body>
                    <b-card-body class="mx-0 px-0 box">
                      <b-row>
                        <div class="col-lg-12 col-md-12">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="shipping.address[0]">Address Line 1</label>
                            <input
                              type="text"
                              name="shipping.address[0]"
                              v-model="shipping.address[0]"
                              v-validate="'required'"
                              data-vv-as="Address line 1"
                              data-vv-name="shipping.address[0]"
                              data-vv-scope="shipping"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('shipping.address[0]'), 'has-invalid': errors.has('shipping.address[0]') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('shipping.address[0]')">
                              {{ errors.first('shipping.address[0]') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="shipping.city">City</label>
                            <input
                              type="text"
                              name="city"
                              v-model="shipping.city"
                              v-validate="'required'"
                              data-vv-as="City"
                              data-vv-name="shipping.city"
                              data-vv-scope="shipping"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('shipping.city'), 'has-invalid': errors.has('shipping.city') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('shipping.city')">
                              {{ errors.first('shipping.city') }}
                            </div>
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="shipping.zipCode">Zip Code</label>
                            <input
                              type="text"
                              name="zipCode"
                              v-model="shipping.zipCode"
                              v-validate="'required'"
                              v-mask="'#####'"
                              data-vv-as="Zip Code"
                              data-vv-name="shipping.zipCode"
                              data-vv-scope="shipping"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('shipping.zipCode'), 'has-invalid': errors.has('shipping.zipCode') }" />
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('shipping.zipCode')">
                              {{ errors.first('shipping.zipCode') }}
                            </div>
                          </b-form-group>
                        </div>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>
                <div class="col-lg-6 col-md-6 col-6 no-gutter-left">
                  <b-card no-body>
                    <b-card-body class="mx-0 px-0 box">
                      <b-row>
                        <div class="col-lg-12 col-md-12">
                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="shipping.address[1]">Address Line 2</label>
                            <input type="text" name="shipping.address[1]" v-model="shipping.address[1]" class="form-control no-instruction" />
                          </b-form-group>

                          <b-form-group role="group" class="mb-3 has-feedback">
                            <label class="w-100 form-control-label" for="shipping.state">State</label>
                            <b-form-select
                              id="state"
                              v-model="shipping.state"
                              :options="states"
                              name="shipping.state"
                              placeholder="Select State"
                              data-vv-as="Status"
                              data-vv-name="shipping.state"
                              data-vv-scope="shipping"
                              class="form-control no-instruction"
                              :class="{ input: true, 'is-invalid': errors.has('shipping.state'), 'has-invalid': errors.has('shipping.state') }">
                            </b-form-select>
                            <span class="has-icon-feedback"></span>
                            <div class="invalid-feedback" :is-invalid="errors.has('shipping.state')">
                              {{ errors.first('shipping.state') }}
                            </div>
                          </b-form-group>
                        </div>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-5 col-md-5 col-12 col-12 py-3 pl-4"></div>
                <div class="col-lg-7 col-md-7 col-12 col-12">
                  <button
                    type="button"
                    :disabled="loading"
                    id="updateShippingInfo"
                    @click="updateShippingInfo(user.userID)"
                    class="btn btn-aqua float-right w-sm-100 mb-3 mb-md-0">
                    Update
                    <span class="promise-btn__spinner-wrapper hidden"
                      ><span
                        data-v-cc3e8e04=""
                        class="spinner"
                        style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                    ></span>
                  </button>
                </div>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </Drawer>

    <Drawer :direction="'right'" :exist="true" :isOverlayEnable="true" :width="600" ref="noteContainer" class="noteContainer">
      <div class="sidenav-header d-flex align-items-center navbar-dark navbar-expand-l bg-default">
        <div class="pull-right text-right w-100 pr-2">
          <a class="text-aqua btn btn-outline-aqua closeBtn" @click="closeNotes"
            >CLOSE <span class="btn-inner--icon"><i class="fas fa-arrow-right"></i></span
          ></a>
        </div>
      </div>
      <div class="container" id="processNotesForm">
        <b-row>
          <div class="cold-lg-6 col-md-6 col-6">
            <div class="panel-footer">
              <b-row>
                <div class="col-lg-12 col-md-12 col-12">
                  <b-form-group role="group" class="mb-3 has-feedback">
                    <label class="w-100 form-control-label" for="notes.notes">Notes</label>
                    <textarea
                      id="btn-input"
                      v-model="notes.notes"
                      v-validate="'required'"
                      placeholder="Type your notes here..."
                      data-vv-as="Notes"
                      data-vv-name="notes.notes"
                      data-vv-scope="notes"
                      class="form-control no-instruction"
                      :class="{ input: true, 'is-invalid': errors.has('notes.notes'), 'has-invalid': errors.has('notes.notes') }">
                    </textarea>
                    <span class="has-icon-feedback"></span>
                    <div class="invalid-feedback" :is-invalid="errors.has('notes.notes')">
                      {{ errors.first('notes.notes') }}
                    </div>
                  </b-form-group>
                  <b-form-group role="group" class="mb-3 has-feedback">
                    <label class="w-100 form-control-label" for="notes.outcome">Outcome</label>
                    <textarea id="btn-input" class="form-control" v-model="notes.outcome" placeholder="Type the outcome here..." />
                  </b-form-group>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-12 col-md-12 col-12">
                  <button type="button" :disabled="loading" id="processNotes" @click="processNote" class="btn btn-aqua float-right w-sm-100 w-100 mb-3 mb-md-0">
                    {{ updateNote ? 'Update' : 'Create' }}
                    <span class="promise-btn__spinner-wrapper hidden"
                      ><span
                        data-v-cc3e8e04=""
                        class="spinner"
                        style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                    ></span>
                  </button>
                </div>
              </b-row>
              <b-row>
                <div class="col-lg-12 col-md-12 col-12 mt-2">
                  <button type="button" @click="clearNote()" class="btn btn-outline-dark2 float-right w-sm-100 w-100 mb-3 mb-md-0">
                    {{ updateNote ? 'Cancel' : 'Clear' }}
                  </button>
                </div>
              </b-row>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-6 no-gutter-left">
            <div class="row">
              <div class="col-lg-12 col-md-12 no-gutter-right">
                <perfect-scrollbar class="w-100" id="noteContainer">
                  <div class="panel panel-primary">
                    <div>
                      <div class="panel-body">
                        <b-row v-if="notesLoading">
                          <div class="col-lg-12 text-center mt-3">
                            <pulse-loader :color="'#0cb799'"></pulse-loader>
                          </div>
                        </b-row>
                        <ul class="note" v-if="noteList.length > 0 && !notesLoading">
                          <li
                            class="left clearfix"
                            v-for="(note, index) in noteList"
                            :key="index"
                            :class="{ currentUpdating: updateNote && currentNoteID === note.actions }">
                            <a @click="loadNote(note)">
                              <div class="note-body clearfix">
                                <span class="noteTime">{{ note.dateCreated }}</span>
                                <p>
                                  {{ note.notes }}
                                </p>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </Drawer>

    <div class="userContent" :class="{ show: userContentShow }">
      <div class="row" id="userContentForm">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="bg-aqua close-div w-100 py-3 px-3 pull-left text-white">
            <h4 class="text-left text-white m-0">User Profile</h4>
            <a class="closeUserBtn" @click="closeUserContent"><span class="close text-white">×</span></a>
          </div>

          <b-card no-body class="w-100 mb-0">
            <b-row class="userContentRow">
              <div class="col-lg-12 col-md-12 col-12">
                <a @click="editProfile" class="btn btn-sm btn-torquioise text-white cancelBtn profile"><i class="fas fa-edit"> </i> Edit</a>
                <a @click="updateStatus(user.blockedStatus, user.userID, 'block')" class="btn btn-sm btn-danger text-white blockBtn profile"
                  ><i :class="user.blockedStatus === 'active' ? 'fas fa-user-slash' : 'far fa-user'"></i>
                  {{ user.blockedStatus === 'active' ? 'Block' : 'Unblock' }}</a
                >
                <a
                  v-if="!user.profiles"
                  @click="deleteProcess(user.userID, user)"
                  class="btn btn-sm btn-danger text-white deleteBtn profile"
                  :style="{ right: user.blockedStatus === 'active' ? '150px' : '159px' }"
                  ><i class="fas fa-trash-alt"></i> Delete
                </a>

                <div class="col-lg-12 col-md-12 col-12">
                  <ul class="accountList pt-4">
                    <li class="p-0 mb-2">
                      <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 col">
                          <img v-if="user.avatar" :src="user.avatar" width="45px" height="45px" style="border-radius: 50%" class="pull-left" />
                        </div>
                        <div class="col-lg-3 col-md-2 col">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">First Name</h6>
                          <h4 class="mt-0 d-block h4">
                            <span class="">{{ user.firstname }}</span>
                          </h4>
                        </div>
                        <div class="col-lg-3 col-md-2 col">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">Last Name</h6>
                          <h4 class="mt-0 d-block h4">
                            <span class="">{{ user.lastname }}</span>
                          </h4>
                        </div>
                        <div class="col-lg-4 col-md-4 col">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">Display Name</h6>
                          <h4 class="mt-0 d-block h4">
                            <span class="">{{ user.displayName.trim() ? user.displayName : '---' }}</span>
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row align-items-center">
                        <div class="col-lg-1 col-md-1 col">
                          <span class="title text-break-all text-sm-custom2">
                            <span v-if="user.blockedStatus === 'blocked'" class="badge badge-pill text-white bg-danger">Blocked</span>
                            <span
                              v-else
                              :class="{
                                'bg-success': user.status === 'active',
                                'bg-danger': user.status === 'blocked',
                                'bg-gray': user.status === 'inactive',
                                'bg-default abandoned': user.status === 'abandoned',
                                'bg-warning unverified': user.status === 'unverified',
                              }"
                              class="badge badge-pill text-white"
                              >{{ user.status }}</span
                            >
                          </span>
                        </div>
                        <div class="col-lg-6 col-md-4 col">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">Email</h6>
                          <h4 class="mt-0 d-block h4">
                            <span class="">{{ user.email }}</span>
                          </h4>
                        </div>
                        <div class="col-lg-3 col-md-3 col" v-if="user.status === 'abandoned' || user.status === 'unverified'">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">Validation Code</h6>
                          <h4 class="mt-0 d-block h4">
                            <span class="">{{ user.validationCode }}</span>
                          </h4>
                        </div>
                        <div class="col-lg-2 col-md-2 col">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">Points</h6>
                          <h4 class="mt-0 d-block h4">
                            <span class="pl-2">{{ user.points }}</span>
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li class="p-0" v-if="user.profiles && user.profiles.length > 0">
                      <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12 col">
                          <h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">Profiles</h6>
                          <b-row class="profileItemsListHeader">
                            <div class=""></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray ml-5">Full Name</h6></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">POINTS</h6></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">STATUS</h6></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">PAYMENT STATUS</h6></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">TYPE</h6></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">SUBSCRIPTION</h6></div>
                            <div class=""><h6 class="heading-smaller pb-0 mb-1 mt-2 text-gray">START DATE</h6></div>
                          </b-row>
                          <ul class="profileItems">
                            <li v-for="(item, index) in user.profiles" :key="index" class=" " :class="{ rowEven: index % 2 }">
                              <b-row class="profileItemsList">
                                <div>
                                  <img v-if="item.avatar" :src="item.avatar" width="30px" height="30px" style="border-radius: 50%" class="pull-left" />
                                  <img v-else src="/img/avatars/user-sample-gray.png" width="30px" height="30px" style="border-radius: 50%" class="pull-left" />
                                </div>
                                <div>
                                  <span class="">{{ item.firstname }} {{ item.lastname }}</span>
                                </div>
                                <div>
                                  <span class="">{{ item.points }}</span>
                                </div>
                                <div>
                                  <span class="">{{ item.status || 'INITIAL' }}</span>
                                </div>
                                <div>
                                  <span class="">{{ item.billing.subscription.status ? item.billing.subscription.status.toUpperCase() : 'INACTIVE' }}</span>
                                </div>
                                <div>
                                  <span class="text-uppercase">{{ item.subscriptionType === 'school' ? 'School/Group' : 'Individual/Family' }}</span>
                                </div>
                                <div>
                                  <span class="text-uppercase">{{ item.subscription || 'FULL' }}</span>
                                </div>
                                <div>
                                  <span class="">{{ formatDate(item.createdAt) }}</span>
                                </div>
                                <div>
                                  <a @click="editChildProfile(item)"><i class="far fa-edit"></i></a>
                                </div>
                              </b-row>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="p-0"></li>
                  </ul>
                </div>
              </div>
            </b-row>
            <div class="border-top w-100 fixedBottom">
              <b-row>
                <div class="col-lg-5 col-md-5 col-12 col-12 py-1 pl-4"></div>
                <div class="col-lg-7 col-md-7 col-12 col-12 py-1 px-5"></div>
              </b-row>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <div class="userBackdrop" :class="{ show: userContentShow }"></div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-12 col-12">
            <h3 class="mb-0">
              User Profiles
              <span v-if="!loading">
                <small class="ml-3 text-gray ml-2" v-if="searchRequest || selectedGroup !== 'all' || selectedStatus !== 'all'"
                  >TOTAL FILTERED USERS (<strong class="text-default">{{ totalRows }}</strong
                  >)</small
                >
                <span v-else>
                  <small class="ml-3 text-gray ml-2"
                    >TOTAL USERS (<strong class="text-default">{{ totalUsers }}</strong
                    >)</small
                  >
                  <small class="ml-3 text-gray ml-2"
                    >TOTAL ACTIVE USERS (<strong class="text-default">{{ totalActiveRows }}</strong
                    >)</small
                  >
                  <!-- <small class="ml-3 text-gray ml-2">TOTAL INACTIVE USERS (<strong class="text-default">{{totalInactiveRows}}</strong>)</small> -->
                  <small class="ml-3 text-gray ml-2"
                    >UNVERIFIED USERS (<strong class="text-default">{{ totalUnverifiedRows }}</strong
                    >)</small
                  >
                  <small class="ml-3 text-gray ml-2"
                    >ABANDONED USERS (<strong class="text-default">{{ totalAbandonedRows }}</strong
                    >)</small
                  >
                  <small class="ml-3 text-gray ml-2"
                    >SUSPENDED USERS (<strong class="text-default">{{ totalSuspendedRows }}</strong
                    >)</small
                  >
                </span>
              </span>
            </h3>
          </div>
        </div>

        <b-row id="userlistContainer">
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="float-left d-flex align-items-center col-lg-4 no-gutters" v-if="totalUsers > 0">
                      <b-form-group role="group" class="has-feedback w-100">
                        <label class="w-100 form-control-label userLabel" for="selectedStatus">User Status</label>
                        <b-form-select v-model="selectedStatus" :options="statuses" name="Filter" />
                      </b-form-group>
                    </div>
                    <div class="float-left d-flex align-items-center col-lg-4 no-gutter-right"></div>
                    <div class="col-lg-3 pull-right no-gutters">
                      <b-form-group role="group" v-if="!loading" class="has-feedback w-100">
                        <label class="w-100 form-control-label userLabel" for="searchRequest">Search User</label>
                        <base-input v-model="searchRequest" id="searchInput" prepend-icon="fas fa-search" placeholder="Search"></base-input>
                      </b-form-group>
                    </div>
                  </div>
                </b-row>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader :color="'#0cb799'"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="mobileUserTable"
                  id="mobileUserTable"
                  responsive
                  hover
                  fixed
                  :tbody-tr-class="rowClass"
                  :items="mobileUserList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch">
                  <template v-slot:cell(fullname)="data">
                    <div class="row data">
                      <div class="col-lg-10">
                        <span class="title text-break-all">{{ data.item.fullname }}</span>
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(email)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">
                        {{ data.item.email }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:cell(points)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">
                        {{ data.item.points }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:cell(status)="data">
                    <div class="data">
                      <span class="title text-break-all text-sm-custom">
                        <span v-if="data.item.blockedStatus === 'blocked'" class="badge badge-pill text-white bg-danger">Blocked</span>
                        <span
                          v-else
                          :class="{
                            'bg-success': data.item.status === 'active',
                            'bg-danger': data.item.status === 'blocked',
                            'bg-gray': data.item.status === 'inactive',
                            'bg-default': data.item.status === 'abandoned',
                            'bg-warning': data.item.status === 'unverified',
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.status }}</span
                        >
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(dateCreated)="data">
                    <div class="data">
                      <span class="title text-break-all w-100 text-sm-custom text-center">{{ data.item.dateCreated }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <div class="data text-center">
                      <a
                        size="sm"
                        @click="showUser(data.item)"
                        v-b-tooltip.hover
                        :title="!detailsShowing ? 'Show Details' : 'Hide Details'"
                        class="btn btn-outline-vobo btn-sm text-vobo">
                        <span class="btn-inner--icon"><i :class="{ 'far fa-eye-slash': detailsShowing, 'far fa-eye': !detailsShowing }"></i> </span>
                      </a>
                      <a
                        v-if="false"
                        @click="updateStatus(data.item.status, data.item.userID)"
                        v-b-tooltip.hover
                        :title="data.item.status === 'active' ? 'Deactivate User' : 'Activate User'"
                        class="btn btn-outline-vobo btn-sm text-vobo">
                        <span class="btn-inner--icon"><i :class="data.item.status === 'active' ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></i> </span>
                      </a>
                      <a
                        v-if="false"
                        @click="updateStatus(data.item.blockedStatus, data.item.userID, 'block')"
                        v-b-tooltip.hover
                        :title="data.item.blockedStatus === 'active' ? 'Block User' : 'Unblock User'"
                        class="btn btn-outline-danger btn-sm text-danger">
                        <span class="btn-inner--icon"><i :class="data.item.blockedStatus === 'active' ? 'fas fa-user-slash' : 'far fa-user'"></i> </span>
                      </a>
                      <a @click="showNotes(data.item.userID)" v-b-tooltip.hover title="Show Notes" class="btn btn-outline-aqua btn-sm text-aqua">
                        <span class="btn-inner--icon"><i class="ni ni-collection"></i> </span>
                      </a>
                    </div>
                  </template>
                </b-table>
                <b-row v-if="mobileUserList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>

                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center" />
                <div class="float-right" v-if="totalRows > perPage && mobileUserList.length !== 0">
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import Drawer from '@/components/Drawer';
import moment from 'moment';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import showConfirmation from '@/utils/mixins/deleteNotify';

Vue.use(Cookies);

export default {
  name: 'userList',
  components: {
    Drawer,
  },
  mixins: [showConfirmation],
  data: function () {
    return {
      user: {
        firstname: '',
        lastname: '',
        displayName: '',
        avatar: '',
        email: '',
        isActive: '',
        isBlocked: '',
        id: '',
        createdAt: '',
        lastLogin: '',
        profiles: [],
        points: 0,
      },
      profile: {
        id: '',
        firstname: '',
        lastname: '',
        gender: '',
        previousPlanId: '',
        birthdate: {
          month: '',
          date: '',
          year: '',
        },
        billing: {
          subscription: {
            status: '',
            remark: '',
          },
        },
        shipping: {},
      },
      remarksLatest: {
        created: '',
        remarks: '',
      },
      remarksShow: false,
      shipping: {
        address: [],
        city: '',
        state: '',
        zipCode: '',
      },
      billing: {
        address: [],
        city: '',
        state: '',
        zipCode: '',
      },
      previousPlanID: '',
      userContentShow: false,
      searchRequest: '',
      currentPage: 1,
      totalRows: 0,
      totalActiveRows: 0,
      totalSuspendedRows: 0,
      // totalInactiveRows: 0,
      totalUnverifiedRows: 0,
      totalAbandonedRows: 0,
      perPage: 10,
      pageOptions: [10, 50, 100],
      allTimeStatus: false,
      selectedStatus: 'all',
      selectedGroup: 'all',
      statuses: [
        { value: 'all', text: 'All' },
        { value: 'active', text: 'Active' },
        // { value: 'inactive', text: 'Inactive' },
        { value: 'suspended', text: 'Suspended' },
        // { value: 'failed', text: 'Failed Registration' },
        { value: 'unverified', text: 'Unverified' },
        { value: 'abandoned', text: 'Abandoned' },
      ],
      sortBy: 'dateCreated',
      sortDesc: true,
      mobileUserListFilter: [],
      ListFields: [
        {
          key: 'fullname',
          label: 'Full Name',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'points',
          label: 'points',
          sortable: true,
        },
        {
          key: 'status',
          label: 'status',
          sortable: true,
        },
        {
          key: 'dateCreated',
          label: 'startDate',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'actions',
        },
      ],
      totalUsers: 0,
      companyAdminsID: [],
      detailsShowing: false,
      gender: [
        { value: null, text: 'Please select gender', disabled: true },
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' },
      ],
      states: [
        { value: null, text: 'Please select state', disabled: true },
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'DC', text: 'District Of Columbia' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
      ],
      notes: {
        notes: '',
        outcome: '',
        noteID: '',
      },
      updateNote: false,
      noteListTemp: [],
      currentNoteID: '',
      currentNoteUserID: '',
    };
  },
  computed: {
    loading: {
      get: function () {
        return this.$store.state.users.loading;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    updateLoading: {
      get: function () {
        return this.$store.state.users.updateLoading;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    notesLoading: {
      get: function () {
        return this.$store.state.users.notesLoading;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    mobileUserList: {
      get() {
        return this.mobileUserListFilter;
      },
      set(newList) {
        return newList;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    profileStatuses: {
      get: function () {
        let data = [];

        for (var i = 0; i <= 24; i++) {
          if (i === 0) {
            data.push({ value: `INITIAL`, text: `INITIAL` });
          } else {
            data.push({ value: `MONTH ${i}`, text: `MONTH ${i}` });
          }
        }

        return [{ value: null, text: 'Please select status', disabled: true }, ...data];
      },
      set: function (newValue) {
        return newValue;
      },
    },
    birthMonth: {
      get: function () {
        let data = [];

        for (var i = 1; i <= 12; i++) {
          var val = i < 10 ? `0${i}` : i;
          data.push({ value: String(val), text: String(val) });
        }

        return [{ value: null, text: 'Please select month', disabled: true }, ...data];
      },
      set: function (newValue) {
        return newValue;
      },
    },
    birthDate: {
      get: function () {
        let data = [];

        for (var i = 1; i <= 31; i++) {
          var val = i < 10 ? `0${i}` : i;
          data.push({ value: String(val), text: String(val) });
        }

        return [{ value: null, text: 'Please select month', disabled: true }, ...data];
      },
      set: function (newValue) {
        return newValue;
      },
    },
    birthYear: {
      get: function () {
        let data = [];

        data = [...Array(new Date().getFullYear() - 1919)].map((y, i) => new Date().getFullYear() - i);

        return [{ value: null, text: 'Please select month', disabled: true }, ...data];
      },
      set: function (newValue) {
        return newValue;
      },
    },
    plans: {
      get: function () {
        var plan = [];
        var products = this.$store.state.products.productList || [];

        if (products.length > 0) {
          if (this.profile.subscriptionType && this.profile.subscriptionType === 'school') {
            products[0].plan
              .filter((p) => p.nickname.includes('MONTHLY'))
              .map((value) => {
                plan.push({ value: value.planID, text: value.nickname.replace('MONTHLY - ', '') });
              });
          } else {
            products[0].plan.map((value) => {
              plan.push({ value: value.planID, text: value.nickname });
            });
          }
        }
        return plan;
      },
      set: function (newVal) {
        return newVal;
      },
    },
    noteList: {
      get: function () {
        return this.noteListTemp || [];
      },
      set: function (newVal) {
        return newVal;
      },
    },
  },
  methods: {
    editProfile() {
      this.$refs.profileEditor.open();
    },
    closeEditProfile() {
      this.$refs.profileEditor.close();
    },
    editChildProfile(profile) {
      this.$refs.childProfileEditor.open();
      this.profile = profile;
      this.profile.birthdate = profile.birthdate || { month: null, date: null, year: null };
      this.profile.status = profile.status || 'INITIAL';
      this.billing = profile.billing;
      this.billing.profileID = profile._id;
      this.previousPlanID = profile.billing.planId;
      this.shipping = profile.shipping;
      if ('remarks' in profile.billing.subscription && profile.billing.subscription.remarks.length > 1) {
        var remarks = profile.billing.subscription.remarks;

        const sortedRemarks = remarks.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        this.remarksLatest = {
          remarks: this.trimTime(sortedRemarks[0].remark),
          created: moment(new Date(sortedRemarks[0].createdAt)).format('YYYY-MM-DD HH:mm:SS'),
        };
      } else if ('remarks' in profile.billing.subscription && profile.billing.subscription.remarks.length === 1) {
        this.remarksLatest = {
          remarks: this.trimTime(profile.billing.subscription.remarks[0].remark),
          created: moment(new Date(profile.billing.subscription.remarks[0].createdAt)).format('YYYY-MM-DD HH:mm:SS'),
        };
      }
      // =  ? ( === 1 ? trimTime(profile.billing.subscription.remarks[0].remark) : '') : ''
    },
    closeChildProfileEditor() {
      this.$refs.childProfileEditor.close();
    },
    loadAdminUsers(companyID) {
      this.$store.dispatch('admin/GET_ADMIN_USERS', companyID).then((resp) => {
        var activeAdmins = this.$store.state.admin.userList.active;
        const currentCompanyAdmins = activeAdmins.filter((company) => {
          return (company.userGroup = 'Admin');
        });
        currentCompanyAdmins.map((admin) => {
          this.companyAdminsID.push(admin.userID);
        });
      });
    },
    loadMobileUsers(currentPage) {
      this.currentPage = currentPage;
      this.$store.dispatch('users/GET_MOBILE_USERS').then((resp) => {
        this.mobileUserListFilter = this.$store.state.users.userList.all;
        this.totalRows = this.mobileUserListFilter.length;
        this.totalActiveRows = this.$store.state.users.userList.active.length;
        // this.totalInactiveRows = this.$store.state.users.userList.inactive.length
        this.totalUnverifiedRows = this.$store.state.users.userList.unverified.length;
        this.totalAbandonedRows = this.$store.state.users.userList.abandoned.length;
        this.totalSuspendedRows = this.$store.state.users.userList.suspended.length;
        this.totalUsers = this.$store.state.users.userList.all.length;
        this.currentPage = currentPage || 1;
        this.selectedStatus = 'all';
      });
    },
    loadProducts() {
      this.$store.dispatch('products/GET_PRODUCTS');
    },
    showUser(user) {
      this.userContentShow = true;
      this.user = user;
    },
    showNotes(userID) {
      this.$refs.noteContainer.open();
      this.currentNoteUserID = userID;
      this.$store.dispatch('users/GET_MOBILE_USER_NOTES', userID).then((notes) => {
        this.notes.userID = userID;
        this.noteListTemp = notes;
      });
    },
    closeNotes() {
      this.$refs.noteContainer.close();
    },
    updateUserInfo() {
      const promiseBtn = document.getElementById('updateUserInfo');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('editProfileForm');
      bodyWrapper.classList.add('non-clickable');

      const userID = String(this.user.userID).trim();
      const userData = {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        displayName: this.user.displayName,
        email: this.user.email,
        points: this.user.points,
        customerID: this.user.stripe.customerId,
      };

      return this.$validator.validateAll('user').then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch('users/UPDATE_USER_INFO', { userData, userID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('User Profile successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.$refs.profileEditor.close();
                // this.userContentShow = false
                this.loadMobileUsers(this.currentPage);
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error updating User Profile'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateChildProfile() {
      const promiseBtn = document.getElementById('updateChildProfile');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('editProfileForm');
      bodyWrapper.classList.add('non-clickable');

      const profileID = String(this.profile._id).trim();

      const userChildData = {
        firstname: this.profile.firstname,
        lastname: this.profile.lastname,
        gender: this.profile.gender,
        status: this.profile.status,
        points: this.profile.points,
        birthdate: this.profile.birthdate,
      };

      return this.$validator.validateAll('profile').then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch('users/UPDATE_USER_CHILD_INFO', { userChildData, profileID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('User Child Profile successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.$refs.childProfileEditor.close();
                this.loadMobileUsers(this.currentPage);
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error updating User Child Profile'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateBillingInfo(userID) {
      const promiseBtn = document.getElementById('updateBillingInfo');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('editProfileForm');
      bodyWrapper.classList.add('non-clickable');

      const profileID = String(this.billing.profileID).trim();

      const userChildBillingData = {
        address: this.billing.address,
        city: this.billing.city,
        state: this.billing.state,
        zipCode: this.billing.zipCode,
        planId: this.billing.planId,
        previousPlanId: this.previousPlanID,
        customerID: this.user.stripe.customerId,
      };

      return this.$validator.validateAll('billing').then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch('users/UPDATE_USER_CHILD_BILLING', { userChildBillingData, profileID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Profile Billing Info successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.previousPlanID = '';
                this.$refs.childProfileEditor.close();
                this.userContentShow = false;
                this.loadMobileUsers(this.currentPage);
                const mobileUsers = this.$store.state.users.userList.all;
                const userFilter = mobileUsers.filter((user) => {
                  return user.userID === userID;
                });
                if (userFilter.length > 0) {
                  const userProfile = userFilter[0].profiles.filter((profile) => {
                    return profile._id === profileID;
                  });

                  if (userProfile.length > 0) {
                    this.profile = userProfile[0];
                    this.profile.birthdate = userProfile[0].birthdate || { month: null, date: null, year: null };
                    this.profile.status = userProfile[0].status || 'INITIAL';
                    this.billing = userProfile[0].billing;
                    this.billing.profileID = userProfile[0]._id;
                    this.previousPlanID = userProfile[0].billing.planId;
                    this.shipping = userProfile[0].shipping;
                  }
                }
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error updating Profile Billing Info'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateShippingInfo(userID) {
      const promiseBtn = document.getElementById('updateShippingInfo');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('editProfileForm');
      bodyWrapper.classList.add('non-clickable');

      const profileID = String(this.billing.profileID).trim();

      const userChildShippingData = {
        address: this.shipping.address,
        city: this.shipping.city,
        state: this.shipping.state,
        zipCode: this.shipping.zipCode,
      };

      return this.$validator.validateAll('shipping').then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch('users/UPDATE_USER_CHILD_SHIPPING', { userChildShippingData, profileID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('User successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.previousPlanID = '';
                this.$refs.childProfileEditor.close();
                this.loadMobileUsers(this.currentPage);

                const mobileUsers = this.$store.state.users.userList.all;
                const userFilter = mobileUsers.filter((user) => {
                  return user.userID === userID;
                });
                if (userFilter.length > 0) {
                  const userProfile = userFilter[0].profiles.filter((profile) => {
                    return profile._id === profileID;
                  });

                  if (userProfile.length > 0) {
                    this.profile = userProfile[0];
                    this.profile.birthdate = userProfile[0].birthdate || { month: null, date: null, year: null };
                    this.profile.status = userProfile[0].status || 'INITIAL';
                    this.billing = userProfile[0].billing;
                    this.billing.profileID = userProfile[0]._id;
                    this.previousPlanID = userProfile[0].billing.planId;
                    this.shipping = userProfile[0].shipping;
                  }
                }
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error updating User'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    loadNote(notes) {
      this.updateNote = true;
      this.notes = notes;
      this.currentNoteID = notes.actions;
    },
    clearNote() {
      this.updateNote = false;
      this.notes = {
        notes: '',
        outcome: '',
      };
      this.currentNoteID = '';
      this.$validator.reset();
      this.errors.clear();
    },
    processNote() {
      if (this.updateNote) {
        this.updateNotes();
      } else {
        this.createNotes();
      }
    },
    createNotes() {
      const promiseBtn = document.getElementById('processNotes');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processNotesForm');
      bodyWrapper.classList.add('non-clickable');

      return this.$validator.validateAll('notes').then((isValid) => {
        if (isValid) {
          const { notes, outcome, userID } = this.notes;
          const noteUserID = this.notes.userID || this.currentNoteUserID;

          this.$store
            .dispatch('users/CREATE_MOBILE_USER_NOTES', { notes, outcome, userID: noteUserID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Notes successfully added'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });

                this.notes = {
                  notes: '',
                  outcome: '',
                };
                this.updateNote = false;
                this.$validator.reset();
                this.errors.clear();
                this.showNotes(noteUserID);
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error adding Notes'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }
              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateNotes() {
      const promiseBtn = document.getElementById('processNotes');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processNotesForm');
      bodyWrapper.classList.add('non-clickable');

      return this.$validator.validateAll('notes').then((isValid) => {
        if (isValid) {
          const { notes, outcome } = this.notes;
          const noteData = {
            notes,
            outcome,
          };

          this.$store
            .dispatch('users/UPDATE_MOBILE_USER_NOTES', { noteData, noteID: this.currentNoteID })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Notes successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });

                this.notes = {
                  notes: '',
                  outcome: '',
                };
                this.updateNote = false;
                this.$validator.reset();
                this.errors.clear();

                this.showNotes(this.currentNoteUserID);
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('Error updating Notes'),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
              }
              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
              });

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled');
              bodyWrapper.classList.remove('non-clickable');
            });
        } else {
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    rowClass(item, type) {},
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        // this.currentPage = 1
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    deleteProcess(id, item) {
      const userID = String(id).trim();
      const fullname = String(item.firstname).trim() + ' ' + String(item.lastname).trim();
      const email = String(item.email).trim();
      const phone = String(item.phone).trim();
      const phoneHtml = item.phone ? `<tr><td><strong>Phone</strong></td><td>${phone}</td></tr>` : '';

      const otherInfo = `<br/>
                          <table class="table table-borderless mb-0">
                            <tr><td><strong>Full Name</strong></td><td>${fullname}</td></tr>
                            <tr><td><strong>Email</strong></td><td>${email}</td></tr>
                            ${phoneHtml}
                          </table>`;

      this.showConfirmation(this.deleteUser, userID, 'this user? Please confirm the details below:', otherInfo, true);
    },
    deleteUser(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch('users/DELETE_MOBILE_USER', userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('User successfully deleted'),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadMobileUsers(this.currentPage);
            this.userContentShow = false;
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error deleting User'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
    resetPassword(id) {
      const userID = String(id).trim();
      this.$store
        .dispatch('users/RESET_PASSWORD_REQUEST', userID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Email sent to user to reset the password'),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error resetting user password'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
    updateStatus(currentStatus, id, type = 'status') {
      let isActive = 0;
      let isBlocked = 0;
      let statusValue = '';
      var userData = {};
      const userID = String(id).trim();

      if (type === 'status') {
        if (currentStatus === 'active') {
          isActive = 0;
          statusValue = 'Deactivated';
        } else {
          isActive = 1;
          statusValue = 'Activated';
        }

        userData = { isActive };
      } else {
        if (currentStatus === 'active') {
          isBlocked = 1;
          statusValue = 'Blocked';
        } else {
          isBlocked = 0;
          statusValue = 'Unblocked';
        }

        userData = { isBlocked };
      }

      this.$store
        .dispatch('users/UPDATE_USER_STATUS', { userData, userID })
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('User successfully ' + statusValue),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadMobileUsers(this.currentPage);

            if (type === 'block' && statusValue === 'Blocked') {
              this.user.blockedStatus = 'blocked';
            } else if (type === 'block' && statusValue === 'Unblocked') {
              this.user.blockedStatus = 'active';
            }
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error creating user'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
    cancelProcess(profile) {
      const htmlContent = `<div class="mt-4">Are you sure you want to cancel the subscription of ${profile.firstname} ${profile.lastname}?</div><br/>`;
      const btn = { value: `Yes, cancel the subscription`, class: 'btn-danger' };
      this.showConfirmation(this.cancelSubscription, profile, 'this', '', false, htmlContent, true, false, btn);
    },
    cancelSubscription(profile) {
      const promiseBtn = document.getElementById('cancelSubscription');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const { _id, billing } = profile;
      const { userID } = this.user;
      const cancelData = {
        profileID: _id,
        action: 'cancel',
        formData: {
          parent: userID,
          subscriptionId: billing.subscriptionId,
          remark: 'Canceled by',
          fromAdmin: true,
        },
      };

      this.$store
        .dispatch('users/CANCEL_SUBSCRIPTION', cancelData)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('User subscription successfully canceled'),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadMobileUsers(this.currentPage);

            if (cancelData.action === 'cancel') {
              this.profile.billing.subscription.status = 'canceled';
              this.$refs.childProfileEditor.close();
              this.userContentShow = false;
            }
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error canceling user subscription'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }

          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled');
        });
    },
    formatDate(date) {
      return moment(new Date(date)).format('YYYY-MM-DD');
    },
    closeUserContent() {
      this.userContentShow = false;
    },
    trimTime(str) {
      if (str) {
        if (str.indexOf('Canceled by Company Admin') !== -1) {
          return 'Subscription Canceled by Admin';
        }

        if (str.indexOf('Subscription canceled') !== -1) {
          return 'Subscription Canceled by the User';
        }
      }

      return '';
    },
    onRemarksClose() {
      this.remarksShow = false;
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.selectedGroup = 'all';
      this.mobileUserListFilter = this.$store.state.users.userList[context] || [];
      this.totalRows = this.mobileUserListFilter.length;
      // this.currentPage = 1
    },
    selectedGroup: function (context) {
      this.selectedStatus = 'all';
      this.mobileUserListFilter = this.$store.state.users.userList[context] || [];
      this.totalRows = this.mobileUserListFilter.length;
      // this.currentPage = 1
    },
    searchRequest: function (context) {},
  },
  mounted() {
    this.loadMobileUsers(1);
    this.loadProducts();
    document.getElementById('noteContainer').setAttribute('style', `height:${document.body.offsetHeight - 50}px`);
  },
};
</script>
<style lang="scss">
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}
.popover-header {
  background: #efefef !important;
}
.popover-header p {
  background: #15133e !important;
}
.data .title {
  font-size: 0.8rem !important;
}
.text-break-all {
  word-break: break-word !important;
  white-space: inherit !important;
}
a.btn-outline-vobo:hover,
a.btn-outline-danger:hover,
a.btn-outline-vobo:hover,
a.btn-outline-default:hover,
a.btn-outline-primary:hover {
  color: #fff !important;
}
.mobileUserTable td .userBtn a,
.mobileUserTable td .userBtn button,
.cpMobileUserTable td .userBtn a,
.cpMobileUserTable td .userBtn button {
  margin-right: 0.15rem !important;
  width: 34px !important;
  height: 30px !important;
}
.mobileUserTable td a:last-child,
.cpMobileUserTable td a:last-child {
  margin-right: 0 !important;
}
.mobileUserTable .table td,
.cpMobileUserTable .table td {
  padding: 0.5rem;
}
.mobileUserTable tr,
.cpMobileUserTable tr {
  th {
    text-align: center;
  }
  &:focus {
    outline: 0 !important;
    outline: none !important;
  }
}
.mobileUserTable tr th:first-child,
.mobileUserTable tr td:first-child {
  width: 18% !important;
  text-align: left;
}
.mobileUserTable tr th:nth-child(2),
.mobileUserTable tr td:nth-child(2) {
  width: 22% !important;
  text-align: left;
}
.mobileUserTable tr th:nth-child(3),
.mobileUserTable tr td:nth-child(3) {
  width: 15% !important;
  text-align: center;
}
.mobileUserTable tr th:nth-child(4),
.mobileUserTable tr td:nth-child(4) {
  width: 10% !important;
  text-align: center;
}
.mobileUserTable tr th:nth-child(5),
.mobileUserTable tr td:nth-child(5) {
  width: 18% !important;
  text-align: center;
}
.mobileUserTable tr th:nth-child(6),
.mobileUserTable tr td:nth-child(6) {
  width: 17% !important;
}

.userInfoRow {
  font-size: 13px;
  border-top: 1px solid #dee2e6 !important;
}
.userInfoRow .card-body {
  padding: 0 !important;
}
.userBtn .btn {
  margin-bottom: 4px;
}

.modal-title,
.text-gray .modal-title {
  color: #525f7f !important;
}
.modal-header.text-gray {
  border-bottom: 1px solid #dee2e6;
}

.row-disbaled {
  background: #f3f4f5 !important;
}
.userLabel {
  font-size: 14px;
}
#searchInput {
  height: 43px !important;
}
.noshadow .dropdown-menu {
  min-width: 15rem !important;
}
#userContent .modal-header.text-gray {
  border-bottom: 1px solid #dee2e6;
}
#userContent .modal-header.text-gray {
  padding-bottom: 8px !important;
  padding-top: 13px !important;
  h4 {
    color: #8898aa !important;
  }
}
#userContent .modal-header .close.text-gray {
  padding: 0.9rem;
}
#userContent .modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 10px 15px;
}
.accountList {
  margin: 0 0 5px 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
.heading-smaller {
  font-size: 0.71rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.profileItems {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  float: left;
  li {
    list-style-type: none;
    width: 100%;
    display: block;
    float: left;
    font-size: 13px;
    font-weight: bold;
    background: #e6efff;
  }
}
.rowEven {
  background: #ffe6e6 !important;
}
.text-sm-custom2 {
  font-size: 12px !important;
}
.text-sm-custom2 .badge {
  margin-left: 3px;
}
.text-sm-custom2 .badge.abandoned {
  margin-left: -8px;
}
.text-sm-custom2 .badge.unverified {
  margin-left: -7px;
}
.cancelBtn.profile {
  position: absolute;
  right: 30px;
  top: 13px;
  padding: 3px 6px;
  font-size: 10px !important;
  z-index: 888;
}
.blockBtn.profile {
  position: absolute;
  right: 85px;
  top: 13px;
  padding: 3px 6px;
  font-size: 10px !important;
  z-index: 888;
}

.deleteBtn.profile {
  position: absolute;
  right: 150px;
  top: 13px;
  padding: 3px 6px;
  font-size: 10px !important;
  z-index: 888;
}
.userContent {
  width: 1000px !important;
  height: 530px;
  background: #fff;
  position: fixed;
  top: -850px;
  left: calc(50% - 500px);
  z-index: 1052;
  -webkit-transition: opacity 0.3s ease-out, top 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, top 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, top 0.3s ease-out;
  transition: opacity 0.3s ease-out, top 0.3s ease-out;
}

.userContent.show {
  top: calc(50% - 260px);
  -webkit-transition: opacity 0.3s ease-out, top 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, top 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, top 0.3s ease-out;
  transition: opacity 0.3s ease-out, top 0.3s ease-out;
}
.closeUserBtn {
  position: absolute;
  right: 30px;
  top: 13px;
}
.userBackdrop {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1051;
  display: none;
}

.userBackdrop.show {
  display: inline;
}
.border-top {
  border-top: 1px solid #dadadc !important;
}
.userContentRow {
  height: 400px;
}
.profileItems {
  overflow-y: auto;
  height: 170px;
  display: block;
  overflow-x: hidden;
}
.profileEditor,
.childProfileEditor {
  .sidebar.active {
    border-left: 1px solid #102038;
  }
}

#editProfileForm {
  .box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .nav.nav-pills.flex-column.card-header {
    padding-right: 0 !important;
  }
  .nav-link {
    margin-bottom: 10px;
  }
  .nav-pills .nav-item:not(:last-child) {
    padding-right: 0 !important;
  }
}
#gender,
#state,
#birthMonth,
#birthDate,
#birthYear,
#planId {
  color: #525f7f !important;
}
#noteContainer {
  overflow-x: hidden !important;
}

.note {
  list-style: none;
  margin: 0;
  padding: 20px 20px 0 0;
  li {
    border-bottom: 1px dotted #b3a9a9;
    padding: 0px;
    &:nth-child(even) {
      background: #ecf1f9;
    }
    a {
      width: 100%;
      height: 100%;
      padding: 10px 15px 5px 15px;
      display: block;
    }
    .note-body p {
      margin: 0;
      color: #777777;
      font-size: 13px;
    }
    .noteTime {
      font-size: 12px;
      text-align: right;
      width: 100%;
      display: block;
      font-weight: bold;
    }
  }
  li.currentUpdating {
    background: #9cf5e5;
  }
}

.panel-footer {
  width: 100% !important;
  padding: 30px 0;
  background: #fff;
  .form-group {
    #btn-input {
      height: 160px;
      resize: none;
    }
  }
}
.swal2-container {
  z-index: 10000 !important;
}

.profileItemsList,
.profileItemsListHeader {
  width: 100%;
  padding: 4px 10px;
  margin: 0;
}

.profileItemsList div,
.profileItemsListHeader div {
  float: left;
}

.profileItemsList div:first-child,
.profileItemsListHeader div:first-child {
  width: 4%;
}

.profileItemsList div:nth-child(2),
.profileItemsListHeader div:nth-child(2) {
  width: 20%;
}

.profileItemsList div:nth-child(3),
.profileItemsListHeader div:nth-child(3) {
  width: 7%;
  text-align: center;
}

.profileItemsList div:nth-child(4),
.profileItemsListHeader div:nth-child(4) {
  width: 10%;
  text-align: center;
}

.profileItemsList div:nth-child(5),
.profileItemsListHeader div:nth-child(5) {
  width: 13%;
  text-align: center;
}

.profileItemsList div:nth-child(6),
.profileItemsListHeader div:nth-child(6) {
  width: 18%;
  text-align: center;
}

.profileItemsList div:nth-child(7),
.profileItemsListHeader div:nth-child(7) {
  width: 10%;
  text-align: center;
}

.profileItemsList div:nth-child(8),
.profileItemsListHeader div:nth-child(8) {
  width: 12%;
  text-align: center;
}

.profileItemsList div:nth-child(9),
.profileItemsListHeader div:nth-child(9) {
  width: 5%;
  text-align: center;
  padding-top: 12px;
}

.profileItemsList div {
  padding: 5px;
  span {
    padding: 8px 0;
    float: left;
    width: 100%;
  }
  a {
    padding-top: 10px;
  }
}
</style>
