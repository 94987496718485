<template>
  <div class="categories">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <!-- HEADER -->
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">
              {{ selectedParent ? 'Sub-categories' : 'Categories' }}
            </h3>
          </div>
          <div class="col-lg-3 pull-left">
            <a @click.prevent="composeCategory" href="#" class="btn btn-primary opacity-8 float-right">
              <i class="fa fa-plus-circle"></i>
              {{ selectedParent ? 'New Sub-category' : 'New Category' }}
            </a>
          </div>
        </div>
        <!-- MAIN CONTENTS -->
        <div class="card-body" v-if="$store.state.categories.loading">
          <pulse-loader color="var(--pulse-loader)"></pulse-loader>
        </div>
        <div class="card-body" v-else>
          <div v-for="page in pages.sort()" :key="page" class="mb-2">
            <h1 class="page-name">
              <!-- Hide Button -->
              <div class="left">
                <i
                  @click="toggleRow(page)"
                  :id="'toggleRow-' + page"
                  :class="{
                    'fas fa-toggle-on': rowDisplayed[page] == true,
                    'fas fa-toggle-off': rowDisplayed[page] == false,
                  }"></i>
                <!-- Tooltip -->
                <!-- <b-tooltip :target="'toggleRow-'+page" placement="top">
                    {{ rowDisplayed[page] == true ? 'Hide': 'Show' }} <strong>{{ page }}</strong> row.
                  </b-tooltip> -->
                <!-- Row/Page Name -->
                <span>
                  {{ selectedParent ? categories.find((c) => c._id === selectedParent).title : page }}
                </span>
              </div>
              <div class="right">
                <div @click="openModalTwo(page, selectedParent)" class="update-order"><i class="fas fa-edit"></i>Update Order</div>
              </div>
            </h1>
            <div v-if="rowDisplayed[page]" class="row categoriesContainer">
              <div
                class="col-lg-4"
                v-for="category in filteredCategories.filter((c) => (selectedParent ? true : c.page.name === page)).sort(compare)"
                :key="category._id">
                <!-- Category -->
                <div
                  class="card category"
                  @click="
                    selectedParent
                      ? selectCategory(category, page)
                      : page == 'connection' || page == 'culture'
                      ? selectCategory(category, page)
                      : $router.push(`/contents/categories/${category._id}`)
                  ">
                  <img v-if="category.background" :src="category.background" width="100%" />
                  <div class="card-body">
                    <h3 class="card-title">
                      {{ category.title }}
                      <a href="edit" class="pull-right" @click.stop.prevent="selectCategory(category, page)">
                        <i class="fa fa-edit"></i>
                      </a>
                    </h3>
                  </div>
                </div>
                <!-- /Category -->
              </div>
            </div>
            <br />
          </div>
        </div>

        <!-- Category Modal -->
        <b-modal id="modalOne" v-model="modal" ref="vuemodal" hide-header-close no-close-on-esc no-close-on-backdrop centered>
          <template v-slot:modal-title>
            <span style="color: #000">Category</span>
          </template>
          <div class="card-body" v-if="$store.state.categories.loading">
            <pulse-loader color="var(--pulse-loader)"></pulse-loader>
          </div>
          <div v-else>
            <div class="form-group" v-if="!selectedParent && !categoryData._id">
              <b-form-group role="group" class="mb-3 has-feedback">
                <label class="w-100 form-control-label" for="page">Select Page </label>
                <b-form-select
                  v-model="categoryData.page"
                  :options="[
                    { text: 'Community', value: '602cc007e5ba430015dbc5f6' },
                    { text: 'Connection', value: '602cc007e5ba430015dbc5f5' },
                    { text: 'Culture', value: '602cc007e5ba430015dbc5f4' },
                  ]"
                  name="page"
                  placeholder="Please select page"
                  v-validate="'required'"
                  data-vv-as="page"
                  data-vv-name="page"
                  class="form-control no-instruction custom-select"
                  :class="{
                    'is-invalid': errors.has('page'),
                    'has-invalid': errors.has('page'),
                  }">
                </b-form-select>
                <span class="has-icon-feedback"></span>
                <div class="invalid-feedback" :is-invalid="errors.has('page')">
                  {{ errors.first('page') }}
                </div>
              </b-form-group>
            </div>

            <b-form-group role="group" class="mb-3 has-feedback">
              <label class="w-100 form-control-label" for="title">Title</label>
              <input
                type="text"
                name="title"
                v-model.trim="categoryData.title"
                data-vv-as="title"
                v-validate="'required'"
                data-vv-name="title"
                placeholder="Category Title"
                autocomplete="NoAutocomplete"
                class="form-control no-instruction"
                :class="{
                  input: true,
                  'is-invalid': errors.has('title'),
                  'has-invalid': errors.has('title'),
                }" />
              <span class="has-icon-feedback"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('title')">
                {{ errors.first('title') }}
              </div>
            </b-form-group>
            <label v-if="categoryData.background && categoryData.newBackground" class="w-100 form-control-label" for="page">Crop Image</label>
            <cropper
              v-if="categoryData.newBackground"
              ref="cropperBackground"
              class="cropper"
              :src="categoryData.background"
              :stencil-props="{
                aspectRatio: backgroundLimitations.aspectRatio,
              }"
              @change="onChangeBackground" />
            <div class="row mb-4">
              <div class="col-sm-4 pr-1">
                <label v-if="categoryData.background" class="w-100 form-control-label" for="page">Preview</label>
                <img style="width: 100%" v-if="categoryData.background" :src="backgroundPreview || categoryData.background" alt="" />
              </div>
              <div class="col-sm-8 pl-1">
                <div class="form-group mb-0">
                  <b-form-group role="group" class="mb-3 has-feedback no-gutters">
                    <label class="w-100 form-control-label" for="page">Background</label>
                    <b-form-file
                      accept="image/*"
                      data-vv-as="background"
                      data-vv-name="background"
                      placeholder="Browse file or drop here..."
                      drop-placeholder="Drop file here..."
                      @change="imageFileChange($event, 'background')"
                      :state="!backgroundHasImageError"
                      class="form-control no-instruction"
                      :class="{
                        'is-invalid': backgroundHasImageError,
                        'has-invalid': backgroundHasImageError,
                      }"></b-form-file>
                    <span class="has-icon-feedback"></span>
                    <div class="invalid-feedback" :is-invalid="backgroundHasImageError">
                      {{ backgroundImageError }}
                    </div>
                  </b-form-group>
                  <h5 v-if="!selectedParent">
                    <i>For best display results, make sure to upload images with a 1:1 aspect ratio. Minimum recommended size is 360x360</i>
                  </h5>
                  <h5 v-if="selectedParent">
                    <i>For best display results, make sure to upload images with a 1.5:1 aspect ratio. Minimum recommended size is 360x240</i>
                  </h5>
                </div>
              </div>
            </div>
            <label v-if="categoryData.featured && categoryData.newFeatured" class="w-100 form-control-label" for="page">Crop Image</label>
            <cropper
              v-if="categoryData.newFeatured"
              ref="cropperFeature"
              class="cropper"
              :src="categoryData.featured"
              :stencil-props="{
                aspectRatio: featuredLimitations.aspectRatio,
              }"
              @change="onChangeFeature" />
            <div
              v-if="
                selectedParent == null &&
                !(categoryData.page == '602cc007e5ba430015dbc5f5' || categoryData.page == '602cc007e5ba430015dbc5f4') &&
                !(selectedParentCategory == 'connection' || selectedParentCategory == 'culture')
              "
              class="row mb-4">
              <div class="col-sm-4 pr-1">
                <label v-if="categoryData.featured" class="w-100 form-control-label" for="page">Preview</label>
                <img style="width: 100%" v-if="categoryData.featured" :src="featuredPreview || categoryData.featured" alt="" />
              </div>
              <div class="col-sm-8 pl-1">
                <div class="form-group mb-0">
                  <b-form-group role="group" class="mb-3 has-feedback no-gutters">
                    <label class="w-100 form-control-label" for="featured">Featured Image</label>
                    <b-form-file
                      accept="image/*"
                      data-vv-as="featured"
                      data-vv-name="featured"
                      placeholder="Browse file or drop here..."
                      drop-placeholder="Drop file here..."
                      @change="imageFileChange($event, 'feature')"
                      :state="!featureHasImageError"
                      class="form-control no-instruction"
                      :class="{
                        'is-invalid': featureHasImageError,
                        'has-invalid': featureHasImageError,
                      }"></b-form-file>
                    <span class="has-icon-feedback"></span>
                    <div class="invalid-feedback" :is-invalid="featureHasImageError">
                      {{ featureImageError }}
                    </div>
                  </b-form-group>
                </div>
                <h5><i>Please use 4:3 aspect ratio for best results.</i></h5>
              </div>
            </div>

            <div class="form-group mb-0">
              <label class="form-control-label" style="position: relative; top: -8px; margin-right: 4px">Show</label>
              <base-switch v-model="categoryData.show"></base-switch>
            </div>

            <!-- <div class="row">
              <div class="col-sm-6">
                <img v-if="categoryData.background" src="" alt="" />
              </div>
              <div class="col-sm-6"></div>
            </div> -->
          </div>
          <template v-slot:modal-footer>
            <base-button type="danger" v-if="categoryData._id" @click="deleteCategory" :disabled="$store.state.categories.loading"> Delete </base-button>
            <div style="flex: 1"></div>
            <base-button type="flat" @click="cancelAction()">Cancel</base-button>
            <base-button type="primary" @click="categoryData._id ? updateCategory() : createCategory()" :disabled="$store.state.categories.loading">
              {{ categoryData._id ? 'Update Category' : 'Create Category' }}
            </base-button>
          </template>
        </b-modal>

        <!-- Category Modal -->
        <b-modal v-model="modal2" size="xl" hide-header-close no-close-on-esc no-close-on-backdrop centered>
          <template v-slot:modal-title>
            <span style="color: #000">Category Order</span>
          </template>
          <div class="draggable-container">
            <draggable v-model="selectedCategories" v-bind="dragOptions" tag="div" @start="drag = true" @end="drag = false" ghost-class="ghost" class="">
              <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="draggable-categories">
                <div
                  v-for="category in selectedCategories"
                  :key="category.index"
                  class="category-item"
                  :class="{
                    'even-item': category.index % 2 == 0,
                    'odd-item': category.index % 2 != 0,
                  }">
                  <p>
                    {{ category.title }}
                  </p>
                  <img :src="category.background" alt="" />
                </div>
              </transition-group>
            </draggable>
          </div>
          <template v-slot:modal-footer>
            <div style="flex: 1"></div>
            <base-button type="flat" @click="modal2 = false">Cancel</base-button>
            <base-button type="default" class="btn-aqua" @click="updateCategoryOrder()" :disabled="$store.state.categories.loading"> Update Order </base-button>
          </template>
        </b-modal>
      </card>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { Validator } from 'vee-validate';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

const MAX_SIZE = 1048576;
const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;
const MIN_WIDTH = 360;
const MIN_HEIGHT = 240;

export default {
  components: {
    draggable,
    Cropper,
  },
  display: 'Transitions',
  data() {
    return {
      backgroundPreview: null,
      featuredPreview: null,
      backgroundLimitations: {
        maxWidth: 360,
        maxHeight: 360,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 1 / 1,
      },
      featuredLimitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      modal: false,
      modal2: false,
      categoryData: {
        _id: null,
        title: null,
        background: null,
        newBackground: null,
        featured: null,
        newFeatured: null,
        show: true,
        community: true,
        culture: true,
        connection: true,
      },
      col1: true,
      selectedParent: this.$route.params.selectedParent,
      selectedCategories: null,
      selectedParentCategory: null,
      rowDisplayed: {
        connection: true,
        culture: true,
        community: true,
      },
      forPayload: null,
      drag: false,
      hasImageError: false,
      imageError: '',
      imageUrl: '',
      imageDimension: {
        width: '',
        height: '',
      },
      imageLoaded: false,
      backgroundHasImageError: false,
      backgroundImageError: '',
      featureHasImageError: false,
      featureImageError: '',
    };
  },
  watch: {
    '$route.params'(params) {
      this.selectedParent = params.selectedParent;
    },
    'categoryData.newBackground'(newBackground) {
      if (newBackground) {
        this.categoryData.background = URL.createObjectURL(newBackground);
      }
    },
    'categoryData.newFeatured'(newFeatured) {
      if (newFeatured) {
        this.categoryData.featured = URL.createObjectURL(newFeatured);
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    categories() {
      return this.$store.state.categories.categories;
    },
    pages() {
      return this.categories
        .map((c) => c.page && c.page.name)
        .filter((page, i, arr) => page && arr.indexOf(page) === i)
        .filter((page) => (this.selectedParent ? this.categories.find((c) => c._id === this.selectedParent).page.name === page : true));
    },
    filteredCategories() {
      return this.categories.filter((category) => (this.selectedParent ? category.parent === this.selectedParent : !!category.page && !category.parent));
    },
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    onChangeBackground({ coordinates, canvas }) {
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      this.backgroundPreview = canvas.toDataURL();
    },
    onChangeFeature({ coordinates, canvas }) {
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      this.featuredPreview = canvas.toDataURL();
    },
    cancelAction() {
      this.backgroundPreview = null;
      this.featuredPreview = null;
      (this.backgroundHasImageError = false),
        (this.backgroundImageError = ''),
        (this.featureHasImageError = false),
        (this.featureImageError = ''),
        (this.modal = false);
    },
    imageFileChange(e, type) {
      const file = e.target.files[0];
      console.log(file);
      if (file) {
        if (type == 'background') {
          this.categoryData.newBackground = file;
          this.categoryData.background = URL.createObjectURL(this.categoryData.newBackground);

          this.backgroundHasImageError = false;
          this.backgroundImageError = '';
        } else {
          this.categoryData.newFeatured = file;
          this.categoryData.featured = URL.createObjectURL(this.categoryData.newFeatured);

          this.featureHasImageError = false;
          this.featureImageError = '';
        }

        this.hasImageError = false;
        this.imageError = '';
        (this.imageUrl = ''), (this.imageDimension.width = '');
        this.imageDimension.height = '';
        this.imageLoaded = false;

        // if(file.size > MAX_SIZE) {
        //   this.hasImageError = true
        //   this.imageError = `The image size (${this.formatBytes(file.size)}px) exceeds the maximum file size (${this.formatBytes(MAX_SIZE, 0)}px).`;

        //   // Check who has an error
        //   if(type == 'background') {
        //     this.backgroundHasImageError = true
        //     this.backgroundImageError = this.imageError
        //   } else {
        //     this.featureHasImageError = true
        //     this.featureImageError = this.imageError
        //   }

        //   return
        // }

        var reader = new FileReader();
        var self = this;

        reader.readAsDataURL(file);
        reader.onload = (evt) => {
          let img = new Image();

          img.onload = () => {
            self.imageDimension.width = img.width;
            self.imageDimension.height = img.height;
            console.log(self.imageDimension.width);
            // if(self.imageDimension.width > MAX_WIDTH) {
            //   self.hasImageError = true
            //   self.imageError = `The image width (${self.imageDimension.width}px) exceeds the maximum width of (${MAX_WIDTH}px).`

            //   // Check who has an error
            //   if(type == 'background') {
            //     this.backgroundHasImageError = true
            //     this.backgroundImageError = self.imageError
            //   } else {
            //     this.featureHasImageError = true
            //     this.featureImageError = self.imageError
            //   }

            //   return
            // }

            // if(self.imageDimension.height > MAX_HEIGHT) {
            //   self.hasImageError = true
            //   self.imageError = `The image height (${self.imageDimension.height}px) exceeds the maximum height of (${MAX_HEIGHT}px).`

            //   // Check who has an error
            //   if(type == 'background') {
            //     this.backgroundHasImageError = true
            //     this.backgroundImageError = self.imageError
            //   } else {
            //     this.featureHasImageError = true
            //     this.featureImageError = self.imageError
            //   }

            //   return
            // }

            if (self.imageDimension.width < MIN_WIDTH) {
              self.hasImageError = true;
              self.imageError = `The image width (${self.imageDimension.width}px)  is too small (minimum width: ${MIN_WIDTH}px).`;

              // Check who has an error
              if (type == 'background') {
                this.backgroundHasImageError = true;
                this.backgroundImageError = self.imageError;
              } else {
                this.featureHasImageError = true;
                this.featureImageError = self.imageError;
              }

              return;
            }

            if (self.imageDimension.height < MIN_HEIGHT) {
              self.hasImageError = true;
              self.imageError = `The image width (${self.imageDimension.height}px)  is too small (minimum width: ${MIN_HEIGHT}px).`;

              // Check who has an error
              if (type == 'background') {
                this.backgroundHasImageError = true;
                this.backgroundImageError = self.imageError;
              } else {
                this.featureHasImageError = true;
                this.featureImageError = self.imageError;
              }

              return;
            }

            self.imageLoaded = true;

            if (!self.hasImageError) {
              self.imageUrl = evt.target.result;
            }
          };

          img.src = evt.target.result;
        };
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    compare(a, b) {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    },
    openModalTwo(page, category) {
      var options = [
        { text: 'culture', value: '602cc007e5ba430015dbc5f4' },
        { text: 'connection', value: '602cc007e5ba430015dbc5f5' },
        { text: 'community', value: '602cc007e5ba430015dbc5f6' },
      ];

      if (!category) {
        this.selectedCategories = this.filteredCategories.filter((cat) => cat.page.name === page).sort(this.compare);
      } else {
        this.selectedCategories = this.filteredCategories.filter((cat) => cat.parent === category).sort(this.compare);
      }

      this.forPayload = {
        section: page,
        category: category || '',
      };

      this.modal2 = true;
    },
    toggleRow(page) {
      this.rowDisplayed[page] = !this.rowDisplayed[page];
    },
    resetCategoryData() {
      this.selectedParentCategory = null;
      this.backgroundPreview = null;
      this.featuredPreview = null;
      this.categoryData = {
        _id: null,
        title: null,
        background: null,
        newBackground: null,
        featured: null,
        newFeatured: null,
        show: true,
      };
    },
    composeCategory() {
      // If Sub category
      if (this.selectedParent) {
        this.backgroundLimitations.aspectRatio = 1.5 / 1;
      } else {
        this.backgroundLimitations.aspectRatio = 1 / 1;
      }
      this.resetCategoryData();
      this.modal = true;
    },
    async selectCategory(category, page = undefined) {
      // If sub category
      console.log(page);
      if (this.selectedParent) {
        this.backgroundLimitations.aspectRatio = 1.5 / 1;
        this.selectedParentCategory = page;
      } else {
        this.backgroundLimitations.aspectRatio = 1 / 1;
        this.selectedParentCategory = category.page.name;
      }

      category = JSON.parse(JSON.stringify(category));
      if (!category.featured) {
        category.featured = null;
      }
      this.categoryData = category;
      console.log(this.categoryData);
      this.modal = true;
    },
    async updateCategory() {
      return this.$validator.validateAll().then(async (isValid) => {
        // If Sub Category
        if (this.selectedParent != null) {
          if (this.backgroundHasImageError) {
            this.$notify({
              message: 'There is an error on your image',
              timeout: 4000,
              type: 'danger',
            });
            return;
          }
        } else {
          if (this.featureHasImageError || this.backgroundHasImageError) {
            this.$notify({
              message: 'There is an error on your image',
              timeout: 4000,
              type: 'danger',
            });
            return;
          }
        }
        if (isValid) {
          let canvasBackground;
          let canvasFeature;
          let background;
          let feature;

          if (this.categoryData.newBackground) {
            canvasBackground = this.$refs.cropperBackground.getResult().canvas;
            background = this.dataURLtoFile(canvasBackground.toDataURL(), this.categoryData.newBackground.name);
          }

          if (this.categoryData.newFeatured) {
            canvasFeature = this.$refs.cropperFeature.getResult().canvas;
            feature = this.dataURLtoFile(canvasFeature.toDataURL(), this.categoryData.newFeatured.name);
          }

          let category = this.categoryData;
          let formData = new FormData();

          if (this.categoryData.newBackground) {
            formData.set('background', background);
          }

          if (this.categoryData.newFeatured) {
            formData.set('featured', feature);
          }

          formData.set('category', JSON.stringify(category));

          try {
            await this.$store.dispatch('categories/UPDATE_CATEGORY', formData);
            this.resetCategoryData();
            this.modal = false;
            this.$notify({
              message: 'Category has been updated.',
              timeout: 4000,
              type: 'success',
            });
          } catch (error) {
            this.$notify({
              message: error.message,
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        }
      });
    },
    async updateCategoryOrder() {
      let formData = new FormData();
      let newOrder = [];

      for (var i in this.selectedCategories) {
        newOrder.push({
          categoryID: this.selectedCategories[i]._id,
          index: Number(i) + 1,
        });
      }

      let categoryData = {
        newOrder: newOrder,
        payload: this.forPayload,
      };

      formData.set('categories', JSON.stringify(categoryData));
      try {
        await this.$store.dispatch('categories/UPDATE_CATEGORY_ORDER', formData);
        this.selectedCategories = null;
        this.modal2 = false;
        this.$notify({
          message: 'Category has been ordered.',
          timeout: 4000,
          type: 'success',
        });
      } catch (error) {
        this.$notify({
          message: error.message,
          timeout: 4000,
          icon: 'fas fa-exclamation-triangle',
          type: 'danger',
        });
      }
    },
    async createCategory() {
      return this.$validator.validateAll().then(async (isValid) => {
        // If Sub Category
        if (this.selectedParent != null) {
          if (this.categoryData.newBackground == null) {
            this.$notify({
              message: 'Please upload an image',
              timeout: 4000,
              type: 'warning',
            });
            this.backgroundHasImageError = this.categoryData.newBackground == null ? true : this.backgroundHasImageError;
            this.backgroundImageError = this.categoryData.newBackground == null ? 'Please upload a background image' : this.backgroundImageError;
            return;
          }

          if (this.backgroundHasImageError) {
            this.$notify({
              message: 'There is an error on your image',
              timeout: 4000,
              type: 'danger',
            });
            return;
          }
        } else {
          if (this.categoryData.newBackground == null) {
            this.$notify({
              message: 'Please upload an image',
              timeout: 4000,
              type: 'warning',
            });
            this.backgroundHasImageError = this.categoryData.newBackground == null ? true : this.backgroundHasImageError;
            this.featureHasImageError = this.categoryData.newFeatured == null ? true : this.featureHasImageError;
            this.backgroundImageError = this.categoryData.newBackground == null ? 'Please upload a background image' : this.backgroundImageError;
            this.featureImageError = this.categoryData.newFeatured == null ? 'Please upload a featured image' : this.featureImageError;
            return;
          }

          if (this.featureHasImageError || this.backgroundHasImageError) {
            this.$notify({
              message: 'There is an error on your image',
              timeout: 4000,
              type: 'danger',
            });
            return;
          }
        }

        if (isValid) {
          let canvasBackground;
          let canvasFeature;
          let background;
          let feature;

          if (this.categoryData.newFeatured) {
            canvasFeature = this.$refs.cropperFeature.getResult().canvas;
            feature = this.dataURLtoFile(canvasFeature.toDataURL(), this.categoryData.newFeatured.name);
          }

          if (this.categoryData.newBackground) {
            canvasBackground = this.$refs.cropperBackground.getResult().canvas;
            background = this.dataURLtoFile(canvasBackground.toDataURL(), this.categoryData.newBackground.name);
          }

          let category = this.categoryData;
          let formData = new FormData();

          if (this.selectedParent) {
            category.parent = this.selectedParent;
            delete category.page;
          } else {
            delete category.parent;
          }

          delete category._id;

          if (this.categoryData.newBackground) {
            formData.set('background', background);
          }

          if (this.categoryData.newFeatured) {
            formData.set('featured', feature);
          }

          formData.set('category', JSON.stringify(category));

          try {
            await this.$store.dispatch('categories/CREATE_CATEGORY', formData);
            this.resetCategoryData();
            this.modal = false;
            this.$notify({
              message: 'Category has been created.',
              timeout: 4000,
              type: 'success',
            });
          } catch (error) {
            this.$notify({
              message: error.message,
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        }
      });
    },
    async deleteCategory() {
      let category = this.categoryData;

      try {
        await this.$store.dispatch('categories/DELETE_CATEGORY', category._id);
        this.resetCategoryData();
        this.modal = false;
        this.$notify({
          message: 'Category has been deleted.',
          timeout: 4000,
          type: 'success',
        });
      } catch (error) {
        this.$notify({
          message: error.message,
          timeout: 4000,
          icon: 'fas fa-exclamation-triangle',
          type: 'danger',
        });
      }
    },
  },
  async created() {
    try {
      await this.$store.dispatch('categories/GET_CATEGORIES');
    } catch (error) {
      this.$notify({
        message: error.message,
        timeout: 4000,
        icon: 'fas fa-exclamation-triangle',
        type: 'danger',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.page-name {
  text-transform: capitalize;
  background: #2f2d2d;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  margin-bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-right: 10px;
    color: var(--primary);
    cursor: pointer;
  }

  .right {
    font-size: 20px;
    text-transform: uppercase;
  }
}

/* .category:hover {
    cursor: pointer;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 25%);
  }

  .category:active {
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  } */

.custom-modal-title {
  color: #000;
}
.tooltip-custom {
}

.draggable-container {
  // height: 500px;
  // width: 100%;
  // overflow-x: auto;
}

.draggable-categories {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // grid-template-columns: repeat( auto-fit, minmax(220px, 1fr) );
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 20px;
  // display: flex;
  // position: relative;

  // .odd-item {
  //   position:sticky;
  //   top: 0;
  // }
  // .even-item {
  //   position:sticky;
  //   bottom: 0;
  // }
}

.category-item {
  cursor: move;
  position: relative;
  margin: 0 15px;
  min-width: 170px;
  p {
    position: absolute;
    font-weight: 900;
    color: white;
    margin-bottom: 0;
    bottom: 15px;
    left: 15px;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
}
.update-order {
  cursor: pointer;
}

.ghost {
  opacity: 0.2;
  border: 3px solid var(--primary);
  border-radius: 20px;
}

.categoriesContainer {
  border: 2px solid #102038;
  padding: 10px;
  width: 100%;
  margin: auto;
  background: #f4f4f4;
}
</style>
