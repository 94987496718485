var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-4 col-7"},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)])])]),_c('div',{staticClass:"container mt--6 minHeight"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{staticClass:"createConnectionContainer",attrs:{"no-body":"","body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg-10 col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Edit Connection")])])]),_c('b-row',{attrs:{"id":"processConnectionForm"}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pt-0"},[_c('b-row',[_c('form',{staticClass:"w-100"},[_c('div',{staticClass:"p-lg-4 px-lg-4 px-md-4 px-sm-4"},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[(_vm.cropperThumbnail)?_c('cropper',{ref:"thumbnail",staticClass:"cropper",attrs:{"src":_vm.cropperThumbnail,"stencil-props":{
                                    aspectRatio: _vm.thumbnailLimitations.aspectRatio,
                                  },"default-boundaries":_vm.defaultThumbnailBoundaries},on:{"change":_vm.thumbnailOnChange}}):_vm._e(),(!_vm.cropperThumbnail)?_c('div',{staticClass:"preview-image"},[(_vm.connection.avatar)?_c('img',{attrs:{"width":"100%","src":_vm.connection.avatar,"alt":"","srcset":""}}):_c('img',{attrs:{"width":"50%","src":require("../../assets/images/img-placeholder.png"),"alt":"","srcset":""}})]):_vm._e(),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"avatar"}},[_vm._v("Thumbnail")]),_c('b-form-file',{staticClass:"form-control no-instruction",class:{
                                      'is-invalid': _vm.avatarImageError,
                                      'has-invalid': _vm.avatarImageError,
                                    },attrs:{"id":"forAvatar","accept":"image/*","data-vv-name":"avatar","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onFileChange,"ondrop":_vm.onFileChange,"ondragover":_vm.onFileChange}}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.avatarImageError}},[_vm._v(" "+_vm._s(_vm.imageErrorMessage.avatar)+" ")])],1),_c('h5',[_c('i',[_vm._v("For best display results, make sure to upload images with a 1:1 aspect ratio. Minimum recommended size is 360x360")])]),_c('br'),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"title"}},[_vm._v("Event Title ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.connection.title),expression:"connection.title",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('title'),
                                      'has-invalid': _vm.errors.has('title'),
                                    },attrs:{"type":"text","name":"title","data-vv-as":"event title","data-vv-name":"title","placeholder":"Event Title","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.connection.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.connection, "title", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('title')}},[_vm._v(" "+_vm._s(_vm.errors.first('title'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"description"}},[_vm._v("Description")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.connection.description),expression:"connection.description",modifiers:{"trim":true}}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('description'),
                                      'has-invalid': _vm.errors.has('description'),
                                    },attrs:{"type":"text","name":"description","data-vv-as":"event description","data-vv-name":"description","placeholder":"Event description","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.connection.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.connection, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('description')}},[_vm._v(" "+_vm._s(_vm.errors.first('description'))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"startDate"}},[_vm._v("Start Date")]),_c('b-form-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:(("required|validateDate:" + (_vm.connection.endDate))),expression:"`required|validateDate:${connection.endDate}`"}],staticClass:"reportDatePicker no-instruction",class:{
                                          'is-invalid': _vm.errors.has('startDate'),
                                          'has-invalid': _vm.errors.has('startDate'),
                                          'has-error': _vm.errors.has('startDate'),
                                        },attrs:{"format":"YYYY-MM-DD","formatted":"DD MMM YYYY","min":_vm.minDate,"name":"startDate","onlyDate":"","data-vv-name":"startDate","data-vv-as":"Start Date"},model:{value:(_vm.connection.startDate),callback:function ($$v) {_vm.$set(_vm.connection, "startDate", $$v)},expression:"connection.startDate"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('startDate')}},[_vm._v(" "+_vm._s(_vm.errors.first('startDate'))+" ")])],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"endDate"}},[_vm._v("End Date")]),_c('b-form-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:("required"),expression:"`required`"}],staticClass:"reportDatePicker no-instruction",class:{
                                          'is-invalid': _vm.errors.has('endDate'),
                                          'has-invalid': _vm.errors.has('endDate'),
                                          'has-error': _vm.errors.has('endDate'),
                                        },attrs:{"format":"YYYY-MM-DD","formatted":"DD MMM YYYY","min":_vm.minEndDate,"name":"endDate","onlyDate":"","data-vv-name":"endDate","data-vv-as":"End Date"},model:{value:(_vm.connection.endDate),callback:function ($$v) {_vm.$set(_vm.connection, "endDate", $$v)},expression:"connection.endDate"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('endDate')}},[_vm._v(" "+_vm._s(_vm.errors.first('endDate'))+" ")])],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"time"}},[_vm._v("Time")]),_c('b-form-timepicker',{directives:[{name:"validate",rawName:"v-validate",value:("required"),expression:"`required`"}],staticClass:"reportDatePicker no-instruction mb-2",class:{
                                          'is-invalid': _vm.errors.has('time'),
                                          'has-invalid': _vm.errors.has('time'),
                                        },attrs:{"name":"time","data-vv-name":"time","data-vv-as":"Time"},model:{value:(_vm.connection.time),callback:function ($$v) {_vm.$set(_vm.connection, "time", $$v)},expression:"connection.time"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('time')}},[_vm._v(" "+_vm._s(_vm.errors.first('time'))+" ")])],1)],1),_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url"}},[_vm._v("Article Link Url")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.connection.url),expression:"connection.url",modifiers:{"trim":true}}],staticClass:"form-control no-instruction",class:{
                                          input: true,
                                          'is-invalid': _vm.errors.has('urls'),
                                          'has-invalid': _vm.errors.has('urls'),
                                        },attrs:{"type":"text","name":"url","data-vv-as":"aricle url","data-vv-name":"url","placeholder":"https://www.example.com","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.connection.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.connection, "url", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('urls')}},[_vm._v(" "+_vm._s(_vm.errors.first('urls'))+" ")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"email"}},[_vm._v("Email Adddress")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.connection.email),expression:"connection.email",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('email'),expression:"'email'"}],staticClass:"form-control no-instruction",class:{
                                          input: true,
                                          'is-invalid': _vm.errors.has('email'),
                                          'has-invalid': _vm.errors.has('email'),
                                        },attrs:{"type":"text","name":"email","data-vv-as":"email","data-vv-name":"email","placeholder":"user@example.com","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.connection.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.connection, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('email')}},[_vm._v(" "+_vm._s(_vm.errors.first('email'))+" ")])])],1)])],1),_c('div',{staticClass:"col-lg-6"},[(_vm.cropperCover)?_c('cropper',{ref:"cover",staticClass:"cropper",attrs:{"src":_vm.cropperCover,"stencil-props":{
                                    aspectRatio: _vm.coverLimitations.aspectRatio,
                                  },"default-boundaries":_vm.defaultCoverBoundaries},on:{"change":_vm.coverOnChange}}):_vm._e(),(!_vm.cropperCover)?_c('div',{staticClass:"preview-image"},[(_vm.connection.cover.url)?_c('img',{attrs:{"width":"100%","src":_vm.connection.cover.url,"alt":"","srcset":""}}):_c('img',{attrs:{"width":"100%","src":require("../../assets/images/img-placeholder.png"),"alt":"","srcset":""}})]):_vm._e(),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"cover"}},[_vm._v("Connection Flyer")]),_c('b-form-file',{staticClass:"form-control no-instruction",class:{
                                      'is-invalid': _vm.coverImageError,
                                      'has-invalid': _vm.coverImageError,
                                    },attrs:{"id":"forConver","accept":"image/*","data-vv-name":"cover","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":_vm.onFileChange,"ondrop":_vm.onFileChange,"ondragover":_vm.onFileChange}}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.coverImageError}},[_vm._v(" "+_vm._s(_vm.imageErrorMessage.cover)+" ")])],1),_c('h5',[_c('i',[_vm._v("Please use 4:3 aspect ratio for best results.")])]),_c('br'),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"city"}},[_vm._v("Select City ")]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction custom-select",class:{
                                      'is-invalid': _vm.errors.has('city'),
                                      'has-invalid': _vm.errors.has('city'),
                                    },attrs:{"options":_vm.cities,"name":"city","placeholder":"Please select city","data-vv-as":"city","data-vv-name":"city"},model:{value:(_vm.connection.city),callback:function ($$v) {_vm.$set(_vm.connection, "city", $$v)},expression:"connection.city"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('city')}},[_vm._v(" "+_vm._s(_vm.errors.first('city'))+" ")])],1),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"connectionParentCategory"}},[_vm._v("Select Connection Category ")]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction custom-select",class:{
                                      'is-invalid': _vm.errors.has('connectionParentCategory'),
                                      'has-invalid': _vm.errors.has('connectionParentCategory'),
                                    },attrs:{"options":_vm.connectionCategories,"name":"connectionParentCategory","placeholder":"Please select connectionParentCategory","data-vv-as":"connectionParentCategory","data-vv-name":"connectionParentCategory"},on:{"change":function($event){return _vm.getSubCategories()}},model:{value:(_vm.connectionParentCategory),callback:function ($$v) {_vm.connectionParentCategory=$$v},expression:"connectionParentCategory"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('connectionParentCategory')}},[_vm._v(" "+_vm._s(_vm.errors.first('connectionParentCategory'))+" ")])],1),(_vm.subCategories.length > 1)?_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[(_vm.subCategories.length > 1)?_c('div',[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"subCategory"}},[_vm._v("Select Sub Category ")]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction custom-select",class:{
                                        'is-invalid': _vm.errors.has('subCategory'),
                                        'has-invalid': _vm.errors.has('subCategory'),
                                      },attrs:{"options":_vm.subCategories,"name":"subCategory","placeholder":"Please select subCategory","data-vv-as":"subCategory","data-vv-name":"subCategory"},model:{value:(_vm.connectionSubCategory),callback:function ($$v) {_vm.connectionSubCategory=$$v},expression:"connectionSubCategory"}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('subCategory')}},[_vm._v(" "+_vm._s(_vm.errors.first('subCategory'))+" ")])],1):_c('div',[_c('label',{staticClass:"w-100 form-control-label error-msg",attrs:{"for":"error"}},[_vm._v("No Sub Category Found")])])]):_vm._e()],1),_c('div',{staticClass:"col-lg-12 mt-6"},[(_vm.ckeditorReady == false)?_c('b-row',[_c('div',{staticClass:"col-lg-12 text-center"},[_c('pulse-loader',{attrs:{"color":"var(--pulse-loader)"}}),_c('b',[_vm._v("Loading...")])],1)]):_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[(_vm.ckeditorReady)?_c('ckeditor',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('content'),
                                      'has-invalid': _vm.errors.has('content'),
                                    },attrs:{"id":"contentBlackCard","name":"content","editor":_vm.editor,"config":_vm.editorConfig,"data-vv-name":"content","data-vv-as":"content","data-vv-scope":"content"},model:{value:(_vm.connection.content),callback:function ($$v) {_vm.$set(_vm.connection, "content", $$v)},expression:"connection.content"}}):_vm._e(),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('content')}},[_vm._v(" "+_vm._s(_vm.errors.first('content'))+" ")])],1)],1)])])]),_c('div',{staticClass:"row mt-5 px-4"},[_c('div',{staticClass:"col-md-3 col-lg-4"},[_c('router-link',{ref:"clear",staticClass:"btn btn-outline-danger pr-5 pl-5 w-sm-100",attrs:{"to":"/connections/list","exact":""}},[_vm._v("Cancel")])],1),(_vm.connectionStatus === 'pending')?_c('div',{staticClass:"col-md-3 col-lg-3"},[_c('button',{staticClass:"btn btn-aqua float-right w-sm-100 mb-3 mb-md-0",attrs:{"type":"button","id":"approveConnection"},on:{"click":function($event){return _vm.approveConnection(_vm.connectionID)}}},[_vm._v(" Approve Event "),_c('span',{staticClass:"promise-btn__spinner-wrapper hidden"},[_c('span',{staticClass:"spinner",staticStyle:{"border-top-color":"rgb(52, 152, 219)","width":"18px","height":"18px","border-width":"4px","animation-duration":"1s"},attrs:{"data-v-cc3e8e04":""}})])])]):_vm._e(),_c('div',{staticClass:"col-md-3",class:{
                              'col-lg-5': _vm.connectionStatus === 'pending' && _vm.action !== 'create',
                              'col-lg-8': _vm.connectionStatus !== 'pending' || _vm.action === 'create',
                            }},[_c('button',{staticClass:"btn btn-primary float-right w-sm-100 mb-3 mb-md-0",attrs:{"type":"button","id":"processConnection"},on:{"click":function($event){return _vm.processConnection(_vm.connectionID)}}},[_c('b-icon',{staticClass:"btn-loader hidden",attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"1"}}),_vm._v(" "+_vm._s(_vm.action === 'create' ? 'Create Connection' : 'Update Connection')+" "),_c('span',{staticClass:"promise-btn__spinner-wrapper hidden"},[_c('span',{staticClass:"spinner",staticStyle:{"border-top-color":"rgb(52, 152, 219)","width":"18px","height":"18px","border-width":"4px","animation-duration":"1s"},attrs:{"data-v-cc3e8e04":""}})])],1)])])])])])],1)],1)],1)])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }