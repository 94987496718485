<template>
  <div>
    <notifications></notifications>
    <div class="main-content">
      <router-view></router-view>
    </div>

    <footer id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6"></div>
          <div class="col-xl-6"></div>
        </div>
      </div>
      <b-modal
        id="browser_warning"
        ref="browser_warning"
        title="Warning"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        :header-text-variant="'light'"
        hide-footer
        :header-bg-variant="'warning'">
        <div class="p-2 pl-3 w-100 title-temp">
          <h4 class="w-75">Warning</h4>
          <a @click="closeWarning" class="closebtn">×</a>
        </div>
        <div class="body-temp d-block text-center w-100">For best results please use latest version of Chrome browser</div>
      </b-modal>
    </footer>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import Vue from 'vue';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

export default {
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add('bg-default');
    },
    removeBackgroundColor() {
      document.body.classList.remove('bg-default');
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
    closeWarning() {
      Cookies.set('browser-warning', '0', { expires: 3 });
      this.$bvModal.hide('browser_warning');
    },
  },
  beforeDestroy() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
  mounted() {
    const browserInfo = this.$options.filters.getBrowserInfo();

    if (browserInfo[0] !== 'Chrome' && browserInfo[1] <= 79) {
      if (Cookies.get('browser-warning') !== '0') {
        this.$bvModal.show('browser_warning');
      }
    }
  },
};
</script>
<style>
#browser_warning .modal-header .modal-title,
#browser_warning .close.text-light {
  color: #fff !important;
}

#browser_warning .modal-header.bg-warning.text-light {
  padding: 0.6rem 0.75rem !important;
}
#browser_warning .modal-title {
  margin-top: 5px;
}

#browser_warning .modal-body {
  padding: 0 !important;
}

#browser_warning .title-temp {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  color: rgb(82, 95, 127);
  border-top: 6px solid rgb(255, 184, 34);
  border-bottom: 1px solid #eaeaea;
}

#browser_warning .body-temp {
  padding: 20px;
}
#browser_warning .closebtn {
  right: 15px;
  position: absolute;
  top: 15px;
}
</style>
