const state = {
  loading: false,
  loadedOnce: false,
  userloadedOnce: false,
  connectionLoading: false,
  success: false,
  error: false,
  errorUser: false,
  cities: [],
  userClientList: {},
  subCategories: [],
  connectionCategories: [],
  communityCategories: [],
  selectedUser: {},
  selectedConnectionSuccess: false,
  selectedConnectionError: false,
  selectedConnection: {},
};

export default state;
