import Vue from 'vue';
import { getClientUsers, getBusinessList, getBusinessClientUserByID, cancelSubscription, updateBusiness, deleteAdminUser } from '@/api/request/business';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_BUSINESS_LIST: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_BUSINESS_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getBusinessList(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let businessListResource = resp.data;
              let newBusinessList = {};
              let businessList = businessListResource.data.map((value) => {
                return {
                  businessAdsCover: value.cover.url,
                  businessName: value.name,
                  businessOwner: value.owner.firstname + ' ' + value.owner.lastname,
                  businessEmail: value.email,
                  businessOwnerID: value.owner.id,
                  ownerEmail: value.owner.email,
                  businessContact: value.phone,
                  businessAddress: `${value.location.address.line1} ${value.location.address.line2}`,
                  businessCreated: value.owner.createdAt,
                  businessStatus: value.owner.status,
                  subscription: value.owner.billing.subscription.status,
                  actions: value._id,
                };
              });

              // businessList = businessList.sort(function (a, b) {
              //   return new Date(b.createdAt) - new Date(a.createdAt)
              // })

              // newBusinessList['all'] = businessList.filter(admin => admin.isDeleted === 0)
              newBusinessList['all'] = businessList;
              newBusinessList['active'] = businessList.filter((admin) => admin.subscription === 'active' || admin.subscription === 'trialing');
              newBusinessList['canceled'] = businessList.filter((admin) => admin.subscription === 'canceled');

              commit('SET_BUSINESS_LIST_SUCCESS', newBusinessList);
              resolve(newBusinessList);
            } else {
              commit('GET_BUSINESS_LIST_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_BUSINESS_LIST_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CLIENT_USERS: ({ commit, dispatch }, companyID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADMIN_USER_REQUEST');
    return new Promise((resolve, reject) => {
      return getClientUsers(token, companyID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let clientUsersSource = resp.data;

              let newClientUsers = {};

              let clientUsers = clientUsersSource.map((value) => {
                return {
                  userID: value._id,
                  roleID: value.roleID,
                  roleLabel: value.roleLabel,
                  firstname: value.firstname || '',
                  lastname: value.lastname || '',
                  fullName: `${value.firstname} ${value.lastname}` || '',
                  email: value.email || '',
                  status: value.isActive === 1 ? 'active' : 'inactive',
                  isDeleted: value.isDeleted,
                  isBlocked: value.isBlocked,
                  isActive: value.isActive,
                  createdAt: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  createdYmd: moment(new Date(value.createdAt)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.createdAt)).format('HH:mm:SS'),
                  actions: value._id,
                };
              });

              clientUsers = clientUsers.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });

              newClientUsers['all'] = clientUsers.filter((admin) => admin.isDeleted === 0);
              newClientUsers['active'] = clientUsers.filter((admin) => admin.status === 'active' && admin.isDeleted === 0);
              newClientUsers['inactive'] = clientUsers.filter((admin) => admin.status === 'inactive' && admin.isDeleted === 0);
              newClientUsers['deleted'] = clientUsers.filter((admin) => admin.isDeleted === 1);

              commit('SET_CLIENT_USER_SUCCESS', newClientUsers);
              resolve(newClientUsers);
            } else {
              commit('GET_ADMIN_USER_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADMIN_USER_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_CLIENT_USER_BY_ID: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_CLIENT_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getBusinessClientUserByID(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_CLIENT_USER_BY_ID', resp.data);
              resolve(resp.data);
            } else {
              commit('GET_CLIENT_USER_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('GET_CLIENT_USER_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  CANCEL_SUBSCRIPTION: ({ commit, dispatch }, businessID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_BUSINESS_REQUEST');
    return new Promise((resolve, reject) => {
      return cancelSubscription(token, businessID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_BUSINESS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_BUSINESS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_BUSINESS_FAILED');
          reject(errors(err));
        });
    });
  },
  UPDATE_BUSINESS: ({ commit, dispatch }, { businessID, businessData, query }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_BUSINESS_REQUEST');
    return new Promise((resolve, reject) => {
      return updateBusiness(token, businessID, businessData, query)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_BUSINESS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_BUSINESS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_BUSINESS_FAILED');
          reject(errors(err));
        });
    });
  },
  DELETE_ADMIN_USER: ({ commit, dispatch }, userID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADMIN_REQUEST');
    return new Promise((resolve, reject) => {
      return deleteAdminUser(token, userID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADMIN_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADMIN_FAILED');
          reject(errors(err));
        });
    });
  },
};

export default actions;
