const state = {
  loading: false,
  contentsList: {},
  cultureCategories: [],
  cities: [],
  subCategories: [],
  selectedCulture: {},
};

export default state;
