const state = {
  loading: false,
  success: false,
  error: false,
  adsList: {},
  selectedAds: {},
  businessAdsInfo: {},
};

export default state;
