<template>
  <div class="reportList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-12 col-12">
            <h3 class="mb-0">Export Report</h3>
          </div>
        </div>

        <b-row id="exportlistContainer">
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="float-left d-flex align-items-center col-lg-4 cold-md-4 col-sm-4 no-gutter-left">
                      <b-form-group role="group" class="mb-3 mr-3 has-feedback w-100">
                        <label class="w-100 form-control-label" for="startDate">Date From</label>
                        <datetime-picker
                          v-model="startDate"
                          label="Select Start Date"
                          :min-date="minDate"
                          :max-date="maxDate"
                          format="YYYY-MM-DD"
                          formatted="DD MMM YYYY"
                          color="#0cb799"
                          button-color="#0cb799"
                          onlyDate
                          autoClose
                          no-button-now
                          no-label
                          id="reportStartDatePicker"
                          v-validate="`required|validateDate:${endDate}`"
                          name="startDate"
                          data-vv-name="startDate"
                          data-vv-as="Date From"
                          @change="changeStartDate"
                          class="reportDatePicker no-instruction"
                          :class="{
                            'is-invalid': errors.has('startDate'),
                            'has-invalid': errors.has('startDate'),
                            'has-error': errors.has('startDate'),
                          }"></datetime-picker>
                        <span class="has-icon-feedback"></span>
                        <div class="invalid-feedback" :is-invalid="errors.has('startDate')">
                          {{ errors.first('startDate') }}
                        </div>
                      </b-form-group>
                    </div>
                    <div class="float-left d-flex align-items-center col-lg-4 cold-md-4 col-sm-4 no-gutter-left">
                      <b-form-group role="group" class="mb-3 has-feedback w-100">
                        <label class="w-100 form-control-label" for="endDate">Date To</label>
                        <datetime-picker
                          v-model="endDate"
                          label="Select End Date"
                          :min-date="minEndDate"
                          :max-date="maxDate"
                          format="YYYY-MM-DD"
                          formatted="DD MMM YYYY"
                          color="#0cb799"
                          button-color="#0cb799"
                          onlyDate
                          autoClose
                          no-button-now
                          no-label
                          id="reportEndDatePicker"
                          v-validate="'required'"
                          name="endDate"
                          data-vv-name="endDate"
                          data-vv-as="Date To"
                          class="reportDatePicker no-instruction"
                          :class="{
                            'is-invalid': errors.has('endDate'),
                            'has-invalid': errors.has('endDate'),
                            'has-error': errors.has('endDate'),
                          }"></datetime-picker>
                        <span class="has-icon-feedback"></span>
                        <div class="invalid-feedback" :is-invalid="errors.has('endDate')">
                          {{ errors.first('endDate') }}
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-lg-4 pull-right no-gutters">
                      <b-form-group role="group" class="has-feedback w-100 m-0">
                        <label class="w-100 form-control-label" for="endDate">&nbsp; </label>
                        <div v-if="loading" class="col-lg-4 text-center pt-3">
                          <pulse-loader :color="'#0cb799'"></pulse-loader>
                        </div>
                        <download-excel
                          v-if="filteredExcelData.length > 0 && !loading"
                          class="downloadExcelButton btn btn-vobo float-left w-sm-100"
                          :data="filteredExcelData"
                          :name="fileName">
                          Export
                        </download-excel>
                        <span class="text-danger pt-3 pull-left" v-if="filteredExcelData.length === 0 && !loading">
                          <small>No data available to export</small>
                        </span>
                      </b-form-group>
                    </div>
                  </div>
                </b-row>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import moment from 'moment';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import showConfirmation from '@/utils/mixins/deleteNotify';
import { Validator } from 'vee-validate';
import JsonCSV from 'vue-json-csv';
import JsonExcel from 'vue-json-excel';

Vue.use(Cookies);
Vue.component('downloadCsv', JsonCSV);
Vue.component('downloadExcel', JsonExcel);

const validateDate = {
  getMessage: (field) => `Please select date earlier than Date To field`,
  validate(value, param) {
    return new Promise((resolve) => {
      resolve({
        valid: param[0] !== 'null' ? new Date(value) <= new Date(param[0]) : true,
        data: value === '' ? { message: `Date From field is required` } : '',
      });
    });
  },
};

Validator.extend('validateDate', validateDate);

export default {
  name: 'ReportList',
  mixins: [showConfirmation],
  data: function () {
    return {
      loadedOnce: false,
      startDate: String(moment(new Date()).tz('America/Los_Angeles').format('YYYY-MM-DD')),
      endDate: String(moment(new Date()).tz('America/Los_Angeles').format('YYYY-MM-DD')),
      minDate: String(moment(new Date('2020-09-01')).tz('America/Los_Angeles').format('YYYY-MM-DD')),
      maxDate: String(moment(new Date()).tz('America/Los_Angeles').format('YYYY-MM-DD')),
      minEndDate: String(moment(new Date()).tz('America/Los_Angeles').format('YYYY-MM-DD')),
      CSVData: [],
      ExcelData: [],
    };
  },
  computed: {
    loading: {
      get: function () {
        return this.$store.state.reports.loading;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    filteredExcelData: {
      get: function () {
        var filteredList = [];
        var userList = this.ExcelData;

        userList.map((user) => {
          const userFiltered = {};
          userFiltered['Subscription Type'] = user.subscriptionType === 'school' ? 'School/Group' : 'Individual/Family';
          userFiltered['Subscription'] = user.subscription === 'digital-only' ? 'Digital-Only' : 'Full';
          userFiltered['Subscription Start Date'] = user.profileStartDate;
          userFiltered['Order Number'] = user.profileOrderNumber;
          userFiltered['Subscription ID'] = user.profileSubscriptionId;
          userFiltered['Promo Code'] = user.profilePromoCode;
          userFiltered['Profile TIME Status'] = user.profileTimeStatus;
          userFiltered['Profile PAYMENT Status'] = user.profilePaymentStatus;
          userFiltered['Date of PAYMENT Status Suspension, Pause or Cancel'] = user.profileDatePaymentStatus;
          userFiltered["Subscriptions's Resume Date"] = user.profileDateResumesPaymentStatus;
          userFiltered['Profile First Name'] = user.profileFirstname;
          userFiltered['Profile Last Name'] = user.profileLastname;
          userFiltered['Profile Shipping Address'] = user.profileShippingAddress;
          userFiltered['Profile Shipping City'] = user.profileShippingCity;
          userFiltered['Profile Shipping State'] = user.profileShippingState;
          userFiltered['Profile Shipping Zip Code'] = user.profileShippingZipCode;
          userFiltered['Profile Points'] = user.profilePoints;
          userFiltered['Payment Plan'] = user.paymentPlan;
          userFiltered['Next Renewal Date'] = user.nextBillingDate;
          userFiltered['Previous MONTHLY Fulfillment'] = '';
          userFiltered['MONTHLY Fulfillment'] = '';
          userFiltered['MONTHLY Tracking'] = '';
          userFiltered['MONTHLY Fulfillment Date'] = '';
          userFiltered['Previous SEASONAL Fulfillment'] = '';
          userFiltered['SEASONAL Fulfillment'] = '';
          userFiltered['SEASONAL Tracking'] = '';
          userFiltered['SEASONAL Fulfillment Date'] = '';
          userFiltered['Profile Age'] = user.age;
          userFiltered['Profile Gender'] = user.gender;
          userFiltered['Account Email'] = user.email;
          userFiltered['Account First Name'] = user.firstname;
          userFiltered['Account Last Name'] = user.lastname;
          userFiltered['Billing Street Address'] = user.billingAddress;
          userFiltered['Billing City'] = user.billingCity;
          userFiltered['Billing State'] = user.billingState;
          userFiltered['Billing Zip Code'] = user.billingZipCode;
          userFiltered['Account Phone Number'] = user.phone;
          userFiltered['How did you hear about us?'] = user.source;
          userFiltered['Subaru Email List?'] = user.subscribeSubaru;
          userFiltered['Clif Kid Email List?'] = user.subscribeOffers;
          filteredList.push(userFiltered);
        });

        return filteredList;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    fileName() {
      return `report-${moment(new Date(this.startDate)).format('YYYY-MM-DD')}-${moment(new Date(this.endDate)).format('YYYY-MM-DD')}.xls`;
    },
  },
  methods: {
    getUserReport(startDate, endDate) {
      this.$store.dispatch('reports/GET_USER_REPORTS', { startDate, endDate }).then((reportList) => {
        this.ExcelData = reportList.all;
        if (this.ExcelData.length > 0) {
          this.loadedOnce = true;
        }
      });
    },
    formatDate(date) {
      return moment(new Date(date)).format('YYYY-MM-DD');
    },
    changeStartDate() {
      this.getUserReport(this.startDate, this.endDate);
    },
  },
  watch: {
    startDate: function (value, oldVal) {
      this.$nextTick(() => {
        this.minEndDate = value;
      });
      if (value === null) {
        this.endDate = value;
        this.$validator.remove('endDate');
      }

      if (value && this.endDate) {
        this.getUserReport(this.startDate, this.endDate);
      }
    },
    endDate: function (value, oldVal) {
      if (value && this.endDate) {
        this.getUserReport(this.startDate, this.endDate);
      }
    },
  },
  mounted() {
    this.getUserReport(this.startDate, this.endDate);
  },
};
</script>
<style lang="scss">
#reportStartDatePicker-input,
#reportEndDatePicker-input {
  font-size: 0.875rem !important;
  transition: all 0.15s ease-in-out !important;
  display: block !important;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px) !important;
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #525f7f !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #adb5bd;
  border-radius: 0.25rem;
  font-family: Open Sans, sans-serif;
  &::-webkit-input-placeholder {
    /* Edge */
    color: #b1b8c0;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b1b8c0;
  }
  &::placeholder {
    color: #b1b8c0;
  }
}
.reportDatePicker.has-invalid {
  padding-right: 0 !important;
  input {
    border: 1px solid #f4516c !important;
  }
}
.reportDatePicker.has-invalid ~ .invalid-feedback {
  display: block;
}
.downloadCSVButton,
.downloadExcelButton {
  cursor: pointer;
}
.downloadExcelButton {
  margin-top: 7px;
}
</style>
