export const loadStripeCheckout = (pk, version = 'v3', callback) => {
  var isExist = false;
  let e = document.createElement('script');
  e.src = `https://js.stripe.com/${version}`;
  e.type = 'text/javascript';

  /*var scripts = document.getElementsByTagName('script')
  for (var i = scripts.length; i--;) {
      if (scripts[i].src == e.src) {
        isExist = true
      }
  }*/

  // if (!isExist) {
  document.getElementsByTagName('head')[0].appendChild(e);
  e.addEventListener('load', callback);
  // }
};
