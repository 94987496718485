const mutations = {
  loading(state) {
    state.loading = true;
  },
  CREATE_CONNECTION_REQUEST: (state) => {
    state.loading = true;
  },
  CREATE_CONNECTION_SUCCESS: (state) => {
    state.loading = false;
  },
  CREATE_CONNECTION_FAILED: (state) => {
    state.loading = false;
  },
  GET_CONNECTION_LIST_FAILED: (state) => {
    state.loading = false;
  },
  setCityList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.cities = payload.data;
  },
  setConnectionCategoryList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.connectionCategories = payload.data;
  },
  setCommunityCategoryList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.communityCategories = payload.data;
  },
  setSubCategoryList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.subCategories = payload.data;
  },
  GET_CONNECTION_LIST_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.userloadedOnce = false;
    state.success = false;
    state.error = false;
  },
  SET_CONNECTION_LIST_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.userloadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorUser = false;
    state.connectionsList = data;
    state.selectedUser = {};
  },
  GET_CONNECTION_BY_ID_REQUEST: (state) => {
    state.loading = true;
    state.selectedConnectionSuccess = false;
    state.selectedConnectionError = false;
  },
  SET_CONNECTION_BY_ID: (state, data) => {
    state.loading = false;
    state.selectedConnectionSuccess = true;
    state.selectedConnection = data;
  },
  GET_CONNECTION_BY_ID_FAILED: (state) => {
    state.loading = false;
    state.selectedUserError = true;
    state.selectedConnection = {};
  },
  UPDATE_CONNECTION_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  UPDATE_CONNECTION_SUCCESS: (state) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  UPDATE_CONNECTION_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
};

export default mutations;
