const state = {
  loading: false,
  success: false,
  error: false,
  advertisementList: {},
  subscriptionsList: {},
  selectedSubscription: {},
  userSubscription: {},
  plans: undefined,
  card: {},
};

export default state;
