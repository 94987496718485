<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createConnectionContainer">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">App Configuration</h3>
              </div>
            </div>

            <b-row id="processContentForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-row v-if="loading">
                    <div class="col-lg-12 text-center">
                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                    </div>
                  </b-row>
                  <b-card-body v-else class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-12">
                              <div class="row form-container">
                                <div class="col-lg-6">
                                  <h1 class="form-name">Contact</h1>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="email">Email</label>
                                    <input
                                      type="text"
                                      name="email"
                                      v-model.trim="settings.email"
                                      v-validate="'required|email'"
                                      data-vv-name="email"
                                      data-vv-as="Email"
                                      placeholder="Enter Email"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('email'),
                                        'has-invalid': errors.has('email'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('email')">
                                      {{ errors.first('email') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="address">Address</label>
                                    <input
                                      type="text"
                                      name="address"
                                      v-model.trim="settings.address"
                                      data-vv-as="Address"
                                      v-validate="'required'"
                                      data-vv-name="address"
                                      placeholder="Enter address"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('address'),
                                        'has-invalid': errors.has('address'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('address')">
                                      {{ errors.first('address') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="phone">Phone Number </label>
                                    <input
                                      type="text"
                                      name="phone"
                                      v-model.trim="settings.phone"
                                      v-mask="'### ###-####'"
                                      v-validate="{
                                        required: true,
                                        min: 10,
                                        regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                      }"
                                      data-vv-name="phone"
                                      data-vv-as="Phone Number"
                                      placeholder="Enter Phone Number (123 123-1234)"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('phone'),
                                        'has-invalid': errors.has('phone'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('phone')">
                                      {{ errors.first('phone') }}
                                    </div>
                                  </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                  <h1 class="form-name">Other Info</h1>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="askUsEmail">Ask Us Email</label>
                                    <input
                                      type="text"
                                      name="askUsEmail"
                                      v-model.trim="settings.askUsEmail"
                                      v-validate="'required|email'"
                                      data-vv-name="askUsEmail"
                                      data-vv-as="Ask Us Email"
                                      placeholder="Please enter an email for Ask Us form"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('askUsEmail'),
                                        'has-invalid': errors.has('askUsEmail'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('askUsEmail')">
                                      {{ errors.first('askUsEmail') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="url">About Us URL</label>
                                    <input
                                      type="text"
                                      name="url"
                                      v-model.trim="settings.aboutUsUrl"
                                      data-vv-as="About Us Url"
                                      v-validate="'required'"
                                      data-vv-name="url"
                                      placeholder="Enter a url"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('url'),
                                        'has-invalid': errors.has('url'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('url')">
                                      {{ errors.first('url') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="url2">Advertise With Us URL</label>
                                    <input
                                      type="text"
                                      name="url2"
                                      v-model.trim="settings.advertiseWithUsUrl"
                                      data-vv-as="Advertise With Us"
                                      v-validate="'required'"
                                      data-vv-name="url2"
                                      placeholder="Enter a url"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('url2'),
                                        'has-invalid': errors.has('url2'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('url2')">
                                      {{ errors.first('url2') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="url3">Black Card URL</label>
                                    <input
                                      type="text"
                                      name="url3"
                                      v-model.trim="settings.blackCardUrl"
                                      data-vv-as="Black Card"
                                      v-validate="'required'"
                                      data-vv-name="url3"
                                      placeholder="Enter a url"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('url3'),
                                        'has-invalid': errors.has('url3'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('url3')">
                                      {{ errors.first('url3') }}
                                    </div>
                                  </b-form-group>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="url4">Privacy Policy URL</label>
                                    <input
                                      type="text"
                                      name="url4"
                                      v-model.trim="settings.privacyPolicyUrl"
                                      data-vv-as="Privacy Policy"
                                      v-validate="'required'"
                                      data-vv-name="url4"
                                      placeholder="Enter a url"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('url4'),
                                        'has-invalid': errors.has('url4'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('url4')">
                                      {{ errors.first('url4') }}
                                    </div>
                                  </b-form-group>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="url5">Business Registration URL</label>
                                    <input
                                      type="text"
                                      name="url5"
                                      v-model.trim="settings.registerBusinessUrl"
                                      data-vv-as="Register Business"
                                      v-validate="'required'"
                                      data-vv-name="url5"
                                      placeholder="Enter a url"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('url5'),
                                        'has-invalid': errors.has('url5'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('url5')">
                                      {{ errors.first('url5') }}
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <!-- <router-link ref="clear" :to="`/community/content/list`" exact class="btn btn-outline-danger pr-5 pl-5 w-sm-100">Cancel</router-link> -->
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button type="button" id="processContent" @click="updateContent()" class="btn btn-primary float-right w-sm-100 mb-3 mb-md-0">
                                Update
                                <span class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import { Validator } from 'vee-validate';
import moment from 'moment';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';

import Link from '@ckeditor/ckeditor5-link/src/link';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import List from '@ckeditor/ckeditor5-list/src/list';
import Font from '@ckeditor/ckeditor5-font/src/font';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import ParagraphButtonUI from '@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';

import { API_URL } from '@/api/constants/baseUrl';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';

Vue.use(Cookies);

export default {
  data() {
    return {
      action: 'create',
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EasyImage,
          EssentialsPlugin,
          Heading,
          BoldPlugin,
          ItalicPlugin,
          BlockQuote,
          Underline,
          LinkPlugin,
          ParagraphPlugin,
          Link,
          List,
          Alignment,
          Indent,
          IndentBlock,
          Font,
          HeadingButtonsUI,
          ParagraphButtonUI,
          Alignment,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          Autoformat,
        ],
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'blockQuote',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo',
          '|',
          'link',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'alignment:left',
          'alignment:right',
          'alignment:center',
          'alignment:justify',
          '|',
          'imageUpload',
          'imageStyle:side',
          'imageStyle:full',
        ],
        image: {
          toolbar: ['imageTextAlternative', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
          styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight'],
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
          ],
        },
        indentBlock: {
          classes: [
            'custom-block-indent-a', // First step - smallest indentation.
            'custom-block-indent-b',
            'custom-block-indent-c', // Last step - biggest indentation.
          ],
        },
        cloudServices: {
          tokenUrl: API_URL + '/endpoint',
          uploadUrl: 'https://79127.cke-cs.com/easyimage/upload/',
        },
      },
      settings: {
        email: null,
        address: null,
        phone: null,
        askUsEmail: null,
        aboutUsUrl: null,
        advertiseWithUsUrl: null,
        blackCardUrl: null,
        privacyPolicyUrl: null,
        registerBusinessUrl: null,
      },
      formData: new FormData(),
    };
  },
  computed: {
    loading() {
      return this.$store.state.appconfigs.loading;
    },
  },
  methods: {
    getContactInfo() {
      this.$store.dispatch('appconfigs/GET_SETTINGS').then((settings) => {
        this.settings = settings;
      });
    },
    updateContent() {
      const promiseBtn = document.getElementById('processContent');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processContentForm');
      bodyWrapper.classList.add('non-clickable');

      let settings = this.settings;

      // lowercase the following
      settings.aboutUsUrl = settings.aboutUsUrl ? settings.aboutUsUrl.replace(/^https/i, 'https') : null;
      settings.aboutUsUrl = settings.aboutUsUrl ? settings.aboutUsUrl.replace(/^http/i, 'http') : null;
      settings.aboutUsUrl = settings.aboutUsUrl ? settings.aboutUsUrl.replace(/www./i, 'www.') : null;

      settings.advertiseWithUsUrl = settings.advertiseWithUsUrl ? settings.advertiseWithUsUrl.replace(/^https/i, 'https') : null;
      settings.advertiseWithUsUrl = settings.advertiseWithUsUrl ? settings.advertiseWithUsUrl.replace(/^http/i, 'http') : null;
      settings.advertiseWithUsUrl = settings.advertiseWithUsUrl ? settings.advertiseWithUsUrl.replace(/www./i, 'www.') : null;

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$store
            .dispatch('appconfigs/UPDATE_SETTINGS', settings)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('App Config Content successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.getContactInfo();
              } else {
                if (res.data.error === 'error_user_already_exist') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('App Config already exists'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Error updating connection'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                }
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
                promiseBtnInner.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
                bodyWrapper.classList.remove('non-clickable');
              });
            });
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.getContactInfo();
  },
};
</script>
<style lang="scss">
.form-name {
  text-transform: uppercase;
}
</style>
