import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/
const getAdminUsers = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins`, null, 'GET', header);
};

const getClientUsers = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/clients`, null, 'GET', header);
};

const getAdminUserByID = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/admin/${adminID}`, null, 'GET', header);
};

const createAdminUser = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/`, data, 'POST', header);
};

const updateAdminUser = (token, adminID, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}`, data, 'PUT', header);
};

// SOFT DELETE FUNCTION

// const deleteAdminUser = (token, adminID) => {
//   const header = (token) ? {'authorization': token} : null
//   return $http(`/user/admins/${adminID}`, '', 'DELETE', header)
// }

// HARD(PERMANENT) DELETE FUNCTION
const deleteAdminUser = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}/permanent`, '', 'DELETE', header);
};

// Delete User Client Admin Account and Business Ads
const deleteClientAdminUser = (token, adminID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/admins/${adminID}/client`, '', 'DELETE', header);
};

export { getClientUsers, getAdminUsers, getAdminUserByID, createAdminUser, updateAdminUser, deleteAdminUser, deleteClientAdminUser };
