import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

/*
  $http (url, data, method, header)
*/
const getUser = (user) => {
  return $http(`/user/login`, user, 'POST');
};

const destroySession = (token) => {
  return $http(`/user/login/expire`, { token: token }, 'POST');
};

const getDashboard = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/dashboard`, null, 'GET', header);
};

const registerUser = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/user/register/`, data, 'POST', header);
};

const registerUsers = (data) => {
  const header = {
    'Content-Type': 'multipart/form-data',
  };
  return $http(`/api/user/client/register/bulk`, data, 'POST', header);
};

const publicRegisterUser = (data) => {
  const header = {
    'Content-Type': 'multipart/form-data',
  };
  return $http(`/api/user/client/register`, data, 'POST', header);
};

const getUserProfile = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/account`, null, 'GET', header);
};

const updateCurrentUserProfile = (token, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/account/`, data, 'PUT', header);
};

const updateStatusUser = (token, adminID, data) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/status/${adminID}`, data, 'PUT', header);
};

const validateUser = (username) => {
  return $http(`user/validate/${username}`, null, 'GET');
};

const validateEmail = (email) => {
  return $http(`user/validate-email/${email}`, null, 'GET');
};

const checkBillingComplete = (token) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/user/billing/complete`, null, 'GET', header);
};

export {
  registerUser,
  registerUsers,
  getUser,
  updateCurrentUserProfile,
  destroySession,
  getDashboard,
  getUserProfile,
  checkBillingComplete,
  updateStatusUser,
  validateUser,
  validateEmail,
  publicRegisterUser,
};
