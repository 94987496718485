const errors = {
  register_not_valid: 'There is an error with your registration. Please check the required fields',
  account_not_verified: 'account not verified',
  invalid_credentials: 'oops! that email / password combination is not valid',
  user_not_found: 'oops! that email / password combination is not valid',
  invalid_password: 'invalid Password',
  email_already_registered: 'email already registered',
  username_already_registered: 'username already registered',
  error_duplicate_user: 'Username already in use',
  email_does_not_exist: "We're sorry. We weren't able to identify you given the information you provided.", // &apos;
  error_email_already_exist: 'Email already in use',
  error_user_already_exist: 'User already exist',
  error_contact_already_exist: 'contact already exist',
  error_cp_already_exist: 'Content provider already exist',
  duplicate_username: 'username already taken',
  duplicate_email: 'email already taken',
  token_error: 'error confirming your account!',
  username_found: ' Username already in use',
  email_found: 'Email already in use',
  error_retrieving_data: 'Error retrieving data',
  error_updating_password: 'Error updating password. Check if your old password is correct',
  error_same_password: 'Please use another password different from your old passwords',
  'Network Error': 'Error 001: Server currently not available. Please Contact the website administrator', // Cant\' Connect to API/database
  jwt_malformed: 'Error on Link',
  jwt_expired: 'Link already expired',
  user_already_verified: 'User already verified',
  username_already_verified: 'User already verified',
  current_password_incorrect: 'Your current password provided is incorrect',
  error_updating_mobile_user: 'Error updating registered user',
  error_group_name_already_exist: 'Group name already exists',
  request_not_allowed: 'You are not allowed to request password. Please request password from your admin',
  inactive_cp_user: 'This account is de-activated. Please contact your organization administrator',
  inactive_user: 'Your account is de-activated. Please contact administrator',
  error_already_have_default_channel: 'Organization already have default channel',
  error_channel_category_exists: 'Channel Category Already Exists',
  error_channel_group_exists: 'Channel Group Already Exists',
  error_channel_type_exists: 'Channel type Already Exists',
  error_event_name_exists: 'Event Name already exists',
  error_card_name_exists: 'Card Name already exists',
  error_challenge_name_exists: 'Challenge Name already exists',
  error_updating_submission_status: 'If you want to publish the content please approve the points',
  error_school_promo_code_exists: 'Promo code already exists',
  default: 'Error 002: API Internal Error. Contact the website administrator', // API have unexpected output
};

const errorMap = (serverError) =>
  new Promise((resolve, reject) => {
    try {
      let errMsg;
      if (typeof serverError === 'object') {
        if (serverError.hasOwnProperty('message') && ['Network Error'].includes(serverError.message)) {
          errMsg = serverError.message;
        } else if (serverError.hasOwnProperty('data') && serverError.data.hasOwnProperty('isValid')) {
          errMsg = serverError.data.error;
        } else {
          if (['duplicate_email', 'duplicate_username'].includes(serverError.response.data.message)) {
            errMsg = serverError.response.data.message;
          } else {
            errMsg = serverError.response.data.error || serverError.response.data.message;
          }
        }
      } else {
        if (typeof serverError === 'string' && ['admin_account_not_allowed'].includes(serverError)) {
          errMsg = serverError;
        } else {
          errMsg = serverError.response.data.error;
        }
      }
      const defaults = errors[errMsg];
      const returnError = defaults === undefined ? errMsg : errors[errMsg];
      const errorMsg = { errMessage: returnError };
      resolve(errorMsg);
    } catch (err) {
      reject(new Error(err));
    }
  });

export default errorMap;
