import Vue from 'vue';
import Cookies from 'js-cookie';
import { getContent, createContent, updateContent, deleteContent } from '@/api/request/home';

Vue.use(Cookies);

const actions = {
  GET_CONTENT: async ({ commit, dispatch }, query) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);
    try {
      let {
        data: { success, data, message },
      } = await getContent(token, query);
      let top, bottom;
      top = data.filter((data) => data.position == 1);
      bottom = data.filter((data) => data.position == 0);
      commit('contents', data);
      console.log(top);
      console.log(bottom);
      commit('setFeaturedData', { top, bottom });
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  UPDATE_CONTENT: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);

    try {
      let {
        data: { success, data, message },
      } = await updateContent(token, formData);
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  CREATE_CONTENT: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);

    try {
      let {
        data: { data },
      } = await createContent(token, formData);
      return data;
    } catch (error) {
      const { success, message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
  DELETE_CONTENT: async ({ commit, dispatch }, id) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('loading', true);

    try {
      let {
        data: { success, data, message },
      } = await deleteContent(token, id);
      commit('loading', false);
      return data;
    } catch (error) {
      const { message } = error.response.data;
      commit('loading', false);
      throw new Error(message);
    }
  },
};

export default actions;
