<template>
  <div class="cityList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">City List</h3>
          </div>
          <div class="col-lg-3 pull-left">
            <router-link :to="`/contents/cities/create`" class="btn btn-primary opacity-8 float-right">
              <i class="fa fa-plus-circle"></i>
              Add City
            </router-link>
          </div>
        </div>
        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body>
                <b-container>
                  <!-- <b-row>
                    <b-col class="d-flex">
                      <div class="mr-3 text-nowrap">User Status</div>
                      <b-form-select v-model="selectedStatus" :options="statuses" name="Filter" :disabled="loading" class="float-right"/>
                    </b-col>
                    <b-col>
                    </b-col>
                    <b-col>
                        <base-input v-model="searchRequest"  :disabled="loading" prepend-icon="fas fa-search" placeholder="Search"></base-input>
                    </b-col>
                  </b-row> -->
                </b-container>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>
                <b-table
                  v-if="!loading"
                  class="notificationTable"
                  id="notificationTable"
                  responsive
                  hover
                  :items="cityList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                  :filter-included-fields="['city', 'createdAt']"
                  @context-changed="loadInitialPendingVisibleItems">
                  <template v-slot:cell(show)="data">
                    <div class="data">
                      <div class="title text-break-all w-100 text-sm-custom text-center">
                        <span
                          :class="{
                            'bg-success': data.item.show == true,
                            'bg-warning': data.item.show == false,
                            'bg-danger': data.item.status == false,
                          }"
                          class="badge badge-pill text-white"
                          >{{ data.item.show == true ? 'Active' : 'Hidden' }}</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data" class="data">
                    <div>
                      <router-link
                        :to="`/contents/cities/edit/${data.item.actions}`"
                        v-b-tooltip.hover
                        title="Edit City"
                        class="btn btn-outline-primary btn-sm">
                        <span class="btn-inner--icon"><i class="far fa-edit"></i> </span>
                      </router-link>

                      <!-- <a @click="deleteProcess(data.item.actions, data.item)" v-b-tooltip.hover title="Show/Hide City" class="btn btn-outline-danger btn-sm text-danger">
                        <span class="btn-inner--icon"><i class="far fa-trash-alt"></i> </span>
                      </a> -->

                      <a
                        @click="changeCityStatus(data.item.show, data.item.actions)"
                        v-b-tooltip.hover
                        :title="data.item.show === true ? 'Hide City' : 'Show City'"
                        class="btn btn-outline-vobo btn-sm text-vobo">
                        <span class="btn-inner--icon"><i :class="data.item.show === true ? 'fas fa-toggle-on' : 'fas fa-toggle-off'"></i> </span>
                      </a>
                    </div>
                  </template>
                </b-table>
                <b-row v-if="cityList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4 class="card-heading text-center">No records found</h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > perPage && !loading"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center" />
                <div class="float-right" v-if="totalRows > perPage && cityList.length !== 0">
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Cookies from 'js-cookie';
import moment from 'moment';
import showConfirmation from '@/utils/mixins/deleteNotify';

Vue.use(Cookies);

export default {
  name: 'cityList',
  mixins: [showConfirmation],
  data: function () {
    return {
      listCollection: [],
      errorFields: [],
      transactionID: '',
      cityListFilter: [],
      searchRequest: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, 100],
      allTimeStatus: false,
      selectedFilter: 'all',
      selectedStatus: 'all',
      statuses: [
        { value: 'all', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'deleted', text: 'Deleted' },
      ],
      sortBy: 'dateCreated',
      sortDesc: true,
      ListFields: [
        {
          key: 'city',
          label: 'City',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created',
          sortable: true,
        },
        {
          key: 'show',
          label: 'Show',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'actions',
        },
      ],
      updateAccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.cities.loading;
    },
    cityList: {
      get: function () {
        return this.cityListFilter;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    loadCities() {
      this.$store.dispatch('cities/GET_CITIES').then((resp) => {
        this.cityListFilter = this.$store.state.cities.cities.filter((city) => city.city != 'All');
        this.totalRows = this.cityListFilter.length;
      });
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    deleteProcess(id, item) {
      const cityID = String(id).trim();
      const city = String(item.city).trim();
      const otherInfo = `<br/>
                          <table class="table table-borderless table-popup">
                            <tr><td><strong>City Name</strong></td><td>${city}</td></tr>
                          </table>`;
      this.showConfirmation(this.deleteCity, cityID, 'this admin user? Please confirm the details below:', otherInfo, true);
    },
    deleteCity(id) {
      const cityID = String(id).trim();
      this.$store
        .dispatch('cities/DELETE_CITY', cityID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('City successfully deleted'),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadCities(this.currentPage);
            this.$router.push('/contents/cities');
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error deleting City'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
    changeCityStatus(currentStatus, id) {
      let formData = new FormData();
      const cityID = String(id).trim();
      let show = false;
      let statusValue = '';

      if (currentStatus === true) {
        show = false;
        statusValue = 'hidden';
      } else {
        show = true;
        statusValue = 'showed';
      }

      let city = {
        _id: cityID,
        show: show,
      };

      formData.set('city', JSON.stringify(city));
      this.$store
        .dispatch('cities/UPDATE_CITY_STATUS', formData)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords('City successfully ' + statusValue),
              timeout: 4000,
              icon: 'fas fa-check',
              type: 'success',
            });
            this.loadCities(this.currentPage);
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords('Error creating user'),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: 'fas fa-exclamation-triangle',
              type: 'danger',
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.cityListFilter = this.$store.state.admin.notifications[context];
      this.totalRows = this.cityListFilter.length;
    },
  },
  mounted() {
    this.loadCities();
  },
};
</script>
<style lang="scss">
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}

a.btn-outline-danger:hover {
  color: #fff !important;
}
a.btn-outline-vobo:hover {
  color: #fff !important;
}
a.btn-outline-default:hover {
  color: #fff !important;
}
a.btn-outline-primary:hover {
  color: #fff !important;
}
.notificationTable td a {
  margin-right: 0.25rem !important;
}
.notificationTable td a:last-child {
  margin-right: 0 !important;
}
.notificationTable .data .title {
  font-size: 0.8rem !important;
}
.notificationTable .table td {
  padding: 0.7rem;
}
.notificationTable tr {
  th {
    text-align: center;
  }
  &:focus {
    outline: 0 !important;
    outline: none !important;
  }
}
.notificationTable tr th:first-child,
.notificationTable tr td:first-child {
  width: 20% !important;
}
.notificationTable tr th:nth-child(2),
.notificationTable tr td:nth-child(2) {
  width: 25% !important;
}
.notificationTable tr th:nth-child(3),
.notificationTable tr td:nth-child(3) {
  width: 15% !important;
}
.notificationTable tr th:nth-child(4),
.notificationTable tr td:nth-child(4) {
  width: 12% !important;
}
.notificationTable tr th:nth-child(5),
.notificationTable tr td:nth-child(5) {
  width: 10% !important;
}
.notificationTable tr th:nth-child(6),
.notificationTable tr td:nth-child(6) {
  width: 20% !important;
}
.text-break-all {
  word-break: break-all;
}
.target-fade-cancel {
  -webkit-animation: target-fade-cancel 2s 1;
  -moz-animation: target-fade-cancel 2s 1;
}

@-webkit-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

@-moz-keyframes target-fade-cancel {
  0% {
    background-color: rgba(248, 108, 107, 0.3);
  }
  100% {
    background-color: rgba(248, 108, 107, 0);
  }
}

.target-fade-complete {
  -webkit-animation: target-fade-complete 2s 1;
  -moz-animation: target-fade-complete 2s 1;
}

@-webkit-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}

@-moz-keyframes target-fade-complete {
  0% {
    background-color: rgba(77, 189, 116, 0.3);
  }
  100% {
    background-color: rgba(77, 189, 116, 0);
  }
}
</style>
