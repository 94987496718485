import Vue from 'vue';
import {
  getAdvertisementList,
  getAdvertisementByID,
  setAsFeatured,
  approveAdvertisement,
  rejectAdvertisement,
  getAdvertisementCategoryList,
  createAdvertisement,
  updateAdvertisement,
  registerAdvertisement,
  validateAdvertisement,
} from '@/api/request/advertisement';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';
import { capitalize } from 'lodash';

Vue.use(Cookies);

const actions = {
  REGISTER_ADVERTISEMENT: ({ commit, dispatch }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('REGISTER_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return registerAdvertisement(token, data)
        .then((resp) => {
          if (resp) {
            commit('REGISTER_ADVERTISEMENT_SUCCESS', resp);
            resolve(resp);
          } else {
            commit('REGISTER_ADVERTISEMENT_FAILED');
            reject('Error in creating ad');
          }
        })
        .catch((err) => {
          commit('REGISTER_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },
  VALIDATE_ADVERTISEMENT: ({ commit, dispatch }, data) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('VALIDATE_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return validateAdvertisement(token, data)
        .then((resp) => {
          if (resp) {
            commit('VALIDATE_ADVERTISEMENT_SUCCESS', resp);
            resolve(resp);
          } else {
            commit('VALIDATE_ADVERTISEMENT_FAILED');
            reject('Error in creating ad');
          }
        })
        .catch((err) => {
          commit('VALIDATE_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_ADVERTISEMENT_LIST: ({ commit, dispatch }, advertisementID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADVERTISEMENT_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdvertisementList(token, advertisementID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let advertisementListResource = resp.data.data;
              let newAdvertisementList = {};
              let AdvertisementList = advertisementListResource.map((value) => {
                const today = moment().startOf('day');
                const start = moment(value.adStartDate).startOf('day');
                const end = moment(value.adStartDate).startOf('day').add(value.adDuration, 'months');
                // console.log(value.showAd, value.status, value.adStartDate, value.adDuration, today.diff(start), today.diff(end))
                const visible = value.showAd && value.status == 'approved' && today.diff(start) >= 0 && today.diff(end) <= 0;
                return {
                  adDuration: value.adDuration,
                  adStartDate: value.adStartDate,
                  businessAddress: value.business.address,
                  businessAddressLine2: value.business.addressLine2,
                  businessEmail: value.business.email,
                  businessName: value.business.name,
                  businessPhoneNumber: value.business.phoneNumber,
                  website: value.business.website,
                  category: value.category ? value.category.title : '-',
                  featured: value.featured == null ? false : true,
                  city: value.city == null ? 'City Removed.' : value.city.city,
                  showAd: value.showAd,
                  plan: value.plan,
                  page: capitalize(value.page.name),
                  _id: value._id,
                  status: value.status,
                  visible,
                  createdAt: moment(new Date(value.timestamp.created)).format('YYYY-MM-DD HH:mm:SS'),
                  updatedAt: moment(new Date(value.timestamp.updated)).format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                  cover: value.cover,
                };
              });
              newAdvertisementList['all'] = AdvertisementList;
              newAdvertisementList['pending'] = AdvertisementList.filter((advertisement) => advertisement.status === 'pending');
              newAdvertisementList['approved'] = AdvertisementList.filter((advertisement) => advertisement.status === 'approved');
              newAdvertisementList['rejected'] = AdvertisementList.filter((advertisement) => advertisement.status === 'rejected');

              commit('SET_ADVERTISEMENT_LIST_SUCCESS', newAdvertisementList);
              resolve(newAdvertisementList);
            } else {
              commit('GET_ADVERTISEMENT_LIST_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADVERTISEMENT_LIST_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_ADVERTISEMENT_CATEGORY_LIST: ({ commit }, { query }) => {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      // commit('loading')
      commit('GET_ADVERTISEMENT_CATEGORY_LIST_REQUEST');
      return getAdvertisementCategoryList(token, query)
        .then(async (result) => {
          if (result) {
            commit('SET_ADVERTISEMENT_CATEGORY_LIST', result.data);
            resolve(result);
          }
        })
        .catch((err) => {
          // commit('error', err)
          commit('GET_ADVERTISEMENT_CATEGORY_LIST_FAILED');
          reject(errors(err));
        });
    });
  },

  GET_ADVERTISEMENT_BY_ID: ({ commit, dispatch }, advertisementID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_ADVERTISEMENT_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdvertisementByID(token, advertisementID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_ADVERTISEMENT_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_ADVERTISEMENT_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('GET_ADVERTISEMENT_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  CREATE_ADVERTISEMENT: ({ commit, dispatch }, advertisementData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('CREATE_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return createAdvertisement(token, advertisementData)
        .then((resp) => {
          if (resp) {
            if (resp) {
              commit('CREATE_ADVERTISEMENT_SUCCESS', resp);
              resolve(resp);
            } else {
              commit('CREATE_ADVERTISEMENT_FAILED');
              reject('Error in creating ad');
            }
          }
        })
        .catch((err) => {
          commit('CREATE_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },

  UPDATE_ADVERTISEMENT: ({ commit, dispatch }, { advertisementData, id }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('UPDATE_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return updateAdvertisement(token, advertisementData, id)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADVERTISEMENT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('UPDATE_ADVERTISEMENT_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('UPDATE_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },
  SET_FEATURED: ({ commit, dispatch }, { advertisementID, advertisementData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('APPROVE_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return setAsFeatured(token, advertisementID, advertisementData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('APPROVE_ADVERTISEMENT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('APPROVE_ADVERTISEMENT_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('APPROVE_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },
  APPROVE_ADVERTISEMENT: ({ commit, dispatch }, advertisementID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('APPROVE_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return approveAdvertisement(token, advertisementID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('APPROVE_ADVERTISEMENT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('APPROVE_ADVERTISEMENT_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('APPROVE_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },
  REJECT_ADVERTISEMENT: ({ commit, dispatch }, { advertisementID, advertisementData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('REJECT_ADVERTISEMENT_REQUEST');
    return new Promise((resolve, reject) => {
      return rejectAdvertisement(token, advertisementID, advertisementData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('REJECT_ADVERTISEMENT_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('REJECT_ADVERTISEMENT_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('REJECT_ADVERTISEMENT_FAILED');
          reject(errors(err));
        });
    });
  },
};
export default actions;
