const mutations = {
  loading(state) {
    state.loading = true;
  },
  GET_USER_GROUPS_REQUEST: (state) => {
    state.groupLoading = true;
    state.groupSuccess = false;
    state.groupError = false;
    state.selectedGroups = [];
  },
  GET_USER_GROUPS_SUCCESS: (state, data) => {
    state.groupLoading = false;
    state.groupSuccess = true;
    state.groupError = false;
    state.selectedGroups = data;
  },
  GET_USER_GROUPS_FAILED: (state) => {
    state.groupLoading = false;
    state.groupSuccess = false;
    state.groupError = true;
    state.selectedGroups = [];
  },
  GET_MESSAGES_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
    state.messageList = [];
  },
  SET_MESSAGES_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
    state.messageList = data;
  },
  GET_MESSAGES_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
    state.messageList = [];
  },
  GET_USERS_REQUEST: (state) => {
    state.userLoading = true;
    state.userSuccess = false;
    state.userError = false;
    state.userList = [];
  },
  SET_USERS_SUCCESS: (state, data) => {
    state.userLoading = false;
    state.userSuccess = true;
    state.userError = false;
    state.userList = data;
  },
  GET_USERS_FAILED: (state) => {
    state.userLoading = false;
    state.userSuccess = false;
    state.userError = true;
    state.userList = [];
  },
  SEND_MESSAGE_REQUEST: (state) => {
    state.loading = true;
    state.success = false;
    state.error = false;
  },
  SEND_MESSAGE_SUCCESS: (state, data) => {
    state.loading = false;
    state.success = true;
    state.error = false;
  },
  SEND_MESSAGE_FAILED: (state) => {
    state.loading = false;
    state.success = false;
    state.error = true;
  },
  GET_CAMPAIGN_REQUEST: (state) => {
    state.campaignLoading = true;
    state.campaignSuccess = false;
    state.campaignError = false;
  },
  GET_CAMPAIGN_SUCCESS: (state, data) => {
    state.campaignLoading = false;
    state.campaignSuccess = true;
    (state.campaignError = false), (state.campaignList = data);
  },
  GET_CAMPAIGN_FAILED: (state) => {
    state.campaignLoading = false;
    state.campaignSuccess = false;
    state.campaignError = true;
  },
  GET_MOBILE_MESSAGES_RECEIVER: (state) => {
    state.messageReceiverLoading = true;
    state.messageReceiverSuccess = false;
    state.messageReceiverError = false;
    state.messageReceiverList = [];
  },
  SET_MOBILE_MESSAGES_SUCCESS_RECEIVER: (state, data) => {
    state.messageReceiverLoading = false;
    state.messageReceiverSuccess = true;
    state.messageReceiverError = false;
    state.messageReceiverList = data;
  },
  GET_MOBILE_MESSAGES_FAILED_RECEIVER: (state) => {
    state.messageReceiverLoading = false;
    state.messageReceiverSuccess = false;
    state.messageReceiverError = true;
    state.messageReceiverList = [];
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.success = false;
    state.error = false;
    state.groupLoading = false;
    state.groupSuccess = false;
    state.groupError = false;
    state.selectedGroups = [];
    state.userLoading = false;
    state.userSuccess = false;
    state.userError = false;
    state.userList = false;
    state.campaignLoading = false;
    state.campaignSuccess = false;
    state.campaignError = false;
    state.campaignList = [];
  },
  GET_NOTIFICATIONS_REQUEST: (state) => {
    state.loading = true;
    state.loadedOnce = false;
    state.userloadedOnce = false;
    state.success = false;
    state.error = false;
  },
  SET_NOTIFICATIONS_SUCCESS: (state, data) => {
    state.loading = false;
    state.loadedOnce = true;
    state.userloadedOnce = true;
    state.success = true;
    state.error = false;
    state.errorUser = false;
    state.notifications = data;
  },
  GET_NOTIFICATIONS_FAILED: (state) => {
    state.loading = false;
  },
  setCityList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.cities = payload.data;
  },
  setSectionList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.sections = payload;
  },
  setSubCategoryList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.subCategories = payload.data;
  },
};

export default mutations;
