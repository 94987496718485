const mutations = {
  GET_SETTINGS_REQUEST: (state) => {
    state.success = false;
    state.error = false;
    state.settingsList = [];
  },
  GET_SETTINGS_SUCCESS: (state, data) => {
    state.success = true;
    state.error = false;
    state.settingsList = data;
  },
  GET_SETTINGS_FAILED: (state) => {
    state.success = false;
    state.error = true;
  },
  GET_SETTINGS_VOBO_CONNECT: (state) => {
    state.voboLoading = true;
  },
  GET_SETTINGS_VOBO_CONNECT_DONE: (state) => {
    state.voboLoading = false;
  },
  GET_SETTINGS_ACCESS_CODE: (state) => {
    state.accessLoading = true;
  },
  GET_SETTINGS_ACCESS_CODE_DONE: (state) => {
    state.accessLoading = false;
  },
  GET_SETTINGS_AUTO_PHONE: (state) => {
    state.autoPhoneLoading = true;
  },
  GET_SETTINGS_AUTO_PHONE_DONE: (state) => {
    state.autoPhoneLoading = false;
  },
  GET_SETTINGS_MAX_NOTIF: (state) => {
    state.notifMaxLoading = true;
  },
  GET_SETTINGS_MAX_NOTIF_DONE: (state) => {
    state.notifMaxLoading = false;
  },
  GET_SETTINGS_BACKGROUND: (state) => {
    state.backgroundLoading = true;
  },
  GET_SETTINGS_BACKGROUND_DONE: (state) => {
    state.backgroundLoading = false;
  },
  GET_SETTINGS_SOCIAL_MEDIA: (state) => {
    state.socialMediaLoading = true;
  },
  GET_SETTINGS_SOCIAL_MEDIA_DONE: (state) => {
    state.socialMediaLoading = false;
  },
  GET_SETTINGS_CUSTOM_MENU: (state) => {
    state.customMenuLoading = true;
  },
  GET_SETTINGS_CUSTOM_MENU_DONE: (state) => {
    state.customMenuLoading = false;
  },
  GET_SETTINGS_CUSTOM_MENU_SAVE: (state) => {
    state.customMenuLoadingSave = true;
  },
  GET_SETTINGS_CUSTOM_MENU_SAVE_DONE: (state) => {
    state.customMenuLoadingSave = false;
  },
  RESET_DATA: (state) => {
    state.voboLoading = false;
    state.accessLoading = false;
    state.backgroundLoading = false;
    state.autoPhoneLoading = false;
    state.notifMaxLoading = false;
    state.socialMediaLoading = false;
    state.success = false;
    state.error = false;
    (state.settingsList = []), (state.customMenuLoading = false);
  },
};

export default mutations;
