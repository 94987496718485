import Vue from 'vue';
import { getAdsList, getAdsByID, getBusinessAdsID, setAsFeatured, approveAds, rejectAds } from '@/api/request/ads';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_ADS_LIST: ({ commit, dispatch }, adsID = null) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADS_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdsList(token, adsID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              let adsListResource = resp.data.data;
              let newAdsList = {};
              let AdsList = adsListResource
                .map((value) => {
                  if (!value.business) {
                    console.error('INVALID BUSINESS', value.business);
                    return null;
                  }
                  let category = value.category == null ? 'Category removed.' : value.category;
                  return {
                    adsCover: value.business.cover.url,
                    adsOwner: value.business.name,
                    businessEmail: value.business.email,
                    businessCategory: category.parent ? category.parent.title : category.title || 'Category Removed',
                    businessSubCategory: category.parent ? category.title : 'No Sub Category',
                    featured: value.featured == null ? false : true,
                    status: value.business.status,
                    city: value.city.city == null ? 'City Removed.' : value.city.city,
                    city_status: value.city.show,
                    showAd: value.showAd,
                    createdAt: moment(new Date(value.business.timestamp.created)).format('YYYY-MM-DD HH:mm:SS'),
                    updatedAt: moment(new Date(value.business.timestamp.updated)).format('YYYY-MM-DD HH:mm:SS'),
                    actions: value._id,
                  };
                })
                .filter((b) => b != null);
              newAdsList['all'] = AdsList;
              newAdsList['pending'] = AdsList.filter((ads) => ads.status === 'pending');
              newAdsList['approved'] = AdsList.filter((ads) => ads.status === 'approved');
              commit('SET_ADS_LIST_SUCCESS', newAdsList);
              resolve(newAdsList);
            } else {
              commit('GET_ADS_LIST_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADS_LIST_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_BUSINESS_ADS_ID: ({ commit, dispatch }, businessID) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_ADS_LIST_REQUEST');
    return new Promise((resolve, reject) => {
      return getBusinessAdsID(token, businessID)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SET_BUSINESS_ADS_INFO', resp.data);
              resolve(resp.data);
            } else {
              commit('GET_ADS_LIST_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_ADS_LIST_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_ADS_BY_ID: ({ commit, dispatch }, adsID) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('GET_ADS_BY_ID_REQUEST');
    return new Promise((resolve, reject) => {
      return getAdsByID(token, adsID)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              commit('SET_ADS_BY_ID', resp.data.data);
              resolve(resp.data.data);
            } else {
              commit('GET_ADS_BY_ID_FAILED');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          commit('GET_ADS_BY_ID_FAILED');
          reject(errors(err));
        });
    });
  },
  SET_FEATURED: ({ commit, dispatch }, { adsID, adsData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('APPROVE_ADS_REQUEST');
    return new Promise((resolve, reject) => {
      return setAsFeatured(token, adsID, adsData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('APPROVE_ADS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('APPROVE_ADS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('APPROVE_ADS_FAILED');
          reject(errors(err));
        });
    });
  },
  APPROVE_ADS: ({ commit, dispatch }, { adsID, adsData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('APPROVE_ADS_REQUEST');
    return new Promise((resolve, reject) => {
      return approveAds(token, adsID, adsData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('APPROVE_ADS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('APPROVE_ADS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('APPROVE_ADS_FAILED');
          reject(errors(err));
        });
    });
  },
  REJECT_ADS: ({ commit, dispatch }, { adsID, adsData }) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('REJECT_ADS_REQUEST');
    return new Promise((resolve, reject) => {
      return rejectAds(token, adsID, adsData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('REJECT_ADS_SUCCESS', resp.data);
              resolve(resp);
            } else {
              commit('REJECT_ADS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('REJECT_ADS_FAILED');
          reject(errors(err));
        });
    });
  },
};
export default actions;
