<template>
  <div class="profile">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container mt--6 minHeight">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createConnectionContainer">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">Edit Content</h3>
              </div>
            </div>

            <b-row id="processContentForm">
              <div class="col-lg-12 col-md-12 col-12">
                <b-card no-body>
                  <b-card-body class="pt-0">
                    <b-row>
                      <form class="w-100">
                        <div class="p-lg-4 px-lg-4 px-md-4 px-sm-4">
                          <div class="row px-4">
                            <div class="col-lg-12">
                              <div class="row form-container">
                                <div class="col-lg-5">
                                  <h1>Culture Content</h1>
                                  <br />
                                  <label class="w-100 form-control-label pb-2" for="image">Cover Image</label>
                                  <cropper
                                    v-if="CropperImageCatcher"
                                    ref="cropper"
                                    class="cropper"
                                    :src="CropperImageCatcher"
                                    :stencil-props="{
                                      aspectRatio: limitations.aspectRatio,
                                    }"
                                    @change="coverOnChange" />
                                  <img
                                    v-if="!CropperImageCatcher && !culture.cover.url"
                                    width="100%"
                                    src="../../assets/images/img-placeholder.png"
                                    alt=""
                                    srcset="" />
                                  <b-form-group role="group" class="my-3 has-feedback">
                                    <b-form-file
                                      id="forFeaturedImage"
                                      accept="image/*"
                                      data-vv-name="image"
                                      @change="onFileChange"
                                      @ondrop="onFileChange"
                                      @ondragover="onFileChange"
                                      placeholder="Choose a file or drop it here..."
                                      drop-placeholder="Drop file here..."
                                      class="form-control no-instruction"
                                      :class="{
                                        'is-invalid': imageError,
                                        'has-invalid': imageError,
                                      }"></b-form-file>
                                    <div class="invalid-feedback" :is-invalid="imageError">
                                      {{ imageErrorMessage }}
                                    </div>
                                  </b-form-group>
                                  <h5>
                                    <i>Please use 4:3 aspect ratio for best results.</i>
                                  </h5>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="title">Title </label>
                                    <input
                                      type="text"
                                      name="title"
                                      v-model.trim="culture.title"
                                      data-vv-as="event title"
                                      v-validate="'required'"
                                      data-vv-name="title"
                                      placeholder="Content Title"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('title'),
                                        'has-invalid': errors.has('title'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('title')">
                                      {{ errors.first('title') }}
                                    </div>
                                  </b-form-group>

                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="description">Description </label>
                                    <input
                                      type="text"
                                      name="description"
                                      v-model.trim="culture.description"
                                      data-vv-as="description"
                                      v-validate="'required'"
                                      data-vv-name="description"
                                      placeholder="Description"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('description'),
                                        'has-invalid': errors.has('shortDescription'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('description')">
                                      {{ errors.first('description') }}
                                    </div>
                                  </b-form-group>
                                  <b-form-group role="group" class="mb-3 has-feedback">
                                    <label class="w-100 form-control-label" for="url">Article Link Url</label>
                                    <input
                                      type="text"
                                      name="url"
                                      v-model.trim="culture.url"
                                      data-vv-as="aricle url"
                                      data-vv-name="url"
                                      placeholder="https://www.example.com"
                                      autocomplete="NoAutocomplete"
                                      class="form-control no-instruction"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('urls'),
                                        'has-invalid': errors.has('urls'),
                                      }" />
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('urls')">
                                      {{ errors.first('urls') }}
                                    </div>
                                  </b-form-group>

                                  <div class="row">
                                    <!-- <div class="col-lg-6">
                                        <b-form-group role="group" class="mb-3 has-feedback">
                                          <label class="w-100 form-control-label" for="date">Date</label>
                                          <b-form-datepicker
                                          v-model="culture.date"
                                          format="YYYY-MM-DD"
                                          formatted="DD MMM YYYY"
                                          :min="min"
                                          locale="en"
                                          v-validate="`required`"
                                          name="date"
                                          onlyDate
                                          data-vv-name="date"
                                          data-vv-as="Date"
                                          class="reportDatePicker no-instruction"
                                          :class="{'is-invalid': errors.has('date'),'has-invalid': errors.has('date'), 'has-error': errors.has('date')}"
                                          ></b-form-datepicker>
                                          <span class="has-icon-feedback"></span>
                                          <div class="invalid-feedback" :is-invalid="errors.has('date')">
                                            {{errors.first('date')}}
                                          </div>
                                        </b-form-group>
                                      </div> -->
                                    <div class="col-lg-12">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="city">Select City </label>
                                        <b-form-select
                                          v-model="culture.city"
                                          :options="cities"
                                          name="city"
                                          placeholder="Please select city"
                                          v-validate="'required'"
                                          data-vv-as="city"
                                          data-vv-name="city"
                                          class="form-control no-instruction custom-select"
                                          :class="{
                                            'is-invalid': errors.has('city'),
                                            'has-invalid': errors.has('city'),
                                          }">
                                        </b-form-select>
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('city')">
                                          {{ errors.first('city') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-lg-6">
                                      <b-form-group role="group" class="mb-3 has-feedback">
                                        <label class="w-100 form-control-label" for="category">Select Content Category </label>
                                        <b-form-select
                                          v-model="contentCategory"
                                          :options="cultureCategories"
                                          name="category"
                                          placeholder="Please select category"
                                          v-validate="'required'"
                                          data-vv-as="category"
                                          data-vv-name="category"
                                          class="form-control no-instruction custom-select"
                                          :class="{
                                            'is-invalid': errors.has('category'),
                                            'has-invalid': errors.has('category'),
                                          }">
                                        </b-form-select>
                                        <span class="has-icon-feedback"></span>
                                        <div class="invalid-feedback" :is-invalid="errors.has('category')">
                                          {{ errors.first('category') }}
                                        </div>
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-7">
                                  <b-row v-if="ckeditorReady == false" class="pt-8">
                                    <div class="col-lg-12 text-center">
                                      <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                                      <b>Loading...</b>
                                    </div>
                                  </b-row>
                                  <b-form-group v-else role="group" class="mb-3 has-feedback pt-6">
                                    <label class="w-100 form-control-label pb-2" for="content">Content</label>
                                    <ckeditor
                                      v-if="ckeditorReady"
                                      id="content"
                                      name="content"
                                      :editor="editor"
                                      v-model="culture.content"
                                      :config="editorConfig"
                                      v-validate="'required'"
                                      data-vv-name="content"
                                      data-vv-as="content"
                                      data-vv-scope="content"
                                      class="no-instruction custom-ckeditor"
                                      :class="{
                                        input: true,
                                        'is-invalid': errors.has('content'),
                                        'has-invalid': errors.has('content'),
                                      }">
                                    </ckeditor>
                                    <span class="has-icon-feedback"></span>
                                    <div class="invalid-feedback" :is-invalid="errors.has('content')">
                                      {{ errors.first('content') }}
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-5 px-4">
                            <div class="col-lg-5 col-md-5 col-12">
                              <router-link ref="clear" :to="`/culture/content/list`" exact class="btn btn-outline-danger pr-5 pl-5 w-sm-100"
                                >Cancel</router-link
                              >
                            </div>
                            <div class="col-lg-7 col-md-7 col-12">
                              <button
                                type="button"
                                id="processContent"
                                @click="processContent(contentID)"
                                class="btn btn-primary float-right w-sm-100 mb-3 mb-md-0">
                                <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1"></b-icon>
                                {{ action === 'create' ? 'Create Content' : 'Update Content' }}
                                <span class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="border-top-color: rgb(52, 152, 219); width: 18px; height: 18px; border-width: 4px; animation-duration: 1s"></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import { Validator } from 'vee-validate';
import moment from 'moment';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';

import Link from '@ckeditor/ckeditor5-link/src/link';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import List from '@ckeditor/ckeditor5-list/src/list';
import Font from '@ckeditor/ckeditor5-font/src/font';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui';
import ParagraphButtonUI from '@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';

import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';

import { API_URL } from '@/api/constants/baseUrl';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

Vue.use(Cookies);

export default {
  components: {
    Cropper,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);

    return {
      action: 'create',
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EasyImage,
          EssentialsPlugin,
          Heading,
          BoldPlugin,
          ItalicPlugin,
          BlockQuote,
          Underline,
          LinkPlugin,
          ParagraphPlugin,
          Link,
          List,
          Alignment,
          Indent,
          IndentBlock,
          Font,
          HeadingButtonsUI,
          ParagraphButtonUI,
          Alignment,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          ImageUpload,
          CloudServices,
          Autoformat,
        ],
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'blockQuote',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo',
          '|',
          'link',
          '|',
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'alignment:left',
          'alignment:right',
          'alignment:center',
          'alignment:justify',
          '|',
          'imageUpload',
          'imageStyle:side',
          'imageStyle:full',
        ],
        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              icon: 'original',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              icon: 'medium',
            },
            {
              name: 'resizeImage:75',
              value: '75',
              icon: 'large',
            },
          ],
          toolbar: [
            'imageTextAlternative',
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            'resizeImage:50',
            'resizeImage:75',
            'resizeImage:original',
          ],
          styles: ['full', 'side', 'alignLeft', 'alignCenter', 'alignRight'],
        },
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
          ],
        },
        indentBlock: {
          classes: [
            'custom-block-indent-a', // First step - smallest indentation.
            'custom-block-indent-b',
            'custom-block-indent-c', // Last step - biggest indentation.
          ],
        },
        cloudServices: {
          tokenUrl: API_URL + '/endpoint',
          uploadUrl: 'https://79127.cke-cs.com/easyimage/upload/',
        },
      },
      culture: {
        cover: {
          url: undefined,
          preview: undefined,
          previewCoordinates: null,
          type: 'image',
        },
        city: null,
        category: null,
        content: '',
        description: '',
        url: '',
        title: '',
      },
      openPreview: false,
      CropperImagePreview: undefined,
      CropperImageCatcher: undefined,
      CropperImageName: '',
      contentCategory: null,
      formData: new FormData(),
      imageError: false,
      imageErrorMessage: 'Cover image is required.',
      min: minDate,
      dateTime: undefined,
      ckeditorReady: false,
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      updatedPreview: null,
      coordinatesChanges: false,
      coordinatesSet: false,
      previewFileExtension: '',
    };
  },
  computed: {
    currentTime() {
      return moment().format('HH:mm:ss');
    },
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [{ value: null, text: 'Please select city', disabled: true }, ...data];
    },
    cityList: {
      get: function () {
        return this.$store.state.culture.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    cultureCategories() {
      let data = [];
      this.cultureCategoryList.map((cultureCategory) => {
        data.push({
          value: cultureCategory._id,
          text: cultureCategory.title,
        });
      });

      return [{ value: null, text: 'Please select culture category', disabled: true }, ...data];
    },
    cultureCategoryList: {
      get: function () {
        return this.$store.state.culture.cultureCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.culture.loading;
    },
  },
  methods: {
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    },
    coverOnChange({ coordinates, canvas }) {
      const { cropper } = this.$refs;
      // Check if cropper is loaded or changed
      if (this.action == 'update' && this.coordinatesSet == false) {
        // If loaded, set coordinates from DB
        if (this.culture.cover.previewCoordinates) {
          cropper.setCoordinates(this.culture.cover.previewCoordinates);
        }
        // Set to true if the coordinates is set
        this.coordinatesSet = true;
      } else {
        // If the cropper is changed, store the coordinates and set
        this.coordinatesChanges = true;
        this.culture.cover.previewCoordinates = coordinates;
      }
      console.log('Updated Cover!!!');
      // Get the cropped image
      this.updatedPreview = this.dataURLtoFile(
        canvas.toDataURL(),
        this.CropperImageName ? this.CropperImageName : `${this.convertToSlug(this.culture.title)}-preview.${this.previewFileExtension}`
      );
    },
    makeid(length) {
      var result = [];
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
      }
      return result.join('');
    },
    dataURLtoFile(dataurl, filename) {
      let name = filename ? filename : this.makeid(10);
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], name, { type: mime });
    },
    setTime() {
      let [year, month, date] = this.culture.date.split('-');
      // In JS, months in a year starts at 0
      month = month - 1;
      // Set year, month, and date of given time data
      this.dateTime = moment(this.dateTime).set({ year, month, date }).valueOf();
      return this.dateTime;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.CropperImageName = file.name;
      this.CropperImageCatcher = URL.createObjectURL(file);
      this.culture.cover.url = URL.createObjectURL(file);
      this.imageError = false;
      this.formData.append('cover', file);
    },
    getCultureInformation(contentID) {
      this.$store.dispatch('culture/GET_CONTENT_BY_ID', contentID).then((selectedculture) => {
        let category;

        // Check if category is removed
        if (!selectedculture.data.category) {
          category = null;
        } else {
          category = selectedculture.data.category._id;
        }

        this.dateTime = selectedculture.data.date;
        this.culture.title = selectedculture.data.title;
        this.culture.description = selectedculture.data.description;
        this.culture.url = selectedculture.data.url;
        this.culture.content = selectedculture.data.content;
        this.culture.city = selectedculture.data.city._id;
        this.culture.avatar = selectedculture.data.avatar;
        this.culture.cover = selectedculture.data.cover;

        // Load to Cropper
        this.culture.cover.previewCoordinates = selectedculture.data.cover.previewCoordinates || null;
        this.CropperImageCatcher = selectedculture.data.cover.url;
        this.previewFileExtension = selectedculture.data.cover.url.split(/[#?]/)[0].split('.').pop().trim();

        this.contentCategory = category;
        this.culture.date = moment(this.dateTime).format('YYYY-MM-DD');
        this.ckeditorReady = true;
        this.openPreview = true;
      });
    },
    processContent(contentID) {
      if (this.action === 'create') {
        this.createCulture();
      } else {
        this.updateCulture(contentID);
      }
    },
    createCulture() {
      const promiseBtn = document.getElementById('processContent');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processContentForm');
      bodyWrapper.classList.add('non-clickable');

      if (this.CropperImageCatcher == undefined) {
        this.imageError = true;
      } else {
        this.imageError = false;
      }

      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const content = {
            title: this.culture.title,
            cover: this.culture.cover,
            // date: this.setTime(),
            city: this.culture.city,
            description: this.culture.description,
            url: this.culture.url || null,
            content: this.culture.content,
            category: this.contentCategory,
          };

          // if(!content.url && !content.content) {
          //   this.$notify({
          //     message: this.$options.filters.capitalizeWords('Please put the article link or ckEditor content'),
          //     timeout: 4000,
          //     icon: 'fas fa-check',
          //     type: 'warning'
          //   })

          //   promiseBtnInner.classList.add('hidden')
          //   promiseBtn.removeAttribute('disabled','disabled')
          //   bodyWrapper.classList.remove('non-clickable')

          //   return false
          // }

          // Send Cropper Image to API
          this.formData.append('cover_preview', this.updatedPreview);

          // lowercase the following
          content.url = content.url ? content.url.replace(/^https/i, 'https') : null;
          content.url = content.url ? content.url.replace(/^http/i, 'http') : null;
          content.url = content.url ? content.url.replace(/www/i, 'www') : null;

          this.formData.set('culture', JSON.stringify(content));

          this.$store
            .dispatch('culture/CREATE_CONTENT', this.formData)
            .then((res) => {
              if (res.data.success) {
                (this.event = {
                  title: '',
                  description: '',
                  cover: null,
                  avatar: null,
                  date: undefined,
                }),
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('culture successfully created'),
                    timeout: 4000,
                    icon: 'fas fa-check',
                    type: 'success',
                  });

                this.$router.push('/culture/content/list');
              } else {
                if (res.data.error === 'error_user_already_exist') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Email already in use'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else if (res.data.error === 'Error_creating_business') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Business name already in use'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Error creating user'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                }
              }
              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                if (error.errMessage == 'error_creating_business') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Business name already in use!'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add('hidden');
                  promiseBtn.removeAttribute('disabled', 'disabled');
                  bodyWrapper.classList.remove('non-clickable');
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(error.errMessage),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add('hidden');
                  promiseBtn.removeAttribute('disabled', 'disabled');
                  bodyWrapper.classList.remove('non-clickable');
                }
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords('Please fill up the required forms'),
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'danger',
          });
          btnLoader.classList.add('hidden');
          promiseBtnInner.classList.add('hidden');
          promiseBtn.removeAttribute('disabled', 'disabled');
          bodyWrapper.classList.remove('non-clickable');
        }
      });
    },
    updateCulture(contentID) {
      const promiseBtn = document.getElementById('processContent');
      const promiseBtnInner = promiseBtn.querySelector('.promise-btn__spinner-wrapper');
      const btnLoader = promiseBtn.querySelector('.btn-loader');
      btnLoader.classList.remove('hidden');
      promiseBtnInner.classList.remove('hidden');
      promiseBtn.setAttribute('disabled', 'disabled');

      const bodyWrapper = document.getElementById('processContentForm');
      bodyWrapper.classList.add('non-clickable');
      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const content = {
            title: this.culture.title,
            cover: this.culture.cover,
            // date: this.setTime(),
            city: this.culture.city,
            description: this.culture.description,
            url: this.culture.url || null,
            content: this.culture.content,
            category: this.contentCategory,
          };

          // if(!content.url && !content.content) {
          //   this.$notify({
          //     message: this.$options.filters.capitalizeWords('Please put the article link or ckEditor content'),
          //     timeout: 4000,
          //     icon: 'fas fa-check',
          //     type: 'warning'
          //   })

          //   btnLoader.classList.add('hidden')
          //   promiseBtnInner.classList.add('hidden')
          //   promiseBtn.removeAttribute('disabled','disabled')
          //   bodyWrapper.classList.remove('non-clickable')

          //   return false
          // }

          // If uploaded new Image, Send Cropper Image to API
          if (this.CropperImageCatcher && this.coordinatesChanges == true) {
            this.formData.append('cover_preview', this.updatedPreview);
          }

          // lowercase the following
          content.url = content.url ? content.url.replace(/^https/i, 'https') : null;
          content.url = content.url ? content.url.replace(/^http/i, 'http') : null;
          content.url = content.url ? content.url.replace(/www./i, 'www.') : null;

          this.formData.set('culture', JSON.stringify(content));
          this.$store
            .dispatch('culture/UPDATE_CONTENT', {
              contentID,
              contentData: this.formData,
            })
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords('culture Content successfully updated'),
                  timeout: 4000,
                  icon: 'fas fa-check',
                  type: 'success',
                });
                this.$router.push('/culture/content/list');
              } else {
                if (res.data.error === 'error_user_already_exist') {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Connection already exists'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords('Error updating connection'),
                    timeout: 4000,
                    icon: 'fas fa-exclamation-triangle',
                    type: 'danger',
                  });
                }
              }

              promiseBtnInner.classList.add('hidden');
              promiseBtn.removeAttribute('disabled', 'disabled');
              bodyWrapper.classList.remove('non-clickable');
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(error.errMessage),
                  timeout: 4000,
                  icon: 'fas fa-exclamation-triangle',
                  type: 'danger',
                });
                this.hasError = true;
                promiseBtnInner.classList.add('hidden');
                promiseBtn.removeAttribute('disabled', 'disabled');
                bodyWrapper.classList.remove('non-clickable');
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords('Please fill up the required fields.'),
            timeout: 4000,
            icon: 'fas fa-exclamation-triangle',
            type: 'warning',
          });
          btnLoader.classList.add('hidden');
          bodyWrapper.classList.remove('non-clickable');
          promiseBtn.removeAttribute('disabled', 'disabled');
        }
      });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword = this.password = this.confirmPassword = this.changePasswordError = this.progressLabel = '';
      this.progressWidth = 0;
      this.hasNumber = this.hasUpperCase = this.hasLowerCase = this.hasLowercaseUppercase = this.hasSpecialChar = this.hasPeriodDash = this.hasError = false;
      this.cancelUpdateAccount();
    },
    getCities() {
      this.$store.dispatch('culture/GET_CITY_LIST');
    },
    getCultureCategories() {
      this.$store.dispatch('culture/GET_CULTURE_CATEGORY_LIST');
    },
  },
  watch: {},
  mounted() {
    this.getCities();
    this.getCultureCategories();
    this.action = this.$route.params.id ? 'update' : 'create';
    this.ckeditorReady = this.action == 'create' ? true : false;
    this.contentID = this.$route.params.id;
    if (this.$route.params.id) {
      this.getCultureInformation(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss">
.ck-content {
  background: #000000 !important;
  min-height: 400px;
}
body {
  color: white;
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}
</style>
