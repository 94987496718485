const state = {
  loading: false,
  loadedOnce: false,
  usersLoadedOnce: false,
  success: false,
  error: false,
  userList: [],
};

export default state;
