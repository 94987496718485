import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/
const createContent = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/organizations`, data, 'POST', header);
};

const getContentByID = (token, contentID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/organizations/${contentID}`, null, 'GET', header);
};

const getContentList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/organizations`, null, 'GET', header);
};

const getCultureCategoryList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories/type/culture?show=true`, null, 'GET', header);
};

const getCityListRequest = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/cities?city=All`, null, 'GET', header);
};

const getSubCategoryList = (token, parentCategoryID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories?parent=${parentCategoryID}&show=true`, null, 'GET', header);
};

const updateContent = (token, contentID, contentData) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/organizations/${contentID}`, contentData, 'PUT', header);
};

const setFeatured = (token, contentID, contentData) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/organizations/${contentID}/set-featured`, contentData, 'PUT', header);
};

const deleteContent = (token, contentID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/organizations/${contentID}`, '', 'DELETE', header);
};

export {
  createContent,
  getContentList,
  getCultureCategoryList,
  getCityListRequest,
  getSubCategoryList,
  getContentByID,
  updateContent,
  setFeatured,
  deleteContent,
};
