import Vue from 'vue';
import { sendMessage, getNotifications, getSectionList, getCityListRequest, getSubCategoryList } from '@/api/request/message';
import Cookies from 'js-cookie';
import errors from '@/utils/errors';
import { globalStore } from '@/utils/global';
import moment from 'moment';

Vue.use(Cookies);

const actions = {
  GET_NOTIFICATIONS: ({ commit }, payload) => {
    const token = Cookies.get('user-token') || globalStore.token;
    commit('GET_NOTIFICATIONS_REQUEST');
    return new Promise((resolve, reject) => {
      return getNotifications(token, payload)
        .then((resp) => {
          if (resp) {
            if (resp.data.data) {
              let notificationSource = resp.data.data;
              let newNotifications = {};
              let notificationsList = notificationSource.map((value) => {
                return {
                  title: value.title,
                  message: value.message,
                  city: value.city.city,
                  section: value.section || '---',
                  category: value.category || '---',
                  sub_category: value.sub_category || '---',
                  createdAt: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  actions: value._id,
                };
              });
              notificationsList = notificationsList.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });

              // newNotifications['all'] = notificationsList.filter(admin => admin.isDeleted === 0)
              newNotifications['all'] = notificationsList.filter((notification) => notification);
              commit('SET_NOTIFICATIONS_SUCCESS', newNotifications);
              resolve(newNotifications);
            } else {
              commit('GET_NOTIFICATIONS_FAILED');
            }
          }
        })
        .catch((err) => {
          commit('GET_NOTIFICATIONS_FAILED');
          reject(errors(err));
        });
    });
  },
  SEND_MESSAGE: ({ commit, dispatch }, messageData) => {
    const token = Cookies.get('user-token') || globalStore.token || token;
    commit('SEND_MESSAGE_REQUEST');
    return new Promise((resolve, reject) => {
      return sendMessage(token, messageData)
        .then((resp) => {
          if (resp) {
            if (resp.data) {
              commit('SEND_MESSAGE_SUCCESS', resp.data);
              resolve(resp);
            }
          }
        })
        .catch((err) => {
          commit('SEND_MESSAGE_FAILED');
          reject(errors(err));
        });
    });
  },
  GET_SECTION_LIST({ commit }, payload) {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('loading');
      return getSectionList(token, payload)
        .then(async (result) => {
          if (result.data.data) {
            commit('setSectionList', result.data.data);
            resolve(result.data.data);
          }
        })
        .catch((err) => {
          commit('error', err);
          reject(errors(err));
        });
    });
  },
  GET_SUB_CATEGORY_LIST({ commit }, parentCategoryID) {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('loading');
      return getSubCategoryList(token, parentCategoryID)
        .then(async (result) => {
          if (result) {
            commit('setSubCategoryList', result.data);
            resolve(result);
          }
        })
        .catch((err) => {
          commit('error', err);
          reject(errors(err));
        });
    });
  },
  GET_CITY_LIST({ commit }, payload) {
    const token = Cookies.get('user-token') || globalStore.token;
    return new Promise((resolve, reject) => {
      commit('loading');
      return getCityListRequest(token, payload)
        .then(async (result) => {
          if (result) {
            commit('setCityList', result.data);
            resolve(result);
          }
        })
        .catch((err) => {
          commit('error', err);
          reject(errors(err));
        });
    });
  },
};

export default actions;
