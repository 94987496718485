import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import Full from '@/container/Full';
import Public from '@/container/Public';
// GeneralViews
const PageNotFound = () => import(/* webpackChunkName: "Pages" */ '@/views/pages/NotFoundPage');

// Pages
const Login = () => import(/* webpackChunkName: "Pages" */ '@/views/pages/Login');
const ForgotPassword = () => import(/* webpackChunkName: "Pages" */ '@/views/pages/ForgotPassword');

// Admins
const AdminsFull = () => import(/* webpackChunkName: "Admin" */ '@/views/admin/AdminsFull');
const AdminList = () => import(/* webpackChunkName: "Admin" */ '@/views/admin/AdminList');
const AdminForm = () => import(/* webpackChunkName: "Admin" */ '@/views/admin/AdminForm');

// Advertisement List
const AdvertisementFull = () => import(/* webpackChunkName: "Admin" */ '@/views/advertisement/AdvertisementFull');
const AdvertisementList = () => import(/* webpackChunkName: "Admin" */ '@/views/advertisement/AdvertisementList');
const AdvertisementForm = () => import(/* webpackChunkName: "Admin" */ '@/views/advertisement/AdvertisementForm');

// Analytics
const AnalyticsFull = () => import(/* webpackChunkName: "Pages" */ '@/views/analytics/AnalyticsFull');
const AnalyticsList = () => import(/* webpackChunkName: "Pages" */ '@/views/analytics/AnalyticsList');

// Business List
const BusinessFull = () => import(/* webpackChunkName: "Admin" */ '@/views/business-list/BusinessFull');
const BusinessList = () => import(/* webpackChunkName: "Admin" */ '@/views/business-list/BusinessList');
const BusinessForm = () => import(/* webpackChunkName: "Admin" */ '@/views/business-list/BusinessForm');

// Business Ads
const BusinessAdsFull = () => import(/* webpackChunkName: "Admin" */ '@/views/community/BusinessAdsFull');
const BusinessAdsList = () => import(/* webpackChunkName: "Admin" */ '@/views/community/BusinessAdsList');
const BusinessAdsForm = () => import(/* webpackChunkName: "Admin" */ '@/views/community/BusinessAdsForm');

// Business Clients
const BusinessClientsFull = () => import(/* webpackChunkName: "Admin" */ '@/views/clients/BusinessClientsFull');
const BusinessClientList = () => import(/* webpackChunkName: "Admin" */ '@/views/clients/BusinessClientList');
const BusinessClientForm = () => import(/* webpackChunkName: "Admin" */ '@/views/clients/BusinessClientForm');

// Business Clients Profile
const ClientProfileFull = () => import(/* webpackChunkName: "Admin" */ '@/views/clients-profile/ClientProfileFull');
const ClientProfileForm = () => import(/* webpackChunkName: "Admin" */ '@/views/clients-profile/ClientProfileForm');

// Business Clients Subscription
const ClientSubscriptionFull = () => import(/* webpackChunkName: "Admin" */ '@/views/clients-subscription/ClientSubscriptionFull');
const ClientSubscriptionForm = () => import(/* webpackChunkName: "Admin" */ '@/views/clients-subscription/ClientSubscriptionForm');

// Clients Business
const ClientBusinessFull = () => import(/* webpackChunkName: "Admin" */ '@/views/clients-business/ClientBusinessFull');
const ClientBusinessForm = () => import(/* webpackChunkName: "Admin" */ '@/views/clients-business/ClientBusinessForm');

// Connection
const CultureContentFull = () => import(/* webpackChunkName: "Admin" */ '@/views/culture/CultureContentFull');
const CultureContentList = () => import(/* webpackChunkName: "Admin" */ '@/views/culture/CultureContentList');
const CultureContentForm = () => import(/* webpackChunkName: "Admin" */ '@/views/culture/CultureContentForm');

// Connection
const ConnectionFull = () => import(/* webpackChunkName: "Admin" */ '@/views/connection/ConnectionFull');
const ConnectionList = () => import(/* webpackChunkName: "Admin" */ '@/views/connection/ConnectionList');
const ConnectionForm = () => import(/* webpackChunkName: "Admin" */ '@/views/connection/ConnectionForm');

// Send Notification
const SendNotificationFull = () => import(/* webpackChunkName: "Admin" */ '@/views/send-notification/SendNotificationFull');
const NotificationsList = () => import(/* webpackChunkName: "Admin" */ '@/views/send-notification/NotificationsList');
const SendNotificationForm = () => import(/* webpackChunkName: "Admin" */ '@/views/send-notification/SendNotificationForm');

// Clients
// const ClientFull = () => import(/* webpackChunkName: "Client" */ '@/views/clients/ClientFull')
// const ClientList = () => import(/* webpackChunkName: "Client" */ '@/views/clients/ClientList')

// Dashboard
const Dashboard = () => import(/* webpackChunkName: "Pages" */ '@/views/dashboard/Dashboard');

// Roles and permissions // Admin Roles
const RolesFull = () => import(/* webpackChunkName: "Roles" */ '@/views/admin-roles/RolesFull');
const RolesList = () => import(/* webpackChunkName: "Roles" */ '@/views/admin-roles/RolesList');
const RolesForm = () => import(/* webpackChunkName: "Roles" */ '@/views/admin-roles/RolesForm');
const RolesFormEdit = () => import(/* webpackChunkName: "Roles" */ '@/views/admin-roles/RolesFormEdit');

// Register Page
const RegisterPageForm = () => import(/* webpackChunkName: "Pages" */ '@/views/register-page/RegisterPageForm');

// Register Advertisement
const RegisterAdvertisementForm = () => import(/* webpackChunkName: "Pages" */ '@/views/register-advertisement/RegisterAdvertisementForm');
const AdvertisementCheckoutSuccess = () => import(/* webpackChunkName: "Pages" */ '@/views/register-advertisement/AdvertisementCheckoutSuccess');
const AdvertisementCheckoutFailure = () => import(/* webpackChunkName: "Pages" */ '@/views/register-advertisement/AdvertisementCheckoutFailure');
// Reports
const ReportFull = () => import(/* webpackChunkName: "Pages" */ '@/views/reports/ReportFull');
const ReportList = () => import(/* webpackChunkName: "Pages" */ '@/views/reports/ReportList');

// Users List (Mobile )
const UserList = () => import(/* webpackChunkName: "User" */ '@/views/users/UserList');

// Settings
const SettingFull = () => import(/* webpackChunkName: "Pages" */ '@/views/settings/SettingFull');
const Settings = () => import(/* webpackChunkName: "Pages" */ '@/views/settings/Settings');

// User Profile
const Profile = () => import(/* webpackChunkName: "User" */ '@/views/user/Profile');

// Contents Management
const Categories = () => import(/* webpackChunkName: "Contents" */ '@/views/categories/Categories');
const Videos = () => import(/* webpackChunkName: "Contents" */ '@/views/videos/Videos');
const Home = () => import(/* webpackChunkName: "Contents" */ '@/views/home/Home');

// Cities
const CityFull = () => import(/* webpackChunkName: "Admin" */ '@/views/cities/CityFull');
const CityList = () => import(/* webpackChunkName: "Admin" */ '@/views/cities/CityList');
const CityForm = () => import(/* webpackChunkName: "Admin" */ '@/views/cities/CityForm');

// Black Card
const BlackCard = () => import(/* webpackChunkName: "Contents" */ '@/views/black-card/BlackCard');

// App Config
const AppConfigs = () => import(/* webpackChunkName: "Contents" */ '@/views/contact-info/AppConfigs');

// Subscriptions
const SubscriptionsFull = () => import(/* webpackChunkName: "Admin" */ '@/views/subscriptions/SubscriptionsFull');
const SubscriptionsList = () => import(/* webpackChunkName: "Admin" */ '@/views/subscriptions/SubscriptionsList');
const SubscriptionsForm = () => import(/* webpackChunkName: "Admin" */ '@/views/subscriptions/SubscriptionsForm');

// App Config
const ImportBusiness = () => import(/* webpackChunkName: "Contents" */ '@/views/import-business/ImportBusiness');

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  // console.log(store.state.user.companyID)
  next('/login');
};

const notVerified = (to, from, next) => {
  const userEmail = store.getters['auth/userEmail'];
  const registerUserEmail = store.getters['register/registerUserEmail'];
  if ((userEmail !== undefined && userEmail !== '') || registerUserEmail || (to.name === 'VerifyToken' && to.params.token)) {
    next();
    return;
  }
  // console.log(store.state.user.companyID)
  next('/login');
};

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          beforeEnter: ifAuthenticated,
        },
        // Roles and Permissions
        {
          path: 'permissions',
          name: 'Roles',
          redirect: {
            path: '/roles',
          },
          component: RolesFull,
          meta: {
            access: ['roles'],
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'List',
              path: '/roles',
              component: RolesList,
              meta: {
                access: ['roles'],
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:type/:id',
              name: 'Edit',
              component: RolesFormEdit,
              meta: {
                access: ['roles'],
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'add/:type',
              name: 'Add',
              component: RolesForm,
              meta: {
                access: ['roles'],
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Admin Users
        {
          path: 'user/admins',
          name: 'Admin Users',
          redirect: {
            path: '/user/admins',
          },
          component: AdminsFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Admin List',
              path: '/user/admins',
              component: AdminList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Admin',
              component: AdminForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Admin',
              component: AdminForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Advertisement List
        {
          path: 'advertisement/list',
          name: 'Advertisement Listing',
          redirect: {
            path: '/advertisement/list',
          },
          component: AdvertisementFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Advertisement List',
              path: '/advertisement/list',
              component: AdvertisementList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Advertisement',
              component: AdvertisementForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Advertisement',
              component: AdvertisementForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Business List
        {
          path: 'business/list',
          name: 'Business Listing',
          redirect: {
            path: '/business/list',
          },
          component: BusinessFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Business List',
              path: '/business/list',
              component: BusinessList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Business',
              component: BusinessForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Business Ads
        {
          path: 'business/ads/list',
          name: 'Community Ads',
          redirect: {
            path: '/business/ads/list',
          },
          component: BusinessAdsFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Ads List',
              path: '/business/ads/list',
              component: BusinessAdsList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'View Business Ads',
              component: BusinessAdsForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Business Clients
        {
          path: 'user/business-clients',
          name: 'Business Client Users',
          redirect: {
            path: '/user/business-clients',
          },
          component: BusinessClientsFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Business Client List',
              path: '/user/business-clients',
              component: BusinessClientList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Business Client',
              component: BusinessClientForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Business Client',
              component: BusinessClientForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Business Clients Profile
        {
          path: 'client/profile',
          name: 'Client Profile',
          redirect: {
            path: '/client/profile',
          },
          component: ClientProfileFull,
          meta: {
            userGroup: 2,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Client Profile Form',
              path: '/client/profile',
              component: ClientProfileForm,
              meta: {
                userGroup: 2,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Business Clients Subscription
        {
          path: 'client/subscription',
          name: 'Client Subscription',
          redirect: {
            path: '/client/subscription',
          },
          component: ClientSubscriptionFull,
          meta: {
            userGroup: 2,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Client Subscription Info',
              path: '/client/subscription',
              component: ClientSubscriptionForm,
              meta: {
                userGroup: 2,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Clients Business Information
        {
          path: 'client/business',
          name: 'Client Business',
          redirect: {
            path: '/client/business',
          },
          component: ClientBusinessFull,
          meta: {
            userGroup: 2,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Client Business Form',
              path: '/client/business',
              component: ClientBusinessForm,
              meta: {
                userGroup: 2,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Clients Black Card URL
        {
          path: 'client/black-card',
          name: 'Black Card Url',
          beforeEnter() {
            store
              .dispatch('appconfigs/GET_SETTINGS')
              .then((settings) => {
                if (settings.blackCardUrl.includes('https://') || settings.blackCardUrl.includes('http://')) {
                  window.open(settings.blackCardUrl, '_blank');
                } else {
                  window.open(`https://${settings.blackCardUrl}`, '_blank');
                }
              })
              .catch((e) => console.log(e));
          },
        },
        // Community Content
        {
          path: 'culture/content/list',
          name: 'Culture Content Listing',
          redirect: {
            path: '/culture/content/list',
          },
          component: CultureContentFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Culture Content List',
              path: '/culture/content/list',
              component: CultureContentList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Culture Content',
              component: CultureContentForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Culture Content',
              component: CultureContentForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Connections
        {
          path: 'connections/list',
          name: 'Connection Listing',
          redirect: {
            path: '/connections/list',
          },
          component: ConnectionFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Connections List',
              path: '/connections/list',
              component: ConnectionList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Connection',
              component: ConnectionForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Connection',
              component: ConnectionForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Send Notification
        {
          path: 'notifications',
          name: 'Notifications',
          redirect: {
            path: '/notifications',
          },
          component: SendNotificationFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Notifications List',
              path: '/notifications',
              component: NotificationsList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            // {
            //   path: 'edit/:id',
            //   name: 'Edit Admin',
            //   component: AdminForm,
            //   meta: {
            //     userGroup: 1
            //   },
            //   beforeEnter: ifAuthenticated
            // },
            {
              path: 'create',
              name: 'Send Notification',
              component: SendNotificationForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },

        // Clients
        // {
        //   path: 'clients',
        //   name: 'Glamper Vendors',
        //   redirect: {
        //     path: '/clients/list'
        //   },
        //   component: ClientFull,
        //   meta: {
        //     access: ['clients']
        //   },
        //   beforeEnter: ifAuthenticated,
        //   children: [
        //     {
        //       name: 'Glamper Vendor List',
        //       path: '/clients/list',
        //       component: ClientList,
        //       meta: {
        //         access: ['clients']
        //       },
        //       beforeEnter: ifAuthenticated
        //     }
        //   ]
        // },
        // Mobile Users
        {
          path: 'users',
          redirect: '/users',
          name: 'Registered Users',
          beforeEnter: ifAuthenticated,
          meta: {
            access: ['userGroup'],
          },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '/users',
              name: 'User List',
              component: UserList,
              meta: {
                access: ['profile', 'userList'],
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Settings
        {
          path: 'settings',
          name: 'Settings',
          redirect: {
            path: '/settings',
          },
          component: SettingFull,
          meta: {
            access: ['settings'],
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Setting List',
              path: '/settings',
              component: Settings,
              meta: {
                access: ['settings'],
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Reports
        {
          path: 'reports',
          name: 'Reports',
          redirect: {
            path: '/reports',
          },
          component: ReportFull,
          meta: {
            access: ['reports'],
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Report',
              path: '/reports',
              component: ReportList,
              meta: {
                access: ['reports'],
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Analytics
        {
          path: 'analytics',
          name: 'Analytics',
          redirect: {
            path: '/analytics',
          },
          component: AnalyticsFull,
          meta: {
            access: ['analytics'],
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Analytics List',
              path: '/analytics',
              component: AnalyticsList,
              meta: {
                access: ['analytics'],
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // User Profile
        {
          path: 'user',
          redirect: '/user/profile',
          name: 'User',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'profile',
              name: 'Profile',
              meta: {
                userGroup: 1,
              },
              component: Profile,
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // City Management
        {
          path: '/contents/cities',
          name: 'City',
          redirect: {
            path: '/contents/cities',
          },
          component: CityFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'City List',
              path: '/contents/cities',
              component: CityList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit City',
              component: CityForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create City',
              component: CityForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Categories Management
        {
          path: '/contents/categories',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Categories',
              meta: {
                userGroup: 1,
              },
              component: Categories,
            },
            {
              path: ':selectedParent',
              name: 'Sub-categories',
              meta: {
                userGroup: 1,
              },
              component: Categories,
            },
          ],
        },
        // Videos Management
        {
          path: '/contents/videos',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Videos',
              meta: {
                userGroup: 1,
              },
              component: Videos,
            },
          ],
        },
        // Home Featured
        {
          path: '/contents/home',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Mobile Home Management',
              meta: {
                userGroup: 1,
              },
              component: Home,
            },
          ],
        },
        // Black Card
        {
          path: '/black-card',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Black Card',
              meta: {
                userGroup: 1,
              },
              component: BlackCard,
            },
          ],
        },
        // App Config
        {
          path: '/settings/app-configs',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'App Configuration',
              meta: {
                userGroup: 1,
              },
              component: AppConfigs,
            },
          ],
        },
        // Subscriptions Management
        {
          path: '/settings/subscriptions',
          name: 'Subscriptions',
          redirect: {
            path: '/settings/subscriptions',
          },
          component: SubscriptionsFull,
          meta: {
            userGroup: 1,
          },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Subscriptions List',
              path: '/settings/subscriptions',
              component: SubscriptionsList,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Subscription',
              component: SubscriptionsForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Subscription',
              component: SubscriptionsForm,
              meta: {
                userGroup: 1,
              },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Register Users
        {
          path: '/settings/import-business',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Import Business',
              meta: {
                userGroup: 1,
              },
              component: ImportBusiness,
            },
          ],
        },
      ],
    },
    // Register Page
    {
      path: '/register-page',
      name: 'Register Page',
      component: RegisterPageForm,
    },
    // Register Advertisement
    {
      path: '/advertisement/register',
      name: 'Register Advertisement',
      component: RegisterAdvertisementForm,
    },
    {
      path: '/advertisement/checkout-success/:id',
      name: 'Checkout Success',
      component: AdvertisementCheckoutSuccess,
    },
    {
      path: '/advertisement/checkout-failure',
      name: 'Checkout Failure',
      component: AdvertisementCheckoutFailure,
    },
    // Login Page
    {
      path: '/login',
      component: Public,
      beforeEnter: ifNotAuthenticated,
      children: [
        {
          path: '/',
          name: 'Login',
          component: Login,
        },
      ],
    },
    // Reset Password
    {
      path: '/reset-password/:type',
      component: Public,
      beforeEnter: ifNotAuthenticated,
      children: [
        {
          path: '/',
          name: 'Reset Password',
          component: ForgotPassword,
        },
        {
          path: '/reset-password/:type/:token',
          name: 'Forgot Password',
          component: ForgotPassword,
        },
      ],
    },
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/notFound');
  } else {
    if (to.meta.userGroup == null || to.meta.userGroup == undefined) {
      // Public Pages
      if (to.fullPath === '/dashboard') {
        if (store.state.auth.userGroup == 2) {
          next('/client/business');
          return;
        }
        next();
      }
      next();
    } else {
      // Admin/Client Pages
      if (Number(store.state.auth.userGroup) == Number(to.meta.userGroup)) {
        next();
      } else {
        next('/notFound');
      }
    }
  }
});

export default router;
