import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);
/*
  $http (url, data, method, header)
*/

const sendMessage = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/messages`, data, 'POST', header);
};

const getSectionList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/pages`, null, 'GET', header);
};

const getCityListRequest = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/cities?city=All`, null, 'GET', header);
};

const getSubCategoryList = (token, parentCategoryID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories?parent=${parentCategoryID}&show=true`, null, 'GET', header);
};

const getNotifications = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/messages`, null, 'GET', header);
};

// const getGroupsByCPId = (token, contentProviderID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/user/groups/${contentProviderID}/0`, null, 'GET', header)
// }

// const getMobileUsers = (token, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/user/mobile-users/active/${companyID}`, null, 'GET', header)
// }

// const getMessages = (token, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/${companyID}`, null, 'GET', header)
// }

// const sendMessage = (token, data, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/api/messages`, data, 'POST', header)
// }

// const deleteMessage = (token, messageID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/${messageID}`, null, 'DELETE', header)
// }

// const sendCampaign = (token, data, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/campaign/${companyID}`, data, 'POST', header)
// }

// const sendMessageAttachment = (token, data) => {
//   const header = (token) ? {'Content-Type': 'multipart/form-data', 'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/file`, data, 'POST', header)
// }

// const sendMessageSchedule = (token, data, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/schedule`, data, 'POST', header)
// }

// const sendMessageScheduleAttachment = (token, data) => {
//   const header = (token) ? {'Content-Type': 'multipart/form-data', 'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/schedule/file`, data, 'POST', header)
// }

// const getCampaignMessages = (token, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/campaigns/${companyID}`, null, 'GET', header)
// }

// const updateCampaign = (token, campaignData, campaignID, companyID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/campaign/${campaignID}/${companyID}`, campaignData, 'PUT', header)
// }

// const getCampaignById = (token, campaignID) => {
//   const header = (token) ? {'authorization': token} : null
//   return $http(`/messages/campaign/${campaignID}`, null, 'GET', header)
// }

// const deleteCampaign = (token, campaignID) => {
//   const header = (token) ? {'authorization': token} : null
//   return $http(`/messages/campaign/${campaignID}`, null, 'DELETE', header)
// }

// const getMobileMessagesReceivers = (token, messageID) => {
//   const header = (token) ? {'authorization': token, 'x-company-header': Cookies.get('companyID')} : null
//   return $http(`/messages/${Cookies.get('companyID')}/message/${messageID}`, null, 'GET', header)
// }

export {
  sendMessage,
  getSectionList,
  getCityListRequest,
  getSubCategoryList,
  getNotifications,
  // getGroupsByCPId,
  // getMobileUsers,
  // getMessages,
  // sendMessageAttachment,
  // sendMessageSchedule,
  // sendMessageScheduleAttachment,
  // deleteMessage,
  // sendCampaign,
  // getCampaignMessages,
  // getCampaignById,
  // updateCampaign,
  // deleteCampaign,
  // getMobileMessagesReceivers
};
