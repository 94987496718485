const deleteNotify = {
  methods: {
    showConfirmation(
      actionEvent,
      itemID,
      itemString,
      otherInfo = '',
      isCustom = false,
      htmlCustom = '',
      ishtmlCustom = false,
      isDeleteProcess = true,
      button = false
    ) {
      var html = '';
      if (isCustom) {
        html = `<div class="swal2-icon swal2-question" style="display:flex"></div>Are you sure, you want to delete ${itemString} ${otherInfo} <hr class="mt-4 mb-0">`;
      } else {
        html = `<div class="swal2-icon swal2-question" style="display:flex"></div>Are you sure, you want to delete ${itemString}? ${otherInfo} <hr class="mt-4 mb-0">`;
      }

      if (isDeleteProcess) {
        if (ishtmlCustom) {
          if (isCustom) {
            html = `${htmlCustom}Are you sure, you want to delete ${itemString} ${otherInfo} <hr class="mt-4 mb-0">`;
          } else {
            html = `${htmlCustom}Are you sure, you want to delete ${itemString}? ${otherInfo} <hr class="mt-4 mb-0">`;
          }
        }
        var btnValue = 'Yes, Delete it';
        var btnClass = 'btn-danger';
      } else {
        html = htmlCustom;
        btnValue = button.value;
        btnClass = button.class;
      }

      this.$swal({
        position: 'center',
        html: html,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: btnValue,
        cancelButtonText: 'Cancel',
        cancelButtonClass: 'btn btn-secondary pr-5 pl-5',
        confirmButtonClass: `btn ${btnClass} pr-5 pl-5`,
        reverseButtons: true,
        buttonsStyling: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          return actionEvent(itemID);
        }
      });
    },
  },
};

export default deleteNotify;
