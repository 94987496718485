var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-4 col-7"},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)])])]),_c('div',{staticClass:"container mt--6 minHeight"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 order-xl-1"},[_c('card',{staticClass:"createConnectionContainer",attrs:{"no-body":"","body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg-10 col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v("App Configuration")])])]),_c('b-row',{attrs:{"id":"processContentForm"}},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12"},[_c('b-card',{attrs:{"no-body":""}},[(_vm.loading)?_c('b-row',[_c('div',{staticClass:"col-lg-12 text-center"},[_c('pulse-loader',{attrs:{"color":"var(--pulse-loader)"}})],1)]):_c('b-card-body',{staticClass:"pt-0"},[_c('b-row',[_c('form',{staticClass:"w-100"},[_c('div',{staticClass:"p-lg-4 px-lg-4 px-md-4 px-sm-4"},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row form-container"},[_c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"form-name"},[_vm._v("Contact")]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.email),expression:"settings.email",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('email'),
                                      'has-invalid': _vm.errors.has('email'),
                                    },attrs:{"type":"text","name":"email","data-vv-name":"email","data-vv-as":"Email","placeholder":"Enter Email","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('email')}},[_vm._v(" "+_vm._s(_vm.errors.first('email'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"address"}},[_vm._v("Address")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.address),expression:"settings.address",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('address'),
                                      'has-invalid': _vm.errors.has('address'),
                                    },attrs:{"type":"text","name":"address","data-vv-as":"Address","data-vv-name":"address","placeholder":"Enter address","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "address", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('address')}},[_vm._v(" "+_vm._s(_vm.errors.first('address'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"phone"}},[_vm._v("Phone Number ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.phone),expression:"settings.phone",modifiers:{"trim":true}},{name:"mask",rawName:"v-mask",value:('### ###-####'),expression:"'### ###-####'"},{name:"validate",rawName:"v-validate",value:({
                                      required: true,
                                      min: 10,
                                      regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                    }),expression:"{\n                                      required: true,\n                                      min: 10,\n                                      regex: /^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$/,\n                                    }"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('phone'),
                                      'has-invalid': _vm.errors.has('phone'),
                                    },attrs:{"type":"text","name":"phone","data-vv-name":"phone","data-vv-as":"Phone Number","placeholder":"Enter Phone Number (123 123-1234)","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('phone')}},[_vm._v(" "+_vm._s(_vm.errors.first('phone'))+" ")])])],1),_c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"form-name"},[_vm._v("Other Info")]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"askUsEmail"}},[_vm._v("Ask Us Email")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.askUsEmail),expression:"settings.askUsEmail",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('askUsEmail'),
                                      'has-invalid': _vm.errors.has('askUsEmail'),
                                    },attrs:{"type":"text","name":"askUsEmail","data-vv-name":"askUsEmail","data-vv-as":"Ask Us Email","placeholder":"Please enter an email for Ask Us form","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.askUsEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "askUsEmail", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('askUsEmail')}},[_vm._v(" "+_vm._s(_vm.errors.first('askUsEmail'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url"}},[_vm._v("About Us URL")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.aboutUsUrl),expression:"settings.aboutUsUrl",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('url'),
                                      'has-invalid': _vm.errors.has('url'),
                                    },attrs:{"type":"text","name":"url","data-vv-as":"About Us Url","data-vv-name":"url","placeholder":"Enter a url","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.aboutUsUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "aboutUsUrl", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('url')}},[_vm._v(" "+_vm._s(_vm.errors.first('url'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url2"}},[_vm._v("Advertise With Us URL")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.advertiseWithUsUrl),expression:"settings.advertiseWithUsUrl",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('url2'),
                                      'has-invalid': _vm.errors.has('url2'),
                                    },attrs:{"type":"text","name":"url2","data-vv-as":"Advertise With Us","data-vv-name":"url2","placeholder":"Enter a url","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.advertiseWithUsUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "advertiseWithUsUrl", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('url2')}},[_vm._v(" "+_vm._s(_vm.errors.first('url2'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url3"}},[_vm._v("Black Card URL")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.blackCardUrl),expression:"settings.blackCardUrl",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('url3'),
                                      'has-invalid': _vm.errors.has('url3'),
                                    },attrs:{"type":"text","name":"url3","data-vv-as":"Black Card","data-vv-name":"url3","placeholder":"Enter a url","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.blackCardUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "blackCardUrl", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('url3')}},[_vm._v(" "+_vm._s(_vm.errors.first('url3'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url4"}},[_vm._v("Privacy Policy URL")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.privacyPolicyUrl),expression:"settings.privacyPolicyUrl",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('url4'),
                                      'has-invalid': _vm.errors.has('url4'),
                                    },attrs:{"type":"text","name":"url4","data-vv-as":"Privacy Policy","data-vv-name":"url4","placeholder":"Enter a url","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.privacyPolicyUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "privacyPolicyUrl", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('url4')}},[_vm._v(" "+_vm._s(_vm.errors.first('url4'))+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"url5"}},[_vm._v("Business Registration URL")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.settings.registerBusinessUrl),expression:"settings.registerBusinessUrl",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                                      input: true,
                                      'is-invalid': _vm.errors.has('url5'),
                                      'has-invalid': _vm.errors.has('url5'),
                                    },attrs:{"type":"text","name":"url5","data-vv-as":"Register Business","data-vv-name":"url5","placeholder":"Enter a url","autocomplete":"NoAutocomplete"},domProps:{"value":(_vm.settings.registerBusinessUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.settings, "registerBusinessUrl", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('url5')}},[_vm._v(" "+_vm._s(_vm.errors.first('url5'))+" ")])])],1)])])]),_c('div',{staticClass:"row mt-5 px-4"},[_c('div',{staticClass:"col-lg-5 col-md-5 col-12"}),_c('div',{staticClass:"col-lg-7 col-md-7 col-12"},[_c('button',{staticClass:"btn btn-primary float-right w-sm-100 mb-3 mb-md-0",attrs:{"type":"button","id":"processContent"},on:{"click":function($event){return _vm.updateContent()}}},[_vm._v(" Update "),_c('span',{staticClass:"promise-btn__spinner-wrapper hidden"},[_c('span',{staticClass:"spinner",staticStyle:{"border-top-color":"rgb(52, 152, 219)","width":"18px","height":"18px","border-width":"4px","animation-duration":"1s"},attrs:{"data-v-cc3e8e04":""}})])])])])])])])],1)],1)],1)])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }