var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-default"},[_c('div',{staticClass:"header bg-default py-lg-8 pt-lg-4 pt-6"}),_c('div',{staticClass:"forgot-password container mt--9 resetPassword"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9 col-md-9"},[_c('card',{staticClass:"bg-secondary",attrs:{"no-body":""}},[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg-10 col-8"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.title))])])]),_c('div',{staticClass:"card-body border-0 mb-0 p-3"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.verify && !_vm.changePass && _vm.errorChangePass),expression:"!verify && !changePass && errorChangePass"}],staticClass:"mb-5",domProps:{"innerHTML":_vm._s(_vm.message)}}),(_vm.successReset)?_c('div',[_c('h3',{staticClass:"ctitle text-center text-primary"},[_vm._v("Password Reset Successful!")]),_c('p',{staticClass:"w-100 text-center"},[_vm._v(" You successfully change your password "),_c('br'),_c('br'),_c('strong',[_vm._v("Redirecting to login page in "),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.countDown))])])])]):_vm._e(),(_vm.verify)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.message)}}),_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.success),expression:"!success"}],staticClass:"forgotPassword"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"form-feedback",class:{ 'is-invalid': _vm.hasError }},[_vm._v(" "+_vm._s(_vm.error)+" ")]),_c('div',{staticClass:"mb-5",attrs:{"role":"group"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-control no-instruction",class:{
                      input: true,
                      'is-invalid': _vm.errors.has('email'),
                      'has-invalid': _vm.errors.has('email'),
                    },attrs:{"type":"text","name":"email","placeholder":"Enter your email","autocomplete":"autocomplete off"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.email=$event.target.value},_vm.emailCheck]}}),(_vm.errors.has('email'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('email'))+" ")]):_vm._e()]),_c('button',{directives:[{name:"promise-btn",rawName:"v-promise-btn"}],staticClass:"btn btn-primary pr-5 pl-5 btn-block",attrs:{"type":"button","name":"button"},on:{"click":_vm.forgotPassword}},[_vm._v("Send Request")])])]):_vm._e(),(_vm.changePass)?_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.success),expression:"!success"}],staticClass:"forgotPassword"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorChangePass),expression:"errorChangePass"}],staticClass:"form-feedback",class:{ 'is-invalid': _vm.errorChangePass }},[_vm._v(" "+_vm._s(_vm._f("capitalizeWords")(_vm.errorChangePassword))+" ")]),_c('b-row',[_c('div',{staticClass:"col-lg-6"},[_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"password"}},[_vm._v("New Password")]),(_vm.changePass)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-instruction",class:{
                          'is-invalid': _vm.errors.has('account.password') || _vm.passwordCriteriaError,
                          'has-invalid': _vm.errors.has('account.password') || (_vm.password.length > 0 && _vm.progressWidth <= 40) || _vm.passwordCriteriaError,
                          'has-valid': _vm.progressWidth >= 75 && _vm.hasLowercaseUppercase && _vm.hasNumber && _vm.hasSpecialChar,
                          'has-warning': _vm.password.length > 0 && _vm.progressWidth > 40 && _vm.progressWidth < 75,
                        },attrs:{"type":"password","name":"password","data-vv-name":"password","data-vv-as":"password","data-vv-scope":"account","placeholder":"Enter password","autocomplete":"new-password"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.password=$event.target.value.trim()},_vm.passwordCheck],"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e(),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('account.password') || _vm.passwordCriteriaError}},[_vm._v(" "+_vm._s(_vm.errors.first('account.password') || _vm.passwordCriteriaError)+" ")])]),_c('b-form-group',{staticClass:"mb-3 has-feedback",attrs:{"role":"group"}},[_c('label',{staticClass:"w-100 form-control-label",attrs:{"for":"confirmPassword"}},[_vm._v("Confirm Password")]),(_vm.changePass)?_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirmPassword),expression:"confirmPassword",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:(("required|confirmPassword:" + _vm.password)),expression:"`required|confirmPassword:${password}`"}],staticClass:"form-control no-instruction input",class:{
                          'is-invalid': _vm.errors.has('account.confirmPassword'),
                          'has-invalid': _vm.errors.has('account.confirmPassword'),
                          'has-valid': !_vm.errors.has('account.confirmPassword') && _vm.confirmPassword.length >= 8,
                        },attrs:{"type":"password","name":"confirmPassword","data-vv-as":"confirm password","data-vv-name":"confirmPassword","data-vv-scope":"account","placeholder":"Re-enter password","autocomplete":"new-password","data-vv-delay":"1000"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}):_vm._e(),_c('span',{staticClass:"has-icon-feedback"}),_c('div',{staticClass:"invalid-feedback",attrs:{"is-invalid":_vm.errors.has('account.confirmPassword')}},[_vm._v(" "+_vm._s(_vm.errors.first('account.confirmPassword'))+" ")])])],1),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"mb-3 d-none d-lg-block",attrs:{"role":"group"}},[_c('p',[_vm._v("Your password must not contain any blank spaces and meet the following criteria:")]),_c('ul',{staticClass:"list-icon"},[_c('li',{class:{
                            'icon-check-circle-o muted': _vm.password.length == 0,
                            'icon-check-circle valid': _vm.password.length >= 8,
                            'icon-warning invalid': _vm.password.length < 8 && _vm.password.length > 0,
                          }},[_vm._v(" 8 characters ")]),_c('li',{class:{
                            'icon-check-circle-o muted': _vm.hasLowerCase === false && _vm.hasUpperCase === false,
                            'icon-exclamation-circle warning': (!_vm.hasLowerCase && _vm.hasUpperCase) || (_vm.hasUpperCase === false && _vm.hasLowerCase),
                            'icon-check-circle valid': _vm.hasLowercaseUppercase,
                            'icon-warning invalid': _vm.hasLowercaseUppercase === false && _vm.password.length > 0,
                          }},[_vm._v(" Uppercase and lowercase letters ")]),_c('li',{class:{
                            'icon-check-circle-o muted': _vm.password.length == 0,
                            'icon-check-circle valid': _vm.hasNumber,
                            'icon-warning invalid': !_vm.hasNumber && _vm.password.length > 0,
                          }},[_vm._v(" Number(s) ")]),_c('li',{class:{
                            'icon-check-circle-o muted': _vm.password.length == 0,
                            'icon-check-circle valid': _vm.hasSpecialChar,
                            'icon-warning invalid': !_vm.hasSpecialChar && _vm.password.length > 0,
                          }},[_vm._v(" Special character(s) ")])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar passwordMeter",style:({ width: _vm.progressWidth + '%' }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.progressLabel)+" ")])])])])]),_c('b-row',[_c('div',{staticClass:"col-lg-6"}),_c('div',{staticClass:"col-lg-6 text-right mt-5"},[_c('button',{directives:[{name:"promise-btn",rawName:"v-promise-btn"}],staticClass:"btn btn-primary",attrs:{"type":"button","id":"changePassword","name":"button"},on:{"click":_vm.changePassword}},[_vm._v(" Change Password ")])])])],1)]):_vm._e(),(_vm.errorLink)?_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary pr-5 pl-5",attrs:{"name":"button"},on:{"click":function($event){return _vm.redirectToForgotPass()}}},[_vm._v("Request Another Link")])]):_vm._e(),_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"btn btn-outline-primary pr-5 pl-5",attrs:{"to":"/login","name":"button"}},[_vm._v("Back to Sign In")])],1)])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }