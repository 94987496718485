import state from './state';

const mutations = {
  setCityList(state, payload) {
    state.loading = false;
    state.cities = payload;
  },
};

Object.keys(state).forEach((key) => {
  mutations[key] = (state, value) => {
    state[key] = value;
  };
});

export default mutations;
