import $http from '../utils/http';

/*
  $http (url, data, method, header)
*/
const createConnection = (token, data) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/events/`, data, 'POST', header);
};

const updateConnection = (token, connectionID, connectionData) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/events/${connectionID}`, connectionData, 'PUT', header);
};

const setFeatured = (token, connectionID, connectionData) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/events/${connectionID}/set-featured`, connectionData, 'PUT', header);
};

const getConnectionByID = (token, connectionID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/events/${connectionID}`, null, 'GET', header);
};

const getConnectionList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/events`, null, 'GET', header);
};

const getCityListRequest = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/cities?city=All&show=false`, null, 'GET', header);
};

const getConnectionCategoryList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories/type/connection?show=true`, null, 'GET', header);
};

const getCommunityCategoryList = (token) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories/type/community?show=true`, null, 'GET', header);
};

const getSubCategoryList = (token, parentCategoryID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/categories?parent=${parentCategoryID}&show=true`, null, 'GET', header);
};

const deleteConnection = (token, connectionID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/api/events/${connectionID}`, '', 'DELETE', header);
};

export {
  createConnection,
  updateConnection,
  setFeatured,
  getConnectionByID,
  getConnectionList,
  getCityListRequest,
  getConnectionCategoryList,
  getCommunityCategoryList,
  getSubCategoryList,
  deleteConnection,
};
