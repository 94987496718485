import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate'
import Cookies from 'js-cookie';

// modules
import auth from './modules/auth';
import admin from './modules/admin';
import advertisement from './modules/advertisement';
import messages from './modules/messages';
import roles from './modules/roles';
import reports from './modules/reports';
import settings from './modules/settings';
import user from './modules/user';
import users from './modules/users';
import business from './modules/business';
import connections from './modules/connections';
import culture from './modules/culture';
import categories from './modules/categories';
import cities from './modules/cities';
import videos from './modules/videos';
import ads from './modules/ads';
import blackcard from './modules/blackcard';
import home from './modules/home';
import appconfigs from './modules/appconfigs';
import subscriptions from './modules/subscriptions';

Vue.use(Vuex);
Vue.use(Cookies);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    namespaced: true,
    auth,
    admin,
    advertisement,
    messages,
    reports,
    roles,
    settings,
    user,
    users,
    business,
    connections,
    culture,
    categories,
    cities,
    videos,
    ads,
    blackcard,
    home,
    appconfigs,
    subscriptions,
  },
  strict: false,
});

export default store;
