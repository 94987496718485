const mutations = {
  loading(state) {
    state.loading = true;
  },
  success(state) {
    state.loading = false;
    state.success = true;

    setTimeout(() => {
      state.success = false;
    }, 700);
  },
  ERROR_GET_ROLE: (state) => {
    state.loading = false;
    state.error = true;
    state.loadedOnce = false;
    state.userloadedOnce = false;
    setTimeout(() => {
      state.error = false;
    }, 700);
  },
  setErrorUser(state) {
    state.loading = false;
    state.errorUser = true;
  },
  setRoleList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.roles = payload;
  },
  setClientRoleList(state, payload) {
    state.loading = false;
    state.loadedOnce = true;
    state.clientRoles = payload;
  },
  setUserList(state, payload) {
    state.loading = false;
    state.userloadedOnce = true;
    state.userList = payload;
  },
  SET_ROLE_BY_ID: (state, payload) => {
    state.role = payload;
  },
  setUserRoleById(state, payload) {
    state.loading = false;
    state.success = true;
    state.selectedUser = payload;
  },
  RESET_DATA: (state) => {
    state.loading = false;
    state.loadedOnce = false;
    state.userloadedOnce = false;
    state.success = false;
    state.error = false;
    state.errorUser = false;
    state.roles = [];
    state.role = {};
    state.userList = [];
    state.selectedUser = {};
  },
};

export default mutations;
