import dotenv from 'dotenv';
dotenv.config();

import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
/*Template Base*/
import { BootstrapVue, BootstrapVueIcons, SpinnerPlugin } from 'bootstrap-vue';
/*Utils (Filters, Register Components, Directives)*/
import GlobalDirectives from '@/utils/directives';
import Filters from '@/utils/filters';
import RegisterComponents from '@/utils/registers';
/*Custom Components*/
import Notifications from '@/components/NotificationPlugin';
import SideBar from '@/components/SidebarPlugin';
import Drawer from '@/components/Drawer';
/*3rd Party Packages*/
import * as Cookies from 'js-cookie';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DisableAutocomplete from 'vue-disable-autocomplete';
import IdleVue from 'idle-vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import Vue2Filters from 'vue2-filters';
import VuePromiseBtn from 'vue-promise-btn';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask';
import VeeValidate from 'vee-validate';
import { VueMaskDirective } from 'v-mask';
import { Tooltip, Popover, Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option } from 'element-ui';
import { Datetime } from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';

import VueMoment from 'vue-moment';
import timezone from 'moment-timezone';

const options = { name: 'lodash' };
const veeOptions = {
  fieldsBagName: 'veeFields',
  events: 'input',
};

const eventsHub = new Vue();
const idleOptions = {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000, // 3600000 60 mins
};

// Element UI
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Dropdown);
Vue.use(Select);
Vue.use(Option);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Datetime);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(SpinnerPlugin);
Vue.use(CKEditor);
Vue.use(Cookies);
Vue.use(AirbnbStyleDatepicker);
Vue.use(DisableAutocomplete);
Vue.use(Drawer);
Vue.use(GlobalDirectives);
Vue.use(Filters);
Vue.use(IdleVue, idleOptions);
Vue.use(Notifications);
Vue.use(RegisterComponents);
Vue.use(SideBar);
Vue.use(PerfectScrollbar);
Vue.use(Vue2Filters);
Vue.use(VeeValidate, veeOptions);
Vue.use(VuePromiseBtn);
Vue.use(VueMoment, { timezone });
Vue.use(VueSweetalert2);
Vue.use(VueMask);

Vue.directive('mask', VueMaskDirective);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
});
